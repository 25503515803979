import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QRCodeModule } from 'angularx-qrcode';
import moment from 'moment';
import { WebUtils } from '../common/utils';
import { SessionService } from '../session.service';
@Component({
  selector: 'app-qrcode',
  standalone: true,
  imports: [CommonModule, QRCodeModule],
  templateUrl: './qrcode.widget.html',
})
export class QRCodeWidget {
  _common = _common;
  @HostBinding('class') css = 'max-scroll';
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) {}
  ngOnInit() {
    this.refresh();
  }

  qrcode: string;
  timer: any;
  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
  refresh() {
    WebUtils.web_result(
      this.http.get('/api/session/qrcode'),
      (result) => {
        let now = moment();
        let expire = moment.utc(result.expiredUTCAt * 1000);

        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.refresh();
        }, (expire.diff(now, 'seconds') - 60) * 1000);
        this.qrcode = result.memberCode;
      },
      this.snackBar
    );
  }
}
