<ng-container [formGroup]="container.form">
  <div></div>
  <div style="width: 300px">
    <mat-calendar
      [(selected)]="container.selected"
      (selectedChange)="container.dateUpdated()"
      [maxDate]="container.endDate"
      [minDate]="container.startDate"
    ></mat-calendar>
  </div>

  <hr />

  <div *ngIf="!container.hours?.length" class="m-8 text-center">
    {{_common.no_slot_available}}
  </div>

  <ng-container *ngIf="container.hours?.length">
    <div class="font-bold text-center mb-4">{{_common.SelectTime}}</div>
    <div class="hourSelect_dataLabel mb-4">
      <div class="dataLabel">
        <div class="labelColor"></div>
        <div>{{_common.time_available}}</div>
      </div>
      <div class="dataLabel">
        <div class="labelColor unavailable"></div>
        <div>{{_common.time_unavailable}}</div>
      </div>
      <div class="dataLabel">
        <div class="labelColor selected"></div>
        <div>{{_common.time_selected}}</div>
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div
        *ngFor="let o of container.hours; let i=index"
        class="hourChip"
        [class.selected]="
        
        i>=container.selectedIndex && i<=container.selectedIndex+(container.uiSlots/container.object.bookSlotIncrement) -1"
        [class.disabled]="!o.active"
        (click)="container.onCombinedDateSelect(o)"
      >
        {{slotText(o)}}
      </div>
    </div>
  </ng-container>
</ng-container>
