<!-- @format -->
<div class="dialog-max cdialog">
  <div class="dialogHeader">
    <h2 mat-dialog-title>Purpose</h2>
    <div class="spacer"></div>

    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="dialogBody" [formGroup]="form" >
      <app-form-field>
        <app-label>Purpose</app-label>
        <mat-select formControlName="purpose" [compareWith]="compareWith">
          <mat-option *ngFor="let o of purposes" [value]="o"
            >{{o.name}}</mat-option
          >
        </mat-select>
      </app-form-field>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <div class="spacer"></div>
    <button class="app-btn" (click)="save()" color="primary">
      {{_common.confirm}}
    </button>
  </div>
</div>
