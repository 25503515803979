import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NamePair } from '../utils';
import { FilterSelectObjects } from './list.filter';
import { NgIf, NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-filter-objects',
    templateUrl: './filter.select.objects.component.html',
    standalone: true,
    imports: [NgIf, NgFor,FontAwesomeModule]
})
export class FilterSelectObjectsComponent implements OnInit {
   faTimes = faTimes

   _common = _common

   @Input()
   filter = new FilterSelectObjects();


   @Output()
   select = new EventEmitter<FilterSelectObjects>();
   @Output()
   change = new EventEmitter<void>();



   @Input()
   visible = true


   ngOnInit() {
   }




   refresh() {
      this.change.next(void 0);
   }
   remove(c: NamePair, event: Event) {
      this.filter.remove(c, event)
      this.refresh();

   }
   clear(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()

      this.filter.clear();
      this.refresh();
   }
   _select() {
      this.select.next(this.filter)
   }
}
