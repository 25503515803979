import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { SessionService } from '../session.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './profile.page.html',
})
export class ProfilePage {
  @HostBinding('class') css = 'max-scroll';
  constructor(public session: SessionService) {}
  ngOnInit() {}
}
