<!-- @format -->

<div class="cdialog">
  <div class="dialogHeader">
    <div mat-dialog-title>Add Content</div>

    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-scroll dialogBody">
      <div class="card">
        <div class="cardHeader">{{_common.images}}</div>
        <div class="cardBody">
          <app-image-list
            [multiple]="true"
            [imageContainer]="container"
          ></app-image-list>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
