/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import { AppValidators } from '../../common/validators';
import {
  AccessUtils,
  TLocation,
  TOrganization,
  TSpace,
  TSpaceFacility,
  TSpacePurpose,
  TSpaceType,
} from '../../core/entities';
import { SiteEditUtils } from '../../core/lang';
import { AppPermissions } from '../../core/permissions';
import { SessionCache } from '../../session.cache';
import { CONTENT_PATH, SessionService, TIMEZONE } from '../../session.service';
import { TScheduleCalendar } from '../../thinkdesks/thinkdesks';
import { ImageUploadBase } from '../../thinkdesks/widgets/image.upload.base';
import { ObjectImageListComponent } from '../../thinkdesks/widgets/object.image.list';
import { LImageUploadContainer } from '../../thinkdesks/widgets/upload.container';

@Component({
  templateUrl: './space.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    CKEditorModule,
    ObjectImageListComponent,
  ],
})
export class SpaceEditDialogComponent extends CompareWithMixin(EmptyObject) {
  permissions = AppPermissions;
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TSpace;

  api = '/api/space';

  organizations: TOrganization[] = [];
  locations: TLocation[] = [];
  schedules: TScheduleCalendar[] = [];
  spaceTypes: TSpaceType[] = [];
  spacePurposes: TSpacePurpose[] = [];
  spaceFacilities: TSpaceFacility[] = [];

  public Editor = ClassicEditor;

  container = new LImageUploadContainer();
  dlangs = SiteEditUtils.OTHER_LANGS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SpaceEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;

    this.cache.loadOrganization(this.session, (list) => {
      this.organizations = list;
    });
    this.cache.loadSpacePurposes((list) => {
      this.spacePurposes = list;
    });

    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      name_lang: SiteEditUtils.langArray(this.fb),

      code: [''],
      active: [''],
      autoApprove: [''],
      approved: [''],
      locked: [''],
      description: [''],
      description_lang: SiteEditUtils.langArray(this.fb),

      textDescription: [''],
      textDescription_lang: SiteEditUtils.langArray(this.fb),

      organization: ['', Validators.required],
      location: ['', Validators.required],

      thinkdesksSpaceCode: [''],

      bookSchedule: [],
      bookDays: [14, [Validators.required, AppValidators.above(0)]],
      bookMinSlots: ['', Validators.required],
      bookMaxSlots: ['', Validators.required],
      bookSlotIncrement: ['', Validators.required],
      facilities: this.fb.array([]),
      includedFacilities: [''],
      includedFacilities_lang: SiteEditUtils.langArray(this.fb),

      types: this.fb.array([]),
      space: [''],
      capacity: [''],
      area: [''],
      remarks: [''],
      remarks_lang: SiteEditUtils.langArray(this.fb),
      purpose: ['', Validators.required],

      terms: [''],
      terms_lang: SiteEditUtils.langArray(this.fb),
    });
    this.form.controls['thinkdesksSpaceCode'].disable();
  }

  get formFacilities() {
    return this.form.controls['facilities'] as FormArray;
  }
  get formTypes() {
    return this.form.controls['types'] as FormArray;
  }

  updateLocation2(location: TLocation, cb?: () => void) {
    let ff = this.formFacilities;
    ff.clear();
    this.schedules = [];

    if (!location) return;
    this.cache.loadScheduleCalendar(location.id, (list) => {
      this.schedules = list;
    });
    this.cache.loadSpaceFacilities(location.id, (list) => {
      this.spaceFacilities = list;

      for (let t of this.spaceFacilities) {
        ff.push(new FormControl(false));
      }
      if (cb) cb();
    });
  }
  ngOnInit(): void {
    this.cache.loadSpaceTypes((list) => {
      this.spaceTypes = list;
      let ff = this.formTypes;
      ff.clear();
      for (let t of this.spaceTypes) {
        ff.push(new FormControl(false));
      }

      if (this.objectId) {
        WebUtils.web_result(
          this.http.get(`${this.api}/${this.objectId}`),
          (result) => {
            this.object = result as TSpace;

            AccessUtils.fixSpace(this.object);

            let images = this.object.images ?? [];
            this.container.load(
              {
                numImages: images.length,
                imageIds: images.map((c) => null),
                names: images,
              },
              (index, imageId) => {
                return `${CONTENT_PATH}/space/${this.object.id}/${images[index]}`;
              }
            );

            let query = Object.assign({}, this.object) as any;

            query.organization = query.location?.organization;

            utils.fill_form_values(query, this.form);

            this.updateForm();

            this.updateLocation();

            this.updateLocation2(query.location, () => {
              this.spaceFacilities.forEach((c, index) => {
                let cc = this.formFacilities.controls[index];
                cc.setValue(!!this.object.facilities.find((p) => p.id == c.id));
              });
            });
            this.spaceTypes.forEach((c, index) => {
              let cc = this.formTypes.controls[index];
              cc.setValue(!!this.object.types.find((p) => p.id == c.id));
            });
            this.updateKey();
          }
        );
      } else {
        let query = {
          timezone: TIMEZONE,
          organization: this.data?.organization,
          location: this.data?.location,
          active: true,
          autoApprove: true,
          bookDays: 14,
          bookSlotMins: 1,
          bookMinSlots: 1,
          bookMaxSlots: 16,
          bookSlotIncrement: 1,
        } as any;

        utils.fill_form_values(query, this.form);

        this.updateForm();

        this.updateLocation();
      }
    });
  }

  key = {};
  subkey = {};
  updateKey() {
    this.subkey = null;

    if (this.object.bookSchedule) {
      this.key = {
        calendar: WebUtils.objName(this.object.bookSchedule),
      };
      this.subkey = {
        zone: WebUtils.objName(this.object),
      };
    } else {
      this.key = {
        zone: WebUtils.objName(this.object),
      };
    }
  }
  updateLocation() {
    let location =
      (this.object?.location as TLocation) ?? this.form.value.location;
    let organization = this.form.value.organization ?? location?.organization;
    this.locations = [];
    this.schedules = [];
    if (organization) {
      this.cache.loadLocation(organization.id, (list) => {
        this.locations = list;
      });
    }
    if (location) {
      this.updateLocation2(location as TLocation);
    }
  }

  updateForm() {
    if (!this.session.hasPermission(AppPermissions.SPACE_EDIT)) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    if (this.objectId) {
      this.form.controls['organization'].disable();
      this.form.controls['location'].disable();
    }
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let formData = new FormData();

    let query = Object.assign({}, this.form.value);
    utils.form_clean_numbers(query, ['capacity', 'area']);
    query.images = ImageUploadBase.getFile(formData, 'images', this.container);
    SiteEditUtils.langValues(query, 'name');
    SiteEditUtils.langValues(query, 'description');
    SiteEditUtils.langValues(query, 'remarks');
    SiteEditUtils.langValues(query, 'textDescription');
    SiteEditUtils.langValues(query, 'includedFacilities');
    SiteEditUtils.langValues(query, 'terms');

    query.type = WebUtils.objId(query.type);
    query.bookSchedule = WebUtils.objName(query.bookSchedule);

    query.facilities = this.spaceFacilities
      .filter((c, index) => query.facilities[index])
      .map((c) => WebUtils.objName(c));
    query.types = this.spaceTypes
      .filter((c, index) => query.types[index])
      .map((c) => WebUtils.objName(c));

    utils.form_clean_numbers(query, ['lat', 'lng']);
    formData.append('data', JSON.stringify(query));

    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, formData),
        async (result) => {
          this.object = result as TSpace;
          AccessUtils.fixSpace(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, formData),
        async (result) => {
          this.object = result as TSpace;
          AccessUtils.fixSpace(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      },
      this.snackBar
    );
  }
}
