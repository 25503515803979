<div class="client_header_desktop nav">
  <div
    class="font-bold text-2xl cursor-pointer"
    [routerLink]="[session.path('/home')]"
  >
    <img src="/silone/silone_friends_tgt.png" style="height: 50px" />
  </div>
  <div class="spacer"></div>

  <ng-container *ngIf="session.user">
    <a [routerLink]="[session.path('/home')]" class="nav-item">
      <!--<fa-icon icon="house" class="menu-icon"></fa-icon>-->
      {{ _common.Home }}
    </a>
    <a [routerLink]="[session.path('/space')]" class="nav-item">{{
      _common.SpaceBooking
    }}</a>
    <a [routerLink]="[session.path('/my/booking')]" class="nav-item">{{
      _common.MyBookings
    }}</a>
    <!--<a [routerLink]="[session.path('/my/profile')]" class="nav-item">My Profile</a>-->
  </ng-container>

  <ng-container *ngIf="session.hasPermission(permissions.LOCATION_MANAGE)">
    <div>│</div>
    <div [matMenuTriggerFor]="staffMenu" class="nav-item">
      <div>{{ _common.Manage }}</div>
      <fa-icon icon="caret-down" class="ml-1"></fa-icon>
    </div>
    <mat-menu #staffMenu="matMenu" class="user-dropDown">
      <div [routerLink]="[session.path('/manage/location')]">
        {{ _common.MyCenters }}
      </div>
      <div [routerLink]="[session.path('/manage/space')]">
        {{ _common.MySpaces }}
      </div>
      <div [routerLink]="[session.path('/manage/booking')]">
        {{ _common.BookingManagement }}
      </div>
      <div [routerLink]="[session.path('/manage/device')]">
        {{_common.locks_control}}
      </div>
    </mat-menu>
  </ng-container>
  <div class="spacer"></div>

  <div class="flex gap-2">
    <ng-container *ngIf="session.user; let user">
      <div
        [matMenuTriggerFor]="UserMenu"
        class="flex cursor-pointer"
        style="
          border: 1px solid black;
          padding: 8px 12px 8px 20px;
          border-radius: 50px;
        "
      >
        <div>Hello, {{ user.name }}!</div>
        <div style="width: 1.5rem" class="flex justify-center">
          <fa-icon icon="caret-down"></fa-icon>
        </div>
      </div>
      <mat-menu #UserMenu="matMenu">
        <div [routerLink]="[session.path('/my/profile')]">
          {{ _common.Profile }}
        </div>
        <div [routerLink]="[session.path('/my/wishlist')]">
          {{ _common.favourites_space }}
        </div>
        <div (click)="logout()">{{ _common.logout }}</div>
      </mat-menu>
    </ng-container>
    <ng-container *ngIf="!session.user">
      <a (click)="login()" class="cursor-pointer">
        <div
          style="
            background: black;
            color: white;
            padding: 8px 20px;
            border-radius: 50px;
          "
        >
          <fa-icon icon="user" class="mr-2"></fa-icon>
          {{ _common.login }}
        </div>
      </a>
    </ng-container>

    <div class="client_button btn_circle" [matMenuTriggerFor]="Language">
      <fa-icon icon="globe"></fa-icon>
    </div>
    <mat-menu #Language="matMenu">
      <div (click)="changeLocale('zh_hk')">繁中</div>
      <div (click)="changeLocale('zh_cn')">简中</div>
      <div (click)="changeLocale('en')">ENG</div>
    </mat-menu>
  </div>

  <!--<div>
    <span
      class="people-avatar"
      [style.backgroundColor]="peopleColor(session.user)"
      >{{ peopleShortName(session.user) }}</span
    >
  </div>-->
</div>
<div class="client_header_mobile">
  <div class="flex items-center">
    <div class="client_button btn_circle" (click)="drawerOpened = true">
      <fa-icon icon="bars"></fa-icon>
    </div>
  </div>

  <div
    class="font-bold text-2xl cursor-pointer"
    [routerLink]="[session.path('/home')]"
  >
    <img
      src="/silone/silone_friends_tgt.png"
      style="height: 50px; object-fit: contain"
    />
  </div>
  <ng-container *ngIf="!session.user">
    <a (click)="login()" class="cursor-pointer flex items-center justify-end">
      <div>
        <fa-icon icon="arrow-right-to-bracket" class="mr-2"></fa-icon>
        {{ _common.login }}
      </div>
    </a>
  </ng-container>
</div>
<div class="max-height" style="background: #f9f9f9">
  <router-outlet></router-outlet>
</div>

<!--Mobile Side Menu-->
<div class="client_nav_mobile_side" *ngIf="drawerOpened">
  <div>
    <div class="flex justify-end">
      <div (click)="drawerOpened = false">
        <fa-icon icon="xmark" class="text-2xl p-4"></fa-icon>
      </div>
    </div>
  </div>

  <div class="side_nav_container" (click)="drawerOpened = false">
    <ng-container *ngIf="session.user; let user">
      <div class="font-bold text-lg mb-8">Hello, {{ user.name }}!</div>
      <div [routerLink]="[session.path('/my/wishlist')]">
        {{ _common.favourites_space }}
      </div>
    </ng-container>

    <ng-container *ngIf="session.hasPermission(permissions.LOCATION_MANAGE)">
      <hr />
      <div class="nav_subtitle">{{ _common.Manage }}</div>
      <div [routerLink]="[session.path('/manage/location')]" class="nav-item">
        {{ _common.MyCenters }}
      </div>
      <div [routerLink]="[session.path('/manage/space')]" class="nav-item">
        {{ _common.MySpaces }}
      </div>
      <div [routerLink]="[session.path('/manage/booking')]" class="nav-item">
        {{ _common.BookingManagement }}
      </div>
    </ng-container>
    <ng-container *ngIf="session.user; let user">
      <hr />
      <div (click)="logout()">{{ _common.logout }}</div>
    </ng-container>

    <div class="flex space-x-2 mt-8">
      <fa-icon icon="globe"></fa-icon>
      <div (click)="changeLocale('zh_hk')">繁中</div>
      <div (click)="changeLocale('zh_cn')">简中</div>
      <div (click)="changeLocale('en')">ENG</div>
    </div>
  </div>
</div>

<!--Mobile Bottom Menu-->
<div *ngIf="session.user" class="client_nav_mobile">
  <a [routerLink]="[session.path('/home')]">
    <div class="nav_item">
      <div>
        <fa-icon icon="house" class="menu-icon"></fa-icon>
      </div>
      <div class="text-xs">{{ _common.Home }}</div>
    </div>
  </a>
  <a [routerLink]="[session.path('/space')]">
    <div class="nav_item">
      <div>
        <fa-icon icon="thumbtack" class="menu-icon"></fa-icon>
      </div>
      <div class="text-xs">{{ _common.SpaceBookings }}</div>
    </div>
  </a>
  <a [routerLink]="[session.path('/my/booking')]">
    <div class="nav_item">
      <div>
        <fa-icon icon="calendar-days" class="menu-icon"></fa-icon>
      </div>
      <div class="text-xs">{{ _common.MyBookings }}</div>
    </div>
  </a>
  <a [routerLink]="[session.path('/my/profile')]">
    <div class="nav_item">
      <div>
        <fa-icon icon="user" class="user"></fa-icon>
      </div>
      <div class="text-xs">{{ _common.Profile }}</div>
    </div>
  </a>
</div>

<app-loader></app-loader>
