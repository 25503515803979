<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab [label]="_common.Events">
        <div class="max-scroll dialogBody">
          <form [formGroup]="form">
            <div class="card">
              <!-- <div class="cardHeader">Settings</div> -->
              <div class="cardBody space-y-2">
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.Location}}</app-label>
                    <mat-select
                      formControlName="location"
                      [compareWith]="compareWith"
                      (selectionChange)="updateSpace()"
                    >
                      <mat-option *ngFor="let o of locations" [value]="o"
                        >{{o.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.Event}}</app-label>
                    <mat-select
                      formControlName="location"
                      [compareWith]="compareWith"
                    >
                      <mat-option *ngFor="let o of spaces" [value]="o"
                        >{{o.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.code}}</app-label>
                    <input matInput formControlName="code" required />
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.name}}</app-label>
                    <input matInput formControlName="name" required />
                  </app-form-field>
                </div>
                <div>
                  <app-form-field>
                    <app-label>{{_common.date}}</app-label>
                    <input
                      matInput
                      [matDatepicker]="startTime"
                      formControlName="startTime"
                    />

                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="startTime"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startTime></mat-datepicker>
                  </app-form-field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
