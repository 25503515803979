import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  MatDateRangePicker,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { date_utils } from "../date_utils";
import { RangeType } from "./filter.date.range";
import { FilterDateRange } from "./list.filter";
import { NgIf } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";

@Component({
  selector: "app-filter-date-range-button",
  templateUrl: "./filter.date.range.button.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatMenuModule,
    MatRadioModule,
    FormsModule,
    MatDatepickerModule,
    NgIf,
  ],
})
export class FilterDateRangButtonComponent {
  _common = _common;

  @Input()
  label: string = _common.date;
  @Input()
  dateRange = new FilterDateRange();

  @Output()
  update = new EventEmitter<void>();

  @Input()
  future = false;

  @Input()
  showRange = false;
  @Input()
  allowAll = true;
  constructor() {}

  refresh() {
    this.update.next(void 0);
  }
  @ViewChild("_range") _range: MatDateRangePicker<moment.Moment>;
  @ViewChild("trigger") dateMenu: MatMenuTrigger;
  updateType() {
    this.dateMenu.closeMenu();
    this.dateRange.update();
    this.refresh();
    if (this.dateRange.range.type == RangeType.custom) {
      setTimeout(() => {
        this._range.open();
      }, 100);
    }
  }
  formatDateRange(start: moment.Moment | null, end: moment.Moment | null) {
    return date_utils.buildSmartRange(start, end);
  }
}
