import { date_utils } from '../common/date_utils';
import { TBooking } from '../core/entities';
import { SessionService } from '../session.service';
import { Thinkdesks } from './thinkdesks';
type Constructor<T = {}> = new (...args: any[]) => T;
export function BookingMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }
    session: SessionService;
    dateLines(object: TBooking) {
      var date = object.startTime;
      var endDate = object.endTime!;

      var _date = date.clone().startOf('day');
      var _endDate = endDate.clone().startOf('day');
      var duration = endDate.diff(date, 'minutes');

      var timeFormat = 'HH:mm';
      if (_date.isSame(_endDate)) {
        return [
          `${date.format('YYYY MMM,DD ddd')}`,
          `${date.format(timeFormat)} - ${endDate.format(
            timeFormat
          )} (${date_utils.minText(duration)})`,
        ];
      } else {
        var format1 = 'YYYY MMM,DD ddd';
        var format2 = 'MMM,DD ddd';
        return [
          `${date.format(format1)} ${date.format(timeFormat)}`,
          `${endDate.format(format2)} ${endDate.format(timeFormat)}`,
          `(${date_utils.minText(duration)})`,
        ];
      }
    }
    dateText(object: TBooking) {
      let lang = this.session?.lang ?? 'zh_hk';
      return Thinkdesks.dateFormat(object.startTime, lang);
    }
    timeText(object: TBooking, common?: any) {
      let mins = object.endTime.diff(object.startTime, 'minutes');
      return `${object.startTime.format(
        Thinkdesks.timeFormat
      )}-${object.endTime.format(Thinkdesks.timeFormat)} (${date_utils.minText(
        mins,
        common
      )})`;
    }
  };
}
