import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { VizFormModule } from '../common/components/form/form.module';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../common/list.component.base';
import { CompareWithMixin, WebUtils } from '../common/utils';
import { AccessUtils, TLocation, TOrganization } from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { LocationCard } from './location.card';

class _Base extends ScrollableListComponentBase<TLocation> {}

@Component({
  selector: 'app-location-search-list',
  templateUrl: './location.search.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
    LocationCard,
    VizFormModule,
    FormsModule,
    FilterMultiComponent,
  ],
})
export class LocationSearchListComponent extends CompareWithMixin(
  SitePathMixin(SiteFormatMixin(_Base))
) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();

  // district = new FilterMulti();

  displayedColumns: string[] = ['name', 'organization', 'location', 'controls'];
  ALL = { id: 0 } as any;

  area: string = '';
  district: string = '';

  organization: TOrganization = this.ALL;

  organizations: TOrganization[] = [];
  locations: TLocation[] = [];

  clear() {
    
    this.filterObject.clear();
    this.organization = this.ALL;
    this.area = '';
    this.district = '';
    this.refresh();
  }
  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    private cache: SessionCache,
    private session: SessionService,
    private route: ActivatedRoute
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';

    // this.pageSortDir = 'asc';

    // this.filterObject.add('organizations', this.forganization);
    // this.filterObject.add('district', this.district);

    this.cache.loadOrganization(this.session, (list) => {
      this.organizations = list;
    });
  }
  updateArea() {
    this.district = '';
    this.refresh();
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      organization: WebUtils.objId(this.organization),
      area: this.area != '' ? this.area : null,
      district: this.district != '' ? this.district : null,
    } as any;
    Object.assign(query, this.filterObject.query());

    const url = this._listModifyUrl(`/api/public/location/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    this.filter = this.route.snapshot.queryParamMap.get('search') ?? '';
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TLocation[]) {
    for (let o of list) AccessUtils.fixLocation(o);
  }

  override selectObject(o: TLocation) {}
}
