import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root",
})
export class LoaderInterceptor implements HttpInterceptor {
  static excludes: string[] = [];

  constructor(private loaderService: LoaderService) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let excluded = false;

    if (LoaderInterceptor.excludes.length) {
      let path = req.url;
      if (req.url.startsWith("http")) {
        var url = new URL(req.url);
        path = url.pathname;
      }
      excluded = LoaderInterceptor.excludes.includes(path);
    }
    if (!excluded) this.showLoader();

    const res = next.handle(req);
    return res.pipe(
      finalize(() => {
        if (!excluded) this.hideLoader();
      })
    );
  }
  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
