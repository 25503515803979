<div class="dialog-max cdialog"
  style="max-width: 500px;">
  <div class="dialogHeader">
    <h2 mat-dialog-title>{{title}}</h2>
  </div>
  <mat-dialog-content>
    <div style="max-height: 300px" class="dialogBody">
      <div *ngFor="let c of content">{{c}}</div>
    </div>
  </mat-dialog-content>
  <div align="end" class="dialogBar">
    <button class="app-btn btn-text" (click)="cancel()">
      {{_common?.close ?? 'Close'}}
    </button>
  </div>
</div>
