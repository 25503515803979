/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { MatSelectModule } from '@angular/material/select';
import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';

import { SessionCache } from '../../session.cache';
import { SessionService } from '../../session.service';
import {
  THolidayCalendar,
  TScheduleCalendar,
  Thinkdesks,
} from '../../thinkdesks/thinkdesks';

@Component({
  templateUrl: './schedule.calendar.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSelectModule,
  ],
})
export class ScheduleCalendarEditDialogComponent extends CompareWithMixin(
  EmptyObject
) {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TScheduleCalendar;

  api = '/api/schedule-calendar';
  globalCalendars: THolidayCalendar[] = [];

  query = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    private cache: SessionCache,
    private fb: UntypedFormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,

    public dialogRef: MatDialogRef<ScheduleCalendarEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;
    this.query = data.query;

    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      calendar: [null],
      globalCalendar: [null],
    });
    cache.loadHolidayGlobal((list) => {
      this.globalCalendars = list;
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TScheduleCalendar;

          Thinkdesks.fixScheduleCalendar(this.object);

          utils.fill_form_values(this.object, this.form);

          this.updateForm();
        }
      );
    }
  }

  updateForm() {
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);

    query.calendar = WebUtils.objName(query.calendar);
    query.globalCalendar = WebUtils.objName(query.globalCalendar);
    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, query),
        async (result) => {
          this.object = result as TScheduleCalendar;
          Thinkdesks.fixScheduleCalendar(this.object);
          this.dialogRef.close(this.object);
        }
      );
    } else {
      query = Object.assign({}, this.query, query);
      WebUtils.web_result(
        this.http.post(`${this.api}`, query),
        async (result) => {
          this.object = result as TScheduleCalendar;
          Thinkdesks.fixScheduleCalendar(this.object);
          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
}
