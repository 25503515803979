<div
  *ngIf="visible"
  (click)="_select()"
  style="display: flex; align-items: center"
>
  <ng-container *ngIf="filter.v">
    <div>
      {{ filter.v?.name }}
    </div>
    <div style="margin-left: 10px">
      <fa-icon [icon]="faTimes" (click)="clear($event)"></fa-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="!filter.v">
    <ng-content></ng-content>
  </ng-container>
</div>
