<ng-container *ngIf="visible && filter.visible">
   <div [matMenuTriggerFor]="typeMenu">

      <ng-content></ng-content>

   </div>

   <mat-menu #typeMenu="matMenu">
      <div (click)="$event.stopPropagation()" class="ctlFilter">
         <mat-radio-group [(ngModel)]="filter.v">
            <div mat-menu-item *ngFor="let v of values; let i=index">
               <mat-radio-button (change)="filter.update(v,$event); refresh();" [value]="v">

               </mat-radio-button>



               {{names[i]}}


            </div>

            <div class="bottom">
               <div class="button" (click)="filter.clear(); refresh()">{{_common.clear}}</div>
            </div>
         </mat-radio-group>
      </div>
   </mat-menu>
</ng-container>