import { AnimationBuilder } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import {
  GoogleMapsModule,
  MapInfoWindow,
  MapMarker,
} from '@angular/google-maps';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from '../common/utils';
import { AccessUtils, TLocation } from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionService } from '../session.service';
import { MapService } from './map.service';

@Component({
  selector: 'app-map-widget',
  templateUrl: './map.widget.html',
  imports: [CommonModule, RouterLink, GoogleMapsModule, FontAwesomeModule],
  standalone: true,
})
export class MapWidget extends SitePathMixin(EmptyObject) {
  @ViewChild('map') map: ElementRef<HTMLDivElement>;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  _common = _common;

  subscription2: Subscription;
  @HostBinding('class') css = '';

  constructor(
    private builder: AnimationBuilder,
    public session:SessionService,
    private router: Router,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public mapService: MapService
  ) {
    super();
  }

  subscription: Subscription;
  ngOnInit() {
    this.load();
  }

  hk = { lat: 22.381581, lng: 114.133992 };
  hkOptions = {
    title: 'WVHK',
    // icon: {
    //   url: 'assets/wvhk_icon.jpg',
    //   scaledSize: new google.maps.Size(30, 30)
    // }
  } as any;

  locations: any[] = [];
  ngOnDestroy() {
    this.subscription2?.unsubscribe();
  }

  async load() {
    await this.mapService.loadMap();
    this.refresh();
  }
  refresh() {
    WebUtils.web_list(this.http.post('/api/session/location', {}), (list) => {
      this.locations = [];
      for (let oo of list) {
        let o = oo as TLocation;
        AccessUtils.fixLocation(o);
        this.locations.push({
          position: {
            lat: o.lat,
            lng: o.lng,
          },
          options: {
            title: o.name,
          },
          location: o,
        });
      }
    });
  }

  click(marker, p: any) {
    // this.openInfoWindow(marker);
    this.router.navigate([`/location/${p.location.id}`]);
  }
  selected: any;
  openInfoWindow(marker: MapMarker, p) {
    this.selected = p;

    this.infoWindow.open(marker);
  }
}
