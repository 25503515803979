import { Pipe, PipeTransform } from "@angular/core";

export function isDefined(value: any): boolean {
   return typeof value !== 'undefined' && value !== null;
}
@Pipe({
    name: 'translate',
    standalone: true
})
export class TranslatePipe implements PipeTransform {
   transform(value: string, params = {}): string {
      return this.interpolate(value, params)

   }

   templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;

   public interpolate(expr: string | Function, params?: any): string {
      let result: string;

      if (typeof expr === 'string') {
         result = this.interpolateString(expr, params);
      } else if (typeof expr === 'function') {
         result = this.interpolateFunction(expr, params);
      } else {
         // this should not happen, but an unrelated TranslateService test depends on it
         result = expr as string;
      }

      return result;
   }

   getValue(target: any, key: string): any {
      let keys = typeof key === 'string' ? key.split('.') : [key];
      key = '';
      do {
         key += keys.shift();
         if (isDefined(target) && isDefined(target[key]) && (typeof target[key] === 'object' || !keys.length)) {
            target = target[key];
            key = '';
         } else if (!keys.length) {
            target = undefined;
         } else {
            key += '.';
         }
      } while (keys.length);

      return target;
   }

   private interpolateFunction(fn: Function, params?: any) {
      return fn(params);
   }

   private interpolateString(expr: string, params?: any) {
      if (!params) {
         return expr;
      }

      return expr.replace(this.templateMatcher, (substring: string, b: string) => {
         let r = this.getValue(params, b);
         return isDefined(r) ? r : substring;
      });
   }
}