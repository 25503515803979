/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VizFormModule } from '../../common/components/form/form.module';
import { CompareWithMixin, EmptyObject, WebUtils } from '../../common/utils';
import { AccessUtils, TLocation } from '../../core/entities';
import { SessionCache } from '../../session.cache';
import { SessionService } from '../../session.service';
import { TAgent } from '../../thinkdesks/thinkdesks.entities';
import { ImageUploadBase } from '../../thinkdesks/widgets/image.upload.base';
import { ObjectImageListComponent } from '../../thinkdesks/widgets/object.image.list';
import { LImageUploadContainer } from '../../thinkdesks/widgets/upload.container';
@Component({
  templateUrl: './agent.content.add.dialog.html',
  standalone: true,
  imports: [VizFormModule, ObjectImageListComponent, MatDialogModule],
})
export class AgentContentAddDialogComponent extends CompareWithMixin(
  EmptyObject
) {
  _common = _common;

  container = new LImageUploadContainer();

  api = '/api/agent-content';

  location: TLocation;
  agent: TAgent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgentContentAddDialogComponent>
  ) {
    super();

    this.location = data.location;
    this.agent = data.agent;
  }

  ngOnInit(): void {}

  save() {
    let formData = new FormData();
    let query = {
      location: WebUtils.objName(this.location),
      agent: WebUtils.objId(this.agent),
    } as any;

    query.images = ImageUploadBase.getFile(formData, 'images', this.container, {
      noMold: true,
    });

    formData.append('data', JSON.stringify(query));

    WebUtils.web_result(
      this.http.post(`${this.api}`, formData),
      async (result) => {
        for (let o of result) {
          AccessUtils.fixAgentContent(o);
        }

        this.dialogRef.close(result);
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
