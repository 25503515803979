<!--<div class="text-3xl">Recommended Spaces</div>-->

<app-carousel>
  <div class="flex gap-2">
    <div *ngFor="let location of list">
      <app-location-card
        
        [location]="location"
      ></app-location-card>
    </div>
  </div>
</app-carousel>
