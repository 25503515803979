import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import moment from 'moment';
import { EmptyObject, WebUtils } from './common/utils';
import { AccessUtils, TBooking } from './core/entities';
import { SiteLangMixin } from './core/lang';
import { SitePathMixin } from './core/site.path.utils';
import { SessionCache } from './session.cache';
import { SessionService } from './session.service';
import { BookingMixin } from './thinkdesks/booking.mixin';
import { TBookingStatus } from './thinkdesks/thinkdesks.entities';
import { CarouselWidget } from './user/carousel';
import { SpaceCard } from './user/space.card';

@Component({
  selector: 'app-upcoming-booking-list',

  standalone: true,
  imports: [
    CommonModule,
    SpaceCard,
    RouterLink,
    FontAwesomeModule,
    CarouselWidget,
  ],
  templateUrl: './upcoming.booking.list.page.html',
})
export class UpcomingBookingListPage extends 
SiteLangMixin(
BookingMixin(SitePathMixin(EmptyObject))) {
  _common = _common;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    public override session: SessionService,
    private cache: SessionCache
  ) {
    super();
  }
  ngOnInit() {
    this.refresh();
  }

  list: TBooking[] = [];

  refresh() {
    WebUtils.web_list(
      this.http.post(`/api/public/booking/list?limit=10`, {
        start: moment(),
        status:[
          TBookingStatus.approved,
          TBookingStatus.pending,
        ]
      }),
      (result) => {
        let list = result as TBooking[];

        for (let o of list) AccessUtils.fixBooking(o);
        this.list = list;
      },
      null
    );
  }
}
