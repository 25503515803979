import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDatepicker,
  MatDatepickerModule
} from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import moment from "moment";

let prevDate: moment.Moment | null = null;

@Component({
  selector: "app-month-selector",
  templateUrl: "./month.selector.component.html",
  standalone: true,
  imports: [
    FontAwesomeModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class MonthSelectorComponent implements OnInit {
  @Input() date = moment();
  @Output() dateChange = new EventEmitter<moment.Moment>();
  title: string;

  focus() {}
  ngOnInit() {
    if (!this.date) {
      this.date = prevDate ?? moment();
    }
    this.date = this.date
      .clone()
      .date(1)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    this.refresh(true);
  }
  goNext() {
    const d = this.date;
    this.date = d.clone().date(1).add(1, "month");
    this.refresh();
  }
  goNextYear() {
    const d = this.date;
    this.date = d.clone().date(1).add(1, "year");
    this.refresh();
  }
  goPrev() {
    const d = this.date;
    this.date = d.clone().date(1).add(-1, "month");
    this.refresh();
  }
  goPrevYear() {
    const d = this.date;
    this.date = d.clone().date(1).add(-1, "year");
    this.refresh();
  }
  refresh(notfire?: boolean) {
    prevDate = this.date.clone();
    this.title = this.date.format("YYYY MMM");
    if (!notfire) this.dateChange.emit(this.date);
  }
  open(datepicker: MatDatepicker<moment.Moment>) {
    datepicker.open();
  }
  chosenMonthHandler(
    normlizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    // const ctrlValue = this.date.value;
    // ctrlValue.month(normlizedMonth.month());
    // this.date.setValue(ctrlValue);
    this.date = normlizedMonth;
    this.refresh();
    datepicker.close();
  }
}
