import { NgFor, NgIf } from '@angular/common';
import { Component, Injectable, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CGroup, CItem, CRow, CTable, CTableData } from './ctable';

@Injectable()
export class CTableComponentBase implements OnInit {

  @Input()
  table: CTable<any, any>;

  dialog: MatDialog

  ngOnInit() {
    this.init();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['table'])
      this.init();
  }

  init() {
    if (this.table)
      this.table.init();

  }


  edit(value: CTableData<any>) {

  }
  shouldCreate(g: CGroup<any, CItem<any>>, row: CRow<any>) {
    if (row.index) return false;
    let index = row._groups.indexOf(g);
    index++;
    while (index < row._groups.length) {
      if (row._groups[index++].index) return false;
    }
    return true;

  }
}


@Component({
    selector: 'app-ctable-display',
    templateUrl: './ctable.component.html',
    standalone: true,
    imports: [NgIf, NgFor, FontAwesomeModule]
})
export class CTableDisplayComponent extends CTableComponentBase implements OnInit {

  @Input()
  override table: CTable<any, any>;


  constructor(
    override dialog: MatDialog,
  ) {
    super();

  }

}
