import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmptyObject } from '../common/utils';
import { TLocation } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SitePathMixin } from '../core/site.path.utils';
import { calculateDistance } from '../distance';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-location-card',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, FontAwesomeModule],
  templateUrl: './location.card.html',
})
export class LocationCard extends SiteLangMixin(SitePathMixin(EmptyObject)) {
  @Input()
  location: TLocation;
  _common = _common;

  @Input()
  link = true;
  constructor(public override session: SessionService) {
    super();
  }
  hasDistance = false;

  distance: number;

  ngOnInit() {
    let location = this.session.locationStream.value;
    if (location) {
      this.distance = calculateDistance(
        location.lat,
        location.lng,
        this.location.lat,
        this.location.lng
      );
      this.distance = Math.round(this.distance * 10) / 10;
      this.hasDistance = true;
    }
  }
}
