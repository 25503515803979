import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmptyObject } from './common/utils';
import { TLocation } from './core/entities';
import { SitePathMixin } from './core/site.path.utils';
import { calculateDistance } from './distance';
import { SessionCache } from './session.cache';
import { SessionService } from './session.service';
import { CarouselWidget } from './user/carousel';
import { LocationCard } from './user/location.card';
@Component({
  selector: 'app-nearby-location-list',

  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule,
    LocationCard,
    RouterLink,
    CarouselWidget,
  ],
  templateUrl: './nearby.location.list.html',
})
export class NearbyLocationListPage extends SitePathMixin(EmptyObject) {
  susbcription: Subscription;

  list: TLocation[] = [];
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private session: SessionService,
    private cache: SessionCache
  ) {
    super();
  }
  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.cache.loadAllLocation((list) => {
      this.list = list.slice();

      let location = this.session.locationStream.value;
      for (let oo of list) {
        oo._distance = calculateDistance(
          location.lat,
          location.lng,
          oo.lat,
          oo.lng
        );
      }
      this.list.sort((a, b) => a._distance - b._distance);
    });
  }
}
