import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  keyframes,
  style,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FixedCarouselItemDirective } from './fixed.carousel.item.directive';

@Directive({
  selector: '.carousel-item',
})
export class FixedCarouselItemElement {}

@Component({
  selector: 'fixed-carousel',
  exportAs: 'siteCarousel',
  templateUrl: './fixed.carousel.component.html',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  styles: [
    `
      .carousel-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .tt_carousel_button {
        position: absolute;
        top: calc(50% - 20px);

        &.showdots {
          top: calc(50% - 30px);
        }

        .button {
          background-color: #2e2e2e;
          //border-radius: 50%;
          width: 25px;
          height: 40px;
          border: 0px solid black;
          color: white;
          cursor: pointer;
          outline: none;
          color: white;
          opacity: 0.5;
          transition-duration: 0.2s;

          &:hover {
            background-color: var(--primary);
            opacity: 1;
          }
        }

        .icon_left {
          margin-right: 0.625rem;
        }

        .icon_right {
          margin-left: 0.625rem;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 6000px;
        height: 100%;
      }

      .carousel-wrapper {
        overflow: hidden;
      }

      .carousel-inner {
        display: flex;
      }
    `,
  ],
})
export class FullWidthCarouselComponent {
  @ContentChildren(FixedCarouselItemDirective)
  items: QueryList<FixedCarouselItemDirective>;
  // @ViewChildren(FixedCarouselItemElement, { read: ElementRef })
  // private itemsElements: QueryList<ElementRef>;
  @ViewChild('carousel', { static: true }) private carousel: ElementRef;
  @ViewChild('carouselContainer', { static: true })
  private carouselContainer: ElementRef;

  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  @Input() showDots = false;
  _showDots = true;
  @Input() height: string;
  @Input() width: string = '200px';

  @HostBinding('class')
  @Input()
  cssClass = 'tt_carousel';

  @Input()
  slideshow = false;

  @Input()
  slideshowTime = 2;

  @Input()
  wrap = true;

  private player: AnimationPlayer;

  currentSlide = 0;
  carouselWrapperStyle = {};

  timer: any;
  ngOnInit() {
    this._timer();
    this.resize();
  }

  _timer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    if (this.slideshow) {
      this.timer = setInterval(() => {
        this.next();
        // this.move((this.currentSlide + 1) % this.items.length)
      }, this.slideshowTime * 1000);
    }
  }

  refresh() {
    this._timer();
    this.resize();
  }
  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  contentWidth() {
    let cl = this.carousel.nativeElement as HTMLElement;

    let width = 0;
    for (let i = 0; i < cl.children.length; i++) {
      let ii = cl.children[i];
      width += ii.clientWidth;
    }
    return width;
  }

  getItemWidth() {
    let el = this.carouselContainer.nativeElement as HTMLElement;
    let itemWidth = el.offsetWidth;
    if (!itemWidth) {
      itemWidth = (el.children[0] as any).offsetWidth;
    }
    return itemWidth;
  }
  next() {
    if (!this.wrap && this.currentSlide + 1 >= this.items.length) {
      console.log('return');
      return;
    }
    let from: number = null;

    if (this.currentSlide + 1 > this.items.length) {
      from = 0;
      this.currentSlide = 1;
    } else {
      this.currentSlide = this.currentSlide + 1;
    }

    let itemWidth = this.getItemWidth();

    const offset = this.currentSlide * itemWidth;

    let contentWidth = this.contentWidth();
    if (!this.wrap && offset >= contentWidth) return;

    this.doAnimation(offset, from);
  }

  move(index: number) {
    let itemWidth = this.getItemWidth();

    const offset = index * itemWidth;
    let contentWidth = this.contentWidth();
    if (offset > contentWidth) return;

    this.currentSlide = index;
    this.doAnimation(offset);
  }
  prev() {
    if (!this.wrap && this.currentSlide <= 0) return;
    let from: number = null;

    let itemWidth = this.getItemWidth();
    if (this.currentSlide <= 0) {
      from = this.items.length * itemWidth;

      this.currentSlide = this.items.length - 1;
    } else {
      this.currentSlide--;
    }

    let el = this.el.nativeElement as HTMLElement;
    // let itemWidth = this.itemWidth || el.offsetWidth;

    const offset = this.currentSlide * itemWidth;
    if (!this.wrap && offset < 0) return;
    this.currentSlide = this.currentSlide;

    this.doAnimation(offset, from);
  }

  from = 0;
  private buildAnimation(offset: number, from?: number) {
    if (!from) from = this.from;
    this.from = offset;
    console.log(`MOVE ${from}->${offset} ${this.currentSlide}`);
    if (from == null || from == undefined) {
      return this.builder.build([
        animate(this.timing, style({ transform: `translateX(-${offset}px)` })),
      ]);
    } else {
      return this.builder.build([
        animate(
          this.timing,

          keyframes([
            style({
              transform: `translateX(${-from}px)`,
            }),
            style({
              transform: `translateX(${-offset}px)`,
            }),
          ])
        ),
      ]);
    }
  }
  doAnimation(offset: number, from?: number) {
    const myAnimation: AnimationFactory = this.buildAnimation(offset, from);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  constructor(
    private builder: AnimationBuilder,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    // For some reason only here I need to add setTimeout, in my local env it's working without this.
    // setTimeout(() => {
    //     this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
    //     this.carouselWrapperStyle = {
    //         width: `${this.itemWidth}px`
    //     }
    // });

    this.resize(null);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.resize(null);
  }
  @HostListener('window:resize')
  resize(event?: any) {
    let cl = this.carousel.nativeElement as HTMLElement;
    if (!this.wrap && cl.children.length <= 1) {
      this._showDots = false;
      this.slideshow = false;
      this._timer();
    } else if (this.wrap && cl.children.length == 2) {
      this._showDots = false;
      this.slideshow = false;
      this._timer();
    } else {
      this._showDots = true;
      this._timer();
    }

    let el = this.carouselContainer.nativeElement as HTMLElement;
    this._width = el.offsetWidth;
    this._height = el.offsetWidth;
    // this.itemWidth = el.offsetWidth;
    this.cdRef.detectChanges();
  }
  _width = 0;
  _height = 0;

  panX: number;
  onPanStart(event) {
    this.panX = event.center.x;
  }
  onPanEnd(event) {
    let diff = -(event.center.x - this.panX);

    let el = this.el.nativeElement as HTMLElement;
    let itemWidth = el.offsetWidth;
    let d = 0;
    if (diff > 0) d = Math.floor(diff / itemWidth);
    else d = Math.ceil(diff / itemWidth);

    let index = (this.currentSlide + d) % this.items.length;
    if (index < 0) index += this.items.length;
    this.currentSlide = index;

    let offset = this.from + diff;
    if (offset < 0) offset = 0;
    let contentWidth = this.contentWidth() - itemWidth;
    if (offset >= contentWidth) offset = contentWidth;

    this.doAnimation(offset);
    // this.move(index);
  }
}
