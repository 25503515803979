<!-- @format -->

<div class="imageUpload" [class]="cssClass">
  <div style="display: none">
    <input type="file" ng2FileSelect [uploader]="uploader" multiple #fileUploadInput accept="image/*" />
  </div>
  <div ng2FileDrop [uploader]="uploader" [ngClass]="{'fileOver': hasBaseDropZoneOver}" class="fileUpload"
    (fileOver)="fileOverBase($event)">
    <div class="imageContainer" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListOrientation]="direction">
      <ng-container *ngFor="let image of imageContainer.images; let i=index">
        <div class="imagePreviewContainer" *ngIf="!image._removed" cdkDrag>
          <img [attr.src]="image.path" class="imagePreview" *ngIf="!image._dataUrl && !image.id"
            (click)="showImage(image,i)" />
          <img [src]="image._dataUrl" class="imagePreview" *ngIf="image._dataUrl" />

          <div class="imageTrash dragHide">
            <fa-icon icon="times" (click)="imageContainer.removeImage(image)"></fa-icon>
          </div>

          <div class="ctlDropPlaceHolder horizontal" *cdkDragPlaceholder></div>
        </div>
      </ng-container>
      <div class="addImageButton" style="display: flex; flex-direction: column"
        *ngIf="multiple || imageContainer.numImages()<1">
        <div (click)="uploadImage()" class="subImageButton">
          <fa-icon icon="plus"> </fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>