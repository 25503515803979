import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../common/list.component.base';
import { AccessUtils, TBooking } from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { SpaceCard } from './space.card';

class _Base extends ScrollableListComponentBase<TBooking> {}

@Component({
  selector: 'app-my-booking-list-page',
  standalone: true,
  imports: [CommonModule, ScrollContainerComponent, RouterLink, SpaceCard],
  templateUrl: './my.booking.list.page.html',
})
export class MyBookingListPage extends SiteFormatMixin(SitePathMixin(_Base)) {
  @HostBinding('class') css = 'max-height';

  mode = 'upcoming';
  _common = _common;
  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    private router: Router,
    override snackBar: MatSnackBar,
    public session: SessionService
  ) {
    super(http, snackBar, elRef);
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      mode: this.mode,
    } as any;

    const url = this._listModifyUrl(`/api/public/booking/list`, {}, options);
    return this.http.post(url, query);
  }

  override listFixList(list: TBooking[]) {
    for (let o of list) AccessUtils.fixBooking(o);
  }
  selectMode(mode: string) {
    this.mode = mode;
    this.refresh();
  }
}
