import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateSelectorConfig } from '../../daterange_utils';
import { DateSelectorDialogComponent } from './date.selector.dialog';



 


@Component({
    selector: 'app-date-selector',
    templateUrl: './date.selector.component.html',
    standalone: true,
    imports: [FontAwesomeModule],
})
export class DateSelectorComponent implements OnInit {
   _common = _common;

   @Input()
   config: DateSelectorConfig = new DateSelectorConfig();


   @Output()
   onChange = new EventEmitter<DateSelectorConfig>();
   displayText: string;


   constructor(
      @Inject(LOCALE_ID) public locale: string,
      private dialog: MatDialog) {
      // this.config = this.session.curDateSelector.getValue();
      this.config.init(14, 14);

   }

   ngOnInit(): void {

      this.initDisplay();

   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes['config'])
         this.initDisplay();
   }

   initDisplay() {
      this.displayText = this.config.range.start.format('ll') + ' - ' + this.config.range.end.format('ll');
   }
   open() {
      const dialogRef = this.dialog.open(DateSelectorDialogComponent, {
         height: '80%',
         data: {
            config: this.config.clone()
         }
      });
      dialogRef.afterClosed().subscribe(data => {
         if (data) {
            this.config = <DateSelectorConfig>data.config;
            this.initDisplay();
            this.onChange.emit(this.config);
            // this.session.curDateSelector.next(this.config);
         }
      });

   }
}
