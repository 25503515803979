import { NgIf } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from "@angular/material/dialog";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

interface ConfirmOption {
  remember?: boolean;
  rememberId?: string;
}

@Component({
  templateUrl: "./confirm.dialog.html",
  standalone: true,
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    
    NgIf,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class ConfirmDialogComponent implements OnInit {
  _common = _common;

  static confirmed: { [key: string]: boolean } = {};

  static async openAsync(dialog: MatDialog, title: string, content: string) {
    return new Promise((resolve, reject) => {
      const dialogRef = dialog.open(ConfirmDialogComponent, {
        data: {
          title,
          content,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
  static open(
    dialog: MatDialog,
    title: string,
    content: string,
    cb: () => void,
    opts?: ConfirmOption
  ) {
    if (opts) {
      if (opts.remember && opts.rememberId) {
        if (this.confirmed[opts.rememberId]) {
          cb();
          return;
        }
      }
    }
    const dialogRef = dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        content,
        opts,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        cb();
      }
    });
  }

  title: string;
  content: string;
  opts: ConfirmOption;
  _remember: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.title = data.title as string;
    this.content = data.content as string;
    this.opts = (data.opts as ConfirmOption) || {};
  }

  ngOnInit(): void {}

  confirm() {
    if (this.opts.remember && this.opts.rememberId && this._remember) {
      ConfirmDialogComponent.confirmed[this.opts.rememberId as string] = true;
    }

    this.dialogRef.close(true);
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
