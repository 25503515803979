<div class="sectionHeader" (click)="filterObject.toggleFilter(id, $event)">
  <div class="spacer">
    <ng-content></ng-content>
  </div>
  <div>
    <fa-icon [icon]="faChevronDown" *ngIf="!filterObject.section[id]"></fa-icon>
    <fa-icon [icon]="faChevronUp" *ngIf="filterObject.section[id]"></fa-icon>
  </div>
</div>
<div class="sectionBody" *ngIf="filterObject.section[id]">
  <div>
    <mat-radio-group
      (change)="filter.filter.update($event); refresh()"
      [(ngModel)]="filter.filter.v"
    >
      <div mat-menu-item>
        <mat-radio-button [value]="boolTypes.ALL">{{
          filter.names[0]
        }}</mat-radio-button>
      </div>
      <div mat-menu-item>
        <mat-radio-button [value]="boolTypes.TRUE">{{
          filter.names[1]
        }}</mat-radio-button>
      </div>
      <div mat-menu-item>
        <mat-radio-button [value]="boolTypes.FALSE">{{
          filter.names[2]
        }}</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="bottom">
    <div class="button" (click)="filter.filter.clear(); refresh()">
      {{ _common.clear }}
    </div>
  </div>
</div>
