/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { VizFormModule } from '../components/form/form.module';
import { FilterBoolComponent } from './filter.bool.component';
import { FilterBoolTypes, FilterObject } from './list.filter';

@Component({
  selector: 'app-filter-bool-filter',
  templateUrl: './filter.bool.filter.component.html',
  standalone: true,
  imports: [
    CommonModule,
    VizFormModule,
    MatMenuModule,
    FontAwesomeModule,
    MatRadioModule,
  ],
})
export class FilterBoolFilterComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  _common = _common;

  @Input()
  filterObject: FilterObject;
  @Input()
  id: string;
  @Input()
  filter = new FilterBoolComponent();

  boolTypes = FilterBoolTypes;
  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}

  refresh() {
    this.change.next(void 0);
  }
}
