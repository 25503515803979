<div style="overflow: scroll;">
<div class="client_pageBody terms_page" >

    <h1 style="margin-bottom: 1rem;">Privacy Policy for 21C Booking System</h1>

    <h2>1. Introduction</h2>
    <p>Welcome to 21C Booking System ("we", "our", "us"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data when you use our app, website, and related services (collectively, the "Services").</p>
    <p>By using our Services, you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using our Services.</p>
    <hr>

    <h2>2. Information We Collect</h2>
    <p>We may collect the following types of information:</p>
    <h3>2.1 Personal Information</h3>
    <p>When you use our Services, we may collect personal information, such as:</p>
    <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Profile picture (if applicable)</li>
    </ul>
    <h3>2.2 Booking Information</h3>
    <p>When you make a booking, we collect details such as:</p>
    <ul>
        <li>Service type</li>
        <li>Date, time, and location of booking</li>
        <li>Special requests or preferences</li>
    </ul>
    <h3>2.3 Usage Data</h3>
    <p>We collect information about how you interact with our Services, including:</p>
    <ul>
        <li>IP address</li>
        <li>Device type and operating system</li>
        <li>Browser type and version</li>
        <li>App usage data, such as pages viewed and features accessed</li>
    </ul>
    <h3>2.4 Location Data</h3>
    <p>With your permission, we may collect your device's location data to provide location-based services (e.g., finding nearby providers or venues).</p>
    <h3>2.5 Cookies and Tracking Technologies</h3>
    <p>We use cookies and similar tracking technologies to enhance your experience and analyze app usage. For more details, see our Cookie Policy.</p>
    <hr>

    <h2>3. How We Use Your Information</h2>
    <p>We use your information for the following purposes:</p>
    <ul>
        <li>To process and manage your bookings</li>
        <li>To communicate with you about updates, promotions, or changes to our Services</li>
        <li>To provide customer support</li>
        <li>To personalize and improve your experience</li>
        <li>To process payments securely</li>
        <li>To comply with legal obligations</li>
    </ul>
    <hr>

    <h2>4. How We Share Your Information</h2>
    <p>We do not sell your personal information. However, we may share your information under the following circumstances:</p>
    <h3>4.1 Service Providers</h3>
    <p>We may share your information with third-party service providers who assist us with:</p>
    <ul>
        <li>Booking management</li>
        <li>Analytics and marketing</li>
        <li>Customer support</li>
    </ul>
    <h3>4.2 Business Partners</h3>
    <p>If you book services through one of our partners (e.g., a venue or service provider), we may share booking details with them.</p>
    <h3>4.3 Legal Obligations</h3>
    <p>We may disclose your information if required to do so by law or in response to valid requests by public authorities.</p>
    <h3>4.4 Business Transfers</h3>
    <p>In the event of a merger, acquisition, or sale of our business, your information may be transferred to the new owner.</p>
    <hr>

    <h2>5. Your Rights and Choices</h2>
    <p>Depending on your location, you may have the following rights:</p>
    <ul>
        <li>Access: Request access to the personal data we hold about you.</li>
        <li>Correction: Request that we correct inaccurate or incomplete data.</li>
        <li>Deletion: Request that we delete your personal data.</li>
        <li>Data Portability: Request a copy of your data in a portable format.</li>
        <li>Withdraw Consent: Revoke consent for certain data uses.</li>
        <li>Opt-Out: Opt out of marketing communications or data collection for analytics.</li>
    </ul>
    <p>To exercise your rights, please contact us at hq&#64;hkfyg.org.hk</p>
    <hr>

    <h2>6. Data Security</h2>
    <p>We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or loss. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
    <hr>

    <h2>7. Data Retention</h2>
    <p>We retain your personal information only as long as necessary to fulfill the purposes for which we collected it, including to satisfy legal, accounting, or reporting requirements.</p>
    <hr>

    <h2>8. Third-Party Links</h2>
    <p>Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these third parties and encourage you to review their privacy policies.</p>
    <hr>

    <h2>9. Children's Privacy</h2>
    <p>Our Services are not intended for individuals under the age of 16 (or the applicable minimum age in your jurisdiction). We do not knowingly collect personal information from children.</p>
    <hr>

    <h2>10. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically.</p>
    <hr>

    <h2>11. Contact Us</h2>
    <p>If you have questions or concerns about this Privacy Policy, please contact us:</p>
    <ul>
        <li>Email: hq&#64;hkfyg.org.hk</li>
        <li>Phone: (852) 2527-2448</li>
    </ul>
    <hr>

    <h2>12. Additional Information for Specific Jurisdictions</h2>
    <p>If you are located in certain regions (e.g., the European Union, California), additional rights and requirements may apply. See our Supplemental Privacy Notice for more details.</p>
    <hr>

    <p>This Privacy Policy was last updated on 3 Dec 2024.</p>




</div>
</div>
