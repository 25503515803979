/** @format */

import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import moment from 'moment-timezone';
import { date_utils } from '../date_utils';
import { DELETED, NamePair } from '../utils';
import { CustomListFilterDialogComponent } from './custom.list.filter.dialog';
import { RangeType } from './filter.date.range';
import { ListFilterCache } from './list.filter.cache';

export interface _ListFilter {
  id: number;
  name: string;
  name_lang: { [key: string]: string };
  filter: string;
}
export class ListFilterConfig {
  filters: _ListFilter[] = [];
}

export interface FilterType {
  clear(): void;
  query(): any;
  load(oo: any, opts?: { filter: boolean }): void;
  save(filter?: boolean): any;
  touched: boolean;
}
export class FilterObject {
  filters: { filters: _ListFilter[] } = { filters: [] };
  filter: { [key: string]: FilterType } = {} as any;

  filterCache: ListFilterCache;
  dialog: MatDialog;
  refresh: () => void;
  reset: () => void;
  _touched: (touched: boolean) => void;
  init(
    services: {
      filterCache: ListFilterCache;
      dialog: MatDialog;
      touched: (touched: boolean) => void;
      refresh: () => void;
      reset?: () => void;
    },
    object: string,
  ) {
    this.filterCache = services.filterCache;
    this.dialog = services.dialog;
    this.refresh = services.refresh;
    this.reset = services.reset;
    this._touched = services.touched;

    this.filterCache.load(object, (list) => {
      this.filters = list;
    });
  }

  untouch() {
    for (let id in this.filter) {
      this.filter[id].touched = false;
    }
  }
  touched() {
    for (let id in this.filter) {
      if (this.filter[id].touched) return true;
    }
    return false;
  }

  add(id: string, type: FilterType) {
    this.filter[id] = type;
    return this;
  }
  clear() {
    for (let id in this.filter) {
      this.filter[id].clear();
    }
    this.untouch();
  }
  query() {
    let query: { [key: string]: any } = {};
    for (let id in this.filter) {
      let v = this.filter[id].query();
      if (v && v._body) {
        Object.assign(query, v._body);
      } else if (v != null) query[id] = v;
    }
    return query;
  }
  save(filter?: boolean) {
    let query: { [key: string]: any } = {};
    for (let id in this.filter) {
      let c = this.filter[id];
      let oo = c.save(filter);
      query[id] = oo;
    }
    return query;
  }
  load(
    oo: any,
    opts?: {
      filter?: boolean;
    },
  ) {
    for (let id in this.filter) {
      let c = oo[id];
      this.filter[id].load(c);
    }
    this.untouch();
  }

  filterOpened = false;
  filterPopup(open: boolean) {
    this.filterOpened = open;
  }

  showFilter = false;
  showFilterPopup = false;
  section = {} as any;
  openFilter() {
    this.showFilter = true;
  }
  closeFilter() {
    if (this.showFilterPopup) return;

    this.showFilter = false;
  }
  popup(s: boolean) {
    this.showFilterPopup = s;
  }

  toggleFilter(id: string, event?: MouseEvent) {
    if (this.section[id]) this.section[id] = false;
    else this.section[id] = true;
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  curFilter: _ListFilter | null;

  selectFilter(f?: _ListFilter) {
    this.curFilter = f ?? null;
    this.clear();
    if (f)
      this.load(f.filter, {
        filter: true,
      });
    else {
      if (this.reset) {
        this.reset();
      }
    }

    this.refresh();
  }
  saveFilter(locale?: string) {
    let id: number | null = null;
    let filter = {} as any;
    Object.assign(filter, this.curFilter) as any;
    filter.filter = this.save();
    filter.name = filter.name || 'Untitled';
    if (filter.name_lang)
      filter.name_lang = Object.assign({}, filter.name_lang);
    else filter.name_lang = {};

    if (this.curFilter && !this.touched()) id = this.curFilter.id;
    else filter.name = 'Untitled';

    let ref = this.dialog.open(CustomListFilterDialogComponent, {
      data: {
        object: 'order',
        id,
        locale: locale,
        filter,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        let index = this.filters.filters.indexOf(this.curFilter as any);
        if (index >= 0) this.filters.filters.splice(index, 1);
        this.curFilter = null;
        this.clear();
      } else if (result) {
        if (id) {
          Object.assign(this.curFilter as any, result);
        } else {
          this.filters.filters.push(result);
          this.curFilter = result;
          this.untouch();
        }
      }
    });
  }
}

export enum FilterBoolTypes {
  ALL = 'ALL',
  TRUE = 'true',
  FALSE = 'false',
}

export class FilterBool {
  touched: boolean = false;

  v: string = FilterBoolTypes.ALL;
  clear() {
    this.v = FilterBoolTypes.ALL;
  }
  set(o: boolean | null) {
    if (o == null) {
      this.v = FilterBoolTypes.ALL;
    } else if (o) {
      this.v = FilterBoolTypes.TRUE;
    } else {
      this.v = FilterBoolTypes.FALSE;
    }
  }
  update(event: MatRadioChange) {
    this.v = event.value;
    this.touched = true;
  }

  query() {
    switch (this.v) {
      case FilterBoolTypes.TRUE:
        return true;
      case FilterBoolTypes.FALSE:
        return false;
      default:
        return null;
    }
  }

  save() {
    return this.v;
  }
  load(oo: any) {
    if (!oo) this.v = FilterBoolTypes.ALL;
    else this.v = oo;
  }
}

export class FilterDateRange {
  range = {
    type: RangeType.all,
    start: null,
    end: null,
  } as {
    type: RangeType;
    start: moment.Moment | null;
    end: moment.Moment | null;
  };
  touched: boolean = false;
  ranges = RangeType;

  prefix: string | null;
  utc = false;
  _standalone = false;
  constructor(prefix?: string) {
    this.prefix = prefix ?? null;
  }
  clear() {
    this.range.type = RangeType.all;
    this.range.start = null;
    this.range.end = null;
  }
  standalone() {
    this._standalone = true;
    return this;
  }
  useUtc() {
    this.utc = true;
    return this;
  }
  update(range?: any) {
    if (range) this.range = range;
    this.refresh();
  }
  refresh() {
    const now = moment().startOf('day');

    switch (this.range.type) {
      case RangeType.tomorrow:
        this.range.start = now.clone().add(1, 'day');
        this.range.end = now.clone().add(1, 'day');
        break;
      case RangeType.nextday7:
        this.range.start = now.clone();
        this.range.end = now.clone().add(6, 'day');
        break;
      case RangeType.nextday14:
        this.range.start = now.clone();
        this.range.end = now.clone().add(13, 'day');
        break;
      case RangeType.day7:
        this.range.start = now.clone().add(-6, 'day');
        this.range.end = now;
        break;
      case RangeType.day14:
        this.range.start = now.clone().add(-13, 'day');
        this.range.end = now;
        break;
      case RangeType.today:
        this.range.start = now.clone();
        this.range.end = now;
        break;
      case RangeType.all:
        this.range.start = null;
        this.range.end = null;
        break;

      case RangeType.yesterday:
        this.range.start = now.clone().add(-1, 'day');
        this.range.end = now.clone().add(-1, 'day');
        break;
    }
  }
  query() {
    let query = {} as any;
    if (this.range.start) {
      let v = date_utils.momentUTCToLocalDate(this.range.start) as any;
      if (this.utc) v = date_utils.toQuery(this.range.start);
      if (!this.prefix) query.start = v;
      else query[`${this.prefix}Start`] = v;
    }
    if (this.range.end) {
      let v = date_utils.momentUTCToLocalDate(this.range.end) as any;
      if (this.utc) v = date_utils.toQuery(this.range.end);
      if (!this.prefix) query.end = v;
      else query[`${this.prefix}End`] = v;
    }
    if (!this._standalone)
      return {
        _body: query,
      };
    else return query;
  }
  save() {
    return {
      type: this.range.type,
      start: date_utils.toQuery(this.range.start as moment.Moment) as string,
      end: date_utils.toQuery(this.range.end as moment.Moment) as string,
    };
  }
  load(
    oo: any,
    opts: {
      filter: boolean;
    },
  ) {
    if (!oo) {
      this.range.type = RangeType.all;
      this.range.start = null;
      this.range.end = null;
      return;
    }
    this.range.type = oo.type;
    this.range.start = date_utils.fromQuery(oo.start) as moment.Moment;
    this.range.end = date_utils.fromQuery(oo.end) as moment.Moment;
    this.refresh();
  }
}
export class FilterMulti {
  v = {} as any;
  visible = true;
  touched: boolean = false;

  list: string[] = [];
  names: string[] = [];

  values: { id: string; name: string }[] = [];

  select(list: string[]) {
    for (let id of list) this.v[id] = true;
  }
  _update() {
    this.list = Object.keys(this.v).filter((c) => this.v[c]);
    this.names = this.list;
    if (this.values) {
      this.names = this.list.map((c) => {
        let p = this.values.find((p) => p.id == c);
        if (p) return p.name;
        return c;
      });
    }
  }
  isSingle() {
    return this.values.length <= 1;
  }

  clear() {
    for (let id of Object.keys(this.v)) {
      delete this.v[id];
    }
    this._update();
  }
  query(): any {
    let o = this.v;
    return Object.keys(o).filter((id) => o[id]);
  }
  toggleAll(values: any) {
    for (let c of values) {
      this.v[c] = !this.v[c];
    }
    this._update();
  }
  toggle(id: string, event: MouseEvent) {
    this.v[id] = !this.v[id];
    this.touched = true;
    this._update();
    event.stopPropagation();
    event.preventDefault();
  }
  update(id: string, event: any) {
    this.v[id] = event.checked;
    this.touched = true;
    this._update();
  }
  remove(id: string) {
    this.v[id] = false;
    this._update();
    this.touched = true;
  }
  set(id: string | string[]) {
    this.clear();

    if (typeof id == 'string') {
      this.v[id] = true;
    } else {
      id.forEach((c) => {
        this.v[c] = true;
      });
    }
    this._update();
  }

  save() {
    return this.list;
  }
  load(oo: any) {
    this.v = {};
    if (oo) {
      let l = oo as string[];
      for (let s of l) this.v[s] = true;
    }
    this._update();
  }
}

export class FilterSingle {
  v: any;
  visible = true;
  touched: boolean = false;

  // list: string[] = []
  // names: string[] = [];

  values: { id: string; name: string }[] = [];

  select(list: string) {
    this.v = list;
  }
  _update() {
    // this.list = Object.keys(this.v).filter((c) => this.v[c])
    // this.names = this.list;
    // if (this.values) {
    //    this.names = this.list.map((c) => {
    //       let p = this.values.find((p) => p.id == c);
    //       if (p) return p.name
    //       return c;
    //    })
    // }
  }

  clear() {
    this.v = null;
    this._update();
  }
  query(): any {
    return this.v;
  }
  toggleAll(values: any) {
    for (let c of values) {
      this.v[c] = !this.v[c];
    }
    this._update();
  }
  toggle(id: string, event: MouseEvent) {
    this.v[id] = !this.v[id];
    this.touched = true;
    this._update();
    event.stopPropagation();
    event.preventDefault();
  }
  update(id: string) {
    this.v = id;
    this.touched = true;
    this._update();
  }
  remove(id: string) {
    this.v[id] = false;
    this._update();
    this.touched = true;
  }
  set(id: string | string[]) {
    this.clear();

    if (typeof id == 'string') {
      this.v[id] = true;
    } else {
      id.forEach((c) => {
        this.v[c] = true;
      });
    }
    this._update();
  }

  save() {
    return this.v;
  }
  load(oo: any) {
    this.v = oo;

    this._update();
  }
}

export class FilterMultiBool extends FilterMulti {
  override query(): any {
    let o = this.v;
    let query: { [key: string]: boolean } = {};
    for (let id in o) {
      if (o[id]) {
        query[id] = true;
      }
    }
    return {
      _body: query,
    };
  }
}

export class FilterRadio {
  v: string | null;
  visible = true;
  touched: boolean = false;

  // list: string[] = []
  // names: string[] = [];

  // values: { id: string, name: string }[] = [];

  _update() {
    // this.list = Object.keys(this.v).filter((c) => this.v[c])
    // this.names = this.list;
    // if (this.values) {
    //    this.names = this.list.map((c) => {
    //       let p = this.values.find((p) => p.id == c);
    //       if (p) return p.name
    //       return c;
    //    })
    // }
  }
  clear() {
    this.v = null;
    this._update();
  }
  query(): any {
    return this.v;
  }
  update(id: string, event: MatRadioChange) {
    this.v = id;
    this.touched = true;
    this._update();
  }

  save() {
    return this.v;
  }
  load(oo: any) {
    this.v = oo;
    this._update();
  }
}

export class FilterSelectObject {
  touched: boolean = false;

  v: NamePair | null;
  clear() {
    this.v = null;
  }
  set(o: NamePair) {
    this.v = o;
  }
  update(event: MatRadioChange) {
    this.v = event.value;
    this.touched = true;
  }

  query() {
    return this.v?.id;
  }

  save() {
    return this.v;
  }
  load(oo: any) {}
}

export class FilterSelectObjects {
  touched: boolean = false;

  v: NamePair[] = [];
  clear() {
    this.v = [];
  }
  set(o: NamePair) {
    this.v = [o];
  }
  remove(o: NamePair, event?: Event) {
    event?.stopPropagation();
    event?.preventDefault();
    let index = this.v.findIndex((c) => c.id == o.id);
    if (index >= 0) this.v.splice(index, 1);
  }
  add(list: NamePair[]) {
    for (let c of list) {
      if (this.v.find((p) => p.id == c.id)) continue;
      this.v.push(c);
    }
  }

  update(event: MatRadioChange) {
    this.v = event.value;
    this.touched = true;
  }

  query() {
    if (!this.v || !this.v.length) return null;
    return this.v.map((c) => c.id);
  }

  save() {
    return this.v;
  }
  load(oo: any) {}
}

export class FilterText {
  touched: boolean = false;

  visible = true;
  v: string = '';
  clear() {
    this.v = '';
  }
  set(o: string) {
    this.v = o;
  }
  update(value: any) {
    this.v = value;
    this.touched = true;
  }

  query() {
    if (!this.v || this.v == '') return '';
    return this.v;
  }

  save() {
    return this.v;
  }
  load(oo: any) {
    this.v = oo;
  }
}
