/** @format */

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { TimeAtom } from '../../timespan/time_utils';

export interface DayViewTimeRow {
  start: TimeAtom;
  end: TimeAtom;
}

//import { TeamListAddDialogComponent } from './shared/team.list.add.dialog';

export interface DayViewTimeColumn {
  id: string;
  title: string;
  lines?: string[];
  headers?: any[];
}

export interface DayViewTimeLoad {
  comp: DayViewTimeComponent;
  date: moment.Moment;
}

export interface DayViewTimeItem {
  start: TimeAtom;
  end: TimeAtom;
  title: string;

  color?: string;
  borderColor?: string;
  textColor?: string;
  _top?: number;
  _bottom?: number;
  data?: any;
  lines?: {
    text: string;
    style?: string;
  }[];
}
interface DayViewTimeDataItem {
  items: DayViewTimeItem[];
}

@Component({
  selector: 'app-dayview-time',
  templateUrl: './dayview.time.component.html',
  standalone: true,
  styles: [
    `
      .ctlDayCalendar {
        .table,
        .headerTable {
          display: grid;
          grid-auto-rows: 80px;

          background-color: white;
          border-bottom: 1px solid #ccc;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-top: none;
          //grid-gap: 1px;

          > div {
            background-color: white;
            position: relative;
            border-right: 1px solid #ccc;
            border-bottom: 1px dashed #ccc;
          }
          .header {
            // background-color:#ccc;
            // border-top:1px solid #ccc;
            // border-bottom: 1px solid #ccc;
            border-bottom: none;
          }
          .colHeader {
            // border-left:1px solid #ccc;
          }

          .item {
            width: calc(100% - 10px);
            position: absolute;
            margin: 0px;
            margin-left: 5px;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            padding: 5px;
            // align-items: center;
            // justify-content: center;

            background-color: #ffdc28;
            border: 1px solid #ccc;
            z-index: 500;

            border-radius: 4px;
            border-left: 4px solid transparent;

            .lineHeader {
              font-weight: 500;
              //font-size:0.8rem;
            }
            .itemLine {
              font-size: 0.9em;
              line-height: 0.9em;
              color: #999;
            }
          }
        }
        .headerTable {
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
        }
      }
    `,
  ],
  imports: [CommonModule],
})
export class DayViewTimeComponent {
  @Output()
  loadData = new EventEmitter<DayViewTimeLoad>();
  _common = _common;

  @HostBinding('class') css = 'max-height';

  @Input()
  cssClass: string = null;

  rows: DayViewTimeRow[] = [];
  columns: DayViewTimeColumn[] = [
    {
      id: 'test',
      title: 'test',
    },
  ];
  columnData: DayViewTimeDataItem[][] = [];

  @Input()
  date: moment.Moment = moment().startOf('day');

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public elRef: ElementRef,

    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
  ) {}

  gridStyle = {} as any;
  headerStyle = {} as any;

  ngOnInit() {
    // this.refresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['date']) {
      this.refresh();
    }
  }

  @Input()
  headerTemplate: TemplateRef<any>;

  addHeader(column: string, header: any) {
    let col = this.columns.findIndex((c) => c.id == column);
    if (col < 0) return;

    let cc = this.columns[col];
    cc.headers = cc.headers ?? [];
    cc.headers.push(header);
    this.headers = true;
  }

  addData(column: string, data: DayViewTimeItem) {
    let t = data.start;
    let row = this.rows.findIndex((c) => t.between(c.start, c.end));
    if (row < 0) return;
    let col = this.columns.findIndex((c) => c.id == column);
    if (col < 0) return;
    let cc = this.columnData[row][col];
    cc.items.push(data);

    let min = this.roundMin(t.min);

    data._top = (min / 60) * 100;
    let diff = data.end.diff(data.start);
    let mins = diff.mins();
    mins = this.roundMin(mins);

    data._bottom = (1 - mins / 60) * 100 - data._top;
  }
  roundMin(min: number) {
    return Math.round(min / 15) * 15;
  }
  cellHeight(d: DayViewTimeItem) {
    return `calc(${100 - d._bottom - d._top}% + ${Math.ceil(
      (100 - d._bottom - d._top) / 100,
    )}px`;
  }
  refresh() {
    this.init();
    this.loadData.next({
      comp: this,
      date: this.date,
    });
  }

  // remove unncessary rows at the top
  align() {
    while (true) {
      if (this.rows.length <= 1) break;
      if (this.rows[0].start.hour >= 10) break;
      let data = this.columnData[0];
      let found = false;
      for (let v of data) {
        if (v.items?.length) {
          found = true;
          break;
        }
      }
      if (found) break;

      this.columnData.splice(0, 1);
      this.rows.splice(0, 1);
    }
    if (this.headers) {
      this.headerStyle.gridTemplateRows = `auto auto`;
      this.headerStyle = Object.assign({}, this.headerStyle);
    }
  }
  setColumns(columns: DayViewTimeColumn[]) {
    this.columns = columns;
    this.init();
  }
  @Input()
  hours = 24;

  headers = false;
  init() {
    this.gridStyle = {
      gridTemplateColumns: `100px repeat(${this.columns.length}, 150px)`,
    };
    this.headerStyle = Object.assign(
      {
        gridTemplateRows: `auto `,
      },
      this.gridStyle,
    );

    this.headers = false;
    this.columnData = [];

    for (let i = 0; i < this.hours; i++) {
      this.rows[i] = {
        start: new TimeAtom(i),
        end: new TimeAtom(i + 1),
      };
      let list: DayViewTimeDataItem[] = [];
      for (let c of this.columns)
        list.push({
          items: [],
        });
      this.columnData.push(list);
    }
  }

  @Output()
  itemClick = new EventEmitter<{
    column: DayViewTimeColumn;
    item: DayViewTimeItem;
    list: DayViewTimeItem[];
  }>();
  openItem(
    event: MouseEvent,
    column: DayViewTimeColumn,
    item: DayViewTimeItem,
    rowIndex: number,
    colIndex: number,
  ) {
    event.preventDefault();
    event.stopPropagation();
    let list = [];
    for (let row of this.columnData) {
      list.push(...row[colIndex].items);
    }
    console.log(2);
    this.itemClick.next({
      column,
      item,
      list,
    });
  }
  @Output()
  timeClick = new EventEmitter<{
    column: DayViewTimeColumn;
    row: DayViewTimeRow;
  }>();
  openTime(column: DayViewTimeColumn, rowIndex: number, colIndex: number) {
    this.timeClick.emit({
      column,
      row: this.rows[rowIndex],
    });
  }

  @Output()
  headerClick = new EventEmitter<DayViewTimeColumn>();
  headerClicked(c: DayViewTimeColumn) {
    this.headerClick.next(c);
  }
}
