<app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  #scroll
>
  <div class="client_pageBody">
    <div class="page_Title flex items-baseline">
      <div class="spacer">{{_common.AllCenters}}</div>

      <div
        [routerLink]="[session.path('/location')]"
        class="cursor-pointer"
        style="font-size: 14px; font-weight: normal"
      >
        <fa-icon icon="map-location-dot"></fa-icon>
        {{ _common.ViewMap }}
        <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
      </div>
    </div>
    <div class="max-height body_section">
      <app-location-search-list #list></app-location-search-list>
    </div>
  </div>
</app-scroll-container>
