<div class="dialog-max ctlDateSelectorDialog" style="height: calc(80vh - 30px); width: 900px;">
   <!--<h2 mat-dialog-title>{{_common.DateRangeSelector.title}}</h2>-->

   <div mat-dialog-content>
      <div style="display:flex; height: 100%;">
         <div style="flex: 1 1 auto; overflow: auto; margin-right: 10px;">
            <div>
               <app-three-calendar [events]="events" (selectedDate)="selectDate($event)"></app-three-calendar>
            </div>
            <div style="margin: 0 3%;">
               <div class="subtitle">{{_common.DateRangeSelector.selected}}</div>
               <div class="range">
                  <app-date-selector-daterange #rangeSelector [range]="config.range" [trigger]="trigger"
                     (change)="onChange()"></app-date-selector-daterange>
               </div>
               <div *ngFor="let r of config.subranges" class="range">
                  <app-date-selector-datesubrange [range]="r" [trigger]="trigger" (change)="onChange()"
                     (remove)="onRemove($event)"></app-date-selector-datesubrange>
               </div>

               <div class="addRange" (click)="addPeriod()">
                  <fa-icon icon="plus"></fa-icon>
               </div>
               <div style="margin-top:5px;">
                  <app-form-field style="width:80px">
                     <mat-select [placeholder]="_common.DateRangeSelector.day_filter" [(ngModel)]="config.day">
                        <mat-option *ngFor="let v of dayFilters" [value]="v.id">{{v.name}}</mat-option>
                     </mat-select>
                  </app-form-field>
               </div>
            </div>
         </div>
         <div style="flex: 0 0 200px; overflow: auto;">
            <div style="margin-bottom:20px;">
               {{_common.DateRangeSelector.shortcuts.shortcuts}}
            </div>
            <div *ngFor="let ss of selectors" class="shortcut">
               <div class="header">{{_common.interval[ss.id]}}</div>
               <div class="link-container">
                  <div class="link" *ngFor="let s of ss.selectors" (click)="shortcut(s)">
                     {{_common.DateRangeSelector.shortcuts[s.id]}}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div div align="end">
      <button class="app-btn btn-text" (click)="cancel()">{{_common.cancel}}</button>
      <button class="app-btn" (click)="ok()" color="primary">{{_common.ok}}</button>
   </div>
</div>