import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmptyObject, WebUtils, utils } from '../../common/utils';
import { TScheduleCalendar, Thinkdesks } from '../../thinkdesks/thinkdesks';
import { ScheduleCalendarComponent } from '../../thinkdesks/widgets/schedule.calendar.component';

@Component({
  templateUrl: './schedule.calendar.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    ScheduleCalendarComponent,
  ],
})
export class ScheduleCalendarDialogComponent extends EmptyObject {
  form: UntypedFormGroup;
  _common = _common;

  object: TScheduleCalendar;

  apiUrl = '/api/schedule-calendar';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,

    public http: HttpClient,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ScheduleCalendarDialogComponent>
  ) {
    super();

    this.dialogRef.addPanelClass('full-dialog');
    this.object = data.object;

    this.form = this.fb.group({});
  }

  updateForm() {
    utils.updateValidity(this.form);
  }

  ngOnInit(): void {
    WebUtils.web_result(
      this.http.get(`${this.apiUrl}/${this.object.id}`),
      (result) => {
        this.object = result as TScheduleCalendar;
        Thinkdesks.fixScheduleCalendar(this.object);

        this.updateKey();
      }
    );
  }

  key = {};
  updateKey() {
    this.key = {
      calendar: WebUtils.objName(this.object),
    };
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
