<!-- @format -->

<div
  [matMenuTriggerFor]="dateMenu"
  class="flex items-center space-x-2 overflow-hidden"
  #trigger="matMenuTrigger"
>
  <div>
    {{ label }}
  </div>

  <div
    [class.hidden]="dateRange.range.type != dateRange.ranges.custom"
    class="flex items-center"
  >
    <mat-date-range-input [rangePicker]="_range" style="min-width: 10rem">
      <input matStartDate [(ngModel)]="dateRange.range.start" />
      <input
        matEndDate
        [(ngModel)]="dateRange.range.end"
        (dateChange)="refresh()"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matSuffix
      [for]="_range"
      style="margin: -5px -10px -5px 0px"
    >
    </mat-datepicker-toggle>
    <mat-date-range-picker #_range></mat-date-range-picker>
  </div>
  <ng-container *ngIf="showRange">
    <div *ngIf="dateRange.range.type != dateRange.ranges.custom">
      {{ formatDateRange(dateRange.range.start, dateRange.range.end) }}
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      !showRange &&
      dateRange.range.type != dateRange.ranges.all &&
      dateRange.range.type != dateRange.ranges.custom
    "
  >
    &nbsp; - &nbsp;
    {{ _common.filterObject[dateRange.range.type] || dateRange.range.type }}
  </ng-container>
</div>

<mat-menu #dateMenu="matMenu">
  <div (click)="$event.stopPropagation()" class="ctlFilter">
    <mat-radio-group [(ngModel)]="dateRange.range.type" (change)="updateType()">
      <div mat-menu-item *ngIf="allowAll">
        <mat-radio-button [value]="dateRange.ranges.all"></mat-radio-button>
        {{ _common.filterObject.all_time }}
      </div>
      <div mat-menu-item>
        <mat-radio-button [value]="dateRange.ranges.today"></mat-radio-button>
        {{ _common.filterObject.today }}
      </div>
      <ng-container *ngIf="future">
        <div mat-menu-item>
          <mat-radio-button
            [value]="dateRange.ranges.tomorrow"
          ></mat-radio-button>
          {{ _common.filterObject.tomorrow }}
        </div>
        <div mat-menu-item>
          <mat-radio-button
            [value]="dateRange.ranges.nextday7"
          ></mat-radio-button>
          {{ _common.filterObject.nextday7 }}
        </div>
        <div mat-menu-item>
          <mat-radio-button
            [value]="dateRange.ranges.nextday14"
          ></mat-radio-button>
          {{ _common.filterObject.nextday14 }}
        </div>
      </ng-container>

      <div mat-menu-item>
        <mat-radio-button [value]="dateRange.ranges.day14"></mat-radio-button>
        {{ _common.filterObject.day14 }}
      </div>
      <div mat-menu-item>
        <mat-radio-button [value]="dateRange.ranges.day7"></mat-radio-button>
        {{ _common.filterObject.day7 }}
      </div>
      <div mat-menu-item>
        <mat-radio-button [value]="dateRange.ranges.custom"></mat-radio-button>
        {{ _common.filterObject.custom_time }}
      </div>
    </mat-radio-group>
  </div>
</mat-menu>
