/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import { PasswordDialogComponent } from '../../common/dialogs/password.dialog';
import { DELETED, WebUtils, utils } from '../../common/utils';
import { AccessUtils, TOrganization } from '../../core/entities';
import { SiteEditUtils } from '../../core/lang';
import { LocationListComponent } from '../../manage/location/location.list.component';
import { ScheduleCalendarListComponent } from '../../manage/schedule/schedule.calendar.list.component';
import { SpaceListComponent } from '../../manage/space/space.list.component';
import {
  PASSWORD_LENGTH,
  PASSWORD_PATTERN,
  SessionService,
} from '../../session.service';

@Component({
  templateUrl: './organization.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    LocationListComponent,
    MatTabsModule,
    MatInputModule,
    MatDialogModule,
    SpaceListComponent,
    ScheduleCalendarListComponent,
  ],
})
export class OrganizationEditDialogComponent {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TOrganization;

  api = '/api/organization';

  accessCode: string;

  scheduleQuery = {};
  dlangs = SiteEditUtils.OTHER_LANGS
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrganizationEditDialogComponent>
  ) {
    this.objectId = data.objectId;

    this.form = this.fb.group({
      name: ['', Validators.required],
      name_lang: SiteEditUtils.langArray(this.fb),
      code: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TOrganization;

          AccessUtils.fixOrganization(this.object);

          utils.fill_form_values(this.object, this.form);

          this.scheduleQuery = {
            account: WebUtils.obj(this.object),
          };
          this.updateForm();
        }
      );
    }
  }

  updateForm() {
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);
    SiteEditUtils.langValues(query, 'name');

    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, query),
        async (result) => {
          this.object = result as TOrganization;
          AccessUtils.fixOrganization(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, query),
        async (result) => {
          this.object = result as TOrganization;
          AccessUtils.fixOrganization(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
  code() {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.object.id}/generate`),
      (result) => {
        console.log(result);
        this.accessCode = result.code;
      }
    );
  }

  changePassword() {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      data: {
        passwordLength: PASSWORD_LENGTH,
        passwordPattern: PASSWORD_PATTERN,
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        WebUtils.web_result(
          this.http.post(`${this.api}/${this.object.id}/change_password`, {
            password: value,
          }),
          () => {
            this.snackBar.open(_common.settings.update.PasswordUpdated);
          },
          this.snackBar
        );
      }
    });
  }
}
