/** @format */

import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Directive,
  Inject,
  InjectionToken,
  Input,
  OnInit,
  Optional,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { defer, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { APP_FORM_FIELD } from '../components/form/form.field';
import { LocaleService } from '../locale.service';
import { ErrorMessages } from './error-messages';
import { getNgxMatErrorDefMissingForError } from './errors';

export type ErrorTemplate =
  | {
      template: TemplateRef<any>;
      $implicit: ValidationErrors;
    }
  | {
      template: undefined;
      $implicit: string;
    }
  | undefined;

export const NGX_MAT_ERROR_DEFAULT_OPTIONS = new InjectionToken<ErrorMessages>(
  'NGX_MAT_ERROR_DEFAULT_OPTIONS',
);

@Directive({
  selector: '[appErrorDef]',
  standalone: true,
})
export class AppErrorDef implements OnInit {
  public readonly template = inject(TemplateRef);

  @Input() appErrorDefFor!: string;

  ngOnInit() {
    if (!this.appErrorDefFor) {
      throw getNgxMatErrorDefMissingForError();
    }
  }
}

@Component({
  selector: 'app-errors, [app-errors]',
  template: `<ng-template #defaultTemplate let-error>{{ error }}</ng-template
    ><ng-template [ngIf]="error$ | async" let-error
      ><ng-template
        [ngTemplateOutlet]="$any(error).template ?? defaultTemplate"
        [ngTemplateOutletContext]="error"
      ></ng-template>
    </ng-template>`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // imports: [NgIf, AsyncPipe, NgTemplateOutlet],
  host: {
    class: 'app-errors',
  },
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, AsyncPipe],
})
export class AppErrors {
  @ContentChildren(AppErrorDef, { descendants: true })
  private readonly customErrorMessages!: QueryList<AppErrorDef>;

  constructor(
    private localeService: LocaleService,
    @Inject(NGX_MAT_ERROR_DEFAULT_OPTIONS) private messages: any,
    @Inject(APP_FORM_FIELD) @Optional() private matFormField: any,
  ) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('app-errors')
  control?: MatFormFieldControl<any> | '' | null;
  //Observable<ErrorTemplate>
  public readonly error$ = defer(() => {
    const control = (this.control || this.matFormField?._control)?.ngControl
      ?.control as AbstractControl;
    if (!control) {
      // TODO: Throw error;
      return of(undefined);
    }

    return control.statusChanges.pipe(
      startWith(null as any),
      map(() => {
        if (!control.errors) {
          return null;
        }
        let messages =
          this.messages[this.localeService.locale] || this.messages['en'];

        const errorKeys = Object.keys(control.errors);
        const errorOrErrorDef =
          this.customErrorMessages.find((customErrorMessage) =>
            errorKeys.some(
              (error) => error === customErrorMessage.appErrorDefFor,
            ),
          ) ?? errorKeys.find((key) => key in messages);
        if (!errorOrErrorDef) {
          return errorKeys[0];
        }
        const errors = control.errors as ValidationErrors;
        if (errorOrErrorDef instanceof AppErrorDef) {
          return {
            template: errorOrErrorDef.template,
            $implicit: errors[errorOrErrorDef.appErrorDefFor],
          };
        }
        const message = messages[errorOrErrorDef];
        return {
          $implicit:
            typeof message === 'function'
              ? message(errors[errorOrErrorDef])
              : message,
        };
      }),
      // this distincts only undefined values
      distinctUntilChanged(),
    );
  });
}
