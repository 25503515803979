<!-- @format -->

<div
  class="sectionHeader"
  (click)="filterObject.toggleFilter(id, $event)"
  *ngIf="filter.filter.visible"
>
  <div class="spacer">
    <ng-content></ng-content>
  </div>
  <div>
    <fa-icon [icon]="faChevronDown" *ngIf="!filterObject.section[id]"></fa-icon>
    <fa-icon [icon]="faChevronUp" *ngIf="filterObject.section[id]"></fa-icon>
  </div>
</div>
<div class="sectionBody" *ngIf="filterObject.section[id]">
  <div
    mat-menu-item
    *ngFor="let v of filter.values; let i = index"
    (click)="filter.filter.toggle(v, $event); refresh()"
  >
    <mat-checkbox
      (change)="toggle(v, $event)"
      [(ngModel)]="filter.filter.v[v]"
      (click)="$event.stopPropagation()"
    >
    </mat-checkbox>
    {{ filter.names[i] }}
  </div>

  <div class="bottom">
    <div class="button" (click)="filter.filter.clear(); refresh()">
      {{ _common.clear }}
    </div>
  </div>
</div>
