/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import {
  AccessUtils,
  TEvent,
  TLocation,
  TSpace
} from '../../core/entities';
import { SessionCache } from '../../session.cache';
import { SessionService, TIMEZONE } from '../../session.service';
@Component({
  templateUrl: './event.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
})
export class EventEditDialogComponent extends CompareWithMixin(EmptyObject) {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TEvent;

  api = '/api/event';

  spaces: TSpace[] = [];
  locations: TLocation[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EventEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;


    this.cache.loadLocation(this.data.organization.id, (list) => {
      this.locations = list;
    });

    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      startTime:['',Validators.required],
      space: ['', Validators.required],
      location: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TEvent;

          AccessUtils.fixEvent(this.object);

          let query = Object.assign({}, this.object) as any;

          query.location = query.space?.location;
          utils.fill_form_values(query, this.form);

          this.updateForm();

          this.updateSpace();
        }
      );
    } else {
      let query = {
        timezone: TIMEZONE,
        location: this.data?.location,
        space: this.data?.space,
        
      } as any;

      utils.fill_form_values(query, this.form);

      this.updateForm();

      this.updateSpace();
    }
  }

  updateSpace() {
    let location = this.form.value.location ?? this.object?.space?.location;
    this.spaces = [];
    if (location) {
      this.cache.loadSpace(location.id, (list) => {
        this.spaces = list;
      });
    }
  }

  updateForm() {
    if (this.objectId) {
      this.form.controls['location'].disable();
      this.form.controls['space'].disable();
    }
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);

    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, query),
        async (result) => {
          this.object = result as TEvent;
          AccessUtils.fixEvent(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, query),
        async (result) => {
          this.object = result as TEvent;
          AccessUtils.fixEvent(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
}
