import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TOrganization } from '../../core/entities';
import { MemberSearchComponent } from './organization.search.component';

@Component({
  selector: 'app-organization-search-dialog',
  templateUrl: './organization.search.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    MemberSearchComponent,
  ],
})
export class OrganizationSearchDialog implements OnInit {
  _common = _common;
  selection = new SelectionModel<TOrganization>(false, []);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    public dialogRef: MatDialogRef<OrganizationSearchDialog>
  ) {
    let multiple = data?.multiple || false;
    this.selection = new SelectionModel<TOrganization>(multiple, []);
    this.query = data.query || {};
  }

  query = {};
  ngOnInit(): void {}

  save() {
    this.dialogRef.close(this.selection.selected);
  }
  useGuest() {
    this.dialogRef.close([null]);
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
