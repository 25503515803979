import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterObject } from '../../common/filter/list.filter';
import {
   ListOption,
   ScrollableListComponentBase,
} from '../../common/list.component.base';
import { AccessUtils, TOrganization } from '../../core/entities';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';

//import { TeamListAddDialogComponent } from './shared/team.list.add.dialog';
class _Base extends ScrollableListComponentBase<TOrganization> {}
@Component({
  selector: 'app-organization-search',
  templateUrl: './organization.search.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    ScrollContainerComponent,
    MatTableModule,
    MatCheckboxModule,
  ],
})
export class MemberSearchComponent extends SiteFormatMixin(_Base) {
  @Input()
  override selection = new SelectionModel<TOrganization>(true, []);

  @HostBinding('class') css = 'max-height';

  displayedColumns: string[] = ['select', 'name', 'email'];
  _common = _common;

  @Input()
  memberOnly = false;

  filterObject = new FilterObject();

  constructor(
    override elRef: ElementRef,
    override http: HttpClient,
    override snackBar: MatSnackBar
  ) {
    super(http, snackBar, elRef);
  }

  override ngOnInit() {}

  @Input()
  query: any;

  override createRequest(options: ListOption): Observable<Object> {
    let query = Object.assign({}, this.query ?? {});
    query = Object.assign(query, this.filterObject.query());
    const url = this._listModifyUrl(`/api/organization/list`, {}, options);
    return this.http.post(url, query);
  }

  override listFixList(list: TOrganization[]) {
    for (let o of list) AccessUtils.fixOrganization(o);
  }

  // add() {
  //    const dialogRef = this.dialog.open(ProductEditDialogComponent, {

  //       data: {

  //       }
  //    });
  //    dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //          this.refresh();
  //       }
  //    });

  // }

  override selectObject(o: TOrganization) {
    // const dialogRef = this.dialog.open(ProductEditDialogComponent, {
    //    data: {
    //       objectId: o.id
    //    }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //    if (result == DELETED) {
    //       this._removeFromList(o);
    //    } else if (result) {
    //       Object.assign(o, result);
    //    }
    // });
  }
}
