import {
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  makeEnvironmentProviders,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { routes } from './app.routes';
import './common';
import { provideMaterialDefault, provideTimeout } from './common/common.module';
import { ImageViewerService } from './common/components/image.viewer/image.viewer.service';
import { provideLoader } from './common/loader/loader.module';
import { SessionCache } from './session.cache';
import { SessionService } from './session.service';
import { ThinkdesksCache } from './thinkdesks/thinkdesks.cache';
import { BookingSharingService } from './user/booking.sharing';
import { MapService } from './user/map.service';
export function init_app(session: SessionService) {
  return async () => await session.init();
}
export const appConfig: ApplicationConfig = {
  providers: [
    SessionService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideRouter(routes),
    provideMaterialDefault(),
    provideTimeout(),
    provideLoader(),
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [SessionService],
      multi: true,
    },
    makeEnvironmentProviders([
      { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000 } },
    ]),
    importProvidersFrom(MatSnackBarModule),

    importProvidersFrom(
      MatTooltipModule,
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
      })
    ),
    SessionCache,
    MapService,
    BookingSharingService,
    ThinkdesksCache,
    ImageViewerService,
  ],
};
