import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { WebUtils } from '../common/utils';
import { TLocker, TMemberGroup, TSpace } from '../core/entities';
import {
  THolidayCalendar,
  TScheduleCalendar,
  Thinkdesks,
} from '../thinkdesks/thinkdesks';

//TODO move types reading to public
@Injectable()
export class ThinkdesksCache {
  constructor(private snackbar: MatSnackBar, private http: HttpClient) {}

  ////////////////////////////////////////////////////////////////////////////////////
  space: { [key: number]: TSpace[] } = {};
  loadSpace(location: number, cb?: (list: TSpace[]) => void) {
    let list = this.space[location];
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/space/list`, {
          location: location,
        }),
        (result) => {
          list = result as TSpace[];
          this.space[location] = list;
          if (cb) cb(list);
          resolve(list);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }
  spaces: TSpace[];
  loadSpaces(cb?: (list: TSpace[]) => void) {
    if (this.spaces) {
      if (cb) cb(this.spaces);
      return Promise.resolve(this.spaces);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/space/list`, {}),
        (result) => {
          this.spaces = result as TSpace[];

          if (cb) cb(this.spaces);
          resolve(this.spaces);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////
  locker: { [key: number]: TLocker[] } = {};
  loadLocker(location: number, cb?: (list: TLocker[]) => void) {
    let list = this.locker[location];
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/locker/list`, {
          location: location,
        }),
        (result) => {
          list = result as TLocker[];
          this.locker[location] = list;
          if (cb) cb(list);
          resolve(list);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////
  schedules: TScheduleCalendar[];
  loadScheduleCalendar(cb?: (list: TScheduleCalendar[]) => void) {
    if (this.schedules) {
      if (cb) cb(this.schedules);
      return Promise.resolve(this.schedules);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/schedule-calendar/list?limit=-1`, {}),
        (result) => {
          this.schedules = result as TScheduleCalendar[];
          if (cb) cb(this.schedules);
          resolve(this.schedules);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////
  holidayGlobal: THolidayCalendar[];

  loadHolidayGlobal(cb?: (list: THolidayCalendar[]) => void) {
    if (this.holidayGlobal) {
      if (cb) cb(this.holidayGlobal);
      return Promise.resolve(this.holidayGlobal);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/holiday-calendar/list/global`, {}),
        (list) => {
          this.holidayGlobal = list as THolidayCalendar[];
          for (let o of this.holidayGlobal) Thinkdesks.fixHolidayCalendar(o);
          if (cb) cb(this.holidayGlobal);
          resolve(this.holidayGlobal);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }
  clearHolidayGlobal() {
    this.holidayGlobal = null;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  memberGroups: TMemberGroup[];
  loadMemberGroups(cb?: (list: TMemberGroup[]) => void) {
    if (this.memberGroups) {
      if (cb) cb(this.memberGroups);
      return Promise.resolve(this.memberGroups);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/member-group/list`, {}),
        (result) => {
          this.memberGroups = result as TSpace[];

          if (cb) cb(this.memberGroups);
          resolve(this.memberGroups);
        },
        this.snackbar,
        (err) => reject(err)
      );
    });
  }
  clearMemberGroup() {
    this.memberGroups = null;
  }
}
