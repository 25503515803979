/** @format */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgFor } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VizFormModule } from '../form/form.module';

@Component({
  selector: 'app-tags-edit',
  templateUrl: './tags.edit.component.html',
  standalone: true,
  imports: [VizFormModule, NgFor, FontAwesomeModule, MatChipsModule],
})
export class TagsEditComponent implements OnInit {
  _common = _common;

  @Input()
  tags: string[] = [];

  @Input()
  title = _common.tags;

  @Output()
  updated = new EventEmitter<string[]>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {}

  ngOnInit() {}

  removeTag(tag: string) {
    let index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.updated.next(this.tags);
    }
  }
  addTag(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());

      this.updated.next(this.tags);
    }

    // Reset the input value
    if (input) input.value = '';
  }
}
