<div class="flex items-center">

   <div *ngIf="filterObject.touched()" class="app-btn btn-soft btn-square" (click)="filterObject.saveFilter(locale)"
      matTooltip="Save filter">
      <fa-icon [icon]="faSave"></fa-icon>
   </div>
   <div *ngIf="filterObject.curFilter && !filterObject.touched()" class="app-btn btn-soft btn-square"
      (click)="filterObject.saveFilter(locale)" matTooltip="Saved. Click to edit.">
      <fa-icon [icon]="faStar"></fa-icon>
   </div>

   <ng-content></ng-content>
</div>