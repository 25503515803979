<!-- @format -->

<div
  class="app-form-field app-ui"
  [class.field-invalid]="_control?.errorState"
  [class.field-disabled]="_control?.disabled"
  [class.prefix-border]="prefixBorder"
  [class.suffix-border]="suffixBorder"
>
  <div class="app-label" *ngIf="_labelChildNonStatic || _hintChild">
    <ng-content select="app-label"></ng-content>
    <ng-content select="app-hint"></ng-content>
  </div>
  <div class="app-form-field-line">
    <div
      class="app-form-field-container"
      [class.app-form-field-container-selected]="_isFocused"
    >
      <div class="app-field-icon-prefix" *ngIf="_hasIconPrefix">
        <ng-content select="[appPrefix], [appIconPrefix]"></ng-content>
      </div>

      <div class="app-form-field-text-prefix" *ngIf="_hasTextPrefix">
        <ng-content select="[appTextPrefix]"></ng-content>
      </div>

      <div class="app-form-field-content">
        <ng-content></ng-content>
      </div>

      <div class="app-field-icon-suffix" *ngIf="_hasIconSuffix">
        <ng-content select="[appSuffix], [appIconSuffix]"></ng-content>
      </div>

      <div class="app-form-field-text-suffix" *ngIf="_hasTextSuffix">
        <ng-content select="[appTextSuffix]"></ng-content>
      </div>
    </div>
    <div class="app-form-field-addon" *ngIf="_addonChild">
      <ng-content select="app-form-field-addon"></ng-content>
    </div>
  </div>
  <div class="app-form-field-help" *ngIf="_helpChild">
    <ng-content select="app-help"></ng-content>
  </div>
  <div class="app-form-field-error" *ngIf="_control?.errorState">
    <ng-content select="app-error"></ng-content>
  </div>
</div>
