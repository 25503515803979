import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FilterSingle } from "./list.filter";
import { NgIf, NgFor } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";

@Component({
  selector: "app-filter-single",
  templateUrl: "./filter.single.component.html",
  standalone: true,
  imports: [NgIf, NgFor, MatMenuModule],
})
export class FilterSingleComponent implements OnInit {
  _common = _common;

  @Input()
  filter = new FilterSingle();
  @Input()
  values: any[];
  @Input()
  names: string[];

  @Input()
  visible = true;
  @Input()
  set objectValues(c: any[]) {
    this.values = c.map((c) => c.id?.toString());
    this.names = c.map((c) => c.name);
  }

  @Input()
  set common(c: any) {
    this.names = this.values.map((v) => c[v] || v);
  }

  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["values"]) {
      if (!this.names) this.names = this.values;
    }
    this.filter._update();
  }

  refresh() {
    this.change.next(void 0);
  }
}
