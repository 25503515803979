
export interface IResizeOptions {
    width?: number;
    height?: number;

}
interface IResizeImageOptions extends IResizeOptions {
    // maxSize: number;
    file: File | Blob;
    asUrl?: boolean;
}




export class ImageUtils {

    static dataURItoBlob(dataURI: string) {
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    }
    static toDataURI(file: File | Blob): Promise<string> {
        const reader = new FileReader();
        const image = new Image();
        return new Promise((ok, no) => {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }

            reader.onload = (readerEvent: any) => {
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = image.width;
                    canvas.height = image.height;
                    canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width, image.height);

                    ok(canvas.toDataURL('image/jpeg'));

                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        })
    }

    static resizeImage(settings: IResizeImageOptions): Promise<Blob | string> {
        const file = settings.file;
        const _width = settings.width;
        const _height = settings.height;

        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement('canvas');

        const resize = () => {
            let iwidth = image.width;
            let iheight = image.height;
            let width = image.width;
            let height = image.height;


            if (_width && width > _width) {
                height = iheight * _width / iwidth;
                width = _width;
            } else if (_height && height > _height) {
                width = iwidth * _height / iheight;
                height = _height;
            }

            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, iwidth, iheight, 0, 0, width, height);

            let dataUrl = canvas.toDataURL('image/jpeg');
            if (settings.asUrl)
                return dataUrl
            return ImageUtils.dataURItoBlob(dataUrl);
        };

        return new Promise((ok, no) => {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }

            reader.onload = (readerEvent: any) => {
                image.onload = () => ok(resize());
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        })
    }
    static resizeCropCoverImage(settings: IResizeImageOptions): Promise<Blob | string> {
        const file = settings.file;
        const _width = settings.width;
        const _height = settings.height;
        const reader = new FileReader();
        const image = new Image();
        const canvas = document.createElement('canvas');

        const resize = () => {
            let width = image.width;
            let height = image.height;

            let x = 0;
            let y = 0;

            if (width / height > _width / _height) {
                //cut width
                x = (width - height * _width / _height) / 2;
                // x *= _height / height;

            } else {
                // cut height
                y = (height - width * _height / _width) / 2;
                // y *= _width / width;
            }

            canvas.width = _width;
            canvas.height = _height;
            canvas.getContext('2d').drawImage(image, x, y, width - 2 * x, height - 2 * y, 0, 0, _width, _height);
            let dataUrl = canvas.toDataURL('image/jpeg');
            if (settings.asUrl)
                return dataUrl;
            return ImageUtils.dataURItoBlob(dataUrl);
        };

        return new Promise((ok, no) => {
            if (file.type && !file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }

            reader.onload = (readerEvent: any) => {
                image.onload = () => ok(resize());
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        })
    }
}