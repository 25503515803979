<ng-container *ngIf="visible">
   <div [matMenuTriggerFor]="typeMenu">
      <ng-content></ng-content>
   </div>


   <mat-menu #typeMenu="matMenu">
      <div (click)="$event.stopPropagation()" class="ctlFilter">

         <mat-radio-group (change)="filter.update($event); refresh()" [(ngModel)]="filter.v">
            <div mat-menu-item>
               <mat-radio-button [value]="boolTypes.ALL">{{names[0]}}</mat-radio-button>
            </div>
            <div mat-menu-item>

               <mat-radio-button [value]="boolTypes.TRUE">{{names[1]}}</mat-radio-button>
            </div>
            <div mat-menu-item>

               <mat-radio-button [value]="boolTypes.FALSE">{{names[2]}}</mat-radio-button>
            </div>
         </mat-radio-group>
         <div class="bottom" mat-menu-item>
            <div class="button" (click)="clear()">{{_common.clear}}</div>
         </div>
      </div>
   </mat-menu>
</ng-container>