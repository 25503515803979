/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import { AccessUtils, TLocation, TSpaceFacility } from '../../core/entities';
import { SiteEditUtils } from '../../core/lang';
import { SessionCache } from '../../session.cache';
import { SessionService, TIMEZONE } from '../../session.service';
@Component({
  templateUrl: './space.facility.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
})
export class SpaceFacilityEditDialogComponent extends CompareWithMixin(
  EmptyObject
) {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TSpaceFacility;

  api = '/api/space-facility';
  dlangs = SiteEditUtils.OTHER_LANGS;
  location: TLocation;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SpaceFacilityEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;
    this.location = data.location;
    // this.cache.loadLocation(this.data.organization.id, (list) => {
    //   this.locations = list;
    // });

    this.form = this.fb.group({
      name: ['', Validators.required],
      name_lang: SiteEditUtils.langArray(this.fb),
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TSpaceFacility;

          AccessUtils.fixSpaceFacility(this.object);

          let query = Object.assign({}, this.object) as any;

          utils.fill_form_values(query, this.form);

          this.updateForm();
        }
      );
    } else {
      let query = {
        timezone: TIMEZONE,
        location: this.data?.location,
        space: this.data?.space,
      } as any;

      utils.fill_form_values(query, this.form);

      this.updateForm();
    }
  }

  updateForm() {
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let formData = new FormData();

    let query = Object.assign({}, this.form.value);
    SiteEditUtils.langValues(query, 'name');

    query.location = WebUtils.objName(this.location);
    // query.images = ImageUploadBase.getFile(formData, 'images', this.container);
    formData.append('data', JSON.stringify(query));
    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, formData),
        async (result) => {
          this.object = result as TSpaceFacility;
          AccessUtils.fixSpaceFacility(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, formData),
        async (result) => {
          this.object = result as TSpaceFacility;
          AccessUtils.fixSpaceFacility(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
}
