<!-- @format -->

<div class="dialog-max cdialog" style="min-width: 600px; height: calc(80vh - 48px)">
  <form [formGroup]="form">
    <div class="dialogHeader">
      <div mat-dialog-title>{{_common.thinkdesks.set_schedule}}</div>
      <div class="spacer"></div>
      <div (click)="cancel()" class="closeButton">
        <fa-icon icon="times"></fa-icon>
      </div>
    </div>
  </form>
  <mat-dialog-content>
    <div class="max-scroll dialogBody">
      <form [formGroup]="form" class="flex flex-col space-y-4">
        <!-- <div class="display:flex">
                     <app-form-field style="flex: 1 1 auto; padding-right:10px;" >
                        <app-label>{{_common.code}}</app-label>
                        <input matInput formControlName="code" required>
                     </app-form-field>

                     <app-form-field style="flex: 1 1 auto; padding-right:10px;" >
                        <app-label>Name</app-label>
                        <input matInput formControlName="name" required>
                     </app-form-field>


                  </div>

                  <div>
                     <app-form-field >
                        <app-label>Mode</app-label>
                        <mat-select>
                           <mat-option>Named</mat-option>
                           <mat-option>Pool</mat-option>
                        </mat-select>
                     </app-form-field>
                  </div> -->

        <div>
          <app-form-field style="margin-right: 10px">
            <app-label>{{_common.thinkdesks.from}}</app-label>
            <input matInput [matDatepicker]="startDate" formControlName="startDate" />
            <mat-datepicker-toggle appSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </app-form-field>

          <app-form-field style="margin-right: 10px">
            <app-label>{{_common.thinkdesks.to}}</app-label>
            <input matInput [matDatepicker]="endDate" formControlName="endDate" />
            <mat-datepicker-toggle appSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </app-form-field>
        </div>

        <div>{{_common.thinkdesks.schedule_info}}</div>

        <div class="flex flex-col space-y-2 divide-y">
          <app-booking-calendar-day [form]="$any(form.controls['mon'])" [title]="_common.thinkdesks.mon" #mon>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['mon'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['tue'])" [title]="_common.thinkdesks.tue" #tue>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['tue'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['wed'])" [title]="_common.thinkdesks.wed" #wed>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['wed'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['thu'])" [title]="_common.thinkdesks.thu" #thu>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['thu'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['fri'])" [title]="_common.thinkdesks.fri" #fri>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['fri'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['sat'])" [title]="_common.thinkdesks.sat" #sat>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['sat'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['sun'])" [title]="_common.thinkdesks.sun" #sun>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['sun'])"></fa-icon>
          </app-booking-calendar-day>

          <app-booking-calendar-day [form]="$any(form.controls['ph'])" [title]="_common.thinkdesks.public_holiday" #ph>
            <fa-icon icon="clone" [matMenuTriggerFor]="applyMenu" (menuOpened)="_open(form.controls['ph'])"></fa-icon>
          </app-booking-calendar-day>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <div class="spacer"></div>
    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>

<mat-menu #applyMenu="matMenu">
  <div (click)="$event.stopPropagation()">
    <div style="line-height: 48px; padding: 0 16px">{{_common.thinkdesks.copy_to}}</div>
    <div mat-menu-item>
      <mat-checkbox (click)="_toggle()"></mat-checkbox>{{_common.thinkdesks.select_all}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_mon"></mat-checkbox> {{_common.thinkdesks.mon}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_tue"></mat-checkbox> {{_common.thinkdesks.tue}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_wed"></mat-checkbox> {{_common.thinkdesks.wed}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_thu"></mat-checkbox> {{_common.thinkdesks.thu}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_fri"></mat-checkbox> {{_common.thinkdesks.fri}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_sat"></mat-checkbox> {{_common.thinkdesks.sat}}
    </div>
    <div mat-menu-item>
      <mat-checkbox [(ngModel)]="_sun"></mat-checkbox> {{_common.thinkdesks.sun}}
    </div>
  </div>
  <div mat-menu-item>
    <button class="app-btn" color="primary" (click)="applyTemplate()" style="width: 100%">
      {{_common.save}}
    </button>
  </div>
</mat-menu>