<div>Locker Page</div>

<div *ngIf="object">
  <div>
    <div *ngFor="let image of object.images">
      <img [src]="lockerImage(object,image)" style="width: 100px" />
    </div>
  </div>
  <div>{{object.name}}</div>

  <div [innerHtml]="description" style="margin-bottom: 1rem"></div>
  <div class="app-btn" [routerLink]="[session.path('/locker/'+id+'/book')]">
    <div>Book Now</div>
  </div>
</div>
