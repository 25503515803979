<!-- @format -->

<div class="ctlSidebarLayout" style="height: 100%">
  <div class="sidebarBody max-height">
    <app-month-calendar (dateClicked)="dateClicked($event)" (dateAction)="dateAction($event)"
      [actions]="[{ icon: 'calendar', action: 'schedule' }]" [loader]="loader" #calendar></app-month-calendar>
  </div>
  <div class="sidebar">
    <ng-content></ng-content>
    <div class="card" style="margin-left: 10px; margin-top: 10px">
      <div class="cardBody">
        <div style="padding: 10px 0">
          <!-- no calendar -->
          <ng-container *ngIf="!subkey">
            <div>
              <button class="app-btn" color="primary" (click)="openDefault()" *ngIf="active">
                {{ _common.thinkdesks.set_schedule }}
              </button>
            </div>
            <div>
              <div style="
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  gap: 5px;
                ">
                <div style="width: 20px; height: 20px; border-radius: 5px" [style.backgroundColor]="colors[0]"></div>
                <div>{{ _common.thinkdesks.regular_schedule }}</div>
              </div>

              <div style="
                  display: flex;
                  align-items: center;
                  margin-top: 10px;
                  margin-bottom: 10px;
                  gap: 5px;
                ">
                <div style="width: 20px; height: 20px; border-radius: 5px" [style.backgroundColor]="dayColor"></div>
                <div>{{ _common.thinkdesks.special_day }}</div>
              </div>
            </div>
            <div *ngIf="selected">
              <div *ngIf="selectedCalendar" style="
                  margin-top: 10px;
                  margin-bottom: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid #ccc;
                ">
                <div>
                  {{ _common.thinkdesks.regular_schedule }} #{{ selectedCalendar.id }}
                </div>
                <div style="margin-bottom: 10px">
                  {{ _common.thinkdesks.applicable_date }}
                  {{ formatDate(selectedCalendar.startDate) }}
                  <ng-container *ngIf="!selectedCalendar.endDate">
                    {{ _common.thinkdesks.onwards }}
                  </ng-container>
                  <ng-container *ngIf="selectedCalendar.endDate">
                    - {{ formatDate(selectedCalendar.endDate) }}
                  </ng-container>
                </div>
                <div *ngIf="selectedCalendar.mon">
                  {{_common.thinkdesks.mon}}:{{ selectedCalendar.mon.toText() }}
                </div>
                <div *ngIf="selectedCalendar.tue">
                  {{_common.thinkdesks.tue}}:{{ selectedCalendar.tue.toText() }}
                </div>
                <div *ngIf="selectedCalendar.wed">
                  {{_common.thinkdesks.wed}}:{{ selectedCalendar.wed.toText() }}
                </div>
                <div *ngIf="selectedCalendar.thu">
                  {{_common.thinkdesks.thu}}:{{ selectedCalendar.thu.toText() }}
                </div>
                <div *ngIf="selectedCalendar.fri">
                  {{_common.thinkdesks.fri}}:{{ selectedCalendar.fri.toText() }}
                </div>
                <div *ngIf="selectedCalendar.sat">
                  {{_common.thinkdesks.sat}}:{{ selectedCalendar.sat.toText() }}
                </div>
                <div *ngIf="selectedCalendar.sun">
                  {{_common.thinkdesks.sun}}:{{ selectedCalendar.sun.toText() }}
                </div>
                <div *ngIf="selectedCalendar.ph">
                  {{_common.thinkdesks.public_holiday}}:{{ selectedCalendar.ph.toText() }}
                </div>
              </div>

              <div style="font-weight: bold; color: #039be5">
                {{ _common.thinkdesks.selected_day }}
                {{ selected.date.format("YYYY-MM-DD") }}
              </div>

              <div *ngIf="selected.day" style="margin-bottom: 10px; color: #039be5">
                {{ _common.thinkdesks.special_in_use }}
                <ng-container *ngIf="selected.daySchedule">
                  {{ selected.daySchedule.toText() }}
                </ng-container>
                <ng-container *ngIf="!selected.daySchedule">
                  {{ _common.thinkdesks.closed }}
                </ng-container>
              </div>

              <!-- <ng-container *ngIf="active">
                <div style="margin-top: 20px">
                  Set Sepcial
                </div>
                <app-booking-calendar-day
                  #calendarDay
                  [form]="form"
                ></app-booking-calendar-day>
                <div class="flex items-center">
                  <button
                    class="app-btn btn-outline"
                    (click)="removeSchedule()"
                    *ngIf="selected.day"
                  >
                    Removed
                  </button>
                  <div class="spacer"></div>
                  <button
                    class="app-btn"
                    color="primary"
                    (click)="saveSchedule()"
                  >
                    {{ _common.save }}
                  </button>
                </div>
              </ng-container> -->
            </div>
          </ng-container>
          <ng-container *ngIf="subkey">
            <div style="
                display: flex;
                align-items: center;
                margin-top: 10px;
                gap: 5px;
              ">
              <div style="width: 20px; height: 20px; border-radius: 5px" [style.backgroundColor]="colors[0]"></div>
              <div>{{ selectedCalendar }}</div>
            </div>

            <div style="
                display: flex;
                align-items: center;
                margin-top: 10px;
                gap: 5px;
              ">
              <div style="width: 20px; height: 20px; border-radius: 5px" [style.backgroundColor]="dayColor"></div>
              <div>{{ _common.thinkdesks.special_day }}</div>
            </div>
            <!-- <div *ngIf="selected">
                <ng-container *ngIf="active">
                  <div style="margin-top: 20px">
                    {{ _common.bookings.schedule.SetSpecial }}
                  </div>
                  <app-booking-calendar-day
                    #calendarDay
                    [form]="form"
                  ></app-booking-calendar-day>
                  <div class="flex items-center">
                    <button
                      class="app-btn btn-outline"
                      (click)="removeSchedule(true)"
                      *ngIf="selected.day"
                    >
                      {{ _common.bookings.schedule.removed }}
                    </button>
                    <div class="spacer"></div>
                    <button
                      class="app-btn"
                      color="primary"
                      (click)="saveSchedule(true)"
                    >
                      {{ _common.save }}
                    </button>
                  </div>
                </ng-container>
              </div> -->
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>