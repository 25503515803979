import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SessionService } from '../session.service';
import { MapWidget } from './map.widget';

@Component({
  selector: 'app-location-map',
  standalone: true,
  imports: [CommonModule, MapWidget],
  templateUrl: './location.map.page.html',
})
export class LocationMapPage {
  constructor(private session: SessionService) {}
  ngOnInit() {}
}
