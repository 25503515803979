<!-- @format -->

<ng-container *ngIf="visible && filter.visible">
  <div [matMenuTriggerFor]="typeMenu">
    <ng-content></ng-content>
  </div>

  <mat-menu #typeMenu="matMenu">
    <div (click)="$event.stopPropagation()" class="ctlFilter">
      <div
        mat-menu-item
        *ngFor="let v of values; let i = index"
        style="display: flex; align-items: center"
        (click)="filter.toggle(v, $event); refresh()"
      >
        <mat-checkbox
          [(ngModel)]="filter.v[v]"
          (change)="filter.update(v, $event); refresh()"
          (click)="$event.stopPropagation()"
        >
        </mat-checkbox>
        {{ names[i] }}
      </div>

      <div class="bottom">
        <div class="button" (click)="filter.clear(); refresh()">
          {{ _common.clear }}
        </div>
        <div
          class="button"
          style="margin-left: 10px"
          (click)="filter.toggleAll(values); refresh()"
        >
          {{ _common.toggle }}
        </div>
      </div>
    </div>
  </mat-menu>
</ng-container>
