<div class="client_profile_page" *ngIf="session.user; let user">
  <div class="profile_card_top">
    <div>
      <img src="/silone/silone_hi.png" />
    </div>
    <div class="text-3xl font-bold">{{user.name}}</div>
  </div>

  <div class="profile_card">
    <fa-icon icon="envelope"></fa-icon>

    <div class="mb-4">{{user.email}}</div>

    <fa-icon icon="phone-flip"></fa-icon>
    <div>{{user.mobile}}</div>
    <div *ngIf="!user.mobile">N/A</div>

  </div>


  <div class="profile_card_bottom">
    <div class="client_button btn_circle"
      style="height: 70px;
      width: 70px;
      font-size: 30px;">
      <fa-icon icon="qrcode"></fa-icon>

    </div>
  </div>


</div>
