import { CommonModule, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from '../common/utils';
import { AccessUtils, TBooking, TSpace } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { BookingCalendarUtils } from '../thinkdesks/booking.calendar.utils';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { BookingSharingService } from './booking.sharing';
import { SpaceCard } from './space.card';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-my-booking-page',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, SpaceCard, MatMenuModule,],
  templateUrl: './my.booking.page.html',
})
export class MyBookingPage extends SiteLangMixin(
  SiteFormatMixin(SitePathMixin(EmptyObject))
) {
  @HostBinding('class') css = 'max-scroll';
  susbcription: Subscription;
  id: number;
  _common = _common;

  object: TBooking;
  openingHours: string[] = [];
  constructor(
    public sharing: BookingSharingService,

    private locationStrategy: LocationStrategy,
    private route: ActivatedRoute,
    private router: Router,
    public override session: SessionService,
    private cache: SessionCache,
    private http: HttpClient
  ) {
    super();
  }
  ngOnInit() {
    this.susbcription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));

      this.refresh();
    });
  }
  ngOnDestroy() {
    this.susbcription.unsubscribe();
  }
  refresh() {
    window.scrollTo(0, 0);
    WebUtils.web_result(
      this.http.get(`/api/public/booking/retrieve/${this.id}`),
      (result) => {
        this.object = result;
        AccessUtils.fixBooking(this.object);
        AccessUtils.fixSpace(this.object.space as TSpace);
        let location = this.object.space?.location;
        let s =
          this.lang(location?.openingHours, location?.openingHours_lang) ?? '';
        this.openingHours = s.split('\n');
      }
    );
  }
  googleCalendar() {
    BookingCalendarUtils.googleCalendar(this.locationStrategy, this.object);
  }
  bookAgain() {
    this.router.navigate([`/space/${this.object.space.id}`]);
  }
}
