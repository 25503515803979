<ng-container *ngIf="visible && filter.visible">
   <div [matMenuTriggerFor]="typeMenu">

      <ng-content></ng-content>
      <ng-container *ngIf="filter.v">
         {{filter.v}}
      </ng-container>

   </div>

   <mat-menu #typeMenu="matMenu">
      <div class="ctlFilter">
         <div mat-menu-item *ngFor="let v of values; let i=index" style="display:flex; align-items:center"
            (click)="filter.update(v); refresh();">
            {{names[i]}}

         </div>
         <div class="bottom">
            <div class="button" (click)="filter.clear(); refresh()">{{_common.clear}}</div>
         </div>
      </div>
   </mat-menu>
</ng-container>