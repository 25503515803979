<!-- @format -->

<div class="dialog-max cdialog" style="width: 95vw; height: 95vh">
  <div class="dialogHeader">
    <div mat-dialog-title>
      Schedule - {{object?.name}}
    </div>

    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="dialogBody max-scroll ctlSettings">
      <app-schedule-calendar [key]="key">
        <!--<div class="card">
            <div class="cardBody"></div>
          </div>-->
      </app-schedule-calendar>
    </div>
  </mat-dialog-content>
</div>
