<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader">
    <div mat-dialog-title *ngIf="!objectId">{{_common.add_space}}</div>
    <div mat-dialog-title *ngIf="objectId">
      <div>
        <div class="font-bold">{{object?.name}}</div>
        <div style="font-size: small">{{ object?.location?.name }}</div>
      </div>
    </div>
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab [label]="_common.space_settings">
        <div class="max-scroll dialogBody editDialog_mobile">
          <form [formGroup]="form" class="ctlSidebarLayout">
            <div class="sidebar sidebarBody">
              <div class="card">
                <!-- <div class="cardHeader">Settings</div> -->
                <div class="cardBody space-y-2">
                  <div *ngIf="!objectId">
                    <app-form-field style="width: 50%">
                      <app-label>{{_common.organization}}</app-label>
                      <mat-select
                        formControlName="organization"
                        [compareWith]="compareWith"
                        (selectionChange)="updateLocation()"
                      >
                        <mat-option *ngFor="let o of organizations" [value]="o"
                          >{{o.name}}</mat-option
                        >
                      </mat-select>
                    </app-form-field>
                  </div>
                  <div *ngIf="!objectId">
                    <app-form-field style="width: 50%">
                      <app-label>{{_common.center}}</app-label>
                      <mat-select
                        formControlName="location"
                        [compareWith]="compareWith"
                        (selectionChange)="updateLocation2(this.form.value.location)"
                      >
                        <mat-option *ngFor="let o of locations" [value]="o"
                          >{{o.name}}</mat-option
                        >
                      </mat-select>
                    </app-form-field>
                  </div>
                  <div class="flex gap-2">
                    <app-form-field class="flex-auto">
                      <app-label>{{_common.space_name}}</app-label>
                      <input matInput formControlName="name" required />
                    </app-form-field>
                    <app-form-field style="width: 100px">
                      <app-label>{{_common.code}}</app-label>
                      <app-hint>({{_common.optional}})</app-hint>
                      <input matInput formControlName="code" />
                    </app-form-field>
                  </div>

                  <div>
                    <div>{{_common.introduction}}</div>
                    <ckeditor [editor]="Editor" formControlName="description">
                    </ckeditor>
                  </div>
                  <div>
                    <app-form-field class="w-full">
                      <app-label>{{_common.remarks}}</app-label>
                      <textarea matInput formControlName="remarks"></textarea>
                    </app-form-field>
                  </div>
                  <div class="flex gap-2">
                    <app-form-field style="width: 50%">
                      <app-label>{{_common.capacity}}</app-label>
                      <input matInput formControlName="capacity" />
                    </app-form-field>
                    <app-form-field style="width: 50%">
                      <app-label>{{_common.venue_size}}</app-label>
                      <input matInput formControlName="area" />
                      <span appSuffix>m<sup>2</sup></span>
                    </app-form-field>
                  </div>
                </div>
                <div class="cardDivider"></div>
                <div class="cardHeader">{{_common.space_categories}}</div>
                <div class="cardBody">
                  <div class="flex flex-wrap gap-4">
                    <div
                      *ngFor="let type of spaceTypes; let i=index"
                      style="width: fit-content"
                    >
                      <mat-checkbox
                        [formControl]="$any(formTypes.controls[i])"
                      ></mat-checkbox>
                      {{type.name}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="cardHeader">{{_common.space_facilities}}</div>
                <div class="cardBody">
                  <app-form-field class="w-full">
                    <app-label>{{_common.facilities_included}}</app-label>
                    <input matInput formControlName="includedFacilities" />
                  </app-form-field>
                </div>
                <div class="cardDivider"></div>
                <div class="cardBody">
                  <div class="font-bold mb-4">
                    {{_common.additional_facilities_available_for_rent}}
                  </div>
                  <div *ngIf="!spaceFacilities.length" style="color: #707070">
                    {{_common.no_facility_found}}
                  </div>
                  <div class="grid grid-cols-2 gap-2">
                    <div *ngFor="let f of spaceFacilities; let i=index">
                      <mat-checkbox
                        [formControl]="$any(formFacilities.controls[i])"
                      ></mat-checkbox>
                      {{f.name}}
                    </div>
                  </div>
                </div>
              </div>

              <!--Booking Setting-->
              <div class="card">
                <div class="cardHeader">{{_common.booking_settings}}</div>
                <div class="cardBody space-y-2">
                  <div>
                    <app-form-field style="width: 50%">
                      <app-label
                        >{{_common.defual_space_usage_themes}}</app-label
                      >
                      <mat-select
                        formControlName="purpose"
                        [compareWith]="compareWith"
                      >
                        <mat-option *ngFor="let o of spacePurposes" [value]="o">
                          {{o.name}}
                        </mat-option>
                      </mat-select>
                    </app-form-field>
                  </div>
                  <div>
                    <app-form-field style="width: 50%">
                      <app-label>{{_common.calendar}}</app-label>
                      <mat-select
                        formControlName="bookSchedule"
                        [compareWith]="compareWith"
                      >
                        <mat-option [value]="v" *ngFor="let v of schedules"
                          >{{v.name}}</mat-option
                        >
                      </mat-select>
                    </app-form-field>
                  </div>
                  <div class="flex gap-2">
                    <app-form-field class="w-full">
                      <app-label>{{_common.days_in_advance}}</app-label>
                      <input matInput formControlName="bookDays" />
                      <span appSuffix>{{_common.day}}</span>
                    </app-form-field>
                    <app-form-field class="w-full">
                      <app-label>{{_common.min}}</app-label>
                      <mat-select formControlName="bookMinSlots">
                        <mat-option [value]="1">30 {{_common.mins}}</mat-option>
                        <mat-option [value]="2">60 {{_common.mins}}</mat-option>
                        <mat-option [value]="3">90 {{_common.mins}}</mat-option>
                        <mat-option [value]="4"
                          >120 {{_common.mins}}</mat-option
                        >
                      </mat-select>
                    </app-form-field>
                  </div>
                  <div class="flex gap-2">
                    <app-form-field class="w-full">
                      <app-label>{{_common.increment}}</app-label>
                      <mat-select formControlName="bookSlotIncrement">
                        <mat-option [value]="1">30 {{_common.mins}}</mat-option>
                        <mat-option [value]="2">60 {{_common.mins}}</mat-option>
                      </mat-select>
                    </app-form-field>
                    <app-form-field class="w-full">
                      <app-label>{{_common.max}}</app-label>
                      <mat-select formControlName="bookMaxSlots">
                        <mat-option [value]="1">30 {{_common.mins}}</mat-option>
                        <mat-option [value]="2">60 {{_common.mins}}</mat-option>
                        <mat-option [value]="3">90 {{_common.mins}}</mat-option>
                        <mat-option [value]="4"
                          >120 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="5"
                          >150 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="6"
                          >180 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="7"
                          >210 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="8"
                          >240 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="9"
                          >270 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="10"
                          >300 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="11"
                          >330 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="12"
                          >360 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="13"
                          >390 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="14"
                          >420 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="15"
                          >350 {{_common.mins}}</mat-option
                        >
                        <mat-option [value]="16"
                          >480 {{_common.mins}}</mat-option
                        >
                      </mat-select>
                    </app-form-field>
                  </div>
                </div>
              </div>

              <!--Terms-->
              <div class="card">
                <div
                  class="cardHeader flex-col"
                  style="align-items: flex-start"
                >
                  {{_common.TermsAndConditions_space}}
                  <div class="text-exp">
                    {{_common.TermsAndConditions_space_exp}}
                  </div>
                </div>
                <div class="cardBody">
                  <app-form-field class="w-full">
                    <textarea matInput formControlName="terms"></textarea>
                  </app-form-field>
                </div>
              </div>
            </div>

            <div class="sidebar sidebarRight">
              <div class="card">
                <div class="cardHeader">{{_common.space_status}}</div>
                <div class="cardBody">
                  <div>
                    <mat-slide-toggle
                      formControlName="active"
                    ></mat-slide-toggle>
                    {{_common.active}}
                  </div>
                  <div>
                    <mat-slide-toggle
                      formControlName="autoApprove"
                    ></mat-slide-toggle>
                    {{_common.auto_approve}}
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="cardHeader">{{_common.images}}</div>
                <div class="cardBody">
                  <app-image-list
                    [multiple]="true"
                    [imageContainer]="container"
                  ></app-image-list>
                </div>
              </div>

              <div class="card">
                <div class="cardHeader">{{_common.door_lock_settings}}</div>
                <div class="cardBody space-y-2">
                  <div class="flex">
                    <div class="spacer">{{_common.smart_lock}}</div>
                    <mat-slide-toggle formControlName="active" #smartLockON>
                      <span *ngIf="smartLockON.checked; else smartLockOFF">
                        {{ _common.enable }}
                      </span>
                      <ng-template #smartLockOFF>
                        <span>{{ _common.disable }}</span>
                      </ng-template>
                    </mat-slide-toggle>
                    <!--(A Smart Lock Room, cannot change delete, and?)-->
                  </div>
                  <div>
                    <!--<app-form-field class="w-full">
                      <app-label
                        >Zone ID (when connected to Smart Lock)</app-label
                      >
                      <input matInput formControlName="thinkdesksSpaceCode" />
                    </app-form-field>-->
                    Zone ID: {{object?.thinkdesksSpaceCode}}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="{{_common.other_language}}">
        <div class="dialogBody max-scroll">
          <div *ngFor="let dlang of dlangs" class="card">
            <div class="cardBody flex flex-col space-y-2">
              <div class="lang-chip">
                {{_common[_common.langs??[dlang]??dlang]}}
              </div>

              <div [formGroup]="$any(form.controls['name_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.space_name}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['description_lang'])">
                <div>{{_common.introduction}}</div>
                <ckeditor [editor]="Editor" [formControlName]="dlang">
                </ckeditor>
              </div>

              <div [formGroup]="$any(form.controls['remarks_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.remarks}}</app-label>
                  <textarea matInput [formControlName]="dlang"></textarea>
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['includedFacilities_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.facilities_included}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['terms_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.TermsAndConditions_space}}</app-label>
                  <textarea matInput [formControlName]="dlang"></textarea>
                </app-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <ng-container *ngIf="object">
        <!-- <mat-tab label="活動">
          <div class="max-height dialogBody">
            <app-event-list
              [space]="object"
              [location]="object.location"
              [organization]="object.location?.organization"
            ></app-event-list>
          </div>
        </mat-tab>-->
        <!-- <mat-tab [label]="_common.booking_records">
          <div class="max-height dialogBody">TODO (When frontend ready)</div>
        </mat-tab> -->
        <!--<mat-tab label="Calendar">
          <app-schedule-calendar [key]="key" [subkey]="subkey">
            
          </app-schedule-calendar>
        </mat-tab>-->
      </ng-container>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <button
      class="app-btn btn-outline"
      (click)="remove()"
      *ngIf="objectId && session.hasPermission(permissions.SPACE_DELETE)"
    >
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button
      class="app-btn"
      (click)="save()"
      color="primary"
      *ngIf="session.hasPermission(permissions.SPACE_EDIT)"
    >
      {{_common.save}}
    </button>
  </div>
</div>
