<!-- @format -->

<div
  class="dialog-max cdialog"
  style="width: 40vw; max-height: 80vh"
>
  <div class="dialogHeader">
    <div mat-dialog-title>{{ _common.BookingDetails }}</div>
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-scroll dialogBody" *ngIf="object">

      <ng-container *ngIf="object.status == statusList.pending">
        <div class="card">
          <div class="cardBody flex items-center">
            <div class="flex-auto">{{_common.booking_approval}}</div>
            <div class="flex gap-2">

              <button class="app-btn btn-outline" (click)="reject()">
                <fa-icon icon="circle-xmark" style="margin-right: 5px; color: red;"></fa-icon>
                {{ _common.reject }}
              </button>
              <button class="app-btn btn-outline" (click)="approve()">
                <fa-icon icon="circle-check" style="margin-right: 5px; color: #409E4C;"></fa-icon
                >{{ _common.approve }}
              </button>
            </div>


          </div>

        </div>

      </ng-container>

      <div class="card">
        <div class="cardBody">
          <div class="flex items-center">
            <div class="text-xs spacer" style="color: #707070;">
              {{ object.bookingNo }}
            </div>
            <div>
              {{_common.usage_theme}}: {{ object.purpose?.name }}
              <button class="text-link link-blue" (click)="selectPurpose()">({{_common.change}})</button>
            </div>
          </div>

          <div class="mb-4">
            <div class="spacer">
              <div *ngIf="object.space; let space" class="text-xl font-bold">
                {{ lang(space.name, space.name_lang) }}
              </div>
              <ng-container *ngIf="object.space.location; let location">
                <div class="text-xs">
                  <ng-container
                    *ngIf="$any(location).organization; let organization"
                  >
                    <div style="color: #707070">
                      {{ lang(organization.name, organization.name_lang) }}
                    </div>
                  </ng-container>
                  {{ lang(location.name, location.name_lang) }}
                </div>
              </ng-container>

            </div>

          </div>

          <div class="app-chip Status mb-2" [ngClass]="object.status">
            {{ _common.bookingStatus[object.status] }}
          </div>

          <div style="display: grid; grid-template-columns: 100px 1fr">
            <div><fa-icon icon="calendar-days"></fa-icon></div>
            <div>
              {{ dateText(object) }}
            </div>
            <div><fa-icon icon="clock"></fa-icon></div>
            <div>
              {{ timeText(object, _common) }}
            </div>
            <div><fa-icon icon="user"></fa-icon></div>
            <div>{{ object.member?.name }} {{ object.user?.name }}</div>

            <ng-container *ngIf="object.locker; let locker">
              <div>{{ _common.Locker }}</div>
              <div>
                <div>
                  {{ locker.name }}
                </div>
                <div>
                  {{ object.lockerCell?.name }}
                </div>
              </div>
            </ng-container>
            
          </div>
        </div>
        <!-- <div class="cardDivider"></div> -->
        <div class="cardBody">
          <div class="underline">
            {{_common.AdditionalFacilities}}
          </div>
          <ng-container *ngIf="!object.facilities?.length"> N/A </ng-container>
          <div *ngFor="let f of object.facilities">
            {{ lang(f.name,f.name_lang) }}
          </div>
        </div>

        <!-- <div class="cardDivider"></div> -->
        <div class="cardBody">
          <div class="underline">{{_common.Remarks}}</div>
          <div *ngIf="object.remarks && object.remarks != ''">
            {{ object.remarks }}
          </div>
          <div *ngIf="!object.remarks || object.remarks==''">
            N/A

          </div>
        </div>
      </div>
      <div *ngIf="object" class="card">
        <div class="cardHeader">{{_common.door_log}}</div>
        <div class="cardBody">
          <div *ngIf="!object.checkinLogs.length">N/A</div>
          <div *ngFor="let l of object.checkinLogs">
            <div>
              {{formatTime(l.time)}}
              {{l.member?.name}}
              {{l.deviceName}}
            </div>
            
          </div>
        </div>

      </div>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{ _common.delete }}
    </button>
    <button class="app-btn btn-outline" (click)="sync()" *ngIf="objectId">
      <fa-icon icon="sync" style="margin-right: 5px"></fa-icon
      >{{ _common.Sync }}
    </button>

    <ng-container *ngIf="object">
      <ng-container *ngIf="object.status == statusList.cancelled">
        <button class="app-btn btn-outline" (click)="cancelBooking()">
          <fa-icon icon="sync" style="margin-right: 5px"></fa-icon>Cancel
        </button>
      </ng-container>
    </ng-container>
    <div class="spacer"></div>

    <!-- <button class="app-btn" (click)="save()" color="primary">
      {{ _common.save }}
    </button> -->
  </div>
</div>
