/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import { CompareWithMixin, EmptyObject } from '../../common/utils';
import { SessionCache } from '../../session.cache';
import { SessionService } from '../../session.service';
import { TAgent } from '../../thinkdesks/thinkdesks.entities';
import { AgentContentListComponent } from '../content/agent.content.list.component';
@Component({
  templateUrl: './agent.content.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    AgentContentListComponent,
  ],
})
export class AgentContentDialogComponent extends CompareWithMixin(EmptyObject) {
  _common = _common;

  object: TAgent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgentContentDialogComponent>
  ) {
    super();
    this.object = this.data.object;
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
