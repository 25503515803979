<fa-icon icon="calendar-week" style="font-size: 18px;
color: #707070;
margin-right: 10px;"></fa-icon>

<app-form-field class="type">
   <mat-select [(ngModel)]="range.type" (selectionChange)="onChange()">
      <mat-option *ngFor="let r of types" [value]="r.id">{{r.name}}</mat-option>
   </mat-select>
</app-form-field>


<app-form-field class="date">
   <!--<fa-icon appPrefix icon="edit" *ngIf="highlightStart"></fa-icon>-->
   <input matInput [matDatepicker]="start" [formControl]="startDate" (dateInput)="onChange()" (dateChange)="onChange()">
   <mat-datepicker-toggle appSuffix [for]="start"></mat-datepicker-toggle>
   <mat-datepicker #start></mat-datepicker>


</app-form-field>
<span class="dash">-</span>
<app-form-field class="date date2">
   <fa-icon appPrefix icon="edit" *ngIf="highlightEnd"></fa-icon>
   <input matInput [matDatepicker]="end" [formControl]="endDate" (dateInput)="onChange()" (dateChange)="onChange()">
   <mat-datepicker-toggle appSuffix [for]="end"></mat-datepicker-toggle>
   <mat-datepicker #end></mat-datepicker>
</app-form-field>


<!--<button class="app-btn btn-text"><fa-icon icon="plus"></fa-icon></button>-->