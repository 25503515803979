import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterOutlet } from '@angular/router';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faArrowRightToBracket,
  faBars,
  faCalendar,
  faCalendarCheck,
  faCalendarDays,
  faCalendarXmark,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faClone,
  faCog,
  faCopy,
  faEllipsisH,
  faEllipsisV,
  faEllipsisVertical,
  faEnvelope,
  faGlobe,
  faHeart,
  faHouse,
  faLanguage,
  faLink,
  faMapLocationDot,
  faPen,
  faPhoneFlip,
  faPlus,
  faQrcode,
  faSearch,
  faSlidersH,
  faSync,
  faTags,
  faThumbTack,
  faTimes,
  faTimesCircle,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { LoaderComponent } from './common/loader/loader.component';
import { PeopleUtilsMixin } from './common/people_utils';
import { EmptyObject } from './common/utils';
import { AppPermissions } from './core/permissions';
import { SessionService } from './session.service';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    LoaderComponent,
    FontAwesomeModule,
    MatMenuModule,
  ],
})
export class AppComponent extends PeopleUtilsMixin(EmptyObject) {
  @HostBinding('class') css = 'max-height';

  _common = _common;

  drawerOpened = false;
  constructor(public session: SessionService, library: FaIconLibrary) {
    super();
    // Add an icon to the library for convenient access in other components
    library.addIcons(faEllipsisH);
    library.addIcons(faEllipsisV);
    library.addIcons(faSlidersH);
    library.addIcons(faSearch);
    library.addIcons(faSync);
    library.addIcons(faChevronUp);
    library.addIcons(faChevronDown);
    library.addIcons(faChevronRight);
    library.addIcons(faChevronLeft);
    library.addIcons(faTimes);
    library.addIcons(faPlus);
    library.addIcons(faPen);
    library.addIcons(faTrash);
    library.addIcons(faAngleDoubleLeft);
    library.addIcons(faAngleDoubleRight);
    library.addIcons(faAngleLeft);
    library.addIcons(faAngleRight);
    library.addIcons(faCalendarCheck);
    library.addIcons(faCheck);
    library.addIcons(faCalendar);
    library.addIcons(faClone);
    library.addIcons(faChevronRight);
    library.addIcons(faUser);
    library.addIcons(faUsers);
    library.addIcons(faPhoneFlip);
    library.addIcons(faMapLocationDot);
    library.addIcons(faClock);
    library.addIcons(faHouse);
    library.addIcons(faLink);
    library.addIcons(faCalendarDays);
    library.addIcons(faChevronRight);
    library.addIcons(faThumbTack);
    library.addIcons(faBars);
    library.addIcons(faArrowRightToBracket);
    library.addIcons(faCaretDown);
    library.addIcons(faLanguage);
    library.addIcons(faGlobe);
    library.addIcons(faEllipsisVertical);
    library.addIcons(faArrowRight);
    library.addIcons(faCog);
    library.addIcons(faEnvelope);
    library.addIcons(faHeart);
    library.addIcons(faQrcode);
    library.addIcons(faHeartRegular);
    library.addIcons(faCalendarXmark);
    library.addIcons(faCheckCircle);
    library.addIcons(faTimesCircle);
    library.addIcons(faCopy);
    library.addIcons(faWhatsapp);
    library.addIcons(faTags);
  }

  login() {
    this.session.login();
  }
  logout() {
    this.session.logout();
  }
  changeLocale(locale: string) {
    let href = window.location.href;
    console.log(href);
    let url = new URL(href);
    let path = url.pathname;
    if (path.startsWith('/en/')) {
      path = path.substring(3);
    }
    if (path.startsWith('/zh_hk/')) {
      path = path.substring(6);
    }
    if (path.startsWith('/zh_cn/')) {
      path = path.substring(6);
    }
    path = `/${locale}${path}`;
    window.location.href = path;

    // this.session.changeLocale(locale);
  }


  permissions = AppPermissions;
}
