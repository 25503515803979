<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab [label]="_common.Schedules">
        <form [formGroup]="form" class="max-scroll dialogBody ctlSettings">
          <div class="card">
            <div class="cardHeader">{{_common.Settings}}</div>
            <div class="cardBody space-y-2">
              <div>
                <app-form-field style="width: 50%">
                  <app-label>{{_common.name}}</app-label>
                  <input matInput formControlName="name" required />
                </app-form-field>
              </div>
              <div>
                <app-form-field style="width: 50%">
                  <app-label>{{_common.code}}</app-label>
                  <input matInput formControlName="code" required />
                </app-form-field>
              </div>

              <div>
                <app-form-field appearance="outline" style="width: 50%">
                  <app-label>{{_common.PublicHolidayCalendar}}</app-label>
                  <mat-select
                    formControlName="globalCalendar"
                    [compareWith]="compareWith"
                  >
                    <mat-option *ngFor="let o of globalCalendars" [value]="o"
                      >{{ o.name }}</mat-option
                    >
                  </mat-select>
                </app-form-field>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>
    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
