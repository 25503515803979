<div class="dialog-max cdialog" style="min-width: 300px; min-height: 100px">
  <div class="dialogHeader">
    <fa-icon icon="exclamation-triangle" style="color: #f6c50f; font-size: 35px"></fa-icon>
    <h2 mat-dialog-title style="flex: 1 1 auto">{{title}}</h2>
    <div class="sapcer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div style="text-align: center">
    <mat-dialog-content style="margin: 4em 2em; white-space: pre-line">
      <div>{{content}}</div>
      <div *ngIf="opts.remember" style="margin-top: 1em">
        <mat-checkbox [(ngModel)]="_remember">Don't ask again</mat-checkbox>
      </div>
    </mat-dialog-content>
    <div class="dialogBar" style="justify-content: center">
      <button class="app-btn btn-text" (click)="cancel()" style="width: 45%; margin-right: 20px">
        {{_common.cancel}}
      </button>
      <button class="app-btn" (click)="confirm()" color="primary" style="width: 45%">
        {{_common.confirm}}
      </button>
    </div>
  </div>
</div>