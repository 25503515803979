/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { VizFormModule } from '../components/form/form.module';
import { RangeType } from './filter.date.range';
import { FilterDateRange, FilterObject } from './list.filter';

@Component({
  selector: 'app-filter-date-filter',
  templateUrl: './filter.date.filter.component.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatRadioModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    MatMenuModule,
  ],
})
export class FilterDateFilterComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  _common = _common;

  @Input()
  filterObject: FilterObject;
  @Input()
  id = 'date';

  types = RangeType;
  // @Input()
  // type = RangeType.all;

  @Input()
  filter: FilterDateRange;
  // start: moment.Moment;
  // end: moment.Moment;

  // @Input()
  // set range(range: DateRangeComponent_Range) {
  //    this.type = range.type;
  //    this.start = range.start;
  //    this.end = range.end;
  // }

  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}
  refresh() {
    // const now = moment().startOf('day');

    // switch (this.type) {
    //    case RangeType.day7:
    //       this.start = now.clone().add(-6, 'day');
    //       this.end = now;
    //       break;
    //    case RangeType.day14:
    //       this.start = now.clone().add(-13, 'day');
    //       this.end = now;
    //       break;
    //    case RangeType.today:
    //       this.start = now.clone();
    //       this.end = now;
    //       break;
    //    case RangeType.all:
    //       this.start = null;
    //       this.end = null;
    //       break;

    //    case RangeType.yesterday:
    //       this.start = now.clone().add(-1, 'day');
    //       this.end = now.clone().add(-1, 'day');
    //       break;

    // }
    // this.filter.update({ type: this.type, start: this.start, end: this.end })
    // this.change.next();
    this.filter.refresh();
    this.change.next();
  }
  // @Output()
  // pickerOpened = new EventEmitter<boolean>();
  pickerOpen(s: boolean) {
    this.filterObject.popup(s);
    // this.pickerOpened.next(s);
  }
}
