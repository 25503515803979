import { Component, OnInit } from "@angular/core";
import { TooltipService, TooltipState } from "./tooltip.service";
import { NgIf, NgFor } from "@angular/common";

@Component({
  selector: "app-tooltip",
  styleUrls: ["./tooltip.component.css"],
  templateUrl: "./tooltip.component.html",
  standalone: true,
  imports: [NgIf, NgFor],
})
export class AppTooltipComponent implements OnInit {
  state: TooltipState | null = null;
  top = 0;
  left = 0;
  constructor(private service: TooltipService) {
    this.service.loaderState.subscribe((state) => {
      this.state = state;
      if (state) {
        const rect = state.dom.getBoundingClientRect();

        this.top = rect.bottom + 5;
        this.left = rect.left;
      }
    });
  }

  ngOnInit() {}
}
