import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterSelectObjectComponent } from "./filter.select.object.component";
import { FilterObject, FilterSelectObject } from "./list.filter";
import { NgIf } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-filter-select-object-filter",
  templateUrl: "./filter.select.object.filter.component.html",
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
})
export class FilterSelectObjectFilterComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faTimes = faTimes;
  _common = _common;

  @Input()
  filterObject: FilterObject;
  @Input()
  id: string;
  @Input()
  filter = new FilterSelectObjectComponent();

  @Output()
  change = new EventEmitter<void>();

  @Output()
  select = new EventEmitter<FilterSelectObject>();

  @Input()
  title = "Objects";
  ngOnInit() {}

  refresh() {
    this.change.next(void 0);
  }
  clear(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.filter.filter.clear();
    this.refresh();
  }

  _select() {
    this.select.next(this.filter.filter);
  }
}
