<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-scroll dialogBody">
      <form [formGroup]="form">
        <div class="card">
          <!-- <div class="cardHeader">Settings</div> -->
          <div class="cardBody space-y-2">
            <div>
              <app-form-field style="width: 50%">
                <app-label>name</app-label>
                <input matInput formControlName="name" required />
              </app-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
