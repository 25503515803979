/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import moment from 'moment';
import { VizFormModule } from '../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
} from '../common/utils';
import { AccessUtils, TBooking } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { AppPermissions } from '../core/permissions';
import { SessionService } from '../session.service';
import { BookingMixin } from '../thinkdesks/booking.mixin';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { ThinkdesksCache } from '../thinkdesks/thinkdesks.cache';
import { TBookingStatus } from '../thinkdesks/thinkdesks.entities';
import { BookingPurposeDialog } from './booking.purpose.dialog';
@Component({
  templateUrl: './booking.dialog.component.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
})
export class BookingDialogComponent extends SiteFormatMixin(
  SiteLangMixin(BookingMixin(CompareWithMixin(EmptyObject)))
) {
  permissions = AppPermissions;
  _common = _common;

  objectId: number;
  object: TBooking;
  api = '/api/booking';

  now = moment();
  statusList = TBookingStatus;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public override session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: ThinkdesksCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BookingDialogComponent>
  ) {
    super();
    this.objectId = this.data.objectId;
  }

  locationAllowed = false;

  ngOnInit(): void {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.objectId}?checkin=true`),
      (result) => {
        this.object = result as TBooking;
        AccessUtils.fixBooking(this.object);
        this.locationAllowed = this.session.hasLocation(
          this.object.space?.location?.id
        );
      }
    );
  }

  save() {}

  sync() {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.objectId}/sync`),
      (result) => {}
    );
  }
  cancelBooking() {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.objectId}/cancel`),
      (result) => {
        let object = result as TBooking;
        AccessUtils.fixBooking(object);
        Object.assign(this.object, object);
        if (this.data.object) {
          Object.assign(this.data.object, object);
        }
      }
    );
  }
  approve() {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.objectId}/approve`),
      (result) => {
        let object = result as TBooking;
        AccessUtils.fixBooking(object);
        Object.assign(this.object, object);
        if (this.data.object) {
          Object.assign(this.data.object, object);
        }
      }
    );
  }
  reject() {
    WebUtils.web_result(
      this.http.get(`${this.api}/${this.objectId}/reject`),
      (result) => {
        let object = result as TBooking;
        AccessUtils.fixBooking(object);
        Object.assign(this.object, object);
        if (this.data.object) {
          Object.assign(this.data.object, object);
        }
      }
    );
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
  selectPurpose() {
    this.dialog.open(BookingPurposeDialog, {
      data: {
        object: this.object,
        _object: this.data.object,
      },
    });
  }
  webhook() {
    WebUtils.web_result(
      this.http.get(`/api/client/booking/${this.objectId}/webhook`),
      (result) => {
        this.snackBar.open('Webhook Sent');
      }
    );
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
