<div class="client_pageBody max-height">
  <div class="page_Title">{{_common.MyBookings}}</div>
  <div class="body_section max-height">
    <div class="flex gap-4 mb-4">
      <div
        class="client_tab"
        [class.selected]="mode=='upcoming'"
        (click)="selectMode('upcoming')"
      >
        {{_common.UpComing}}
      </div>
      <div
        class="client_tab"
        [class.selected]="mode=='past'"
        (click)="selectMode('past')"
      >
        {{_common.Past}}
      </div>
      <div
        class="client_tab"
        [class.selected]="mode=='cancelled'"
        (click)="selectMode('cancelled')"
      >
        {{_common.Cancelled}}
      </div>
    </div>
    <div
      *ngIf="!objects.data.length"
      class="text-center"
      style="margin-top: 10%"
    >
      <div class="flex justify-center">
        <img src="/silone/silone_notfound.png" style="height: 100px" />
      </div>

      <div class="mt-4">{{ _common.no_result }}</div>
    </div>
    <app-scroll-container
      [more]="hasMore()"
      (scrolled)="handleScroll($event)"
      class="item_list"
      #scroll
    >
      <ng-container *ngFor="let booking of objects.data" class="item_list">
        <ng-container *ngIf="booking.space; let o">
          <div
            [routerLink]="[session.path('/my/booking/' + booking.id)]"
            class="relative"
          >
            <app-space-card
              [space]="$any(booking.space)"
              [spaceLink]="false"
              class="space_card_booking"
            >
              <div class="spacer"></div>
              <div>
                <div style="color: #ccc">#{{booking.bookingNo}}</div>
                <div class="font-bold">
                  {{formatLongDate(session.lang, booking.startTime)}}
                </div>
                <div>
                  {{booking.startTime.format('hh:mmA')}}-
                  {{booking.endTime.format('hh:mmA')}}
                </div>
              </div>
            </app-space-card>
            <div class="absolute" style="left: 0px; top: 0px; padding: 5px">
              <div class="app-chip Status" [ngClass]="booking.status">
                {{ _common.bookingStatus[booking.status] }}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </app-scroll-container>
  </div>
</div>
