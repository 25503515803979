<!-- @format -->

<!--<mat-toolbar style="display: flex">
  <span>Spaces</span>

  <div class="flex-auto"></div>
</mat-toolbar>-->
<div class="flex justify-center space-x-4 mb-4">
  <div class="search_bar spacer">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input
      class="w-full"
      matInput
      [(ngModel)]="filter"
      (keyup)="applyFilter($event.target)"
      placeholder="{{ _common.search }}"
      #_search
    />
    <fa-icon
      icon="times"
      matSuffix
      (click)="clearFilter(_search)"
      class="clearSearch"
    ></fa-icon>
  </div>
  <div class="client_button btn_circle" (click)="filterObject.openFilter()">
    <fa-icon icon="sliders-h"></fa-icon>
  </div>

  <span class="spacer"></span>
  <span> </span>
</div>
<!--<div>
  <app-form-field>
    <mat-select [(ngModel)]="area" (selectionChange)="updateArea()">
      <mat-option value="">All</mat-option>
      <mat-option value="HK">港島</mat-option>
      <mat-option value="KLN">九龍</mat-option>
      <mat-option value="NT">新界</mat-option>
    </mat-select>
  </app-form-field>
  <app-form-field>
    <mat-select [(ngModel)]="district" (selectionChange)="refresh()">
      <mat-option value="">All</mat-option>
      <ng-container *ngIf="area == 'HK'">
        <mat-option value="HK">中西區</mat-option>
        <mat-option value="HK">灣仔</mat-option>
        <mat-option value="HK">東區</mat-option>
        <mat-option value="HK">南區</mat-option>
      </ng-container>
      <ng-container *ngIf="area == 'KLN'">
        <mat-option value="KLN">油尖旺</mat-option>
        <mat-option value="KLN">深水埗</mat-option>
        <mat-option value="KLN">九龍城</mat-option>
        <mat-option value="KLN">黃大仙</mat-option>
        <mat-option value="KLN">觀塘</mat-option>
      </ng-container>
      <ng-container *ngIf="area == 'NT'">
        <mat-option value="NT">葵青</mat-option>
        <mat-option value="NT">荃灣</mat-option>
        <mat-option value="NT">屯門</mat-option>
        <mat-option value="NT">元朗</mat-option>
        <mat-option value="NT">北區</mat-option>
        <mat-option value="NT">大埔</mat-option>
        <mat-option value="NT">沙田</mat-option>
        <mat-option value="NT">西貢</mat-option>
        <mat-option value="NT">離島</mat-option>
      </ng-container>
    </mat-select>
  </app-form-field>
  <app-form-field>
    <app-label>Type</app-label>
    <mat-select
      [(ngModel)]="type"
      [compareWith]="compareWith"
      (selectionChange)="refresh()"
    >
      <mat-option [value]="ALL">All</mat-option>
      <mat-option *ngFor="let o of types" [value]="o">
        {{ o.name }}
      </mat-option>
    </mat-select>
  </app-form-field>
  <app-form-field>
    <app-label>Capacity</app-label>
    <mat-select [(ngModel)]="capacity" (selectionChange)="refresh()">
      <mat-option [value]="allCapacity">All</mat-option>
      <mat-option *ngFor="let o of capacities" [value]="o"
        >{{ o.min }}-{{ o.max }}
      </mat-option>
    </mat-select>
  </app-form-field>

  <app-form-field>
    <app-label>Organization</app-label>
    <mat-select
      [(ngModel)]="organization"
      [compareWith]="compareWith"
      (selectionChange)="updateOrganization()"
    >
      <mat-option [value]="ALL">All</mat-option>

      <mat-option *ngFor="let o of organizations" [value]="o">
        {{ o.name }}
      </mat-option>
    </mat-select>
  </app-form-field>
  <app-form-field>
    <app-label>Location</app-label>
    <mat-select
      [(ngModel)]="location"
      [compareWith]="compareWith"
      (selectionChange)="refresh()"
    >
    <mat-option [value]="ALL">All</mat-option>

      <mat-option *ngFor="let o of locations" [value]="o">
        {{ o.name }}
      </mat-option>
    </mat-select>
  </app-form-field>
</div>-->

<div
  *ngIf="!objects.data.length && filter && filter != ''"
  class="text-center"
  style="margin-top: 10%"
>
  <div class="flex justify-center">
    <img src="/silone/silone_notfound.png" style="height: 100px;"/>
  </div>
  
  <div class="mt-4">{{ _common.no_result }}</div>
  
</div>
<!-- <app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  #scroll
> -->
  <div class="item_list">
    <div *ngFor="let o of objects.data">
      <app-location-card [location]="o" class="location_card_large"></app-location-card>
    </div>
  </div>
<!-- </app-scroll-container> -->

<!--Filter Overlay-->
<div
  *ngIf="filterObject.showFilter"
  class="ctlFilter listFilter"
  style="z-index: 100"
  (clickOutsidePopup)="filterObject.closeFilter()"
>
  <div class="header">
    <div class="title">
      <fa-icon icon="sliders-h" style="margin-right: 5px"></fa-icon>
      {{_common.Filter.filter}}
    </div>
    <div class="spacer"></div>
    <div
      (click)="filterObject.closeFilter()"
      style="font-size: 1.5em; cursor: pointer"
    >
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div class="body p-4">
    <div class="space-y-2">
      <div>
        <app-form-field class="w-full">
          <app-label>地區</app-label>
          <mat-select [(ngModel)]="area" (selectionChange)="updateArea()">
            <mat-option value="">全部</mat-option>
            <mat-option value="HK">港島</mat-option>
            <mat-option value="KLN">九龍</mat-option>
            <mat-option value="NT">新界</mat-option>
          </mat-select>
        </app-form-field>
      </div>
      <div>
        <app-form-field class="w-full">
          <mat-select [(ngModel)]="district" (selectionChange)="refresh()">
            <mat-option value="">全部</mat-option>
            <ng-container *ngIf="area == 'HK'">
              <mat-option value="HK_CS">中西區</mat-option>
              <mat-option value="HK_WC">灣仔</mat-option>
              <mat-option value="HK_E">東區</mat-option>
              <mat-option value="HK_S">南區</mat-option>
            </ng-container>
            <ng-container *ngIf="area == 'KLN'">
              <mat-option value="KLN_YTM">油尖旺</mat-option>
              <mat-option value="KLN_SSP">深水埗</mat-option>
              <mat-option value="KLN_KC">九龍城</mat-option>
              <mat-option value="KLN_WTS">黃大仙</mat-option>
              <mat-option value="KLN_KT">觀塘</mat-option>
            </ng-container>
            <ng-container *ngIf="area == 'NT'">
              <mat-option value="NT_KT">葵青</mat-option>
              <mat-option value="NT_TW">荃灣</mat-option>
              <mat-option value="NT_TM">屯門</mat-option>
              <mat-option value="NT_YL">元朗</mat-option>
              <mat-option value="NT_N">北區</mat-option>
              <mat-option value="NT_TP">大埔</mat-option>
              <mat-option value="NT_ST">沙田</mat-option>
              <mat-option value="NT_SK">西貢</mat-option>
              <mat-option value="NT_Islands">離島</mat-option>
            </ng-container>
          </mat-select>
        </app-form-field>

      </div>
      

    </div>
    <hr>
    <div>
      <app-form-field class="w-full">
        <app-label>{{_common.Filter.org_name}}</app-label>
        <mat-select
          [(ngModel)]="organization"
          [compareWith]="compareWith"
          (selectionChange)="refresh()"
        >
          <mat-option [value]="ALL">{{_common.Filter.noSpecify}}</mat-option>

          <mat-option *ngFor="let o of organizations" [value]="o">
            {{ o.name }}
          </mat-option>
        </mat-select>
      </app-form-field>
    </div>
  </div>
  <div class="buttons">
    <div (click)="clear()" class="clear_button">{{_common.Filter.Clear}}</div>
  </div>
</div>
