import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../../common/filter';
import {
  ListOption,
  ScrollableListComponentBase
} from '../../common/list.component.base';
import { DELETED, WebUtils } from '../../common/utils';
import {
  AccessUtils,
  TLocation,
  TOrganization,
  TSpace,
} from '../../core/entities';
import { AppPermissions } from '../../core/permissions';
import { SitePathMixin } from '../../core/site.path.utils';
import { SessionCache } from '../../session.cache';
import { SessionService } from '../../session.service';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';
import { SpaceEditDialogComponent } from './space.edit.dialog';

class _Base extends ScrollableListComponentBase<TSpace> {}

@Component({
  selector: 'app-space-list',
  templateUrl: './space.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
    MatTooltipModule,
  ],
})
export class SpaceListComponent extends 
SitePathMixin(
SiteFormatMixin(_Base)) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();
  //   status = new FilterMulti();

  //   type = new FilterMulti();

  permissions=AppPermissions;
  displayedColumns: string[] = [
    //'active',
    'image',
    'name',
    //'organization',
    'location',
    'types',
    'purpose',
    'controls',
  ];

  @Input()
  organization: TOrganization;

  @Input()
  location: TLocation;

  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    private cache: SessionCache,
    public session: SessionService,
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      organization: WebUtils.objId(this.organization),
      location: WebUtils.objId(this.location),
    } as any;
    Object.assign(query, this.filterObject.query());
    Object.assign(query, this.session.userQuery());

    const url = this._listModifyUrl(`/api/space/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TSpace[]) {
    for (let o of list) AccessUtils.fixSpace(o, );
  }

  add() {
    const ref = this.dialog.open(SpaceEditDialogComponent, {
      data: {
        organization: this.organization,
        location: this.location,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this._addToList(result);
      }
    });
  }

  override selectObject(o: TSpace) {
    const dialogRef = this.dialog.open(SpaceEditDialogComponent, {
      data: {
        objectId: o.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        this._removeFromList(o);
      } else if (result) {
        Object.assign(o, result);
      }
    });
  }
}
