/** @format */

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VizFormModule } from '../../common/components/form/form.module';
import { date_utils } from '../../common/date_utils';
import { EmptyObject, NumberPair, WebUtils, utils } from '../../common/utils';
import { BookingCalendarDayComponent } from './booking.calendar.day.component';
import { BookingCalendarUtils } from './booking.calendar.utils';

@Component({
  templateUrl: './booking.calendar.period.edit.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatDialogModule,
    VizFormModule,
    MatDatepickerModule,
    BookingCalendarDayComponent,
    MatMenuModule,
    MatCheckboxModule,
    MatInputModule,
  ],
})
export class BookingCalendarPeriodEditDialogComponent extends EmptyObject {
  form: UntypedFormGroup;
  _common = _common;

  apiUrl = '/api/schedule-calendar/period';

  key: { [key: string]: NumberPair } = {};

  _mon = false;
  _tue = false;
  _wed = false;
  _thu = false;
  _fri = false;
  _sat = false;
  _sun = false;
  _ph = false;
  _ff: UntypedFormControl;
  @ViewChild('mon') mon: BookingCalendarDayComponent;
  @ViewChild('tue') tue: BookingCalendarDayComponent;
  @ViewChild('wed') wed: BookingCalendarDayComponent;
  @ViewChild('thu') thu: BookingCalendarDayComponent;
  @ViewChild('fri') fri: BookingCalendarDayComponent;
  @ViewChild('sat') sat: BookingCalendarDayComponent;
  @ViewChild('sun') sun: BookingCalendarDayComponent;
  @ViewChild('ph') ph: BookingCalendarDayComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,

    public dialogRef: MatDialogRef<BookingCalendarPeriodEditDialogComponent>
  ) {
    super();

    this.key = data.key;

    this.form = this.fb.group({
      startDate: ['', Validators.required],
      endDate: [''],

      mon: BookingCalendarUtils.createDay(this.fb),
      tue: BookingCalendarUtils.createDay(this.fb),
      wed: BookingCalendarUtils.createDay(this.fb),
      thu: BookingCalendarUtils.createDay(this.fb),
      fri: BookingCalendarUtils.createDay(this.fb),
      sat: BookingCalendarUtils.createDay(this.fb),
      sun: BookingCalendarUtils.createDay(this.fb),
      ph: BookingCalendarUtils.createDay(this.fb),
    });
  }

  updateForm() {
    utils.updateValidity(this.form);
  }

  ngOnInit(): void {
    utils.fill_form_values(
      {
        mon: { open: false },
        tue: { open: false },
        wed: { open: false },
        thu: { open: false },
        fri: { open: false },
        sat: { open: false },
        sun: { open: false },
        ph: { open: false },
      },
      this.form
    );
    this.updateForm();
  }

  _toggle() {
    this._mon = !this._mon;
    this._tue = !this._tue;
    this._wed = !this._wed;
    this._thu = !this._thu;
    this._fri = !this._fri;
    this._sat = !this._sat;
    this._sun = !this._sun;
    this._ph = !this._ph;
  }
  _open(ff) {
    this._ff = ff;
  }
  applyTemplate() {
    let value = this._ff.value;
    if (this._mon) {
      this.form.get('mon').setValue(value);
      this.mon.updateForm();
    }
    if (this._tue) {
      this.form.get('tue').setValue(value);
      this.tue.updateForm();
    }
    if (this._wed) {
      this.form.get('wed').setValue(value);
      this.wed.updateForm();
    }
    if (this._thu) {
      this.form.get('thu').setValue(value);
      this.thu.updateForm();
    }
    if (this._fri) {
      this.form.get('fri').setValue(value);
      this.fri.updateForm();
    }
    if (this._sat) {
      this.form.get('sat').setValue(value);
      this.sat.updateForm();
    }
    if (this._sun) {
      this.form.get('sun').setValue(value);
      this.sun.updateForm();
    }
    if (this._ph) {
      this.form.get('ph').setValue(value);
      this.ph.updateForm();
    }
  }

  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);
    query.startDate = date_utils.toQuery(query.startDate);
    query.endDate = date_utils.toQuery(query.endDate);

    query.mon = BookingCalendarUtils.saveDay(query.mon);
    query.tue = BookingCalendarUtils.saveDay(query.tue);
    query.wed = BookingCalendarUtils.saveDay(query.wed);
    query.thu = BookingCalendarUtils.saveDay(query.thu);
    query.fri = BookingCalendarUtils.saveDay(query.fri);
    query.sat = BookingCalendarUtils.saveDay(query.sat);
    query.sun = BookingCalendarUtils.saveDay(query.sun);
    query.ph = BookingCalendarUtils.saveDay(query.ph);

    let key = {};
    for (let id in this.key) {
      key[id] = WebUtils.objName(this.key[id]);
    }

    WebUtils.web_result(
      this.http.post(`${this.apiUrl}`, {
        key,
        body: query,
      }),
      async (result) => {
        this.dialogRef.close(result);
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
