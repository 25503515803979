/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VizFormModule } from '../../common/components/form/form.module';
import { date_utils } from '../../common/date_utils';
import { EmptyObject, WebUtils, utils } from '../../common/utils';
import { BookingCalendarDayComponent } from './booking.calendar.day.component';
import { BookingCalendarEntities } from './booking.calendar.entities';
import { BookingCalendarUtils } from './booking.calendar.utils';

@Component({
  templateUrl: './booking.calendar.day.add.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FontAwesomeModule,
    VizFormModule,
    BookingCalendarDayComponent,
  ],
})
export class BookingCalendarDayAddDialogComponent extends EmptyObject {
  form: UntypedFormGroup;
  _common = _common;

  apiUrl = '/api/schedule-calendar';

  key: any;
  subkey: any;
  date: moment.Moment;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,

    public http: HttpClient,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BookingCalendarDayAddDialogComponent>
  ) {
    super();

    this.date = this.data.date;
    this.key = this.data.key;
    this.subkey = this.data.subkey;

    this.form = BookingCalendarUtils.createDay(fb);

    let text = '';

    utils.fill_form_values(
      {
        open: true,
        time: text,
      },
      this.form
    );
  }

  updateForm() {
    utils.updateValidity(this.form);
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  save(oo?: boolean) {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let body = Object.assign({}, this.form.value);
    body.date = date_utils.toQuery(this.date);

    let key = {};
    if (this.subkey) {
      for (let id in this.subkey) {
        key[id] = WebUtils.objName(this.subkey[id]);
      }
    } else {
      for (let id in this.key) {
        key[id] = WebUtils.objName(this.key[id]);
      }
    }

    let url = `${this.apiUrl}/date`;
    body.schedule = BookingCalendarUtils.saveDay(body);
    WebUtils.web_result(
      this.http.post(url, {
        key,

        body,
      }),
      (result) => {
        BookingCalendarEntities.fixCalendarDate(result);

        this.dialogRef.close(result);
        // this.refresh();
      }
    );
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
