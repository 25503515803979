import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateSelectorConfig, DateSelectorRange, DateSelectorRangeBase, DateSelector_RangeTypeUI } from '../../daterange_utils';
import { VizFormModule } from '../form/form.module';


@Component({
    selector: 'app-date-selector-daterange',
    templateUrl: './daterange.component.html',
    standalone: true,
    imports: [
      FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        NgFor,
        MatSelectModule,
        MatInputModule,
        MatDatepickerModule,
        NgIf,
        VizFormModule,
    ],
})
export class DateRangeComponent implements OnInit, OnChanges {

   _common = _common;

   @Inject(LOCALE_ID) public locale: string;
   @Input() range: DateSelectorRange = new DateSelectorRange();
   @Input() trigger: EventEmitter<DateSelectorConfig>;

   @Output() change = new EventEmitter<DateSelectorRange>();

   startDate: UntypedFormControl;
   endDate: UntypedFormControl;

   highlightStart = false;
   highlightEnd = false;

   types: any = DateSelector_RangeTypeUI.map((o) => { return { id: o, name: o }; })

   constructor(private dialog: MatDialog) {
      this.startDate = new UntypedFormControl(new Date());
      this.endDate = new UntypedFormControl(new Date());


   }
   highlight(curRange: DateSelectorRangeBase, curStart: boolean) {
      this.highlightStart = false;
      this.highlightEnd = false;
      if (curRange === this.range) {
         this.highlightStart = curStart;
         this.highlightEnd = !curStart;
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes['range']) {
         this.refresh();
      }
   }

   ngOnInit(): void {
      this.trigger.subscribe(data => {
         this.refresh();
      });
   }
   refresh() {
      this.startDate.setValue(this.range.start);
      this.endDate.setValue(this.range.end);
      this.updateUI();
   }

   updateUI() {
      if (this.range.hasEndDate())
         this.endDate.enable();
      else
         this.endDate.disable();

      if (this.range.hasStartDate())
         this.startDate.enable();
      else
         this.startDate.disable();
   }
   onChange() {

      this.range.start = this.startDate.value;
      this.range.end = this.endDate.value;
      this.updateUI();
      this.change.emit(this.range);

   }

   hasEndDate() {
      return this.range.hasEndDate();
   }
   hasStartDate() {
      return this.range.hasStartDate();
   }
}
