import { CommonModule } from '@angular/common';
import { FactoryProvider, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppErrorDef, AppErrors, NGX_MAT_ERROR_DEFAULT_OPTIONS } from '../../errors/app-errors.component';
import { errorMessagesEnFactory } from '../../errors/locales/en';
import { errorMessagesZhHKFactory } from '../../errors/locales/zh-hk';
import { TranslatePipe } from '../../i18n/translate.pipe';
import { AppToggleComponent } from './app.toggle';
import { AppError, AppFieldAddon, AppFormField, AppHelp, AppHint, AppLabel, AppPrefix, AppSuffix } from './form.field';
import { AppFormLabel } from './form.label';


export const NGX_MAT_ERROR_DEFAULT_CONFIG: FactoryProvider = {
    useFactory: () => {
        return {
            en: {
                ...errorMessagesEnFactory()
            },
            zh_hk: {
                ...errorMessagesZhHKFactory(),
            }
            // min: (error: MinError) =>
            //     `Min value is ${error.min}, actual is ${error.actual}`,
        };
    },
    provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
};





@NgModule({
    exports: [
        AppToggleComponent,
        AppErrorDef,
        AppErrors,
        AppFormField,
        AppFormLabel,
        AppLabel,
        AppFieldAddon,
        AppHint,
        AppHelp,
        AppSuffix,
        AppPrefix,
        AppError,
        TranslatePipe,
        CommonModule,
        ReactiveFormsModule, FormsModule,
        MatInputModule,
        FontAwesomeModule,
        // Material
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule, FormsModule,
        MatInputModule,
        AppToggleComponent,
        TranslatePipe,
        AppErrorDef,
        AppErrors,
        AppFormField,
        AppFormLabel,
        AppLabel,
        AppFieldAddon,
        AppHint,
        AppHelp,
        AppSuffix,
        AppPrefix,
        AppError,
    ],
    providers: [
        NGX_MAT_ERROR_DEFAULT_CONFIG,
        //TODO make sure this is in the ROOT 
        // LocaleService,
    ],
    bootstrap: []
})
export class VizFormModule {

}
