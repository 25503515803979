<div *ngIf="object" class="client_pageBody body">
  <div class="content item_details_page">
    <div class="tt_sidebar itemImages">
      <div class="mobile_hide">
        <img
        [src]="locationImage(object,object.images[imageIndex])"
          class="tt_sidebar_image"
        />
        <div class="image_grid">
          <img
            *ngFor="let image of object.images; let i =index"
            [src]="locationImage(object,image)"
            [class.selected]="imageIndex==i"
            (click)="imageIndex= i"
            style="width: 100px; height: 100px; object-fit: cover"
          />
        </div>
      </div>
      <!-- <div *ngFor="let image of object.images" class="mobile_hide">
        <img [src]="locationImage(object,image)" class="tt_sidebar_image" />
      </div> -->

      <!--Mobile Carousel-->
      <div class="desktop_hide image_carousel">
        <fixed-carousel>
          <ng-container *ngFor="let image of object.images; let i=index">
            <ng-container *fixedCarouselItem>
              <img [src]="locationImage(object,image)" />
            </ng-container>
          </ng-container>
        </fixed-carousel>
      </div>
    </div>

    <!--Right Side-->
    <div class="tt_right">
      <div>
        {{ lang(object.organization?.name,object.organization?.name_lang) }}
      </div>
      <div class="font-bold text-2xl mb-1 break-words">
        {{ lang(object.name,object.name_lang) }}
      </div>
      <!--Description-->
      <div
        [innerHtml]="description"
        style="margin: 1rem 0"
        class="ckeditor-content"
      ></div>

      <div class="item_info">
        <div class="line_item" *ngIf="object.tel">
          <fa-icon icon="phone-flip" class="icon"></fa-icon>
          {{object.tel}}
        </div>
        <div class="line_item" *ngIf="object.email">
          <fa-icon icon="envelope" class="icon"></fa-icon>
          {{object.email}}
        </div>
        <div class="line_item">
          <fa-icon icon="map-location-dot" class="icon"></fa-icon>
          <div (click)="openGoogleMap()" class="text-link">
            {{ lang(object.address,object.address_lang) }}
          </div>
        </div>
        <div class="line_item">
          <fa-icon icon="clock" class="icon"></fa-icon>
          <div>
            <div *ngFor="let l of openingHours">{{l}}</div>
          </div>
        </div>
      </div>

      <hr />
      <div class="body_section">
        <div class="sectionHeader">
          <div class="title spacer">{{_common.Space_rental}}</div>
          <!--<div [routerLink]="['/location']" class="flex cursor-pointer">
            查看全部
            <fa-icon icon="chevron-right"></fa-icon>
          </div>-->
        </div>
        <ng-container *ngIf="!object.spaces?.length">
           N/A 
        </ng-container>

        <div class="grid gap-2 grid-cols-1 sm:grid-cols-2">
          <app-space-card
            *ngFor="let space of object.spaces"
            [space]="$any(space)"
          ></app-space-card>
        </div>
      </div>

      <ng-container *ngIf="object.facilities?.length">
        <hr />

        <div class="body_section">
          <div class="sectionHeader">
            <div class="title spacer">{{_common.OtherFacilities}}</div>
          </div>
          <div>
            <div *ngFor="let f of object.facilities">
              <img
                [src]="facilityImage(f,f.images[0])"
                style="width: 50px; height: 50px; object-fit: contain"
              />
  
              ⋄ {{ lang(f.name,f.name_lang) }}
            </div>
          </div>
        </div>
      </ng-container>

    </div>

    <!--<ng-container *ngIf="position">
      <div *ngIf="mapService.apiLoader | async">
        <google-map height="300px" width="100%" [center]="position" [zoom]="12">
          <map-marker [position]="position" [options]="options"></map-marker>
        </google-map>
      </div>
    </ng-container>-->
  </div>
</div>
