<!-- @format -->

<nav
  mat-tab-nav-bar
  style="max-width: 90%; border-bottom: none"
  [tabPanel]="tabPanel"
>
  <a
    mat-tab-link
    (click)="filterObject.selectFilter()"
    [active]="!filterObject.curFilter && !filterObject.touched()"
  >
    {{ _common.all || 'All' }}
  </a>

  <a
    *ngFor="let f of filterObject.filters.filters"
    mat-tab-link
    (click)="filterObject.selectFilter(f)"
    [active]="filterObject.curFilter == f && !filterObject.touched()"
  >
    {{ lang(f) }}
  </a>

  <a
    mat-tab-link
    [active]="filterObject.touched()"
    *ngIf="filterObject.touched()"
  >
    {{ _common.untitled || 'Untitled' }}
  </a>
  <a
    *ngFor="let f of extra"
    mat-tab-link
    (click)="selectFilter(f)"
    [active]="filterObject.curFilter == f.filter"
  >
    {{ f.name }}
  </a>
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
