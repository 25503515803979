<app-form-field appearance="fill" style="width:100%">
   <app-label>{{title}}</app-label>
   <mat-chip-grid #tagList [attr.aria-label]="_common.tags">
      <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
         {{tag}}
         <fa-icon icon="times" matChipRemove></fa-icon>
      </mat-chip-row>
      <input [placeholder]="_common.object_common.addTagInstruction" [matChipInputFor]="tagList"
         [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
         (matChipInputTokenEnd)="addTag($event)">
   </mat-chip-grid>
</app-form-field>