/** @format */

import { formatDate } from '@angular/common';
import { FactoryProvider, LOCALE_ID } from '@angular/core';
import { NGX_MAT_ERROR_DEFAULT_OPTIONS } from '../app-errors.component';
import {
  ErrorMessages,
  LengthError,
  MaxError,
  MinError,
} from '../error-messages';

export function errorMessagesEnFactory(
  // locale: string,
  format = 'shortDate',
): ErrorMessages {
  return {
    min: (error: MinError) => `Must be >=${error.min}`,
    max: (error: MaxError) => `Must be <=${error.max}`,
    above: (error: MinError) => `Must be >${error.min}`,
    below: (error: MaxError) => `Must be <${error.max}`,
    required: `Required`,
    duplicated: `Duplicated`,
    email: `Invalid email`,
    mobile: `Invalid mobile`,
    url: `Invalid url`,
    password: (error) => {
      var s = `Invalid password.`;
      if (error.length) {
        s += ` Min Length: ${error.length}`;
      }
      if (error.pattern.indexOf('a') >= 0) {
        s += ` 1 character`;
      }
      if (error.pattern.indexOf('A') >= 0) {
        s += ` 1 capital`;
      }
      if (error.pattern.indexOf('1') >= 0) {
        s += ` 1 number`;
      }
      if (error.pattern.indexOf('@') >= 0) {
        s += ` 1 symbol`;
      }
      return s;
    },
    passwordNotMatched: (error) => 'Password not matched.',
    minlength: (error: LengthError) =>
      `Must be >=${error.requiredLength} characters`,
    maxlength: (error: LengthError) =>
      `Must be <${error.requiredLength} characters`,
    matDatepickerMin: (error: MinError) => {
      const formatted = formatDate(error.min, format, 'en');
      return `Please enter a date greater than or equal to ${
        formatted ?? error.min
      }`;
    },
    matDatepickerMax: (error: MaxError) => {
      const formatted = formatDate(error.max, format, 'en');
      return `Please enter a date less than or equal to ${
        formatted ?? error.max
      }`;
    },
  };
}

export const NGX_MAT_ERROR_CONFIG_EN: FactoryProvider = {
  provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
  useFactory: errorMessagesEnFactory,
  deps: [LOCALE_ID],
};
