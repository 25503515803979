<div class="ctlListSelector">
   <mat-card class="container">
      <div class="containerHeader">Available Choices</div>
      <hr>

      <div class="searchField">
         <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
         <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target)" placeholder="Search" #_search>
         <fa-icon icon="times" matSuffix (click)="applyFilter('')" class="clearSearch"></fa-icon>
      </div>

      <div class="items">
         <div class="item" *ngFor="let i of _notselected" (dblclick)="moveRight(i)" (click)="highlightNotSelected(i)"
            [class.selected]="notselected_highlight.indexOf(i)>=0">
            {{i.name}}</div>
      </div>
   </mat-card>
   <div class="buttons">
      <div class="app-btn btn-text" (click)="leftAll()" appToolTip="Remove All" class="app-btn btn-icon">
         <fa-icon icon="angle-double-left"></fa-icon>
      </div>
      <div class="app-btn btn-text" (click)="left()" appToolTip="Rmove selected items" class="app-btn btn-icon">
         <fa-icon icon="angle-left"></fa-icon>
      </div>

      <div class="app-btn btn-text" (click)="right()" appToolTip="Add selected items" class="app-btn btn-icon">
         <fa-icon icon="angle-right"></fa-icon>
      </div>
      <div class="app-btn btn-text" (click)="rightAll()" appToolTip="Add All" class="app-btn btn-icon">
         <fa-icon icon="angle-double-right"></fa-icon>
      </div>
   </div>
   <mat-card class="container">
      <div class="containerHeader">Selected</div>
      <hr>
      <div class="items" cdkDropList (cdkDropListDropped)="drop($event)">
         <ng-container *ngFor="let i of selected">
            <div cdkDrag class="item" (dblclick)="moveLeft(i)" (click)="highlightSelected(i)"
               [class.selected]="selected_highlight.indexOf(i)>=0">
               <div class="flex-auto">{{i.name}}</div>
               <div *ngIf="canEdit" (click)="onEdit.next(i)">
                  <fa-icon icon="pen"></fa-icon>
               </div>
            </div>
         </ng-container>
      </div>
   </mat-card>
</div>