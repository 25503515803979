<!-- @format -->
<div class="dialog-max cdialog" style="width: 50vw; height: 80vh">
  <div class="dialogHeader">
    <h2 mat-dialog-title>Select Organization</h2>
    <div class="spacer"></div>

    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="margin_top max-height">
    <app-organization-search
      [selection]="selection"
      [query]="query"
    ></app-organization-search>
  </mat-dialog-content>
  <div align="end" class="dialogBar">
    <button class="app-btn" (click)="save()" color="primary">
      {{_common.confirm}}
    </button>
  </div>
</div>
