import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionService } from './session.service';

@Component({
  selector: 'app-lang',
  standalone: true,
  imports: [RouterOutlet],
  template: ` <router-outlet></router-outlet> `,
})
export class LangComponent {
  constructor(private session: SessionService, private route: ActivatedRoute) {}
  @HostBinding('class') css = 'max-height';

  subscription: Subscription;
  ngOnInit() {
    this.subscription = this.route.data.subscribe((result) => {
      let lang = result['lang'] ?? 'zh_hk';
      this.session.changeLocale(lang);
    });
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  async load() {}
}
