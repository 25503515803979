<!-- @format -->

<div class="cdialog" style="min-width: 400px">
  <div class="dialogHeader">
    <div mat-dialog-title>{{_common.add_category}}</div>

    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-scroll dialogBody">
      <form [formGroup]="form" *ngIf="object">
        <div class="card">
          <!-- <div class="cardHeader">Settings</div> -->
          <div class="cardBody space-y-2">
            <div>{{object.filename}}</div>
            <div>
              <img [src]="contentImage(object)" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
