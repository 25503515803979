<mat-toolbar style="display: flex">
  <!-- <fa-icon icon="shopping-bag" class="headerIcon"></fa-icon> -->
  <span>{{_common.BookingManagement}}</span>

  <div class="flex-auto"></div>
  <div class="flex items-center">
    <div (click)="mode = modes.list" class="text-link link-blue">
      {{ _common.Listview }}
    </div>
    <div style="width: 20px; text-align: center; color: #ccc">|</div>
    <div (click)="mode = modes.calendar" class="text-link link-blue">
      {{ _common.Calendarview }}
    </div>
  </div>
</mat-toolbar>

<app-booking-space-list *ngIf="mode == modes.list"> </app-booking-space-list>
<app-booking-space-calendar *ngIf="mode == modes.calendar">
</app-booking-space-calendar>
