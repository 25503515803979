import { TimeAtom } from '../../timespan/time_utils';
import { DayViewBlockLoad } from './dayview.block.component';
import { DayViewTimeLoad } from './dayview.time.component';

export interface DayTime {
  start: number;
  end: number;
}
export interface DaySection extends DayTime {
  qty: number;
  text?: string[];

  capacity?: number;
  blocked?: number;
  booked?: number;
  data?: any;
}

export enum BookingDayMode {
  'day' = 'day',
  'week' = 'week',
}
export class BookingDayCalendarBase {
  overlaps(a: DayTime, b: DayTime): boolean {
    let a1 = a.start;
    let a2 = a.end;
    let b1 = b.start;
    let b2 = b.end;
    return Math.max(a2, b2) - Math.min(a1, b1) < a2 - a1 + (b2 - b1);
    // too fat -- they overlap!
  }
  clean(a: DaySection[]): void {
    a = a.sort((a, b) => a.start - b.start);
    for (let i = 0; i < a.length; i++) {
      let c = a[i];
      while (i + 1 < a.length) {
        let cc = a[i + 1];
        if (c.end == cc.start && c.qty == cc.qty) {
          c.end = cc.end;
          a.splice(i + 1, 1);
        } else break;
      }
    }
  }
  merge(
    a: DaySection[],
    b: DaySection,
    opts?: {
      dataMerge?: (a: DaySection, b: DaySection) => void;
    }
  ): void {
    let textFunc = (v1: string[], v2?: string[]) => {
      if (!v1 && !v2) return null;
      let list = v1 ?? [];
      if (v2) {
        for (let t of v2) {
          if (!list.includes(t)) list.push(t);
        }
        // list = list.concat(v2);
      }
      return list;
    };

    let func = (v) => {
      if (v < 0) return 0;
      return v;
    };
    let ffunc = opts?.dataMerge ?? function (a, b) {};
    // let ffunc = (a, b) => {
    //   a.capacity = (a.capacity ?? 0) + (b.capacity ?? 0);
    //   a.blocked = (a.blocked ?? 0) + (b.blocked ?? 0);
    //   a.booked = (a.booked ?? 0) + (b.booked ?? 0);
    // };

    let extra = [b];
    while (extra.length) {
      let o = extra.pop();
      while (true) {
        if (!o.qty) break;

        let list = a.filter((c) => this.overlaps(c, o));
        if (!list.length) {
          a.push(o);
          break;
        }
        let l = list[0];

        // exact match
        if (l.start == o.start && l.end == o.end) {
          l.qty += o.qty;
          ffunc(l, o);
          l.text = textFunc(l.text, o.text);
          break;
        } else if (l.start == o.start) {
          if (l.end < o.end) {
            // l   o
            // #   #
            // #   #
            // #   #
            //     #
            //     #

            //split
            let ss = {
              start: l.end,
              end: o.end,
              //   qty: func(o.qty),
              qty: o.qty,
              text: textFunc(o.text),
            };
            ffunc(ss, o);

            l.qty = func(l.qty + o.qty);
            ffunc(l, o);
            l.text = textFunc(l.text, o.text);

            o = ss;
            // extra.push(ss);
          } else {
            // l   o
            // #   #
            // #   #
            // #   #
            // #
            // #
            let ss = {
              start: o.end,
              end: l.end,
              qty: func(l.qty),
              text: textFunc(l.text),
            };
            ffunc(ss, l);
            a.push(ss);

            l.qty = func(l.qty + o.qty);
            l.end = o.end;
            ffunc(l, o);
            l.text = textFunc(l.text, o.text);
            // no futher processing needed
            break;
          }
        } else if (l.start < o.start) {
          if (o.end > l.end) {
            // l   o
            // #
            // #
            // #   #
            // #   #
            //     #
            //     #

            let ss = {
              start: l.end,
              end: o.end,
              //   qty: func(o.qty),
              qty: o.qty,
              text: textFunc(o.text),
            };
            ffunc(ss, o);

            let aa = {
              start: o.start,
              end: l.end,
              qty: func(o.qty + l.qty),
              text: textFunc(o.text, l.text),
            };
            ffunc(aa, o);
            ffunc(aa, l);
            a.push(aa);

            l.end = o.start;

            o = ss;
            // extra.push(ss);
          } else {
            // l   o
            // #
            // #
            // #   #
            // #   #
            // #
            // #
            if (o.end < l.end) {
              let aa = {
                start: o.end,
                end: l.end,
                qty: func(l.qty),
                text: textFunc(l.text),
              };
              ffunc(aa, l);
              a.push(aa);
            }
            let aa = {
              start: o.start,
              end: o.end,
              qty: func(o.qty + l.qty),
              text: textFunc(o.text, l.text),
            };
            ffunc(aa, l);
            ffunc(aa, o);
            a.push(aa);

            l.end = o.start;
            // no further processing needed
            break;
          }
        } else if (l.start > o.start) {
          if (o.end >= l.end) {
            // l   o
            //     #
            //     #
            // #   #
            // #   #
            //     #
            //     #

            if (o.end > l.end) {
              let ss = {
                start: l.end,
                end: o.end,
                qty: func(o.qty),
                text: textFunc(o.text),
              };
              ffunc(ss, o);
              // a.push(ss)
              extra.push(ss);
            }

            let ss = {
              start: o.start,
              end: l.start,
              //   qty: func(o.qty),
              qty: o.qty,
              text: textFunc(o.text),
            };
            ffunc(ss, o);

            l.qty = func(l.qty + o.qty);
            ffunc(l, o);
            l.text = textFunc(l.text, o.text);

            o = ss;
            // extra.push(ss);
          } else {
            // l   o
            //     #
            //     #
            // #   #
            // #   #
            // #
            // #

            let ss = {
              start: o.start,
              end: l.start,
              //   qty: func(o.qty),
              qty: o.qty,
              text: textFunc(o.text),
            };
            ffunc(ss, o);

            let aa = {
              start: o.end,
              end: l.end,
              qty: func(l.qty),
              text: textFunc(l.text),
            };
            ffunc(aa, l);
            a.push(aa);

            l.end = o.end;
            l.qty = func(l.qty + o.qty);
            ffunc(l, o);
            l.text = textFunc(o.text, l.text);

            o = ss;
            // extra.push(ss);
          }
        }
      }
    }
  }

  // color = "#d0f0c0";
  // unavailableColor = "#888888";

  addDay(
    event: DayViewTimeLoad,
    id: string,
    schedules: DaySection[],
    opts?: {
      title: (s: DaySection) => { text: string; style?: string }[];
      color?: (s: DaySection) => { color: string; borderColor: string };
    }
  ) {
    let colorFunc = opts?.color;
    if (!colorFunc)
      colorFunc = (s: DaySection) => {
        if (s.qty <= 0) {
          return {
            color: this.bookedColor,
            borderColor: this.bookedBorder,
          };
        }
        return null;
      };

    for (let s of schedules) {
      let ss = '';
      if (s.text) ss = s.text.join(',');

      let color = this.availableColor;
      let borderColor = this.availableBorder;
      let textColor = '#000000';
      let cc=colorFunc(s);
      if(cc) {
        color = cc.color;
        borderColor = cc.borderColor;        
      }
      // if (s.qty <= 0) {
      //   color = this.bookedColor;
      //   borderColor = this.bookedBorder;
      // }
      //  else if (s.text?.length) {
      //   color = "#ffa8aa";
      //   // textColor = '#ffffff';
      // }
      let v = '';
      if (s.qty) v = `${s.qty} `;

      let start = TimeAtom.parse(s.start);
      let end = TimeAtom.parse(s.end);

      let text = [
        ...(s.text ?? []).map((c) => {
          return {
            text: c,
          };
        }),
      ];
      if (opts?.title) {
        let list = opts.title(s);
        text.push(...list.filter((c) => !!c?.text));
      }
      if (s.capacity) {
        v = `${v}/${s.capacity ?? 0}`;
      }
      event.comp.addData(id, {
        start: TimeAtom.parse(s.start),
        end: TimeAtom.parse(s.end),
        title: v,
        color,
        borderColor,
        textColor,
        lines: [{ text: `${start.toString()}-${end.toString()}` }, ...text],
        data: {
          schedule: s,
          qty: s.qty,
        },
      });
    }
  }

  availableColor = '#cdf4dc';
  availableBorder = '#429b66';
  blockedColor = '#ffecec';
  blockedBorder = '#b52e2e';
  bookedColor = '#eeeeee';
  bookedBorder = '#707070';
  addDayBlock(event: DayViewBlockLoad, schedules: DaySection[]) {
    for (let s of schedules) {
      let ss = '';
      if (s.text) ss = s.text.join(',');

      let textColor = '#000000';

      let blocks = [];

      let start = TimeAtom.parse(s.start);
      let end = TimeAtom.parse(s.end);
      let text = `${start.toString()}-${end.toString()}`;
      if (s.qty)
        blocks.push({
          title: `Available ${s.qty}/${s.capacity ?? 0}`,
          color: this.availableColor,
          borderColor: this.availableBorder,
          textColor,
          lines: [
            {
              text,
            },
          ],
          data: {
            schedule: s,
            qty: s.qty,
          },
        });
      if (s.blocked)
        blocks.push({
          title: `Blocked ${s.blocked}/${s.capacity ?? 0}`,
          color: this.blockedColor,
          borderColor: this.blockedBorder,
          textColor,
          lines: [
            {
              text,
            },
          ],
          data: {
            schedule: s,
            qty: s.qty,
          },
        });

      if (s.booked)
        blocks.push({
          title: `Booked ${s.booked}/${s.capacity ?? 0}`,
          color: this.bookedColor,
          borderColor: this.bookedBorder,
          textColor,
          lines: [
            {
              text,
            },
          ],
          data: {
            schedule: s,
            qty: s.qty,
          },
        });

      event.comp.addData({
        start: TimeAtom.parse(s.start),
        end: TimeAtom.parse(s.end),
        blocks,
      });
    }
  }
}
