<!--<div class="text-3xl">Recommended Spaces</div>-->

<div class="body_section" *ngIf="list.length">
  <div class="sectionHeader">
    <div class="title spacer">{{_common.MyBookings}}</div>
    <div [routerLink]="[session.path('/my/booking')]" class="view_all_btn">
      {{_common.ViewAll}}
      <fa-icon icon="chevron-right"></fa-icon>
    </div>
  </div>
  <div>
    <app-carousel>
      <div class="flex gap-2">
        <div
          *ngFor="let booking of list"
          [routerLink]="[session.path('/my/booking/'+booking.id)]"
          class="relative"
        >
          <!--<div>{{booking.space?.location?.name}}</div>-->

          <app-space-card
            [space]="$any(booking.space)"
            [spaceLink]="false"
            class="space_card_small space_card_booking"
          ></app-space-card>
          <div class="font-bold">{{dateText(booking)}}</div>

          <div>
            {{booking.startTime.format('hh:mmA')}}-
            {{booking.endTime.format('hh:mmA')}}
          </div>
          <div class="absolute" style="left: 0px; top: 0px; padding: 5px;">
            <div class="app-chip Status" [ngClass]="booking.status">
              {{ _common.bookingStatus[booking.status] }}
            </div>
          </div>
        </div>
      </div>
    </app-carousel>
  </div>
</div>
