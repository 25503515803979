import { UntypedFormBuilder } from '@angular/forms';
import { DaySchedule } from '../../common/timespan/day.schedule';

export class BookingCalendarUtils {
  static timeValidator = (c) => {
    if (!c.value) return null;
    let v = c.value.trim();
    if (v == '') return null;

    let regex =
      /^(([0-1][0-9]|2[0-3])[0-5][0-9]-([0-1][0-9]|2[0-9])[0-5][0-9] *)+$/g;
    if (!regex.test(v)) {
      return {
        pattern: 'invalid pattern',
      };
    }

    let schedule = DaySchedule.parse(c.value);
    for (let s of schedule.slots) {
      if (s.start.isSameOrAfter(s.end)) {
        return {
          invalid: 'invalid pattern',
        };
      }
    }
    return null;
  };
  static createDay(fb: UntypedFormBuilder) {
    return fb.group({
      open: [false],
      time: ['', [this.timeValidator]],
    });
  }

  static saveDay(o: any) {
    if (!o.open) {
      return null;
    } else {
      let time = DaySchedule.parse(o.time);
      return time.toText();
    }
  }
}
