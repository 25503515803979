<div class="ctlCTable" *ngIf="table">
   <table *ngIf="table">
      <tr *ngFor="let l of table.column.groupLevels">
         <th [attr.colspan]="table.levels+1">

         </th>
         <td *ngFor="let c of l" [attr.colspan]="c.numItems">
            {{c.title}}
         </td>
      </tr>
      <tr>
         <th [attr.colspan]="table.levels+1">

         </th>
         <th *ngFor="let c of table.column._items">
            {{c.title}}
         </th>
      </tr>


      <tr *ngFor="let r of table._items">
         <ng-container *ngFor="let g of r._groups">
            <th [attr.rowspan]="g.numItems" *ngIf="shouldCreate(g, r)">
               {{g.title}}
            </th>
         </ng-container>
         <th>{{r.title}}</th>
         <td *ngFor="let v of r.values;">
            <!-- <ng-container *ngIf="v.editable">
               <app-form-field >
                  <input matInput [(ngModel)]="v.editValue">
               </app-form-field>
            </ng-container> -->
            <div class="vv" [class.edited]="v.edited">
               <div *ngIf="v && v.editable" class="edit" (click)="edit(v)">
                  <fa-icon icon="pen"></fa-icon>
               </div>


               {{v?.editString}}

            </div>
         </td>
      </tr>


   </table>
</div>