import moment from 'moment-timezone';
import numeral from 'numeral';
import { date_utils } from '../common/date_utils';

type Constructor<T = {}> = new (...args: any[]) => T;
export function SiteFormatMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }

    formatDuration(o: number) {
      o = o || 0;
      let mins = Math.floor(o / 60);
      let seconds = o % 60;
      let hours = Math.floor(mins / 60);
      mins = mins % 60;

      return `${hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}:${mins.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}:${seconds}`;
    }
    formatInt(o: number) {
      if (o == null || isNaN(o)) return '';
      return numeral(o).format('0,0');
    }
    formatIntNumber(o: number) {
      if (o == null || isNaN(o)) return '';
      return numeral(o).format('$0,0');
    }

    formatNumber(o: number) {
      if (o == null || isNaN(o)) return '';
      return numeral(o).format('$0,0.00');
    }
    formatFloat(o: number) {
      if (o == null || isNaN(o)) return '';
      return numeral(o).format('0,0.00');
    }
    formatPercent(o: number) {
      if (o == null || isNaN(o)) return '';
      return numeral(o / 100).format('0%');
    }
    formatDate(o: moment.Moment) {
      if (!o) return null;
      return o.format('YYYY-MM-DD');
    }
    formatTime(o: moment.Moment) {
      if (!o) return null;
      return o.format('YYYY-MM-DD HH:mm');
    }
    formatTimeOnly(o: moment.Moment) {
      if (!o) return null;
      return o.format('HH:mm');
    }
    formatDateText(o: moment.Moment) {
      if (!o) return '';
      return date_utils.buildDateText(o);
    }
    formatDateRange(start: moment.Moment, end: moment.Moment) {
      if (!start) return '';
      if (!end) return this.formatDate(start);
      return `${this.formatDate(start)}-${this.formatDate(end)}`;
    }
    formatMins(v: number, common?: any) {
      if (v >= 60) {
        let h = Math.floor(v / 60);
        v = v - h * 60;
        if (!v) {
          if (h == 1) return `${h}${common?.interval?.hour ?? '小時'}`;
          else return `${h}${common?.interval?.hours ?? '小時'}`;
        } else {
          return `${h}${common?.interval?.hours ?? '小時'}${v}${
            common?.interval?.mins ?? '分鐘'
          }`;
        }
      } else {
        return `${v}${common.interval?.mins ?? 'Mins'}`;
      }
    }
    formatLongDate(locale: string, o: moment.Moment) {
      switch (locale) {
        case 'zh_hk':
          return o?.clone().locale('zh_hk').format('YYYY年MM月DD日 (ddd)');
        case 'zh_cn':
          return o?.format('YYYY年MM月DD日 (ddd)');
        case 'en':
        default:
          return o?.format('MMM-DD, YYYY (ddd)');
      }
    }
  };
}
