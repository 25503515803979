import moment from 'moment-timezone';


export enum RangeType {
   tomorrow = 'tomorrow',
   nextday7 = 'nextday7',
   nextday14 = 'nextday14',

   today = 'today',
   yesterday = 'yesterday',
   day7 = 'day7',
   day14 = 'day14',
   week = 'week',
   all = 'all',
   custom = 'custom',
}
export interface DateRangeComponent_Range {
   type?: RangeType
   start: moment.Moment;
   end: moment.Moment;
}
