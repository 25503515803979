import { CommonModule } from '@angular/common';
import { NgModule, makeEnvironmentProviders } from '@angular/core';
import { LoaderComponent } from './loader.component';
import { LoaderService } from './loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './loader.interceptor';

@NgModule({
    imports: [
        LoaderComponent
    ],
    exports: [
        LoaderComponent
    ],
    providers: [
        LoaderService,
        // {
        //    provide: HTTP_INTERCEPTORS,
        //    useClass: LoaderInterceptor,
        //    multi: true
        // }
    ]
})

export class LoaderModule { }

export function provideLoader() {
   return makeEnvironmentProviders([
       LoaderService,
       {
         provide: HTTP_INTERCEPTORS,
         useClass: LoaderInterceptor,
         multi: true,
       },
   ])
}
