<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 50vw; height: 80vh"
>
  <div class="dialogHeader">
    <div mat-dialog-title *ngIf="!objectId">{{_common.AddCenter}}</div>
    <div mat-dialog-title *ngIf="objectId">
      <div class="font-bold">{{object?.name}}</div>
      <div style="font-size: small">{{ object?.organization?.name }}</div>
    </div>
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab [label]="_common.CenterInfo">
        <div class="max-scroll dialogBody">
          <form [formGroup]="form">
            <div class="card">
              <!-- <div class="cardHeader">Settings</div> -->
              <div class="cardBody space-y-2">
                <!--<div>
                  <app-form-field style="width: 50%">
                    <app-label>Organization</app-label>
                    <mat-select
                      formControlName="organization"
                      [compareWith]="compareWith"
                    >
                      <mat-option *ngFor="let o of organizations" [value]="o"
                        >{{o.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>-->

                <app-image-list
                  [multiple]="true"
                  [imageContainer]="container"
                ></app-image-list>

                <div class="flex gap-2">
                  <app-form-field class="flex-auto">
                    <app-label>{{_common.name}}</app-label>
                    <input matInput formControlName="name" required />
                  </app-form-field>
                  <app-form-field style="width: 100px">
                    <app-label>{{_common.code}}</app-label>
                    <app-hint>({{_common.optional}})</app-hint>
                    <input matInput formControlName="code" required />
                  </app-form-field>
                </div>
                <div>
                  <div>{{_common.introduction}}</div>
                  <ckeditor [editor]="Editor" formControlName="description">
                  </ckeditor>
                </div>

                <!--<div>
                  <app-form-field appearance="outline" style="width: 50%">
                    <app-label>Time Zone</app-label>
                    <mat-select formControlName="timezone">
                      <mat-option *ngFor="let o of timezones" [value]="o.id"
                        >{{ o.name }}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>-->
              </div>
              <div class="cardDivider"></div>
              <div class="cardHeader">{{_common.ContactInfo}}</div>
              <div class="cardBody space-y-2">
                <div>
                  <div>{{_common.area}}</div>
                  <div class="flex gap-2">
                    <app-form-field>
                      <mat-select formControlName="area">
                        <mat-option value="HK">{{_common.HK}}</mat-option>
                        <mat-option value="KLN">{{_common.KLN}}</mat-option>
                        <mat-option value="NT">{{_common.NT}}</mat-option>
                      </mat-select>
                    </app-form-field>
                    <app-form-field>
                      <mat-select formControlName="district">
                        <ng-container *ngIf="form.value.area=='HK'">
                          <mat-option value="HK_CS"
                            >{{_common.HK_CS}}</mat-option
                          >
                          <mat-option value="HK_WC"
                            >{{_common.HK_WC}}</mat-option
                          >
                          <mat-option value="HK_E">{{_common.HK_E}}</mat-option>
                          <mat-option value="HK_S">{{_common.HK_S}}</mat-option>
                        </ng-container>
                        <ng-container *ngIf="form.value.area=='KLN'">
                          <mat-option value="KLN_YTM"
                            >{{_common.KLN_YTM}}</mat-option
                          >
                          <mat-option value="KLN_SSP"
                            >{{_common.KLN_SSP}}</mat-option
                          >
                          <mat-option value="KLN_KC"
                            >{{_common.KLN_KC}}</mat-option
                          >
                          <mat-option value="KLN_WTS"
                            >{{_common.KLN_WTS}}</mat-option
                          >
                          <mat-option value="KLN_KT"
                            >{{_common.KLN_KT}}</mat-option
                          >
                        </ng-container>
                        <ng-container *ngIf="form.value.area=='NT'">
                          <mat-option value="NT_KT"
                            >{{_common.NT_KT}}</mat-option
                          >
                          <mat-option value="NT_TW"
                            >{{_common.NT_TW}}</mat-option
                          >
                          <mat-option value="NT_TM"
                            >{{_common.NT_TM}}</mat-option
                          >
                          <mat-option value="NT_YL"
                            >{{_common.NT_YL}}</mat-option
                          >
                          <mat-option value="NT_N">{{_common.NT_N}}</mat-option>
                          <mat-option value="NT_TP"
                            >{{_common.NT_TP}}</mat-option
                          >
                          <mat-option value="NT_ST"
                            >{{_common.NT_ST}}</mat-option
                          >
                          <mat-option value="NT_SK"
                            >{{_common.NT_SK}}</mat-option
                          >
                          <mat-option value="NT_Islands"
                            >{{_common.NT_Islands}}</mat-option
                          >
                        </ng-container>
                      </mat-select>
                    </app-form-field>
                  </div>
                </div>
                <div>
                  <app-form-field class="w-full">
                    <app-label>{{_common.address}}</app-label>
                    <input matInput formControlName="address" />
                  </app-form-field>
                </div>
                <div class="flex gap-2">
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.tel_no}}</app-label>
                    <input matInput formControlName="tel" />
                  </app-form-field>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.email}}</app-label>
                    <input matInput formControlName="email" />
                  </app-form-field>
                </div>
                <div class="flex gap-2">
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.center_contact_person}}</app-label>
                    <input matInput formControlName="contactPerson" />
                  </app-form-field>
                </div>
                <div>
                  <app-form-field class="w-full">
                    <app-label>{{_common.opening_hours}}</app-label>
                    <textarea
                      matInput
                      formControlName="openingHours"
                    ></textarea>
                  </app-form-field>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="cardHeader">{{_common.map_settings}}</div>
              <div class="cardBody space-y-2">
                <div>
                  <app-form-field class="w-full">
                    <app-label>{{_common.google_map_url}}</app-label>
                    <input matInput formControlName="googleMapUrl" />
                  </app-form-field>
                </div>

                <div class="flex gap-2">
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.latitude}}</app-label>
                    <input matInput formControlName="lat" type="number" />
                  </app-form-field>
                  <app-form-field style="width: 50%">
                    <app-label>{{_common.longitude}}</app-label>
                    <input matInput formControlName="lng" type="number" />
                  </app-form-field>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="cardHeader flex-col" style="align-items: flex-start">
                {{_common.TermsAndConditions_centerDefault}}
                <div class="text-exp">
                  {{_common.TermsAndConditions_centerDefault_exp}}
                </div>
              </div>
              <div class="cardBody">
                <app-form-field class="w-full">
                  <textarea matInput formControlName="terms"></textarea>
                </app-form-field>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="{{_common.other_language}}">
        <div class="dialogBody max-scroll">
          <div *ngFor="let dlang of dlangs" class="card">
            <div class="cardBody flex flex-col space-y-2">
              <div class="lang-chip">
                {{_common[_common.langs??[dlang]??dlang]}}
              </div>

              <div [formGroup]="$any(form.controls['name_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.name}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['description_lang'])">
                <div>{{_common.introduction}}</div>
                <ckeditor [editor]="Editor" [formControlName]="dlang">
                </ckeditor>
              </div>

              <div [formGroup]="$any(form.controls['address_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.address}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['contactPerson_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.center_contact_person}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['openingHours_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.opening_hours}}</app-label>
                  <textarea matInput [formControlName]="dlang"></textarea>
                </app-form-field>
              </div>

              <div [formGroup]="$any(form.controls['terms_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label
                    >{{_common.TermsAndConditions_centerDefault}}</app-label
                  >
                  <textarea matInput [formControlName]="dlang"></textarea>
                </app-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <ng-container *ngIf="object">
        <mat-tab [label]="_common.Facilities">
          <div class="max-height dialogBody">
            <app-space-facility-list
              [location]="object"
              [organization]="object.organization"
            ></app-space-facility-list>
          </div>
        </mat-tab>

        <mat-tab
          [label]="_common.Spaces"
          *ngIf="object && session.hasPermission(permissions.SPACE_VIEW)"
        >
          <div class="max-height dialogBody">
            <app-space-list
              [location]="object"
              [organization]="object.organization"
            ></app-space-list>
          </div>
        </mat-tab>
      </ng-container>
      <mat-tab [label]="_common.Schedules" *ngIf="object">
        <div class="max-height dialogBody">
          <app-schedule-calendar-list
            [query]="scheduleQuery"
          ></app-schedule-calendar-list>
        </div>
      </mat-tab>
      <mat-tab [label]="'Content'" *ngIf="object">
        <div class="max-height dialogBody">
          <app-agent-content-list [location]="object"></app-agent-content-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <!--<button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>-->

    <div class="spacer"></div>

    <button
      class="app-btn"
      (click)="save()"
      color="primary"
      *ngIf="session.hasPermission(permissions.LOCATION_EDIT)"
    >
      {{_common.save}}
    </button>
  </div>
</div>
