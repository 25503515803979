<ng-container [formGroup]="container.form">
  <div></div>
  <div style="width: 300px">
    <mat-calendar [(selected)]="container.selected" (selectedChange)="container.dateUpdated()"
      [maxDate]="container.endDate" [minDate]="container.startDate"></mat-calendar>
  </div>

  <div>
    <app-form-field style="width:300px;">
      <app-label>{{_common.thinkdesks.time}}</app-label>
      <mat-select formControlName="startTime" (selectionChange)="container.updateDuration()">
        <mat-option *ngFor="let o of container.hours" [value]="o" [disabled]="!o.active">
          {{o.name}}
        </mat-option>
      </mat-select>
    </app-form-field>
  </div>
  <div>
    <app-form-field style="width:300px;">
      <app-label>{{_common.thinkdesks.duration}}</app-label>
      <mat-select formControlName="duration">
        <mat-option *ngFor="let o of container.slots" [value]="o" [disabled]="!o.active">
          {{o.name}}
        </mat-option>
      </mat-select>
    </app-form-field>
  </div>
</ng-container>