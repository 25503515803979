import { date_utils } from '../../common/date_utils';
import { DaySchedule } from '../../common/timespan/day.schedule';
// TSchedulePeriod
export interface TBookingCalendar {
  id: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  mon: DaySchedule;
  tue: DaySchedule;
  wed: DaySchedule;
  thu: DaySchedule;
  fri: DaySchedule;
  sat: DaySchedule;
  sun: DaySchedule;
  ph: DaySchedule;
}
// TScheduleDate
export interface TBookingCalendarDate {
  id: number;
  date: moment.Moment;
  schedule: DaySchedule;
}

export enum TBookingAdjustmentMode {
  all = 'all',
  month = 'month',
  month_first = 'month_first', // first Mon/TUe of the month
  week = 'week',
}
export interface TBookingAdjustment {
  id: number;
  startDate: moment.Moment;
  endDate: moment.Moment;
  mode: TBookingAdjustmentMode;
  time: DaySchedule;
  mon: DaySchedule;
  tue: DaySchedule;
  wed: DaySchedule;
  thu: DaySchedule;
  fri: DaySchedule;
  sat: DaySchedule;
  sun: DaySchedule;
  qty: number;
  days: number[];
  reason: string;
}
export interface TBookingScheduleDate extends TBookingCalendarDate {}

export class BookingCalendarEntities {
  static fixSchedule(o: string | DaySchedule): DaySchedule {
    if (typeof o == 'string') {
      return DaySchedule.parse(o as string);
    }
    return o;
  }
  static fixSchedulePeriod(o: TBookingCalendar) {
    o.startDate = date_utils.utcToLocal2(o.startDate);
    o.endDate = date_utils.utcToLocal2(o.endDate);
    o.mon = this.fixSchedule(o.mon);
    o.tue = this.fixSchedule(o.tue);
    o.wed = this.fixSchedule(o.wed);
    o.thu = this.fixSchedule(o.thu);
    o.fri = this.fixSchedule(o.fri);
    o.sat = this.fixSchedule(o.sat);
    o.sun = this.fixSchedule(o.sun);
    o.ph = this.fixSchedule(o.ph);
  }
  static fixAdjustment(o: TBookingAdjustment) {
    o.startDate = date_utils.utcToLocal2(o.startDate);
    o.endDate = date_utils.utcToLocal2(o.endDate);
    o.time = this.fixSchedule(o.time);
    o.mon = this.fixSchedule(o.mon);
    o.tue = this.fixSchedule(o.tue);
    o.wed = this.fixSchedule(o.wed);
    o.thu = this.fixSchedule(o.thu);
    o.fri = this.fixSchedule(o.fri);
    o.sat = this.fixSchedule(o.sat);
    o.sun = this.fixSchedule(o.sun);
    if (o.days) {
      o.days = o.days.map((c) => parseInt(c as any));
    }
  }
  static fixCalendarDate(o: TBookingCalendarDate) {
    o.date = date_utils.utcToLocal2(o.date);

    o.schedule = this.fixSchedule(o.schedule);
  }
}
