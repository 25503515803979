import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from '../common/utils';
import { AccessUtils, TMemberWishlist, TSpace } from '../core/entities';
import { FullWidthCarouselComponent } from '../core/fixed.carousel.component';
import { FixedCarouselItemDirective } from '../core/fixed.carousel.item.directive';
import { SiteLangMixin } from '../core/lang';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-space-page',
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    FullWidthCarouselComponent,
    FixedCarouselItemDirective,
  ],
  templateUrl: './space.page.html',
})
export class SpacePage extends SiteLangMixin(SitePathMixin(EmptyObject)) {
  @HostBinding('class') css = 'max-scroll';
  subscription: Subscription;
  subscription2: Subscription;
  id: number;
  _common = _common;

  imageIndex = 0;
  object: TSpace;
  description: SafeHtml;
  openingHours: string[];
  constructor(
    private clipboard: Clipboard,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cache: SessionCache,
    public override session: SessionService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.subscription2 = this.session.userStream.subscribe((result) => {
      this.refreshStatus();
    });
    this.subscription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));
      this.refreshStatus();
      this.refresh();
    });

    let authCode = this.route.snapshot.queryParamMap.get('authCode');
    let source = this.route.snapshot.queryParamMap.get('source');
    if (source == 'app' && authCode) {
      WebUtils.web_result(
        this.http.post('/vp-validate', { authCode }),
        (result) => {
          let member = result;
          this.session.embedded = true;
          this.session.refresh();
        }
      );
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscription2.unsubscribe();
    this.session.setTitle();
  }
  _wishlist: TMemberWishlist;
  refreshStatus() {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'));
    this._wishlist = null;
    WebUtils.web_result(
      this.http.get(`/api/public/client-space/${this.id}/like-status`),
      (result) => {
        this._wishlist = result?.wishlist;
      }
    );
  }
  refresh() {
    WebUtils.web_result(
      this.http.get(`/api/public/space/${this.id}`),
      (result) => {
        this.object = result as TSpace;
        AccessUtils.fixSpace(this.object);
        if (!this.object.active) {
          this.router.navigate(['/notfound']);
          return;
        }
        this.session.setTitle(this.object.name);

        this.description = this.sanitizer.bypassSecurityTrustHtml(
          this.object.description ?? ''
        );
        let ss = this.object.location.openingHours ?? '';

        this.openingHours = ss.split('\n');
      },
      null,
      (err) => {
        this.router.navigate(['/notfound']);
      }
    );
  }
  share() {
    this.clipboard.copy(window.location.href);
    this.snackBar.open(_common.linkCopied);
  }
  openGoogleMap() {
    window.open(this.object.location.googleMapUrl);
  }

  wishlist() {
    if (!this._wishlist) {
      WebUtils.web_result(
        this.http.post(`/api/public/client-space/${this.id}/like`, {}),
        (result) => {
          this._wishlist = result;
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`/api/public/client-space/${this.id}/unlike`, {}),
        (result) => {
          this._wishlist = null;
        }
      );
    }
  }
  book() {
    if (this.session.user) {
      this.router.navigate([this.session.path('/space/' + this.id + '/book')]);
    } else {
      this.session.login();
    }
  }
}
