import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './profile.page.html',
})
export class ProfilePage {
  @HostBinding('class') css = 'max-scroll';
  constructor(
    public dialog: MatDialog,
    public router: Router,
    public session: SessionService
  ) {}
  ngOnInit() {}
  openQrcode() {
    this.session.showQrCode(this);
  }
}
