/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { VizFormModule } from '../components/form/form.module';
import { FilterMultiComponent } from './filter.multi.component';
import { FilterObject } from './list.filter';

@Component({
  selector: 'app-filter-multi-filter',
  templateUrl: './filter.multi.filter.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    MatMenuModule,
    MatCheckboxModule,
    VizFormModule,
  ],
})
export class FilterMultiFilterComponent {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faTimes = faTimes;
  _common = _common;

  @Input()
  filterObject: FilterObject;
  @Input()
  id: string;
  @Input()
  filter = new FilterMultiComponent();

  @Output()
  change = new EventEmitter<void>();

  refresh() {
    this.change.next(void 0);
  }
  toggle(v: any, event: any) {
    this.filter.filter.update(v, event);
    this.refresh();
  }
}
