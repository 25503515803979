import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { WebUtils } from './common/utils';
import {
  AccessUtils,
  TLocation,
  TOrganization,
  TSpace,
  TSpaceFacility,
  TSpacePurpose,
  TSpaceType,
} from './core/entities';
import { SessionService } from './session.service';
import {
  THolidayCalendar,
  TScheduleCalendar,
  Thinkdesks,
} from './thinkdesks/thinkdesks';

//TODO move types reading to public
@Injectable()
export class SessionCache {
  organizations: TOrganization[];
  allLocations: TLocation[];
  spaceTypes: TSpaceType[];
  // spaceFacilities: TSpaceFacility[];
  holidayGlobal: THolidayCalendar[];

  constructor(private http: HttpClient) {}
  ////////////////////////////////////////////////////////////////////////////////////

  loadOrganization(
    session: SessionService,
    cb?: (list: TOrganization[]) => void
  ) {
    if (this.organizations) {
      if (cb) cb(this.organizations);
      return Promise.resolve(this.organizations);
    }
    let query = {};
    Object.assign(query, session.userQuery());
    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/public/organization/list?location=true`, query),
        (list) => {
          this.organizations = list as TOrganization[];
          if (cb) cb(this.organizations);
          resolve(this.organizations);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  clearOrganization() {
    this.organizations = null;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  spaceFacilities: { [key: number]: TSpaceFacility[] } = {};

  loadSpaceFacilities(
    locationId: number,
    cb?: (list: TSpaceFacility[]) => void
  ) {
    let result = this.spaceFacilities[locationId];
    if (result) {
      if (cb) cb(result);
      return Promise.resolve(result);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/session/space-facility`, {
          location: locationId,
        }),
        (list) => {
          result = list as TSpaceFacility[];
          for (let o of result) AccessUtils.fixSpaceFacility(o);
          this.spaceFacilities[locationId] = result;

          if (cb) cb(result);
          resolve(result);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  clearSpaceFacilities() {
    this.spaceFacilities = {};
  }

  ////////////////////////////////////////////////////////////////////////////////////

  loadSpaceTypes(cb?: (list: TSpaceType[]) => void) {
    if (this.spaceTypes) {
      if (cb) cb(this.spaceTypes);
      return Promise.resolve(this.spaceTypes);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/session/space-type`, {}),
        (list) => {
          this.spaceTypes = list as TSpaceType[];
          for (let o of this.spaceTypes) AccessUtils.fixSpaceType(o);

          if (cb) cb(this.spaceTypes);
          resolve(this.spaceTypes);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  clearSpaceTypes() {
    this.spaceTypes = null;
  }
  ////////////////////////////////////////////////////////////////////////////////////

  location: { [key: number]: TLocation[] } = {};
  loadLocation(organization: number, cb?: (list: TLocation[]) => void) {
    let list = this.location[organization];
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/location/list?limit=-1`, {
          organization,
        }),
        (result) => {
          list = result as TLocation[];
          for (let o of list) {
            AccessUtils.fixLocation(o);
          }
          this.location[organization] = list;
          if (cb) cb(list);
          resolve(list);
        },
        null,
        (err) => reject(err)
      );
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////

  managedLocation: TLocation[];
  loadManagedLocation(
    session: SessionService,
    cb?: (list: TLocation[]) => void
  ) {
    let list = this.managedLocation;
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      let query = {};
      Object.assign(query, session.userQuery());
      WebUtils.web_list(
        this.http.post(`/api/location/list?limit=-1`, query),
        (result) => {
          list = result as TLocation[];
          for (let o of list) {
            AccessUtils.fixLocation(o);
          }
          this.managedLocation = list;
          if (cb) cb(list);
          resolve(list);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////
  // client locations
  loadAllLocation(cb?: (list: TLocation[]) => void) {
    let list = this.allLocations;
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/public/location/list?limit=-1`, {}),
        (result) => {
          list = result as TLocation[];
          for (let o of list) {
            AccessUtils.fixLocation(o);
          }
          this.allLocations = list;
          if (cb) cb(list);
          resolve(list);
        },
        null,
        (err) => reject(err)
      );
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////
  space: { [key: number]: TSpace[] } = {};
  loadSpace(location: number, cb?: (list: TSpace[]) => void) {
    let list = this.space[location];
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/space/list`, {
          location: location,
        }),
        (result) => {
          list = result as TSpace[];
          this.space[location] = list;
          if (cb) cb(list);
          resolve(list);
        },
        null,
        (err) => reject(err)
      );
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////
  schedules: { [key: number]: TScheduleCalendar[] } = {};
  loadScheduleCalendar(
    location: number,
    cb?: (list: TScheduleCalendar[]) => void
  ) {
    let list = this.schedules[location];
    if (list) {
      if (cb) cb(list);
      return Promise.resolve(list);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/schedule-calendar/list?limit=-1`, {
          location: {
            id: location,
          },
        }),
        (result) => {
          list = result as TScheduleCalendar[];
          this.schedules[location] = list;
          if (cb) cb(list);
          resolve(list);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////

  loadHolidayGlobal(cb?: (list: THolidayCalendar[]) => void) {
    if (this.holidayGlobal) {
      if (cb) cb(this.holidayGlobal);
      return Promise.resolve(this.holidayGlobal);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/holiday-calendar/list/global`, {}),
        (list) => {
          this.holidayGlobal = list as THolidayCalendar[];
          for (let o of this.holidayGlobal) Thinkdesks.fixHolidayCalendar(o);
          if (cb) cb(this.holidayGlobal);
          resolve(this.holidayGlobal);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  clearHolidayGlobal() {
    this.holidayGlobal = null;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  spacePurposes: TSpacePurpose[];

  loadSpacePurposes(cb?: (list: TSpacePurpose[]) => void) {
    if (this.spacePurposes) {
      if (cb) cb(this.spacePurposes);
      return Promise.resolve(this.spacePurposes);
    }

    return new Promise((resolve, reject) => {
      WebUtils.web_list(
        this.http.post(`/api/space-purpose/list`, {}),
        (list) => {
          this.spacePurposes = list as TSpacePurpose[];
          for (let o of this.spacePurposes) AccessUtils.fixSpacePurpose(o);

          if (cb) cb(this.spacePurposes);
          resolve(this.spacePurposes);
        },
        null,
        (err) => reject(err)
      );
    });
  }
  clearSpacePurposes() {
    this.spacePurposes = null;
  }
}
