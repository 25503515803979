import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { RouterLink } from '@angular/router';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../common/list.component.base';
import { WebUtils } from '../common/utils';
import {
  AccessUtils,
  TLocation,
  TLocker,
  TOrganization
} from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';

class _Base extends ScrollableListComponentBase<TLocker> {}

@Component({
  selector: 'app-locker-search-list',
  templateUrl: './locker.search.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
})
export class LockerSearchListComponent extends SitePathMixin(
  SiteFormatMixin(_Base)
) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();
  //   status = new FilterMulti();

  //   type = new FilterMulti();

  displayedColumns: string[] = ['name', 'organization', 'location', 'controls'];

  @Input()
  organization: TOrganization;

  @Input()
  location: TLocation;

  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    public session: SessionService,
    override snackBar: MatSnackBar,
    private cache: SessionCache
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      organization: WebUtils.objId(this.organization),
      location: WebUtils.objId(this.location),
    } as any;
    Object.assign(query, this.filterObject.query());

    const url = this._listModifyUrl(`/api/locker/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TLocker[]) {
    for (let o of list) AccessUtils.fixLocker(o, );
  }

  override selectObject(o: TLocker) {}
}
