/** @format */

import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const UPDATED = 'UPDATED';
export const DELETED = 'DELETED';
export const MERGED = 'MERGED';
export const DUPLICATED = 'DUPLICATED';
export const ARCHIVED = 'ARCHIVED';
export const UNARCHIVED = 'UNARCHIVED';

export const ALL = { id: 0 };

export interface ValueUpdated<T> {
  oldValue: T;
  newValue: T;
}
export function CompareWithDecorator(): Function {
  return (target: Function): Function => {
    target.prototype.compareWith = (a, b) => {
      return a.id == b.id;
    };
    return target;
  };
}

export class EmptyObject {}

export enum ObjectUpdateAction {
  DELETE,
  ADD,
  UPDATE,
}
export interface ObjectUpdate<T> {
  action: ObjectUpdateAction;
  object: T;
}

type Constructor<T = {}> = new (...args: any[]) => T;
export function CompareWithMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }
    compareCaseInsensitive(a: string, b: string) {
      return a.toLocaleLowerCase() == b.toLocaleLowerCase();
    }
    compareWith(a, b) {
      if (a == null && b == null) return true;
      if ((a != null && b == null) || (a == null && b != null)) return false;
      return a.id == b.id;
    }
  };
}

// export function CompareWithDecorator<TClass extends new (...args: any[]) => Object>(target: TClass) {
//    class Rawrable extends (<new (...args: any[]) => Object>target) {
//       compareWith(a, b) {
//          return a.id == b.id;
//       }
//    }
//    return Rawrable;
// }

// export function CompareWithDecorator() {
//    return function <T extends { new(...args: any[]): {} }>(constructor: T) {
//       return class CompareClass extends constructor {
//          compareWith(a, b) {
//             return a.id == b.id;
//          }
//       }
//    };
// }

export interface HasName {
  name?: string;
}

export interface Pair<T> extends HasName {
  id?: T;
}

export interface NamePair extends Pair<string> {}

export interface NumberPair extends Pair<number> {}

export interface MapLatLng {
  mapLat?: number;
  mapLng?: number;
  address?: string;
  country?: string;
  region?: string;
}

export class WebUtils {
  static REFERENCE_ERROR = 'referenceError';
  static obj(o: { id?: number | string }) {
    if (!o || !o.id || (o as any) == '') return null;
    return {
      id: o.id,
    };
  }

  //reference https://www.w3.org/TR/xml-entity-names/Overview.html#blocks
  static unicode: { l: number; h: number; offset: number }[] = [
    //bold
    {
      l: 0x1d400,
      h: 0x1d419,
      offset: 0x41,
    },
    {
      l: 0x1d41a,
      h: 0x1d433,
      offset: 0x61,
    },
    {
      l: 0x1d7ce,
      h: 0x1d7d7,
      offset: 0x30,
    },
    //italic

    {
      l: 0x1d434,
      h: 0x1d44d,
      offset: 0x41,
    },
    {
      l: 0x1d44e,
      h: 0x1d467,
      offset: 0x61,
    },

    // san serif
    {
      l: 0x1d5a0,
      h: 0x1d5b9,
      offset: 0x41,
    },
    {
      l: 0x1d5ba,
      h: 0x1d5d3,
      offset: 0x61,
    },
    {
      l: 0x1d7e2,
      h: 0x1d7eb,
      offset: 0x30,
    },

    // bold san serif
    {
      l: 0x1d5d4,
      h: 0x1d5ed,
      offset: 0x41,
    },
    {
      l: 0x1d5ee,
      h: 0x1d6a3,
      offset: 0x61,
    },
    {
      l: 0x1d7ec,
      h: 0x1d7f5,
      offset: 0x30,
    },

    // monospace
    {
      l: 0x1d670,
      h: 0x1d689,
      offset: 0x41,
    },
    {
      l: 0x1d68a,
      h: 0x1d6a3,
      offset: 0x61,
    },
    {
      l: 0x1d7f6,
      h: 0x1d7ff,
      offset: 0x30,
    },
  ];
  static removeEmoji(str: string) {
    return str.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
  }
  static clean(str: string) {
    if (!str) return null;

    str = str.normalize();
    var tmp = '';
    for (var i = 0; i < str.length; i++) {
      let cc = str.charCodeAt(i);

      if (cc >= 0xd800 && cc <= 0xdbff) {
        i++;
        let cc2 = str.charCodeAt(i);
        cc = cc & 0x3ff;
        cc2 = cc2 & 0x3ff;
        cc = (cc << 10) | (cc2 + 0x10000);
      }

      if (cc == 12288) {
        cc = cc - 12256;
        // } else if (cc > 65280 && cc < 65375) {
        //   cc = cc - 65248;
      }
      for (let u of this.unicode) {
        if (cc >= u.l && cc <= u.h) {
          cc -= u.l - u.offset;
          break;
        }
      }

      //output
      if (cc >= 0x10000) {
        cc = cc - 0x10000;
        let cc2 = cc & 0x3ff;
        let cc1 = (cc >> 10) & 0x3ff;
        tmp += String.fromCharCode(0xd800 | cc1);
        tmp += String.fromCharCode(0xdc00 | cc2);
      } else {
        tmp += String.fromCharCode(cc);
      }
    }

    let list = tmp.split(' ').filter((c) => c != '');
    tmp = list.join(' ');
    return tmp;
  }

  static cleanEmojiLang(lang: { [key: string]: string }) {
    for (let id in lang) {
      lang[id] = this.cleanEmoji(lang[id]);
    }
  }

  static cleanEmoji(s: string) {
    {
      let regex =
        /<span([^>])*><img height="16" width="16" alt="." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*><\/span>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt="');
          let d1 = c.charAt(ii + 5);
          s = s.replace(c, d1);
        }
      }
    }
    {
      let regex =
        /<img height="16" width="16" alt="." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt="');
          let d1 = c.charAt(ii + 5);
          s = s.replace(c, d1);
        }
      }
    }

    {
      let regex =
        /<span([^>])*><img height="16" width="16" alt=".." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*><\/span>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          s = s.replace(c, d1 + d2);
        }
      }
    }
    {
      let regex =
        /<img height="16" width="16" alt=".." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          s = s.replace(c, d1 + d2);
        }
      }
    }

    {
      let regex =
        /<span([^>])*><img height="16" width="16" alt="..." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*><\/span>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          let d3 = c.charAt(ii + 7);
          s = s.replace(c, d1 + d2 + d3);
        }
      }
    }
    {
      let regex =
        /<img height="16" width="16" alt="..." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          let d3 = c.charAt(ii + 7);
          s = s.replace(c, d1 + d2 + d3);
        }
      }
    }

    {
      let regex =
        /<span([^>])*><img height="16" width="16" alt="...." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*><\/span>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          let d3 = c.charAt(ii + 7);
          let d4 = c.charAt(ii + 8);
          s = s.replace(c, d1 + d2 + d3 + d4);
        }
      }
    }
    {
      let regex =
        /<img height="16" width="16" alt="...." referrerpolicy="origin-when-cross-origin" src="https:\/\/static.xx.fbcdn.net\/images\/emoji.php[^>]*>/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          let ii = c.indexOf('alt=');
          let d1 = c.charAt(ii + 5);
          let d2 = c.charAt(ii + 6);
          let d3 = c.charAt(ii + 7);
          let d4 = c.charAt(ii + 8);

          s = s.replace(c, d1 + d2 + d3 + d4);
        }
      }
    }
    //remove class
    {
      let regex = /class="([0-9]|[a-z]|\s)*"/gm;
      let list = s.match(regex);
      if (list) {
        for (let c of list) {
          s = s.replace(c, '');
        }
      }
    }

    s = s.replace(/font-family: inherit;/g, '');
    s = s.replace(/animation-name: none !important;/g, '');
    s = s.replace(/transition-property: none !important;/g, '');
    s = s.replace(/dir="auto"/g, '');
    s = s.replace(/style="[\s]*"/g, '');
    s = s.replace(/--tw-([a-z]|-)*:[^;]*;/g, '');

    return s;
  }

  static cleanLang(lang: { [key: string]: string }) {
    for (let id in lang) {
      lang[id] = this.clean(lang[id]);
    }
  }

  static objName(o: { id?: number | string; name?: string }) {
    if (!o || !o.id || (o as any) == '') return null;
    return {
      id: o.id,
      name: o.name,
    };
  }
  static objId(o: { id?: number | string }) {
    if (!o) return null;
    return o.id;
  }

  static error_message(err) {
    if (typeof err == 'string') {
      let s: string;
      if (_common.error) s = _common.error[err] || _common[err] || err;
      else s = _common[err] || err;
      return s;
    }
    return err;
  }

  static web_list<T>(
    request: Observable<Object>,
    cb: (a: T[], count?: number, data?: any) => void,
    snackBar?: MatSnackBar,
    err?: (err: string) => void,
  ) {
    request.subscribe(
      (o: any) => {
        cb(o.results as T[], o.count, o.data);
      },
      (error) => {
        error = this.error_message(error);

        if (snackBar) snackBar.open(error);
        if (err) error(error as string);
      },
    );
  }

  static web_list_promise<T>(
    request: Observable<Object>,
    snackBar?: MatSnackBar,
  ) {
    return new Promise<T>((resolve, reject) => {
      request.subscribe(
        (o: any) => {
          resolve(o as T);
        },
        (error) => {
          error = this.error_message(error);

          if (snackBar) snackBar.open(error);
          if (error) reject(error);
        },
      );
    });
  }
  static web_result_promise<T>(
    request: Observable<Object>,
    snackBar?: MatSnackBar,
  ) {
    return new Promise<T>((resolve, reject) => {
      request
        .pipe(
          catchError((error, o) => {
            error = this.error_message(error);

            if (snackBar) snackBar.open(error);
            if (error && error instanceof HttpErrorResponse)
              reject(error.statusText);
            if (error) reject(error);
            return throwError(error);
          }),
        )
        .subscribe((o: any) => {
          if (!o) {
            reject('error');
            return;
          }
          if (o.succeed === null) {
            let ee = 'Program Error: Invalid Response';
            if (snackBar) snackBar.open(ee);
            reject(ee);
            return;
          }
          if (o.succeed) {
            resolve(o.payload as T);
          } else {
            let error = this.error_message(o.error);

            if (snackBar) snackBar.open(error);
            reject(o.error);
          }
        });
    });
  }

  static web_result<T>(
    request: Observable<Object>,
    cb: (T) => void,
    snackBar?: MatSnackBar,
    err?: (string, type?: string, code?: string) => void,
  ) {
    request.subscribe(
      (o: any) => {
        if (o.succeed === null) {
          snackBar.open('Program Error: Invalid Response');
          return;
        }
        if (o.succeed) {
          cb(o.payload as T);
        } else {
          let error = this.error_message(o.error);

          if (snackBar) snackBar.open(error);
          if (err) err(error as string, o.type as string, o.error);
        }
      },
      (_error) => {
        let error = this.error_message(_error);

        if (snackBar) snackBar.open(error);
        if (err) err(error as string, null, _error);
      },
    );
  }
  static web_result_promise_noerror_null<T>(
    request: Observable<Object>,
    snackBar?: MatSnackBar,
  ) {
    return new Promise<T>((resolve, reject) => {
      request
        .pipe(
          catchError((error, o) => {
            error = this.error_message(error);

            if (snackBar) snackBar.open(error);
            if (error && error instanceof HttpErrorResponse)
              reject(error.statusText);
            if (error) reject(error);
            return throwError(error);
          }),
        )
        .subscribe(
          (o: any) => {
            if (!o) {
              reject('error');
              return;
            }
            if (o.succeed === null) {
              let ee = 'Program Error: Invalid Response';
              if (snackBar) snackBar.open(ee);
              reject(ee);
              return;
            }
            if (o.succeed) {
              resolve(o.payload as T);
            } else {
              resolve(null);
            }
          },
          (err) => {
            resolve(null);
          },
        );
    });
  }
}

export namespace utils {
  export function _fill_form_values(
    object: any,
    form: UntypedFormGroup,
    defaultValue?: any,
  ) {
    object = object || {};
    const o = {} as any;
    for (const name in form.controls) {
      let c = form.controls[name];
      if (c instanceof UntypedFormArray) {
        let cc = c as UntypedFormArray;
        let list = object[name] || [];
        let _list = [];
        for (let i = 0; i < cc.length; i++) {
          let child = cc.controls[i];
          let v = list[i];
          if (child instanceof UntypedFormControl) {
          } else if (child instanceof UntypedFormArray) {
            let ll = [];
            for (let j = 0; j < child.controls.length; j++) {
              let vv = _fill_form_values(
                v[j],
                child.controls[j] as UntypedFormGroup,
                null,
              );
              ll.push(vv);
            }
            v = ll;
          } else {
            v = _fill_form_values(
              list[i],
              cc.controls[i] as UntypedFormGroup,
              null,
            );
          }
          if (v == undefined) v = null;
          _list.push(v);
        }
        o[name] = _list;
      } else if (c instanceof UntypedFormGroup) {
        let oo = object[name] || {};
        o[name] = _fill_form_values(oo, c);
      } else {
        o[name] = object[name];
        if (o[name] === null || typeof o[name] === 'undefined') {
          if (defaultValue) o[name] = defaultValue[name];

          if (o[name] === null || typeof o[name] === 'undefined') {
            o[name] = '';
          }
        }
      }
    }
    return o;
  }
  export function fill_form_values(
    object: any,
    form: UntypedFormGroup,
    defaultValue?: any,
  ) {
    const o = _fill_form_values(object, form, defaultValue);
    form.setValue(o);
    return o;
  }

  export function updateValidity(form: UntypedFormGroup) {
    for (let name in form.controls) {
      let c = form.controls[name];
      c.updateValueAndValidity({
        emitEvent: true,
      });

      if (c instanceof UntypedFormGroup) {
        utils.updateValidity(c);
      } else if (c instanceof UntypedFormArray) {
        let l = c as UntypedFormArray;
        for (let cc of l.controls) utils.updateValidity(cc as UntypedFormGroup);
      }
    }
    form.updateValueAndValidity({
      emitEvent: true,
    });
  }

  export function form_read_only(form: UntypedFormGroup) {
    for (const name in form.controls) {
      let cc = form.controls[name];
      cc.disable();
    }
  }
  export function clean_number(v: any) {
    if (!v || v == '') return null;
    if (typeof v == 'string') return parseFloat(v);
    return v;
  }

  export function form_clean_numbers(
    object: any,
    names: string[],
    objectNames?: string[],
  ) {
    for (let name of names) {
      let v = object[name];
      if (v == '') delete object[name];
    }
    if (objectNames) {
      for (let name of objectNames) {
        let v = object[name];
        if (v && !v.id) delete object[name];
      }
    }
  }
  export function subset(object: any, names: string[]) {
    const o = {} as any;
    for (const name of names) {
      o[name] = object[name];
    }
    return o;
  }
  export function decodeObjectType(id: string) {
    const index = id.indexOf(':');
    return id.substr(0, index);
  }
  export function decodeObjectId(id: string) {
    const index = id.indexOf(':');
    return id.substr(index + 1);
  }
  export function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  export function arrayToNamePair(values: any[], _common?: any): NamePair[] {
    const list = [];
    for (const n of values) {
      list.push({
        id: n,
        name: _common ? _common[n] : n,
      });
    }
    return list;
  }

  export function arrayIndexToNamePair(
    values: any[],
    _common?: any,
  ): NamePair[] {
    const list = [];
    for (let i = 0; i < values.length; i++) {
      const n = values[i];
      list.push({
        id: i.toString(),
        name: _common ? _common[n] : n,
      });
    }
    return list;
  }

  export function objectToNamePair<T>(
    values: any,
    nameFunc: (o: T) => string,
  ): NamePair[] {
    const list = [];
    for (const n of Object.keys(values)) {
      list.push({
        id: n,
        name: nameFunc(values[n]),
      });
    }
    return list;
  }

  export function enumToNamePair(values: any, _common?: any): NamePair[] {
    const list = [];
    for (const n of Object.keys(values)) {
      list.push({
        id: n,
        name: _common ? _common[values[n]] : values[n],
      });
    }
    return list;
  }

  export function pushAll<T>(list: T[], src: T[]) {
    for (const s of src) list.push(s);
  }

  export function objectToQueryString(params: any) {
    let p = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) p = p.set(key, params[key]);
    }
    return p.toString();
  }

  export function idArrayToObjects<T extends NamePair>(
    ids: string[],
    objects: T[],
  ) {
    const list: T[] = [];
    for (const s of ids) {
      for (const t of objects) {
        if (s === t.id) {
          list.push(t);
          break;
        }
      }
    }
    return list;
  }
  export function matchNumerParis<T extends NumberPair>(
    objects: T[],
    src: T[],
  ) {
    const list: T[] = [];
    for (const o of objects) {
      for (const s of src) {
        if (s.id === o.id) {
          list.push(s);
          break;
        }
      }
    }
    return list;
  }

  export function objectArrayToId(objects: NamePair[]) {
    const list: string[] = [];
    for (const o of objects) list.push(o.id);
    return list;
  }

  export function numberPairsToIds(objects: NumberPair[]) {
    const list: number[] = [];
    for (const o of objects) list.push(o.id);
    return list;
  }

  export function error(error: HttpErrorResponse, snackBar: MatSnackBar) {
    let msg: any = error.error;
    if (msg.error && error.message) {
      msg = msg.error + ' - ' + msg.message;
    }

    snackBar.open(msg, '', {
      panelClass: 'snackbar_error',
    });
  }

  // export function splitterHack(splitter: SplitComponent) {
  //    //hack
  //    setTimeout(() => {
  //       splitter.build(false, true);
  //    });

  // }
  // export function splitterHack2(splitter: any) {
  //    //hack
  //    setTimeout(() => {
  //       splitter.build(false, true);
  //    });

  // }

  export function filterFunc(data: any, filter: string, fields?: string[]) {
    if (fields) {
      for (const f of fields) {
        const v = data[f];
        if (v && (v + '').toLowerCase().indexOf(filter) >= 0) return true;
      }
      return false;
    } else {
      const dataStr = Object.keys(data)
        .reduce((currentTerm, key) => currentTerm + data[key], '')
        .toLowerCase();
      const transformedFilter = filter.trim().toLowerCase();
      return dataStr.indexOf(transformedFilter) !== -1;
    }
  }
  export function toObjectIdArray(objects: any[]) {
    const list = [];
    for (const o of objects) list.push(o.id);
    return list;
  }
  export function syncListValue<T extends NumberPair>(v: T, values: T[]): T {
    if (v) {
      for (const o of values) {
        if (o.id === v.id) return o;
      }
    }
    if (!v && values.length > 0) return values[0];
    return null as any as T;
  }

  export function debugValidate(formGroup: UntypedFormGroup, prefix?: string) {
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control && !control.valid) {
        console.log(`invalid ${prefix ?? ''}${field}`);
      }
      if (control instanceof UntypedFormGroup) {
        utils.debugValidate(control, `${prefix ?? ''}${field}.`);
      }
    });
  }

  export function scrollToFirstInvalidControl(form) {
    // let form = document.getElementById('formId'); // <-- your formID
    let element = form.getElementsByClassName('ng-invalid')[0];
    if (!element) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    // var offset = 45;
    // const bodyRect = document.body.getBoundingClientRect().top;
    // const elementRect = element.getBoundingClientRect().top;
    // const elementPosition = elementRect - bodyRect;
    // const offsetPosition = elementPosition - offset;

    // window.scrollTo({
    //   top: offsetPosition,
    //   behavior: "smooth"
    // });

    // element.scrollIntoView();
    (element as HTMLElement).focus();
  }
  export function validateAllFormArrays(formArray: FormArray) {
    formArray.controls.forEach((control) => {
      if (control instanceof FormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormArray) {
        //{5}
        let c = control as FormArray;
        for (let cc of c.controls) {
          if (cc instanceof FormGroup) this.validateAllFormFields(cc); //{6}
        }
      } else if (control instanceof FormGroup) {
        //{5}
        this.validateAllFormFields(control); //{6}
      }
    });
  }
  export function validateAllFormFields(
    formGroup: UntypedFormGroup,
    opts?: {
      snackBar?: MatSnackBar;
      msg?: string;
      form?: HTMLElement;
    },
  ) {
    opts = opts || {};
    //{1}
    Object.keys(formGroup.controls).forEach((field) => {
      //{2}
      const control = formGroup.get(field); //{3}
      if (control instanceof UntypedFormControl) {
        //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormArray) {
        //{5}
        let c = control as UntypedFormArray;
        for (let cc of c.controls) {
          if (cc instanceof UntypedFormGroup) validateAllFormFields(cc); //{6}
        }
      } else if (control instanceof UntypedFormGroup) {
        //{5}
        validateAllFormFields(control); //{6}
      }
    });
    if (!formGroup.valid && opts.snackBar) {
      opts.snackBar.open(opts.msg || 'Invalid Input', 'Dismiss', {
        // duration: 5000000,
        panelClass: 'snackbar-error',
      });
    }
    if (opts.form) {
      scrollToFirstInvalidControl(opts.form);
    }
  }

  export function createNameShortHand(s: string) {
    if (!s) return '';
    const list = s.split(' ');
    let b = '';
    for (let i = 0; i < list.length && i < 2; i++) {
      b += list[i].charAt(0);
    }
    return b;
  }
}
