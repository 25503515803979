import { Component, Input, OnInit } from "@angular/core";
import { FilterObject } from "./list.filter";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faSave, faStar } from "@fortawesome/free-regular-svg-icons";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-filter-save",
  templateUrl: "./filter.save.component.html",
  standalone: true,
  imports: [FontAwesomeModule, NgIf],
})
export class FilterSaveComponent implements OnInit {
  faSave = faSave;
  faStar = faStar;
  @Input()
  locale: string;

  @Input()
  filterObject = new FilterObject();

  ngOnInit() {}
}
