<!-- @format -->

<div class="ctlDayCalendar max-height" [ngClass]="cssClass">
  <div class="table headerTable" [style]="headerStyle">
    <div class="header"></div>
    <div
      *ngFor="let c of columns"
      style="text-align: center"
      class="header"
      (click)="headerClicked(c)"
    >
      <div>
        {{ c.title }}
      </div>
      <div *ngFor="let l of c.lines" class="colHeaderLine">
        {{ l }}
      </div>
    </div>

    <ng-container *ngIf="headers">
      <div class="header colHeader"></div>
      <div *ngFor="let c of columns" style="text-align: center">
        <div *ngFor="let header of c.headers" class="header2">
          <ng-container
            *ngTemplateOutlet="headerTemplate; context: { object: header }"
          >
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="max-scroll">
    <div class="table" [style]="gridStyle">
      <ng-container *ngFor="let row of rows; let rowIndex = index">
        <div class="colHeader">{{ row.start.toString() }}</div>
        <div
          *ngFor="let c of columns; let colIndex = index"
          (click)="openTime(c, rowIndex, colIndex)"
        >
          <ng-container *ngIf="columnData[rowIndex][colIndex]; let data">
            <!-- [style.bottom.%]="d._bottom" -->
            <div
              *ngFor="let d of data.items"
              class="item"
              [style.color]="d.textColor"
              [style.backgroundColor]="d.color"
              [style.borderColor]="d.borderColor"
              (click)="openItem($event, c, d, rowIndex, colIndex)"
              [style.top.%]="d._top"
              [style.height]="cellHeight(d)"
            >
              <div class="lineHeader">
                {{ d.title }}
              </div>
              <div *ngFor="let l of d.lines" class="itemLine">
                {{ l.text }}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
