/** @format */

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment-timezone';
import {
  CalendarItem,
  MonthCalendarComponent,
  MonthCalendarItem,
  MonthCalendarLoader,
} from '../../common/components/month.calendar/month.calendar.component';
import { date_utils } from '../../common/date_utils';
import { DaySchedule } from '../../common/timespan/day.schedule';
import { DELETED, EmptyObject, WebUtils, utils } from '../../common/utils';
import { SiteFormatMixin } from '../site.format.mixin';
import { BookingCalendarDayAddDialogComponent } from './booking.calendar.day.add.dialog';
import { BookingCalendarDayComponent } from './booking.calendar.day.component';
import { BookingCalendarDayEditDialogComponent } from './booking.calendar.day.edit.dialog';
import {
  BookingCalendarEntities,
  TBookingAdjustment,
  TBookingCalendar,
  TBookingCalendarDate,
  TBookingScheduleDate,
} from './booking.calendar.entities';
import { BookingCalendarPeriodEditDialogComponent } from './booking.calendar.period.edit.dialog';
import { BookingCalendarUtils } from './booking.calendar.utils';

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//
// key, subkey, key is primary layer, subkey is additional layer on top
//
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DateData {
  date: moment.Moment;
  calendar?: TBookingCalendar;

  calendarSchedule?: DaySchedule;
  day?: TBookingCalendarDate;
  daySchedule?: DaySchedule;
  oday?: TBookingScheduleDate;
  odaySchedule?: DaySchedule;
  adjustment?: TBookingAdjustment;
}

@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule.calendar.component.html',
  standalone: true,
  imports: [MonthCalendarComponent, CommonModule, BookingCalendarDayComponent],
})
export class ScheduleCalendarComponent
  extends SiteFormatMixin(EmptyObject)
  implements MonthCalendarLoader
{
  @Input()
  key: { [key: string]: { id: number; name: string } } = {};

  @Input()
  subkey: { [key: string]: { id: number; name: string } } = null;

  form: UntypedFormGroup;

  @HostBinding('class')
  css = 'max-height';

  @ViewChild('calendar', { static: true }) calendar: MonthCalendarComponent;

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public elRef: ElementRef,

    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar
  ) {
    super();
    this.form = BookingCalendarUtils.createDay(fb);
  }

  _common = _common;

  itemFilter = 'all';
  //   search = '';
  //   searchSubject = new Subject<string>();

  loader = this;

  // @Input()
  // event: TEvent;

  @Output()
  updated = new EventEmitter<void>();

  ngOnInit() {
    // this.searchSubject.pipe(debounce(() => timer(500))).subscribe((value) => {
    //   this.search = value;
    //   this.refresh();
    // });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['key']) {
      this.selected = null;
      this.refresh();
    }
  }

  refresh() {
    this.calendar.loader = this;
    this.calendar.refresh();
  }
  //   applyFilter(s: string) {
  //     s = s.toLowerCase();
  //     this.searchSubject.next(s);
  //   }
  //   clearFilter() {
  //     this.applyFilter('');
  //   }

  //   addSchedules(list: TEventSchedule[], comp: MonthCalendarComponent) {
  //     for (let o of list) {
  //       this.addSchedule(o, comp);
  //     }
  //   }
  //   addSchedule(o: TEventSchedule, comp: MonthCalendarComponent) {
  //     // let text = `${o.event.name}\n`;
  //     // if (o.name && o.name.length) text += `${o.name}\n`;
  //     // o.slots.forEach((s) => {
  //     //   text += `${s._timeText}\n`;
  //     // });
  //     // o.slots.forEach((s) => {
  //     //   comp.addItem(s.date, {
  //     //     date: s.date,
  //     //     title: text,
  //     //     notes: text,
  //     //     data: o,
  //     //   });
  //     // });
  //   }

  selectedItem: CalendarItem;
  selected: DateData;
  selectedCalendar: TBookingCalendar;

  dateAction(event: { item: MonthCalendarItem; action: string }) {
    let c: DateData;
    let ci = event.item.items[0];
    this.onEdit(ci);
    if (event.item && event.item.items) {
      c = event.item.items.find((cc) => {
        let c = cc.data as DateData;
        if (this.subkey) return c.oday;
        else return c.day;
      });
    }
    if (c) {
      // this.editSchedule(c);
    } else {
      let ref = this.dialog.open(BookingCalendarDayAddDialogComponent, {
        data: {
          date: event.item.date,
          key: this.key,
          subkey: this.subkey,
        },
      });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          let cc = {} as any;
          if (!this.subkey) cc.day = result;
          else cc.oday = result;

          this.selected = this.selected ??  {
            date: event.item.date,
          };
          this.afterSave(cc);
        }
      });
    }
  }
  dateClicked(item: MonthCalendarItem) {
    let d = item.date;
    if (d.isBefore(this._start) || d.isSameOrAfter(this._end)) return;
    this.selectedCalendar = null;
    this.selectedItem = null;
    if (item.items.length) {
      let ci = item.items[0];
      this.onEdit(ci);
    } else {
      this.selectedCalendar = this.calendars.find((c) => {
        if (d.isBefore(c.startDate)) return false;
        if (!c.endDate) return true;
        return c.endDate.isSameOrAfter(d);
      });
      this.closeDay(d);
    }
  }
  closeDay(date: moment.Moment) {
    this.selectedItem = null;
    this.selected = {
      date: date,
    };
    utils.fill_form_values(
      {
        open: false,
      },
      this.form
    );
    // if (this.calendarDay) this.calendarDay.updateForm();
    this.active = moment().isBefore(date);
    if (this.active) this.form.enable();
    else this.form.disable();
  }

  editSchedule(data: DateData) {
    if (!this.subkey) {
      let ref = this.dialog.open(BookingCalendarDayEditDialogComponent, {
        data: {
          day: data.day,
          schedule: data.daySchedule,
        },
      });
      ref.afterClosed().subscribe((result) => {
        if (result == DELETED) {
          this.afterRemove({ day: data.day });
        } else if (result) {
          this.afterSave({ day: result });
        }
      });
    } else {
      let ref = this.dialog.open(BookingCalendarDayEditDialogComponent, {
        data: {
          day: data.oday,
          schedule: data.odaySchedule,
        },
      });
      ref.afterClosed().subscribe((result) => {
        if (result == DELETED) {
          this.afterRemove({ oday: data.oday });
        } else if (result) {
          this.afterSave({ oday: result });
        }
      });
    }
  }
  //   @ViewChild('calendarDay') calendarDay: BookingCalendarDayComponent;
  onEdit(item: CalendarItem) {
    if(!item) {
      this.selectedItem = null;
      this.selected= null;
      this.selectedCalendar = null;

      return;
    }
    let data = item.data as DateData;
    // if (data.adjustment) {
    //   let ref = this.dialog.open(BookingAdjustmentEditDialogComponent, {
    //     data: {
    //       objectId: data.adjustment.id,
    //     },
    //   });
    //   ref.afterClosed().subscribe((result) => {
    //     if (result) this.refresh();
    //   });
    //   return;
    // }
    this.selectedItem = item;
    this.selected = data;
    this.selectedCalendar = data.calendar;
    let schedule = data.calendarSchedule;
    if (data.oday) {
      this.editSchedule(data);
      return;
    } else if (data.day) {
      if (!this.subkey) this.editSchedule(data);
      return;
    }
    if (data.day) schedule = data.daySchedule;
    if (data.oday) schedule = data.odaySchedule;
    let text = '';
    if (schedule) text = schedule.toEditText();
    let open = text != null && text != '';
    utils.fill_form_values(
      {
        open,
        time: text,
      },
      this.form
    );
    // this.calendarDay?.updateForm();
  }
  active = true;

  calendars: TBookingCalendar[] = [];
  dayColor = '#ffff00';
  dayCloseColor = '#cccc00';
  colors = ['#88ee88'];
  closeColors = ['#66cc66'];
  _start: moment.Moment;
  _end: moment.Moment;
  loadData(
    comp: MonthCalendarComponent,
    start: moment.Moment,
    end: moment.Moment
  ) {
    this._start = start.clone();
    this._end = end.clone();
    let query = {
      key: this.key,
      subkey: this.subkey,
      start: start,
      end: end,
    };
    if (Object.keys(this.key).length > 0)
      WebUtils.web_result(
        this.http.post(`/api/schedule-calendar/calendar`, query),
        (result) => {
          let cache: { [key: string]: DateData } = {};
          let periods = result.periods as TBookingCalendar[];
          let dates = result.dates as TBookingCalendarDate[];
          let adjustments = result.adjustments as TBookingAdjustment[];
          let odates = result.odates as TBookingScheduleDate[];
          this.calendars = periods;
          for (let c of periods) {
            // fix calendar use local time, while her we need UTC
            let startDate = date_utils.toMoment(c.startDate);
            let endDate = date_utils.toMoment(c.endDate);
            BookingCalendarEntities.fixSchedulePeriod(c);
            startDate = c.startDate;
            endDate = c.endDate;
            let _start = start;
            let _end = end.clone().add(-1, 'day');
            if (startDate.isAfter(_start)) {
              _start = startDate;
            }
            if (c.endDate && endDate.isBefore(_end)) {
              _end = endDate;
            }
            let d = _start.clone() as moment.Moment;
            while (d.isSameOrBefore(_end)) {
              let dd = d.clone();
              d = d.add(1, 'day');
              let w = dd.weekday();
              let schedule: DaySchedule;

              //TODO check holiday
              switch (w) {
                case 0:
                  schedule = c.sun;
                  break;
                case 1:
                  schedule = c.mon;
                  break;
                case 2:
                  schedule = c.tue;
                  break;
                case 3:
                  schedule = c.wed;
                  break;
                case 4:
                  schedule = c.thu;
                  break;
                case 5:
                  schedule = c.fri;
                  break;
                case 6:
                  schedule = c.sat;
                  break;
              }
              cache[dd.format('YYYY-MM-DD')] = {
                date: dd.clone(),
                calendar: c,
                calendarSchedule: schedule,
              };
            }
          }
          for (let d of dates) {
            BookingCalendarEntities.fixCalendarDate(d);
            let key = d.date.format('YYYY-MM-DD');
            let data = cache[key];
            if (!data) {
              data = {
                date: d.date.clone(),
              };
              cache[key] = data;
            }
            data.day = d;
            data.daySchedule = d.schedule;
          }
          for (let d of odates) {
            BookingCalendarEntities.fixCalendarDate(d);
            let key = d.date.format('YYYY-MM-DD');
            let data = cache[key];
            if (!data) {
              data = {
                date: d.date.clone(),
              };
              cache[key] = data;
            }
            data.oday = d;
            data.odaySchedule = d.schedule;
          }
          for (let id in cache) {
            let data = cache[id];
            let dd = data.date;
            let schedule = data.calendarSchedule;
            if (data.day) schedule = data.daySchedule;
            if (data.oday) {
              schedule = data.odaySchedule;
            }
            if (
              (schedule || data.day) &&
              // Schedule date is with in the selected month
              dd.isBetween(start, end, 'day', '[]')
            ) {
              let item = {
                date: dd,
              } as CalendarItem;
              this.updateItem(data, item);
              comp.addItem(dd.clone(), item);
            }
          }
          // adjustment
          //   for (let o of adjustments) {
          //     AccessUtils.fixAdjustment(o);
          //     let _start = start;
          //     let _end = end.clone().add(-1, 'day');
          //     if (o.startDate.isAfter(_start)) {
          //       _start = o.startDate;
          //     }
          //     if (o.endDate && o.endDate.isBefore(_end)) {
          //       _end = o.endDate;
          //     }
          //     let d = _start.clone();
          //     while (!d.isAfter(_end)) {
          //       var r = BookingManager.checkAdjustmentDate(o, d);
          //       if (!r || r.isEmpty()) {
          //         d = d.add(1, 'day');
          //         continue;
          //       }
          //       comp.addItem(d.clone(), {
          //         date: d.clone(),
          //         title: `${o.time.toText()} Block ${o.qty}`,
          //         notes: `Block ${o.qty}`,
          //         data: { adjustment: o },
          //       });
          //       d = d.add(1, 'day');
          //     }
          //   }
        }
      );
  }
  updateItem(data: DateData, item: CalendarItem) {
    let schedule = data.calendarSchedule;
    if (data.day) schedule = data.daySchedule;
    if (data.oday) schedule = data.odaySchedule;
    let color = this.dayColor;
    if (this.subkey) {
      if (!data.odaySchedule) {
        color = this.colors[0];
        // color = this.colors[data.calendar.id % this.colors.length];
      }
    } else {
      if (!data.daySchedule && data.calendar) {
        color = this.colors[data.calendar.id % this.colors.length];
      }
    }
    if (schedule) {
      let items = schedule.slots.map((c) => c.toText());
      item.title = items.length ? items[0] : '';
      item.titles = items.length ? items.slice(1) : null;
      item.color = color;
    } else {
      item.title = 'Closed';
      if (!this.subkey) item.color = '#ccc';
      else {
        if (!data.oday) {
          item.color = this.closeColors[0];
        } else item.color = this.dayCloseColor;
      }
    }
    item.notes = item.title;
    item.data = data;
  }

  //sepcial day
  //   removeSchedule(oo?: boolean) {
  //     if (!this.selected) return;
  //     let o = this.selected.day;
  //     let url = `/api/ecommerce/booking-calendar/date/`;
  //     if (oo) {
  //       o = this.selected.oday;
  //       url = '/api/ecommerce/booking_schedule_date/';
  //     }
  //     if (!o) return;
  //     WebUtils.web_result(this.http.delete(`${url}${o.id}`), (result) => {
  //       if (!oo) {
  //         this.selected.daySchedule = null;
  //         this.selected.day = null;
  //       } else {
  //         this.selected.odaySchedule = null;
  //         this.selected.oday = null;
  //       }
  //       if (
  //         this.selected.calendarSchedule ||
  //         this.selected.daySchedule ||
  //         this.selected.odaySchedule
  //       ) {
  //         this.updateItem(this.selected, this.selectedItem);
  //         this.onEdit(this.selectedItem);
  //       } else {
  //         this.calendar.removeItem(this.selectedItem);
  //         this.closeDay(this.selected.date);
  //       }
  //       // this.selectedItem.title=
  //       // this.refresh();
  //     });
  //   }
  //   saveSchedule(oo?: boolean) {
  //     if (!this.form.valid)
  //       return utils.validateAllFormFields(this.form, {
  //         snackBar: this.snackBar,
  //       });
  //     let body = Object.assign({}, this.form.value);
  //     body.date = date_utils.toQuery(this.selected.date);
  //     let key = {};
  //     for (let id in this.key) {
  //       key[id] = WebUtils.objName(this.key[id]);
  //     }
  //     if (this.subkey) {
  //       for (let id in this.subkey) {
  //         key[id] = WebUtils.objName(this.subkey[id]);
  //       }
  //     }
  //     let url = '/api/ecommerce/booking-calendar/date/';
  //     if (oo) {
  //       url = '/api/ecommerce/booking_schedule_date/';
  //     }
  //     body.schedule = BookingCalendarPeriodEditDialogComponent.saveDay(body);
  //     WebUtils.web_result(
  //       this.http.post(url, {
  //         key,
  //         body,
  //       }),
  //       (result) => {
  //         AccessUtils.fixCalendarDate(result);
  //         if (!oo) {
  //           this.selected.day = result;
  //           this.selected.daySchedule = result.schedule;
  //         } else {
  //           this.selected.oday = result;
  //           this.selected.odaySchedule = result.schedule;
  //         }
  //         if (this.selectedItem) {
  //           this.updateItem(this.selected, this.selectedItem);
  //           this.onEdit(this.selectedItem);
  //         } else {
  //           let dd = this.selected.date.clone();
  //           let item = {
  //             date: dd,
  //           } as CalendarItem;
  //           this.updateItem(this.selected, item);
  //           this.calendar.addItem(this.selected.date.clone(), item);
  //           this.onEdit(item);
  //           //add
  //         }

  //       }
  //     );
  //   }

  afterSave(o: { day?: TBookingCalendarDate; oday?: TBookingScheduleDate }) {
    
    if (o.day) {
      this.selected.day = o.day;
      this.selected.daySchedule = o.day.schedule;
    } else {
      this.selected.oday = o.oday;
      this.selected.odaySchedule = o.oday.schedule;
    }
    if (this.selectedItem) {
      this.updateItem(this.selected, this.selectedItem);
      //this.onEdit(this.selectedItem);
    } else {
      let dd = this.selected.date.clone();
      let item = {
        date: dd,
      } as CalendarItem;
      this.updateItem(this.selected, item);
      this.calendar.addItem(this.selected.date.clone(), item);
      //this.onEdit(item);
      //add
    }
    this.updated.next(null);
  }

  afterRemove(o: { day?: TBookingCalendarDate; oday?: TBookingScheduleDate }) {
    if (o.day) {
      this.selected.daySchedule = null;
      this.selected.day = null;
    } else {
      this.selected.odaySchedule = null;
      this.selected.oday = null;
    }
    if (
      this.selected.calendarSchedule ||
      this.selected.daySchedule ||
      this.selected.odaySchedule
    ) {
      this.updateItem(this.selected, this.selectedItem);
      // this.onEdit(this.selectedItem);
    } else {
      this.calendar.removeItem(this.selectedItem);
      // this.closeDay(this.selected.date);
    }
  }

  openDefault() {
    let ref = this.dialog.open(BookingCalendarPeriodEditDialogComponent, {
      data: {
        key: this.key,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.selected = null;
        this.selectedItem = null;
        this.refresh();
      }
    });
  }
}
