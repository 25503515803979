<div class="cdialog">
  <div class="dialogHeader">
    <h2 mat-dialog-title>{{title}}</h2>
  </div>
  <mat-dialog-content>
    <div class="dialogBody">
      {{prompt}}
      <input type="file" #fileNode />
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn" (click)="upload()" color="primary">
      {{_common?.upload ?? 'Upload'}}
    </button>
    <button class="app-btn btn-text" (click)="cancel()">
      {{_common?.cancel ?? 'Cancel'}}
    </button>
  </div>
</div>
