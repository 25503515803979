<div class="client_pageBody" style="max-width: 500px">
  <div *ngIf="object">
    <!--<div class="text-center mb-4">
      <div class="font-bold text-2xl">已完成預約</div>
      <div>請在簽到時出示會員</div>
    </div>-->

    <div class="card">
      <div class="cardBody">
        <div class="flex mb-2">
          <div class="flex-auto">
            <div class="app-chip Status" [ngClass]="object.status">
              {{ _common.bookingStatus[object.status] }}
            </div>
          </div>

          <div *ngIf="object.passcode">
            {{_common.passcode}}:
            <span class="font-bold text-xl">{{object.passcode}}</span>
          </div>
        </div>

        <div class="flex">
          <div class="spacer font-bold">
            {{formatLongDate(session.lang, object.startTime)}}
          </div>
          <div class="text-link link-blue" (click)="googleCalendar()">
            {{_common.AddToCalendar}}
          </div>
        </div>
        <div class="mb-2">
          {{object.startTime.format('hh:mmA')}}-
          {{object.endTime.format('hh:mmA')}}
          ({{formatMins(object.durationMins,_common)}})
        </div>
        <ng-container *ngIf="object.space; let o">
          <app-space-card
            [space]="$any(object.space)"
            class="space_card_noBorder"
          ></app-space-card>
        </ng-container>
      </div>
      <div class="cardDivider"></div>
      <div class="cardBody">
        <div class="font-bold text-lg mb-2">{{_common.CenterInfo}}</div>
        <div
          *ngIf="$any(object.space?.location); let location"
          class="item_info"
        >
          <div class="item_info">
            <div class="line_item">
              <fa-icon icon="phone-flip" class="icon"></fa-icon>
              {{location.tel}}
            </div>
            <div class="line_item">
              <fa-icon icon="map-location-dot" class="icon"></fa-icon>

              {{ lang(location.address,location.address_lang) }}
            </div>
            <div class="line_item">
              <fa-icon icon="clock" class="icon"></fa-icon>
              <div>
                <div *ngFor="let l of openingHours">{{l}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="cardHeader">{{_common.Remarks}}</div>
      <div class="cardBody space-y-4">
        <ng-container *ngIf="!object.remarks"> N/A </ng-container>

        {{object.remarks}}
      </div>
    </div>

    <div class="card">
      <div class="cardHeader">{{_common.SpaceFacilities}}</div>
      <div class="cardBody space-y-4">
        <div>
          <div class="underline">{{_common.FacilitiesIncluded}}</div>
          <ng-container *ngIf="!object.space.includedFacilities">
            N/A
          </ng-container>
          <div>
            {{lang($any(object.space)?.includedFacilities,$any(object.space)?.includedFacilities_lang)}}
          </div>
        </div>
        <div>
          <div class="underline">
            {{_common.AdditionalFacilitiesAvailableForRent}}
          </div>
          <ng-container *ngIf="!object.facilities.length"> N/A </ng-container>
          <div *ngFor="let f of object.facilities">
            {{lang(f.name, f.name_lang)}}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-8">
      <div class="flex flex-col gap-4" style="width: fit-content;">
        <div [matMenuTriggerFor]="invite_frd" class="client_button"
          style="width: 100%;">
          {{_common.invite_frd}}
        </div>
  
        <div (click)="bookAgain()" class="client_button btn_outline">
          {{_common.RebookSameSpace}}
        </div>
  
      </div>
    </div>

    <mat-menu #invite_frd="matMenu" class="user-dropDown">
      <div (click)="sharing.copy(object)">
        <fa-icon icon="copy" style="margin-right: 5px; color: #707070;"></fa-icon>
        {{_common.copy_invite_msg}}
      </div>
      <div (click)="sharing.share(object)">
        <!-- <fa-icon icon="whatsapp" class="ml-1"></fa-icon> -->
        {{_common.share_whatsapp}}
      </div>
    </mat-menu>

  </div>
</div>
