/** @format */

import { formatDate } from '@angular/common';
import {
  ErrorMessages,
  LengthError,
  MaxError,
  MinError,
} from '../error-messages';

export function errorMessagesZhHKFactory(
  // locale: string,
  format = 'shortDate',
): ErrorMessages {
  return {
    min: (error: MinError) => `請輸入一個大於或等於 ${error.min} 的值。`,
    max: (error: MaxError) => `請輸入小於或等於 ${error.max} 的值。`,
    required: `此欄必須填寫`,
    email: `請輸入有效的電郵地址`,
    mobile: `電話號碼無效`,
    url: `Invalid url`,
    password: (error) => {
      var s = `密碼格式錯誤。`;
      if (error.length) {
        s += `字元長度: ${error.length}`;
      }
      if (error.pattern.indexOf('a') >= 0) {
        s += `1英文字母`;
      }
      if (error.pattern.indexOf('A') >= 0) {
        s += `1大寫英文字母`;
      }
      if (error.pattern.indexOf('1') >= 0) {
        s += `1數字`;
      }
      if (error.pattern.indexOf('@') >= 0) {
        s += `1符號`;
      }
      return s;
    },
    passwordNotMatched: (error) => '密碼不乎',
    minlength: (error: LengthError) =>
      `請輸入至少 ${error.requiredLength} 個字符。`,
    maxlength: (error: LengthError) =>
      `請輸入不超過 ${error.requiredLength} 個字符。`,
    matDatepickerMin: (error: MinError) => {
      const formatted = formatDate(error.min, format, 'en');
      return `請輸入 ${formatted ?? error.min}或之後的日期。`;
    },
    matDatepickerMax: (error: MaxError) => {
      const formatted = formatDate(error.max, format, 'en');
      return `請輸入 ${formatted ?? error.max}或之前的日期。`;
    },
  };
}
