import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Injectable, NgModule, makeEnvironmentProviders } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MAT_TABS_CONFIG, MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CalendarComponent } from "./components/date.selector/calendar.component";
import { DateSelectorComponent } from "./components/date.selector/date.selector.component";
import { DateSelectorDialogComponent } from "./components/date.selector/date.selector.dialog";
import { DateRangeComponent } from "./components/date.selector/daterange.component";
import { DateSubRangeComponent } from "./components/date.selector/datesubrange.component";
import { ThreeCalendarComponent } from "./components/date.selector/three.calendar.component";
import { VizFormModule } from "./components/form/form.module";
import { VizImageViewerModule } from "./components/image.viewer/image.viewer.module";
import { ListSelectorComponent } from "./components/list.selector/list.selector.component";
import { MonthSelectorComponent } from "./components/month.selector/month.selector.component";
import { VizScrollContainerModule } from "./components/scroll.container/scroll.container.module";
import { ObjectsSelectEditComponent } from "./components/tags.editor/objects.select.edit.component";
import { TagsEditComponent } from "./components/tags.editor/tags.edit.component";
import { TagsSelectEditComponent } from "./components/tags.editor/tags.select.edit.component";
import { AppTooltipComponent } from "./components/tooltip/tooltip.component";
import { ToolTipRendererDirective } from "./components/tooltip/tooltip.directive";
import { CTableDisplayComponent } from "./data/ctable/ctable.component";
import { AlertDialogComponent } from "./dialogs/alert.dialog";
import { ConfirmDialogComponent } from "./dialogs/confirm.dialog";
import { PasswordDialogComponent } from "./dialogs/password.dialog";
import { UploadDialogComponent } from "./dialogs/upload.file.dialog";
import { ClickOutsideDirective } from "./directives/click.outside.directive";
import { ClickOutsidePopupDirective } from "./directives/click.outside.popup.directive";
import { CustomListFilterDialogComponent } from "./filter/custom.list.filter.dialog";
import { VizFilterModule } from "./filter/filter.module";
import { TimeoutInterceptor } from "./interceptors/timeout.interceptor";
import { LoaderInterceptor } from "./loader/loader.interceptor";
import { LoaderModule } from "./loader/loader.module";

// export const NGX_MAT_ERROR_DEFAULT_CONFIG: FactoryProvider = {
//     useFactory: () => {
//         return {
//             en: {
//                 ...errorMessagesEnFactory()
//             },
//             zh_hk: {
//                 ...errorMessagesZhHKFactory(),
//             }
//             // min: (error: MinError) =>
//             //     `Min value is ${error.min}, actual is ${error.actual}`,
//         };
//     },
//     provide: NGX_MAT_ERROR_DEFAULT_OPTIONS,
// };

declare let appConfig: any;
@Injectable({
  providedIn: "root",
})
export class AppMomentDateAdapter extends MomentDateAdapter {
  override getFirstDayOfWeek() {
    if (appConfig.dateOptions.firstDayOfWeek === "Mon") return 1;
    else return 0;
  }
}

@NgModule({
  exports: [
    AppTooltipComponent,
    ToolTipRendererDirective,
    ListSelectorComponent,
    ClickOutsideDirective,
    ClickOutsidePopupDirective,
    ConfirmDialogComponent,
    AlertDialogComponent,
    PasswordDialogComponent,
    TagsEditComponent,
    TagsSelectEditComponent,
    ObjectsSelectEditComponent,
    DateSelectorDialogComponent,
    DateSelectorComponent,
    CalendarComponent,
    ThreeCalendarComponent,
    MonthSelectorComponent,
    DateRangeComponent,
    DateSubRangeComponent,
    MonthSelectorComponent,
    CTableDisplayComponent,
    // FilterTabsComponent,
    // FilterSaveComponent,
    // FilterDateRangeComponent,
    // FilterDateFilterComponent,
    // FilterMultiComponent,
    // FilterMultiFilterComponent,
    // FilterMultiChipComponent,
    // FilterSingleComponent,
    // FilterTextComponent,
    // FilterTextFilterComponent,
    // FilterRadioComponent,
    // FilterBoolComponent,
    // FilterBoolChipComponent,
    // FilterBoolFilterComponent,
    // FilterDateRangButtonComponent,
    // FilterSelectObjectComponent,
    // FilterSelectObjectFilterComponent,
    // FilterSelectObjectsComponent,
    // FilterSelectObjectsFilterComponent,
    // AppToggleComponent,
    // TranslatePipe,
    // AppErrorDef,
    // AppErrors,
    // AppFormField,
    // AppFormLabel,
    // AppLabel,
    // AppFieldAddon,
    // AppHint,
    // AppHelp,
    // AppSuffix,
    // AppPrefix,
    // AppError,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // awesome font
    FontAwesomeModule,
    // Material
    MatToolbarModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSliderModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    DragDropModule,
    LoaderModule,
    VizScrollContainerModule,
    VizImageViewerModule,
    VizFormModule,
    VizFilterModule,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // awesome font
    FontAwesomeModule,
    // Material
    MatToolbarModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    MatSliderModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    DragDropModule,
    VizScrollContainerModule,
    VizImageViewerModule,
    VizFormModule,
    VizFilterModule,
    LoaderModule,
    ClickOutsidePopupDirective,
    ClickOutsideDirective,

    AppTooltipComponent,
    ToolTipRendererDirective,
    ListSelectorComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    PasswordDialogComponent,
    UploadDialogComponent,
    TagsEditComponent,
    TagsSelectEditComponent,
    ObjectsSelectEditComponent,
    DateSelectorDialogComponent,
    DateSelectorComponent,
    CalendarComponent,
    ThreeCalendarComponent,
    MonthSelectorComponent,
    DateRangeComponent,
    DateSubRangeComponent,
    CTableDisplayComponent,
    CustomListFilterDialogComponent,
  ],
  providers: [
    // TooltipService,
    // LoaderService,
    // { provide: MAT_DATE_LOCALE, useValue: 'en-us' },
    {
      provide: DateAdapter,
      useClass: AppMomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    // ListFilterCache,
    // NGX_MAT_ERROR_DEFAULT_CONFIG,
    // LocaleService,
  ],
  bootstrap: [],
})
export class VizCommonModule {}

export function provideTimeout() {
  return makeEnvironmentProviders([
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  ]);
}
export function provideMaterialDefault() {
  return makeEnvironmentProviders([
    {
      provide: DateAdapter,
      useClass: AppMomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: {
        stretchTabs: false,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
  ]);
}
