/** @format */

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { VizFormModule } from '../components/form/form.module';
import { FilterMulti } from './list.filter';

@Component({
  selector: 'app-filter-multi',
  templateUrl: './filter.multi.component.html',
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatCheckboxModule, VizFormModule],
})
export class FilterMultiComponent implements OnInit {
  _common = _common;

  @HostBinding('class')
  cssClass = '';

  @Input()
  filter = new FilterMulti();
  @Input()
  values: any[];
  @Input()
  names: string[];

  @Input()
  visible = true;
  @Input()
  set objectValues(c: any[]) {
    this.values = c.map((c) => c.id?.toString());
    this.names = c.map((c) => c.name);
  }

  @Input()
  set common(c: any) {
    this.names = this.values.map((v) => c[v] || v);
  }

  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['values']) {
      if (!this.names) this.names = this.values;
    }
    this.cssClass = this.visible && this.filter.visible ? '' : 'hide';
    this.filter._update();
  }

  refresh() {
    this.change.next(void 0);
  }
}
