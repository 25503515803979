import { date_utils } from '../common/date_utils';
import { DaySchedule, DaySlot } from '../common/timespan/day.schedule';
import { TimeAtom } from '../common/timespan/time_utils';
import {
  TAccessLog,
  TAgent,
  TBookingBase,
  TDevice,
  TLocationBase,
  TLockerBase,
  TLockerCellBase,
  TMemberBase,
  TSpaceBase,
  TUserBase,
} from '../thinkdesks/thinkdesks.entities';
export enum TUserType {
  'admin' = 'admin',
  'user' = 'user',
}
export class TUser extends TUserBase {
  userType: TUserType;
  organizations: TOrganization[];
  locale: string;
}

export class TOrganization {
  id: number;
  name: string;
  name_lang: any;
  numSpaces: number;
  numLocations: number;
  locations: TLocation[];
}

export class TAccount {
  id: number;
  name: string;
  gateCode: number;
}

export class TLocation extends TLocationBase {
  organization: TOrganization;
  terms: string;
  terms_lang: any;

  facilities: TSpaceFacility[];
}

export class TAgentContent {
  id: number;
  ext: string;
  active: boolean;
  filename: string;
}
export class TSpaceType {
  id: number;
  name: string;
  name_lang: any;
}
export class TSpacePurpose {
  id: number;
  name: string;
  name_lang: any;
}

export class TSpaceFacility {
  id: number;
  name: string;
  name_lang: any;
  images: string[];
}
export class TLockerCell extends TLockerCellBase {}

export class TLocker extends TLockerBase {}

export class TMemberWishlist {
  id: number;
  date: moment.Moment;
  space: TSpace;
}
export class TSpace extends TSpaceBase {
  types: TSpaceType[];
  facilities: TSpaceFacility[];
  terms: string;
  terms_lang: any;
}

export class TEvent {
  id: number;
  name: string;
  space: TSpace;
  startTime: moment.Moment;
  endTime: moment.Moment;
}

export class TMemberType {
  id: number;
  name: string;
  permissions: string[];
}
export class TMember extends TMemberBase {
  memberType: TMemberType;
  permissions: string[];
  locations: TLocation[];
}

export class TMemberGroup {
  id: number;
  name: string;
}

export class TCheckinLog {
  id: number;

  time: moment.Moment;
  member: TMember;
  booking: TBooking;
  deviceId: number;
  deviceName: string;
}
export class TBooking extends TBookingBase {
  facilities: TSpaceFacility[];
  purpose: TSpacePurpose;
  checkinLogs: TCheckinLog[];
  checkin: TCheckinLog;
}

export class TLockerBooking {
  id: number;
  user: TUser;
  locker: TLocker;
  startTime: moment.Moment;
  endTime: moment.Moment;

  schedule: DaySchedule;
}

export class AccessUtils {
  static fixMemberGroup(o: TMemberGroup) {}
  static fixMemberType(o: TMemberType) {
    o.permissions = o.permissions ?? [];
  }
  static fixMember(o: TMember) {}

  ////////////
  ////////////
  ////////////
  ////////////
  ////////////
  static fixLocation(o: TLocation) {
    o.name_lang = this.lang(o.name_lang);
    o.description_lang = this.lang(o.description_lang);
    o.address_lang = this.lang(o.address_lang);
    o.openingHours_lang = this.lang(o.openingHours_lang);
    o.contactPerson_lang = this.lang(o.contactPerson_lang);
    o.terms_lang = this.lang(o.terms_lang);

    o.images = o.images ?? [];
    if (o.spaces) {
      for (let s of o.spaces) this.fixSpace(s as any);
    }
    if (o.organization) {
      this.fixOrganization(o.organization);
    }
    o.facilities = o.facilities ?? [];
    for (let f of o.facilities) {
      this.fixSpaceFacility(f);
    }
  }
  static fixLocker(o: TLocker) {
    o.images = o.images ?? [];
  }
  static fixWishlist(o: TMemberWishlist) {
    o.date = date_utils.toMomentLocal(o.date);
    if (o.space) {
      this.fixSpace(o.space);
    }
  }
  static fixSpace(o: TSpace) {
    o.name_lang = this.lang(o.name_lang);
    o.description_lang = this.lang(o.description_lang);
    o.remarks_lang = this.lang(o.remarks_lang);
    o.textDescription_lang = this.lang(o.textDescription_lang);
    o.includedFacilities_lang = this.lang(o.includedFacilities_lang);
    o.terms_lang = this.lang(o.terms_lang);

    if (o.location) {
      this.fixLocation(o.location as any);
    }
    o.types = o.types ?? [];
    for (let t of o.types) {
      this.fixSpaceType(t);
    }
    o.facilities = o.facilities ?? [];
    for (let t of o.facilities) {
      this.fixSpaceFacility(t);
    }
    // if (typeof o.type == 'number') {
    //   o.type = cache.spaceTypes.find((c) => c.id == (o.type as any));
    // }
    // o.facilities = o.facilities ?? [];
    // for (let i = 1; i <= 10; i++) {
    //   let id = o[`facility${i}`];
    //   if (id) {
    //     let f = cache.spaceFacilities.find((c) => c.id == id);
    //     if (f) o.facilities.push(f);
    //   }
    // }
    o.images = o.images ?? [];
  }
  static lang(o: any) {
    if (!o) return {};
    if (typeof o == 'string') {
      return JSON.parse(o);
    }
    return o;
  }
  static fixAgentContent(o: TAgentContent) {}
  static fixSpaceType(o: TSpaceType) {
    o.name_lang = this.lang(o.name_lang);
  }
  static fixSpacePurpose(o: TSpacePurpose) {
    o.name_lang = this.lang(o.name_lang);
  }

  static fixSpaceFacility(o: TSpaceFacility) {
    o.name_lang = this.lang(o.name_lang);
  }
  static fixEvent(o: TEvent) {
    o.startTime = date_utils.toMomentLocal(o.startTime);
    o.endTime = date_utils.toMomentLocal(o.endTime);
  }
  static fixUser(o: TUser) {}
  static fixOrganization(o: TOrganization) {
    o.name_lang = this.lang(o.name_lang);
  }
  static fixBooking(o: TBooking) {
    if (o.checkin) {
      this.fixCheckinLog(o.checkin);
    }
    if (o.checkinLogs) {
      for (let oo of o.checkinLogs) {
        this.fixCheckinLog(oo);
      }
    }
    o.date = date_utils.toMomentLocal(o.date);
    o.startTime = date_utils.toMomentLocal(o.startTime);
    o.endTime = date_utils.toMomentLocal(o.endTime);
    o.durationMins = 0;
    if (o.space) {
      this.fixSpace(o.space as any);
    }
    if (o.locker) {
      this.fixLocker(o.space as any);
    }
    if (o.purpose) {
      this.fixSpacePurpose(o.purpose);
    }
    if (o.startTime && o.endTime) {
      o.durationMins = o.endTime.diff(o.startTime, 'minutes');
    }
    o.facilities = o.facilities ?? [];
    for (let f of o.facilities) {
      this.fixSpaceFacility(f);
    }

    if (o.startTime && o.endTime) {
      let start = TimeAtom.fromMoment(o.startTime);
      let end = TimeAtom.fromMoment(o.endTime);
      o.schedule = new DaySchedule();
      o.schedule.slots.push(new DaySlot({ start, end }));
    }
  }
  static fixDevice(o: TDevice) {}
  static fixAgent(o: TAgent) {}
  static fixAccessLog(o: TAccessLog) {
    o.time = date_utils.toMomentLocal(o.time);
  }
  static fixCheckinLog(o: TCheckinLog) {
    o.time = date_utils.toMomentLocal(o.time);
  }
}
