import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ViewEncapsulation } from '@angular/core';
import { AppHint, AppLabel } from './form.field';
import { NgIf } from '@angular/common';





@Component({
    selector: 'app-form-label',
    templateUrl: './form.label.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [],
    standalone: true,
    imports: [NgIf],
})
export class AppFormLabel {



   @ContentChild(AppLabel) _labelChildNonStatic: AppLabel | undefined;
   @ContentChild(AppHint) _hintChild: AppHint | undefined;

   constructor(
      private _changeDetectorRef: ChangeDetectorRef

   ) {

   }

   ngOnInit() {

   }
   ngAfterViewInit() {
   }


   ngAfterContentInit() {

   }


   ngOnDestroy() {
   }




}




