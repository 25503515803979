import { Component } from '@angular/core';
import { SessionService } from './session.service';

@Component({
  selector: 'app-notfound-page',
  standalone: true,
  imports: [],
  templateUrl: './notfound.page.html',
})
export class NotFoundPage {

  constructor(private session: SessionService) {}
  ngOnInit() {}
}
