export class AppPermissions {
  static ADMIN = 'admin';

  static LOCATION_MANAGE= 'loc_manage';
  static SPACE_MANAGE= 'space_manage';

  static LOCATION_VIEW= 'loc_view';
  static LOCATION_EDIT= 'loc_edit';
  static SPACE_ADD= 'space_add';
  static SPACE_VIEW= 'space_view';
  static SPACE_EDIT= 'space_edit';
  static SPACE_DELETE= 'space_delete';
  static BOOKING_VIEW = 'booking_view';
  static BOOKING_CANCEL = 'booking_cancel';
  static BOOKING_APPROVE = 'booking_approve';
  static BOOKING_EDIT = 'booking_edit';
  static LOCK_CONTROL = 'lock_control';
}
