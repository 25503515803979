import { NgClass, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../../common/list.component.base';
import { DELETED } from '../../common/utils';

import { AppPermissions } from '../../core/permissions';
import { SessionService } from '../../session.service';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';
import { Thinkdesks, TScheduleCalendar } from '../../thinkdesks/thinkdesks';
import { ScheduleCalendarDialogComponent } from './schedule.calendar.dialog';
import { ScheduleCalendarEditDialogComponent } from './schedule.calendar.edit.dialog';

class _Base extends ScrollableListComponentBase<TScheduleCalendar> {}

@Component({
  selector: 'app-schedule-calendar-list',
  templateUrl: './schedule.calendar.list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
})
export class ScheduleCalendarListComponent extends SiteFormatMixin(_Base) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();
  //   status = new FilterMulti();

  //   type = new FilterMulti();

  displayedColumns: string[] = ['name', 'type', 'controls'];

  @Input()
  query = {};

  permissions = AppPermissions;
  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    public session: SessionService,
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = Object.assign({}, this.query);
    Object.assign(query, this.filterObject.query());
    const url = this._listModifyUrl(`/api/schedule-calendar/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TScheduleCalendar[]) {
    for (let o of list) Thinkdesks.fixScheduleCalendar(o);
  }

  add() {
    const ref = this.dialog.open(ScheduleCalendarEditDialogComponent, {
      data: {
        query: this.query,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this._addToList(result);
      }
    });
  }

  override selectObject(o: TScheduleCalendar) {
    const dialogRef = this.dialog.open(ScheduleCalendarEditDialogComponent, {
      data: {
        objectId: o.id,
        query: this.query,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        this._removeFromList(o);
      } else if (result) {
        Object.assign(o, result);
      }
    });
  }
  calendar(o: TScheduleCalendar) {
    const dialogRef = this.dialog.open(ScheduleCalendarDialogComponent, {
      data: {
        // objectId: o.id,
        object: o,
      },
    });
  }
}
