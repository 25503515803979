import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../../common/list.component.base';
import { DELETED } from '../../common/utils';
import {
  AccessUtils,
  TLocation,
  TOrganization,
  TSpaceFacility,
} from '../../core/entities';
import { AppPermissions } from '../../core/permissions';
import { SessionService } from '../../session.service';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';
import { SpaceFacilityEditDialogComponent } from './space.facility.edit.dialog';

class _Base extends ScrollableListComponentBase<TSpaceFacility> {}

@Component({
  selector: 'app-space-facility-list',
  templateUrl: './space.facility.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
})
export class SpaceFacilityListComponent extends SiteFormatMixin(_Base) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  permissions = AppPermissions;
  filterObject = new FilterObject();
  //   status = new FilterMulti();

  //   type = new FilterMulti();

  displayedColumns: string[] = ['name', 'controls'];

  @Input()
  location: TLocation;
  @Input()
  organization: TOrganization;

  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    public session: SessionService
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {} as any;
    Object.assign(query, this.filterObject.query());
    query['location'] = this.location.id;

    const url = this._listModifyUrl(`/api/space-facility/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TSpaceFacility[]) {
    for (let o of list) AccessUtils.fixSpaceFacility(o);
  }

  add() {
    const ref = this.dialog.open(SpaceFacilityEditDialogComponent, {
      data: {
        location: this.location,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this._addToList(result);
      }
    });
  }

  override selectObject(o: TSpaceFacility) {
    const dialogRef = this.dialog.open(SpaceFacilityEditDialogComponent, {
      data: {
        objectId: o.id,
        location: this.location,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        this._removeFromList(o);
      } else if (result) {
        Object.assign(o, result);
      }
    });
  }
}
