/** @format */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NgFor } from '@angular/common';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NumberPair } from '../../utils';
import { VizFormModule } from '../form/form.module';

@Component({
  selector: 'app-objects-select-edit',
  templateUrl: './objects.select.edit.component.html',
  standalone: true,
  imports: [
    VizFormModule,
    NgFor,
    FontAwesomeModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
  ],
})
export class ObjectsSelectEditComponent implements OnInit {
  _common = _common;

  @Input()
  tags: NumberPair[] = [];

  @Input()
  values: NumberPair[] = [];

  @Input()
  title = _common.tags;

  @Output()
  updated = new EventEmitter<NumberPair[]>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('input') input: ElementRef;
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {}

  ngOnInit() {}

  removeTag(tag: NumberPair) {
    let index = this.tags.findIndex((c) => c.id == tag.id);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.updated.next(this.tags);
    }
  }
  // addTag(event: MatChipInputEvent) {
  //    const input = event.input;
  //    const value = event.value;
  //    if ((value || '').trim()) {
  //       this.tags.push(value.trim());

  //       this.updated.next(this.tags);
  //    }

  //    // Reset the input value
  //    if (input)
  //       input.value = '';

  // }
  selected(event: MatAutocompleteSelectedEvent) {
    let value = event.option.value as NumberPair;
    if (!this.tags.find((c) => c.id == value.id)) {
      this.tags.push(value);
      this.updated.next(this.tags);
    }
    this.input.nativeElement.value = '';
  }
}
