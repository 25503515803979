
declare var appConfig: any;

export namespace AppConfig {
   export let appConfig: AppConfig.AppConfigObject;

   export class DateOption {
      firstDayOfWeek: string = 'Mon';
      offsetDayOfWeek: number = 1;
   }
   export class AppConfigObject {
      locale: string;
      _locale: string;
      _language: string;
      dateOptions: DateOption;
   }
   export function initAppConfig() {
      AppConfig.appConfig = (window as any).appConfig;
      if (!appConfig)
         appConfig = new AppConfigObject();
      if (!appConfig.dateOptions)
         appConfig.dateOptions = new DateOption();

      (window as any).appConfig = AppConfig.appConfig;
   }





}


