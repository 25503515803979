import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../session.service';
import { QRCodeWidget } from './qrcode.widget';
@Component({
  selector: 'app-qrcode-page',
  standalone: true,
  imports: [CommonModule, QRCodeWidget],
  templateUrl: './qrcode.page.html',
})
export class QRCodePage {
  _common = _common;
  @HostBinding('class') css = 'max-scroll';
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) {}
}
