import { Directive, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { LImageUploadContainer } from './upload.container';

@Directive()
export class ImageUploadBase {
  imageContainer = new LImageUploadContainer();

  // @ViewChild('uploadProgress', { static: true }) uploadProgress: FirebaseUploadComponent;

  uploader: FileUploader;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;
  hasBaseDropZoneOver: boolean;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  constructor(public snackBar: MatSnackBar) {}

  uploadImage() {
    let o = this.fileUploadInput.nativeElement as HTMLInputElement;
    o.click();
  }
  maxFileSize = 45;
  ngOnInit() {
    this.uploader = new FileUploader({
      url: 'test',
      // allowedMimeType: ['image/png', 'image/jpg', 'image/jpeg'],
      maxFileSize: this.maxFileSize * 1024 * 1024,
      allowedFileType: ['image'],
    });
    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject) => {
      //this.snackBar.open(`${item.name} is above ${this.maxFileSize}M`);
      this.snackBar.open(`File size cannot be over ${this.maxFileSize}MB`);
    };

    this.uploader.onAfterAddingFile = (item: FileItem) => {
      if (
        item._file.type.startsWith('image') ||
        item._file.type.startsWith('video')
      ) {
        this.imageContainer.addImage(item);
      } else {
        this.imageContainer.addFile(item);
      }
    };
  }
  static getFile(
    formData: FormData,
    formName: string,
    container: LImageUploadContainer
  ) {
    let list = container.images.filter((o) => !o._removed);
    container.moldImagePaths();
    list
      .filter((c) => c._file)
      .forEach((c) => {
        formData.append(formName, c._file, c.name);
      });
    return list.map((c) => c.name);
  }
  // async removeImages(pather: (o: LImageUploadItem) => string) {
  //    var storage = firebase.storage().ref();
  //    var list: Promise<any>[] = [];
  //    var tasks: firebase.storage.UploadTask[] = [];

  //    for (let i of this.imageContainer.images) {
  //       if (!i._file) {
  //          let path = pather(i);
  //          list.push(storage.child(path).delete());
  //       }
  //    }

  //    await this.uploadProgress.execute(tasks, list);
  // }
  // async uploadImages(pather: (o: LImageUploadItem) => string) {
  //    var storage = firebase.storage().ref();
  //    var list: Promise<any>[] = [];
  //    var tasks: firebase.storage.UploadTask[] = [];
  //    for (let i of this.imageContainer.images) {
  //       if (i._file) {
  //          let path = pather(i);
  //          PathUtils.putFirebaseTask(tasks, path, i._blob || i._file);
  //       } else if (i._removed) {
  //          let path = pather(i);
  //          list.push(storage.child(path).delete());
  //       }
  //    }

  //    await this.uploadProgress.execute(tasks, list);
  // }
}
