import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule],
  styles: [
    `
      .carousel-container {
        position: relative;
        .carousel-button {
          position: absolute;
          top: calc(50% - 20px);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 1px solid black;
          color: black;
          line-height: 18px;
          font-size: 18px;
          cursor: pointer;
        }
      }
      .carousel {
        overflow: hidden;
      }
    `,
  ],
  templateUrl: './carousel.html',
})
export class CarouselWidget {
  constructor(private cdRef: ChangeDetectorRef) {}
  observer: ResizeObserver;
  ngOnInit() {
    this.observer = new ResizeObserver((list) => {
      this.updateCurrentIndex();
    });
  }
  ngAfterViewInit() {
    this.observer.observe(this.carousel.nativeElement);
    this.updateCurrentIndex();
  }
  ngOnDestroy() {
    this.observer.disconnect();
  }

  @ViewChild('carousel') carousel: ElementRef<HTMLDivElement>;
  next() {
    let cl = this.carousel.nativeElement;
    cl.scrollBy({
      left: cl.clientWidth,
      top: 0,
      behavior: 'smooth',
    });
  }
  prev() {
    let cl = this.carousel.nativeElement;
    cl.scrollBy({
      left: -cl.clientWidth,
      top: 0,
      behavior: 'smooth',
    });
  }

  atStart = true;
  atEnd = false;
  currentIndex = 0;

  dots: any[] = [];
  refreshDots() {
    let cl = this.carousel.nativeElement as HTMLElement;

    this.dots = [];
    length = Math.ceil(cl.scrollWidth / cl.clientWidth);
    for (let i = 0; i < length; i++) {
      this.dots.push({
        id: i,
      });
    }
  }
  updateCurrentIndex() {
    this.refreshDots();
    let cl = this.carousel.nativeElement as HTMLElement;
    let o = Math.floor(cl.scrollLeft / cl.clientWidth);
    console.log(`${cl.clientWidth} ${cl.scrollWidth}`);
    this.currentIndex = o;

    this.atStart = false;
    this.atEnd = false;
    if (cl.scrollLeft < 20) {
      this.atStart = true;
    }
    if (cl.scrollWidth - cl.scrollLeft <= cl.clientWidth + 20) {
      this.atEnd = true;
    }
    if (this.atEnd) {
      this.currentIndex = this.dots.length - 1;
    }
    this.cdRef.detectChanges();
  }
}
