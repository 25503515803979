import moment from 'moment-timezone';
import { DaySchedule } from '../common/timespan/day.schedule';
import { TLockerCell, TMember, TUser } from '../core/entities';
import { TScheduleCalendar } from './thinkdesks';
export enum TBookingType {
  'space' = 'space',
  'locker' = 'locker',
}
export enum TBookingStatus {
  'pending' = 'pending',
  'rejected' = 'rejected',
  'approved' = 'approved',
  'cancelled' = 'cancelled',
}

export class TMemberBase {
  id: number;
  name: string;
  mobile: string;
  email: string;
  active: boolean;
  locale: string;
}

export class TUserBase {
  id: number;
  name: string;
  email: string;
  mobile: string;
}
export class TLocationBase {
  id: number;
  name: string;
  name_lang: any;

  tel: string;
  email: string;
  contactPerson: string;
  contactPerson_lang: any;

  address: string;
  address_lang: any;
  openingHours: string;
  openingHours_lang: any;

  description: string;
  description_lang: any;

  lat: number;
  lng: number;
  _distance: number;
  googleMapUrl: string;

  images: string[];

  spaces: TSpaceBase[];
  numSpaces: number;
}

export enum TAccessDeviceType {
  'door' = 'door',
  'locker' = 'locker',
  'mailbox' = 'mailbox',
}

export class TDeviceCell {
  id: number;
  name: string;

  seq: number;
}
export class TAccessLog {
  id: number;
  time: moment.Moment;
  member: TMember;
  user: TUser;
  visitorId: number;
  device: TDevice;
  lockerCell: TLockerCell;
}
export class TDevice {
  id: number;
  name: string;
  type: TAccessDeviceType;
  numCells: number;
  access: any;
  cells: TDeviceCell[];
}
export interface Bookable {
  bookSlotMins: number;
  bookMinSlots: number;
  bookMaxSlots: number;
  bookSlotIncrement: number;
  bookDays: number;
}
export class TSpaceBase {
  id: number;
  seq: number;
  name: string;
  name_lang: any;

  location: TLocationBase;
  capacity: number;
  area: number;
  textDescription: string;
  textDescription_lang: any;
  description: string;
  description_lang: any;

  remarks: string;
  remarks_lang: any;

  images: string[];

  thinkdesksSpaceCode: string;
  bookSchedule: TScheduleCalendar;

  bookSlotMins: number;
  bookMinSlots: number;
  bookMaxSlots: number;
  bookSlotIncrement: number;
  bookDays: number;

  includedFacilities: string;
  includedFacilities_lang: any;
}

export class TLockerBase {
  id: number;
  name: string;
  name_lang: any;
  location: TLocationBase;

  textDescription: string;
  textDescription_lang: any;
  description: string;
  description_lang: any;

  bookSchedule: TScheduleCalendar;

  bookSlotMins: number;
  bookMinSlots: number;
  bookMaxSlots: number;
  bookSlotIncrement: number;
  bookDays: number;

  images: string[];
  cells: TLockerCellBase[];

  thinkdesksLockerCode: string;

  numCells: number;
}

export class TLockerCellBase {
  id: number;
  name: string;
  thinkdesksCellCode: string;
}

export class TBookingBase {
  id: number;
  user: TUserBase;
  member: TMemberBase;
  bookingNo: string;
  date: moment.Moment;
  durationMins: number;
  status: TBookingStatus;

  passcode:string;
  contactName: string;
  contactEmail: string;
  contactMobile: string;
  type: TBookingType;
  startTime: moment.Moment;
  endTime: moment.Moment;

  space: TSpaceBase;
  locker: TLockerBase;
  lockerCell: TLockerCellBase;

  cancelDate: moment.Moment;
  rejectDate: moment.Moment;
  approveDate: moment.Moment;
  thinkdesksId: number;

  schedule: DaySchedule;

  remarks: string;
}
