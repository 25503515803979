<ng-container *ngIf="location">
  <div
    [routerLink]="link?[session.path('/location/'+location.id)]:null"
    class="location_card"
  >
    <div>
      <img [src]="locationImage(location,location.images[0])" />
    </div>
    <div class="info" style="min-width: 233px">
      <div class="text-xs truncate">
        {{ lang(location.organization?.name,location.organization?.name_lang) }}
      </div>
      <div class="font-bold text-lg truncate">
        {{ lang(location.name,location.name_lang) }}
      </div>
      <div class="mt-2 text-xs flex gap-2 items-start">
        <fa-icon icon="map-location-dot" class="icon"></fa-icon>
        <div style="color: #707070" class="w-full">
          <div class="truncate">
            {{ lang(location.address,location.address_lang) }}
          </div>
          <div *ngIf="hasDistance && distance<1000">~{{distance}}km</div>
        </div>

      </div>

      <div class="spacer"></div>

      <div *ngIf="location.numSpaces">
        {{_common.space_available}}: {{location.numSpaces}}
      </div>

      
      <ng-content></ng-content>
  </div>
  </div>
</ng-container>
