import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';


export class TooltipState {
   content: string;
   contentList: string[];
   dom: HTMLElement;
   constructor(content: string, dom: HTMLElement) {
      this.content = content;
      this.dom = dom;
   }
}
@Injectable({
   providedIn: 'root'
})
export class TooltipService {

   static INSTANCE: TooltipService;

   private loaderSubject = new Subject<TooltipState>();

   loaderState = this.loaderSubject.asObservable();


   constructor() {
      TooltipService.INSTANCE = this;
   }

   show(dom: HTMLElement, content: string, contentList: string[]) {

      this.loaderSubject.next(<TooltipState>{ content, contentList, dom });
   }

   hide() {
      this.loaderSubject.next(null);
   }
}
