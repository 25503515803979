<ng-container *ngIf="filter">
   <div *ngFor="let c of filter.filter.list" class="app-chip mr-2">
      <div>
         {{ name(c) }}
      </div>
      <div class="cursor-pointer ml-1">
         <fa-icon  [icon]="faTimes" (click)="filter.filter.remove(c); refresh();"></fa-icon>
      </div>
   </div>
</ng-container>
<!--<ng-container *ngIf="!filter">
   No filter
</ng-container>-->