<div class="carousel-container">
  <div
    class="carousel"
    #carousel
    (scroll)="updateCurrentIndex()"
    (window:resize)="updateCurrentIndex()"
  >
    <ng-content></ng-content>
  </div>
  <div
    class="carousel-button"
    style="left: 0px"
    (click)="prev()"
    *ngIf="!atStart"
  >
    <fa-icon icon="chevron-left"></fa-icon>
  </div>
  <div
    class="carousel-button"
    style="right: 0px"
    (click)="next()"
    *ngIf="!atEnd"
  >
    <fa-icon icon="chevron-right"></fa-icon>
  </div>
</div>
