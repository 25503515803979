<!-- @format -->

<div class="ctlSearchBar">
  <div class="searchField">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input
      matInput
      (keyup)="applyFilter($any($event.target).value)"
      placeholder="{{ _common.search }}"
      #_search
    />
    <fa-icon
      icon="times"
      matSuffix
      (click)="clearFilter(_search)"
      class="clearSearch"
    ></fa-icon>
  </div>

  <div class="app-filter-bar">
    <div>
      <fa-icon icon="sliders-h"></fa-icon>
    </div>
    <div class="content">
      <app-filter-date-range-button
        [dateRange]="dateRange"
        (update)="refresh()"
      ></app-filter-date-range-button>
      <app-filter-multi
        [filter]="space"
        #_space
        (change)="refresh()"
        [objectValues]="spaces"
      >
        {{ _common.Spaces }}</app-filter-multi
      >
      <app-filter-multi
        [filter]="status"
        #_status
        (change)="refresh()"
        [values]="[
          statusList.pending,
          statusList.rejected,
          statusList.approved,
          statusList.cancelled
        ]"
        [names]="[
          _common.bookingStatus.pending,
          _common.bookingStatus.rejected,
          _common.bookingStatus.approved,
          _common.bookingStatus.cancelled
        ]"
        >{{ _common.Status }}</app-filter-multi
      >
    </div>
    <div (click)="filterObject.openFilter()" class="openFilter">
      <fa-icon icon="ellipsis-h"></fa-icon>
    </div>
  </div>
  <div class="app-btn btn-icon" (click)="refresh()">
    <fa-icon icon="sync"></fa-icon>
  </div>

  <span class="spacer"></span>
  <!-- <span>
    <button class="app-btn" (click)="add()" color="primary">
      <fa-icon icon="plus"></fa-icon> {{ _common.add }}
    </button>
  </span> -->
</div>

<app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  class="max-scroll card-shadow"
  #scroll
>
  <table
    mat-table
    [dataSource]="objects"
    class="app-table scroll"
    matSort
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="datatable-select">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="status">
        <!-- {{ _common.Status }} -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="text-xs mb-1" style="color: #707070;">{{ element.bookingNo }}</div>
        <div class="app-chip Status" [ngClass]="element.status">
          {{ _common.bookingStatus[element.status] }}
        </div>
        
      </td>
    </ng-container>
    <ng-container matColumnDef="bookingNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="bookingNo">#</th>
      <td mat-cell *matCellDef="let element">{{ element.bookingNo }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
        {{ _common.member }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.member?.name ?? element.user?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="space">
      <th mat-header-cell *matHeaderCellDef>{{ _common.Spaces }}</th>
      <td mat-cell *matCellDef="let element">
        <!-- {{ element.space?.name }} -->
        <div class="font-bold">
          {{ lang(element.space?.name, element.space?.name_lang) }}
        </div>
        <div *ngIf="element.space?.location; let location" class="text-xs flex">
          <div *ngIf="location?.organization; let organization">
            {{ lang(organization.name, organization.name_lang) }}
          </div>
          <div *ngIf="element.space?.location; let location">
            {{ lang(location.name, location.name_lang) }}
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="startTime">
        {{ _common.booked_slot }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ dateText(element) }}
        <div>{{ timeText(element, _common) }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="date">
        {{ _common.created_time }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="color: #707070">{{ formatTime(element.date) }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="purpose">
      <th mat-header-cell *matHeaderCellDef>{{ _common.usage_theme }}</th>
      <td mat-cell *matCellDef="let element">
        <div>
          {{ lang(element.purpose?.name, element.purpose?.name_lang) }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="checkin">
      <th mat-header-cell *matHeaderCellDef>{{_common.checkin}}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.checkin; let c">
          <div>
            {{ formatTime(c.time) }}
          </div>
          <div>
            {{ c.member?.name }}
          </div>
          <!-- <div>
            {{ c.deviceName }}
          </div> -->
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="controls" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="floating-buttons">
        <button class="app-btn btn-text" (click)="selectObject(element)">
          <fa-icon icon="pen"></fa-icon>
          <div class="mobile_hide">
            {{ _common.edit }}
          </div>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="datatable-row"
    ></tr>
  </table>
</app-scroll-container>

<div
  *ngIf="filterObject.showFilter"
  class="ctlFilter listFilter"
  (clickOutsidePopup)="filterObject.closeFilter()"
>
  <div class="header">
    <div class="title">
      <fa-icon icon="sliders-h" style="margin-right: 5px"></fa-icon>
      {{ _common.filters }}
    </div>
    <div class="spacer"></div>
    <div
      (click)="filterObject.closeFilter()"
      style="font-size: 1.5em; cursor: pointer"
    >
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div class="body"></div>
  <div class="buttons">
    <div (click)="filterObject.clear(); refresh()" class="clear_button">
      {{ _common.ClearAll }}
    </div>
  </div>
</div>
