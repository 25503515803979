import moment from 'moment-timezone';
import { TimeAtom } from '../common/timespan/time_utils';
export class TScheduleCalendar {
  id: number;
  name: string;
  code: string;
}

export class THolidayCalendar {
  id: number;
  name: string;
  code: string;
}

export enum ThinkdesksTimeMode {
  'end' = 'end',
  'start' = 'start',
}

export enum ThinkdesksMaxMode {
  'none' = 'none',
  'session' = 'session',
  'day' = 'day',
}

export class Thinkdesks {
  static fixHolidayCalendar(o: THolidayCalendar) {}
  static fixScheduleCalendar(o: TScheduleCalendar) {}
  static timeFormat = 'HH:mm'; // 'hh:mmA'
  static timeMode = ThinkdesksTimeMode.end;
  static dateFormats = {
    en: 'YYYY MMM, DD ddd',
    zh_hk: 'YYYY年MM月DD日 (ddd)',
  };

  static dateFormat(date: moment.Moment, locale: string) {
    if (date == null) return null;
    locale = locale ?? 'en';
    if (locale.startsWith('en')) locale = 'en';
    let format = this.dateFormats[locale] ?? this.dateFormats['en'];
    return date.format(format);
  }

  static checkTime(
    now: moment.Moment,
    opts: {
      start: moment.Moment;
      end: moment.Moment;
    }
  ) {
    switch (this.timeMode) {
      case ThinkdesksTimeMode.start:
        if (opts.start.isBefore(now)) {
          return false;
        }
        break;
      case ThinkdesksTimeMode.end:
        if (opts.end.isBefore(now)) {
          return false;
        }
        break;
    }
    return true;
  }
  static init(
    mins: number,
    opts?: {
      days?: number;
      timeFormat?: string;
      timeMode?: ThinkdesksTimeMode;
    }
  ) {
    this.timeMode = opts?.timeMode ?? this.timeMode;
    this.timeFormat = opts?.timeFormat ?? this.timeFormat;

    this.BOOK_MINS = mins;
    this.BOOK_DAYS = opts?.days ?? 1;
    let now = moment();
    this.HOURS = [...Array((24 * 60) / this.BOOK_MINS)].map((c, index) => {
      let mins = index * this.BOOK_MINS;
      let hours = Math.floor(mins / 60);
      mins = mins % 60;
      let t = new TimeAtom(hours, mins);
      let end = t.clone().add(this.BOOK_MINS);

      let time = t.moment(now);
      let endTime = end.moment(now);

      return {
        time: t,
        value: t.toTime(),
        name: `${time.format(this.timeFormat)}`,
      };
    });
  }
  static BOOK_MINS = 30;
  static BOOK_DAYS = 3;

  static HOURS = [...Array((24 * 60) / this.BOOK_MINS)].map((c, index) => {
    let mins = index * this.BOOK_MINS;
    let hours = Math.floor(mins / 60);
    mins = mins % 60;
    let t = new TimeAtom(hours, mins);
    return {
      time: t,
      value: t.toTime(),
      name: t.toString(),
    };
  });
}
