<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab label="User">
        <form [formGroup]="form" class="max-scroll dialogBody ctlSettings">
          <div class="card">
            <div class="cardHeader">Settings</div>
            <div class="cardBody space-y-2">
              <div>
                <app-form-field style="width: 50%">
                  <app-label>Name</app-label>
                  <input matInput formControlName="name" required />
                </app-form-field>
              </div>
              <div>
                <app-form-field style="width: 50%">
                  <app-label>Email</app-label>
                  <input matInput formControlName="email" />
                </app-form-field>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="cardHeader">Organizations</div>
            <div class="cardBody space-y-2">
              <div *ngFor="let g of organizations; let i =index" class="flex">
                <div class="flex-auto">{{g.name}}</div>
                <div>
                  <fa-icon
                    icon="times"
                    (click)="removeOrganization(i)"
                  ></fa-icon>
                </div>
              </div>
              <div>
                <div class="app-btn" (click)="addOrganization()">
                  {{_common.add}}
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <ng-container *ngIf="objectId">
      <button class="app-btn btn-outline" (click)="remove()">
        <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
        >{{_common.delete}}
      </button>
      <button class="app-btn btn-outline" (click)="changePassword()">
        {{_common.change_password}}
      </button>
    </ng-container>
    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
