import moment from 'moment';
import { DateSelectorConfig, DateSelector_RangeType, DateSelector_SubRangeType } from '../../daterange_utils';



export interface CommonDateSelector {
   id: string;
   setup: (c: DateSelectorConfig) => void;
}

export interface CommonDateSelectorGroup {
   id: string;
   selectors: CommonDateSelector[];
}

export function findCommonDateSelector(name: string) {
   for (const c of CommonDateSelectors) {
      if (c.id === name)
         return c;
   }
   return null;
}

export const CommonDateSelectors: CommonDateSelectorGroup[] = [
   {
      id: 'day',
      selectors: [
         {
            id: 'today',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now);
            }
         },
         {
            id: 'yesterday',
            setup: (c: DateSelectorConfig) => {
               const now = moment().add(-1, 'day');
               c.setRange(now, now);
            }
         },
         {
            id: 'today_yesterday',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now);
               c.addPrevSubRange();
            }
         }
      ]
   },
   {
      id: 'week',
      selectors: [
         {
            id: 'thisweek',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.week_align);
            }
         },
         {
            id: 'lastweek',
            setup: (c: DateSelectorConfig) => {
               const now = moment().add(-7, 'day');
               c.setRange(now, now, DateSelector_RangeType.week_align);
            }
         },
         {
            id: 'week_prevweek',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.week_align);
               c.addPrevSubRange();
            }
         },
         {
            id: 'week_52weeks',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.week_align);
               c.addPrevSubRange(DateSelector_SubRangeType.prev52week);
            }
         }

      ]
   },
   {
      id: 'month',
      selectors: [
         {
            id: 'thismonth',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.month);
            }
         },
         {
            id: 'lastmonth',
            setup: (c: DateSelectorConfig) => {
               const now = moment().add(-1, 'month');
               c.setRange(now, now, DateSelector_RangeType.month);
            }
         },
         {
            id: 'month_prevmonth',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.month);
               c.addPrevSubRange(DateSelector_SubRangeType.prevmonth);
            }
         },
         {
            id: 'month_prevyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.month);
               c.addPrevSubRange(DateSelector_SubRangeType.prevyear);
            }
         }

      ]
   },
   {
      id: 'mtd',
      selectors: [
         {
            id: 'mtd',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.mtd);
            }
         },
         {
            id: 'mtd_prevmonth',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.mtd);
               c.addPrevSubRange(DateSelector_SubRangeType.prevmonth);
            }
         },

         {
            id: 'mtd_prevyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.mtd);
               c.addPrevSubRange(DateSelector_SubRangeType.prevyear);
            }
         }
      ]
   },
   {
      id: 'year',
      selectors: [
         {
            id: 'thisyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.year);
            }
         },
         {
            id: 'lastyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment().add(-1, 'year');
               c.setRange(now, now, DateSelector_RangeType.year);
            }
         },
         {
            id: 'year_prevyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.year);
               c.addPrevSubRange(DateSelector_SubRangeType.prevyear);
            }
         }
      ]
   },
   {
      id: 'ytd',
      selectors: [
         {
            id: 'ytd',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.ytd);
            }
         },
         {
            id: 'ytd_prevyear',
            setup: (c: DateSelectorConfig) => {
               const now = moment();
               c.setRange(now, now, DateSelector_RangeType.ytd);
               c.addPrevSubRange(DateSelector_SubRangeType.prevyear);
            }
         }
      ]
   }

];


