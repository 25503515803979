<div class="max-height">
  <div style="display: flex; align-items: center;">
    <app-month-selector
      [date]="date"
      (dateChange)="refresh($event)"
    ></app-month-selector>
    <div style="flex: 1 1 auto">

    </div>
    <div >
      <ng-content></ng-content>
    </div>
  </div>

  <div class="ctlCalendar max-height">
    <div class="grid">
      <div
        *ngFor="let n of names; let i = index"
        class="calendarHeader"
        [class.firstHeader]="!i"
      >
        {{ n }}
      </div>

      <ng-container *ngFor="let row of items">
        <div
          *ngFor="let item of row; let i = index"
          class="date"
          [class.otherMonth]="!item.sameMonth"
          [class.firstDate]="!i"
          (click)="clickDate(item)"
        >
          <div class="px-2 flex items-center">
            <div>{{ item.day }}</div>
            <div class="spacer"></div>

            <div *ngFor="let a of actions">
              <fa-icon
                [icon]="$any(a.icon)"
                (click)="clickAction($event, item, a.action)"
              ></fa-icon>
            </div>
          </div>
          <div class="dateItems">
            <div
              *ngFor="
                let i of item.items.slice(
                  0,
                  maxItems ? maxItems : item.items.length
                )
              "
              class="item"
              (click)="onEdit($event, item, i)"
              [ngStyle]="i.style"
              [style.backgroundColor]="i.color"
              [ngClass]="i.css"
            >
              <div>
                <div>
                  {{ i.title }}
                </div>

                <div
                  *ngFor="let t of i.titles; let j = index"
                  [ngStyle]="i.styles ? i.styles[j] : null"
                >
                  {{ t }}
                </div>
              </div>
              <div>
                <fa-icon *ngIf="i.icon" [icon]="i.icon"></fa-icon>
              </div>
              <div *ngIf="i.badge">
                {{ i.badge }}
              </div>
            </div>
          </div>
          <div
            *ngIf="maxItems && item.items.length > maxItems"
            (click)="openDate(item)"
            class="more"
          >
            <div>{{ item.items.length }} {{ moreText }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
