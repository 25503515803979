/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import {
  AccessUtils,
  TLocation,
  TLocker,
  TOrganization,
} from '../../core/entities';
import { SessionCache } from '../../session.cache';
import { CONTENT_PATH, SessionService, TIMEZONE } from '../../session.service';
import { TScheduleCalendar } from '../../thinkdesks/thinkdesks';
import { ImageUploadBase } from '../../thinkdesks/widgets/image.upload.base';
import { ObjectImageListComponent } from '../../thinkdesks/widgets/object.image.list';
import { ScheduleCalendarComponent } from '../../thinkdesks/widgets/schedule.calendar.component';
import { LImageUploadContainer } from '../../thinkdesks/widgets/upload.container';
import { EventListComponent } from '../event/event.list.component';

@Component({
  templateUrl: './locker.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    EventListComponent,
    MatCheckboxModule,
    CKEditorModule,
    ObjectImageListComponent,
    ScheduleCalendarComponent,
  ],
})
export class LockerEditDialogComponent extends CompareWithMixin(EmptyObject) {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TLocker;

  api = '/api/locker';

  organizations: TOrganization[] = [];
  locations: TLocation[] = [];
  schedules: TScheduleCalendar[] = [];

  public Editor = ClassicEditor;

  container = new LImageUploadContainer();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LockerEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;

    this.cache.loadOrganization(this.session, (list) => {
      this.organizations = list;
    });

    this.form = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
      active: [''],
      approved: [''],
      locked: [''],
      description: [''],
      organization: ['', Validators.required],
      location: ['', Validators.required],

      bookSchedule: [],
      bookMinSlots: ['', Validators.required],
      bookMaxSlots: ['', Validators.required],
      bookSlotIncrement: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TLocker;

          AccessUtils.fixLocker(this.object);

          let images = this.object.images ?? [];
          this.container.load(
            {
              numImages: images.length,
              imageIds: images.map((c) => null),
              names: images,
            },
            (index, imageId) => {
              return `${CONTENT_PATH}/locker/${this.object.id}/${images[index]}`;
            }
          );

          let query = Object.assign({}, this.object) as any;

          query.organization = query.location?.organization;

          utils.fill_form_values(query, this.form);

          this.updateForm();

          this.updateLocation();

          this.updateKey();
        }
      );
    } else {
      let query = {
        timezone: TIMEZONE,
        organization: this.data?.organization,
      } as any;

      utils.fill_form_values(query, this.form);

      this.updateForm();

      this.updateLocation();
    }
  }

  key = {};
  subkey = {};
  updateKey() {
    this.subkey = null;

    if (this.object.bookSchedule) {
      this.key = {
        calendar: WebUtils.objName(this.object.bookSchedule),
      };
      this.subkey = {
        locker: WebUtils.objName(this.object),
      };
    } else {
      this.key = {
        locker: WebUtils.objName(this.object),
      };
    }
  }
  updateLocation() {
    var location =
      (this.object?.location as TLocation) ?? this.form.value.location;

    let organization = this.form.value.organization ?? location?.organization;
    this.locations = [];
    this.schedules = [];
    if (organization) {
      this.cache.loadLocation(organization.id, (list) => {
        this.locations = list;
      });
    }
    if (location) {
      this.cache.loadScheduleCalendar(location.id, (list) => {
        this.schedules = list;
      });
    }
  }

  updateForm() {
    if (this.objectId) {
      this.form.controls['organization'].disable();
      this.form.controls['location'].disable();
    }
    utils.updateValidity(this.form);
  }

  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let formData = new FormData();

    let query = Object.assign({}, this.form.value);
    query.images = ImageUploadBase.getFile(formData, 'images', this.container);

    query.type = WebUtils.objId(query.type);
    query.bookSchedule = WebUtils.objName(query.bookSchedule);

    utils.form_clean_numbers(query, ['lat', 'lng']);
    formData.append('data', JSON.stringify(query));

    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, formData),
        async (result) => {
          this.object = result as TLocker;
          AccessUtils.fixLocker(this.object,);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, formData),
        async (result) => {
          this.object = result as TLocker;
          AccessUtils.fixLocker(this.object, );

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
}
