<div class="sectionHeader" (click)="filterObject.toggleFilter(id, $event)">
  <div class="spacer"><ng-content></ng-content></div>
  <div>
    <fa-icon [icon]="faChevronDown" *ngIf="!filterObject.section[id]"></fa-icon>
    <fa-icon [icon]="faChevronUp" *ngIf="filterObject.section[id]"></fa-icon>
  </div>
</div>
<div class="sectionBody" *ngIf="filterObject.section[id]">
  <div
    mat-menu-item
    (click)="_select()"
    style="display: flex; align-items: center"
  >
    <ng-container *ngIf="filter.filter.v">
      <div class="spacer">
        {{ filter.filter.v?.name }}
      </div>
      <div style="margin-left: 10px">
        <fa-icon [icon]="faTimes" (click)="clear($event)"></fa-icon>
      </div>
    </ng-container>
    <ng-container *ngIf="!filter.filter.v">
      {{ title }}
    </ng-container>
  </div>

  <div class="bottom">
    <div class="button" (click)="filter.filter.clear(); refresh()">
      {{ _common.clear }}
    </div>
  </div>
</div>
