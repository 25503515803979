/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import moment from 'moment-timezone';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SpaceFacilityListComponent } from '../../admin/space.facility/space.facility.list.component';
import { VizFormModule } from '../../common/components/form/form.module';
import { TIMEZONE_NAMES } from '../../common/timezone_list';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import { AccessUtils, TLocation, TOrganization } from '../../core/entities';
import { SiteEditUtils } from '../../core/lang';
import { AppPermissions } from '../../core/permissions';
import { SessionCache } from '../../session.cache';
import { CONTENT_PATH, SessionService, TIMEZONE } from '../../session.service';
import { ImageUploadBase } from '../../thinkdesks/widgets/image.upload.base';
import { ObjectImageListComponent } from '../../thinkdesks/widgets/object.image.list';
import { LImageUploadContainer } from '../../thinkdesks/widgets/upload.container';
import { AgentContentListComponent } from '../content/agent.content.list.component';
import { ScheduleCalendarListComponent } from '../schedule/schedule.calendar.list.component';
import { SpaceListComponent } from '../space/space.list.component';
@Component({
  templateUrl: './location.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
    SpaceListComponent,
    CKEditorModule,
    ObjectImageListComponent,
    ScheduleCalendarListComponent,
    SpaceFacilityListComponent,
    AgentContentListComponent,
  ],
})
export class LocationEditDialogComponent extends CompareWithMixin(EmptyObject) {
  permissions = AppPermissions;
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TLocation;

  api = '/api/location';
  timezones = TIMEZONE_NAMES;

  date = moment();
  public Editor = ClassicEditor;

  organizations: TOrganization[] = [];

  container = new LImageUploadContainer();
  scheduleQuery = {};
  dlangs = SiteEditUtils.OTHER_LANGS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) options: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LocationEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;

    console.log(options);
    this.cache.loadOrganization(this.session, (list) => {
      this.organizations = list;
    });

    this.form = this.fb.group({
      name: ['', Validators.required],
      name_lang: SiteEditUtils.langArray(this.fb),

      code: ['', Validators.required],
      timezone: ['', Validators.required],
      organization: ['', Validators.required],
      description: [''],
      description_lang: SiteEditUtils.langArray(this.fb),

      lat: [''],
      lng: [''],
      googleMapUrl: [''],
      address: [''],
      address_lang: SiteEditUtils.langArray(this.fb),

      tel: [''],
      email: [''],
      openingHours: [''],
      openingHours_lang: SiteEditUtils.langArray(this.fb),

      contactPerson: [''],
      contactPerson_lang: SiteEditUtils.langArray(this.fb),

      district: [''],
      area: [''],

      terms: [''],
      terms_lang: SiteEditUtils.langArray(this.fb),
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TLocation;

          AccessUtils.fixLocation(this.object);

          let images = this.object.images ?? [];
          this.scheduleQuery = {
            location: WebUtils.obj(this.object),
          };
          this.container.load(
            {
              numImages: images.length,
              imageIds: images.map((c) => null),
              names: images,
            },
            (index, imageId) => {
              return `${CONTENT_PATH}/location/${this.object.id}/${images[index]}`;
            }
          );

          let query = Object.assign({}, this.object);
          if (query.lat == 0) {
            delete query.lat;
          }
          if (query.lng == 0) {
            delete query.lng;
          }
          utils.fill_form_values(query, this.form);

          this.updateForm();
        }
      );
    } else {
      let query = {
        timezone: TIMEZONE,
        organization: this.data?.organization,
      } as any;

      utils.fill_form_values(query, this.form);

      this.updateForm();
    }
  }

  updateForm() {
    if (!this.session.hasPermission(AppPermissions.LOCATION_EDIT)) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    if (this.objectId) {
      this.form.controls['organization'].disable();
      this.form.controls['timezone'].disable();
    }
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let formData = new FormData();
    let query = Object.assign({}, this.form.value);
    SiteEditUtils.langValues(query, 'name');
    SiteEditUtils.langValues(query, 'description');
    SiteEditUtils.langValues(query, 'address');
    SiteEditUtils.langValues(query, 'contactPerson');
    SiteEditUtils.langValues(query, 'openingHours');
    SiteEditUtils.langValues(query, 'terms');
    query.images = ImageUploadBase.getFile(formData, 'images', this.container);
    formData.append('data', JSON.stringify(query));

    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, formData),
        async (result) => {
          this.object = result as TLocation;
          AccessUtils.fixLocation(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, formData),
        async (result) => {
          this.object = result as TLocation;
          AccessUtils.fixLocation(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      },
      this.snackBar
    );
  }
}
