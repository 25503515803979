import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { date_utils } from '../common/date_utils';
import { TimeAtom } from '../common/timespan/time_utils';
import { WebUtils, utils } from '../common/utils';
import { AccessUtils, TBooking, TSpace } from '../core/entities';
import { SessionCache } from '../session.cache';
import { BookingAvailabilityManager } from './booking.availability.manager';
import { BookDuration, BookHour } from './booking.session.service';
import { Thinkdesks } from './thinkdesks';
import { Bookable, TBookingType } from './thinkdesks.entities';

export class BookContainerBase<T extends Bookable> {
  form: FormGroup;
  extraForm: FormGroup;
  http: HttpClient;
  cache: SessionCache;
  router: Router;
  snackBar: MatSnackBar;

  startDate: moment.Moment;
  endDate: moment.Moment;
  selected = moment.tz(date_utils.tz).startOf('day');

  object: T;
  hours: BookHour[] = [];
  slots: BookDuration[] = [];

  availabilityManager: BookingAvailabilityManager;
  createSlots(object: Bookable) {
    let mins = Thinkdesks.BOOK_MINS;

    let slots = [];
    for (
      let i = object.bookMinSlots;
      i <= object.bookMaxSlots;
      i += object.bookSlotIncrement
    ) {
      let m = mins * i;
      slots.push({
        slots: i,
        value: m,
        name: `${date_utils.minText(m)}`,
        active: false,
      });
    }
    return slots;
  }

  dateUpdated() {
    let d = this.selected.clone();
    this.availability(d);
  }
  timeUpdated() {
    this.updateDuration();
  }
  availability(d: moment.Moment) {}

  checkSchedule(time: TimeAtom, end: TimeAtom, opts: { now: moment.Moment }) {
    var _start = time.moment(this.selected);
    var _end = end.moment(this.selected);

    if (
      !Thinkdesks.checkTime(opts.now, {
        start: _start,
        end: _end,
      })
    ) {
      return false;
    }
    return this.availabilityManager.match([time]);
  }
  checkTime(date: moment.Moment, time: TimeAtom) {
    return false;
  }
  updateTime() {
    let now = moment.tz(date_utils.tz);

    let list = Thinkdesks.HOURS.filter((c) => {
      var end = c.time.clone();
      end.add(Thinkdesks.BOOK_MINS);
      end.normalize();
      return this.checkSchedule(c.time, end, { now });

      // return this.availabilityManager.match([c.time]);
    }).map((c) => {
      return Object.assign(
        {
          active: true,
        },
        c
      );
    });
    this.hours = [];
    for (let i = 0; i < list.length; i += this.object.bookSlotIncrement) {
      if (i + this.object.bookSlotIncrement <= list.length) {
        this.hours.push(list[i]);
      } else {
        break;
      }
    }

    for (let h of this.hours) {
      // let dd = h.time.moment(this.selected);
      // if (dd.isBefore(now)) {
      //   h.active = false;
      // } else {
      h.active = this.checkTime(this.selected, h.time);
      // }
    }
  }
  updateDuration() {
    let startTime = this.form.value.startTime;
    let duration = this.form.value.duration;
    for (let s of this.slots) {
      s.active = false;
    }
    if (!startTime) return;

    let ss = startTime.time as TimeAtom;

    let now = moment();
    let prevTime = 0;
    for (let s of this.slots) {
      let start = ss.clone();
      start.add(prevTime);
      start.normalize();

      let end = start.clone();
      end.add(Thinkdesks.BOOK_MINS);
      end.normalize();

      prevTime = s.value;

      let booked = false;
      let close = false;
      for (let i = 0; i < this.object.bookMinSlots; i++) {
        if (!this.checkTime(this.selected, start)) {
          booked = true;
          break;
        }
        if (!this.checkSchedule(start, end, { now })) {
          close = true;
          break;
        }
      }

      // cannot continue since there is a booking in the middle
      if (booked) {
        break;
      }
      if (!close) {
        s.active = true;
      }
    }
    if (!duration?.active) this.form.controls['duration'].setValue(null);
  }

  setStartTime(startTime: BookHour) {
    this.form.controls['startTime'].setValue(startTime);
  }
  setDuration(duration: BookDuration) {
    this.form.controls['duration'].setValue(duration);
  }

  selectedIndex = -1;
  uiSlots = 0;
  onCombinedDateSelect(c) {
    if (!c.active) return;
    let selectedIndex = -1;

    let numSlots = this.object.bookSlotIncrement;

    let startTime = this.form.value.startTime;
    let duration = this.form.value.duration;
    if (startTime != null) selectedIndex = this.hours.indexOf(startTime!);

    let index = this.hours.indexOf(c);

    let selected =
      this.selected != null &&
      index >= selectedIndex &&
      index <= selectedIndex + this.uiSlots - 1;

    let object = this.object!;

    // check if slots available for MinSlots
    let selectable = () => {
      let selectable = true;
      let s = object.bookMinSlots / object.bookSlotIncrement - 1;
      let i = index;
      while (s > 0) {
        i++;
        if (i >= this.hours.length || !this.hours[i].active) {
          //TODO should use !this.hours[i].pactive
          selectable = false;
          break;
        }
        s--;
      }
      return selectable;
    };

    //deselect
    if (startTime != null) {
      let selectedIndex = this.hours.indexOf(startTime!);

      if (object.bookMaxSlots == 1) {
        if (index == selectedIndex) {
          this.setStartTime(null);
          this.setDuration(null);

          this.timeUpdated();
        } else {
          this.setStartTime(c);
          this.setDuration(this.slots[0]);

          this.timeUpdated();
          this.uiSlots = 1;
        }
      } else {
        if (index == selectedIndex - 1) {
          // select prev

          if (this.uiSlots + numSlots <= object.bookMaxSlots) {
            this.setStartTime(c);
            this.uiSlots += numSlots;
          } else {
            this.snackBar.open( _common.thinkdesks.exceed_booking_hour +
              ` ${date_utils.minText(
                object.bookMaxSlots * Thinkdesks.BOOK_MINS,
                _common
              )}`
            );
          }
        } else if (index == selectedIndex + this.uiSlots / numSlots) {
          // next
          if (this.uiSlots + numSlots <= object.bookMaxSlots)
            this.uiSlots += numSlots;
          else
            this.snackBar.open( _common.thinkdesks.exceed_booking_hour +
              ` ${date_utils.minText(
                object.bookMaxSlots * Thinkdesks.BOOK_MINS,
                _common
              )}`
            );
        } else if (index == selectedIndex) {
          //select first in the selection
          if (this.uiSlots == object.bookMinSlots) {
            this.setStartTime(null);
            this.setDuration(null);
          } else {
            if (this.uiSlots - numSlots >= object.bookMinSlots) {
              startTime = this.hours[selectedIndex + 1];
              this.setStartTime(startTime);

              this.uiSlots -= numSlots;
            }
          }
        } else if (index == selectedIndex + this.uiSlots / numSlots - 1) {
          // select last in the selection
          if (this.uiSlots == object.bookMinSlots) {
            this.setDuration(null);
          } else if (this.uiSlots - numSlots >= object.bookMinSlots) {
            this.uiSlots -= numSlots;
          }
        } else {
          this.setStartTime(c);
          this.timeUpdated();

          if (!selectable()) {
            this.setStartTime(startTime);
            this.setDuration(duration);
            this.timeUpdated();
            return;
          }

          this.uiSlots = object.bookMinSlots;
        }
        startTime = this.form.value.startTime;
        if (startTime != null) this.timeUpdated();
        if (this.uiSlots > 0) {
          this.setDuration(this.slots.find((c) => c.slots == this.uiSlots));
        }
        this.updateDuration();
      }
    } else {
      this.setStartTime(c);
      this.uiSlots = object.bookMinSlots;
      this.timeUpdated();

      if (!selectable()) {
        this.setStartTime(startTime);
        this.setDuration(duration);
        this.timeUpdated();

        return;
      }

      this.setDuration(this.slots.find((c) => c.slots == this.uiSlots));
      this.updateDuration();
    }
    this.selectedIndex = -1;
    startTime = this.form.value.startTime;
    if (startTime != null) this.selectedIndex = this.hours.indexOf(startTime!);
  }

  afterBooking(o: TBooking) {}
  initObject() {}
}
export class SpaceBookContainer extends BookContainerBase<TSpace> {
  id: number;

  // selected = moment.tz(date_utils.tz).startOf('day');

  createSpaceRequest(): Observable<Object> {
    return this.http.get(`/api/public/space/${this.id}`);
  }

  createSpaceAvailavility(start: moment.Moment, end: moment.Moment) {
    return this.http.post(`/api/public/space/${this.id}/availability`, {
      start: start,
      end: end,
    });
  }

  createSpaceBookingRequest(query): Observable<Object> {
    return this.http.post('/api/public/booking/create/space', query);
  }
  createSpaceManageBookingRequest(query): Observable<Object> {
    return this.http.post('/api/manage/booking/create/space', query);
  }
  refresh() {
    WebUtils.web_result(
      this.createSpaceRequest(),
      (result) => {
        this.object = result as TSpace;
        AccessUtils.fixSpace(this.object);

        this.slots = this.createSlots(this.object);
        // let mins = Thinkdesks.BOOK_MINS;

        // this.slots = [];
        // for (
        //   let i = this.object.bookMinSlots;
        //   i <= this.object.bookMaxSlots;
        //   i += this.object.bookSlotIncrement
        // ) {
        //   let m = mins * i;
        //   this.slots.push({
        //     slots: i,
        //     value: m,
        //     name: `${m} mins`,
        //     active: false,
        //   });
        // }
        let now = moment.tz(date_utils.tz).startOf('day');
        this.startDate = now.clone();
        this.endDate = now.clone().add(this.object.bookDays - 1, 'day');

        this.initObject();
        this.availability(this.selected);
      },
      null,
      (err) => {
        this.router.navigate(['/notfound']);
      }
    );
    let d = moment().tz(date_utils.tz);
  }

  override availability(d: moment.Moment) {
    d = d.clone();
    WebUtils.web_result(
      this.createSpaceAvailavility(
        d,
        d.clone().add(Thinkdesks.BOOK_DAYS, 'day')
      ),
      // this.http.post(`/api/public/space/${this.id}/availability`, {
      //   start: d,
      //   end: d.clone().add(Thinkdesks.BOOK_DAYS, 'day'),
      // }),
      (result) => {
        this.availabilityManager = new BookingAvailabilityManager();
        this.availabilityManager.load(d, result);
        this.updateTime();
      }
    );
  }

  override checkTime(date: moment.Moment, time: TimeAtom) {
    return this.availabilityManager.checkTime(date, time);
  }

  getQuery() {
    if (!this.form.valid) return utils.validateAllFormFields(this.form);

    let value = Object.assign({}, this.form.value);
    let date = this.selected;
    value.startTime = value.startTime.value;
    value.duration = value.duration.value;

    let time = TimeAtom.parse(value.startTime);
    let startTime = time.moment(date);
    let endTime = startTime.clone().add(value.duration, 'minute');

    let query = {
      startTime,
      endTime,
      type: TBookingType.space,
      space: WebUtils.objName(this.object),
      remarks: value.remarks,
    };
    return query as any;
  }

  proceed() {
    let query = this.getQuery();
    if (!query) return;

    WebUtils.web_result(
      this.createSpaceBookingRequest(query),

      (result) => {
        let o = result as TBooking;
        AccessUtils.fixBooking(o);
        this.afterBooking(o);
      },
      this.snackBar
    );
  }
  override afterBooking(o: TBooking) {
    this.router.navigate([`/my/booking/${o.id}`]);
  }

  proceedManage(dto: any, cb: (o: TBooking) => void) {
    let query = this.getQuery();
    if (!query) return;
    Object.assign(query, dto);

    WebUtils.web_result(
      this.createSpaceManageBookingRequest(query),

      (result) => {
        AccessUtils.fixBooking(result);
        cb(result);
      },
      this.snackBar
    );
  }
}
