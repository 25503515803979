import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollContainerComponent } from './scroll.container.component';

@NgModule({
    imports: [
        CommonModule,
        ScrollContainerComponent
    ],
    exports: [
        ScrollContainerComponent,
    ],
    providers: []
})

export class VizScrollContainerModule { }
