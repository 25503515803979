<div style="display:flex">
   <div class="toggle">
      <div class="off" [class.selected]="!checked" (click)="onModelChange(false)">
         {{offText}}
      </div>
      <div class="on" [class.selected]="checked" (click)="onModelChange(true)">
         {{onText}}
      </div>

   </div>
</div>