import { Injectable, Optional, SkipSelf } from '@angular/core';

import { Subject } from 'rxjs';
import { LoaderState } from './loader';

@Injectable({
   providedIn: 'root'
})
export class LoaderService {

   private loaderSubject = new Subject<LoaderState>();

   loaderState = this.loaderSubject.asObservable();

   static a = 1;
   count = 0;
   id = LoaderService.a++;
   parent: LoaderService;
   constructor(@Optional() @SkipSelf() loader: LoaderService) {
      this.parent = loader;
      if (!loader)
         console.log('LoaderService')
   }

   show() {
      if (this.parent) {
         this.parent.show();
      } else {
         this.count++;
         this.loaderSubject.next(<LoaderState>{ show: true, count: this.count });
      }
   }

   hide() {
      if (this.parent) {
         this.parent.hide();
      } else {
         this.count--;
         this.loaderSubject.next(<LoaderState>{ show: false, count: this.count });
      }
   }
}
