
export class TimeZoneName {
    id: string;
    name: string;
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static normalize(name: string) {
        if (!name) return null;
        let s = name.toLowerCase();
        let c = TIMEZONE_NAMES.find((c) => {
            return c.id.toLowerCase() == s
        })
        if (c) return c.id;
        return s;
    }
}



export const TIMEZONE_NAMES = [
    new TimeZoneName('US/Samoa', '(GMT-11 , 00) American Samoa'),
    //new TimeZoneName('International Date Line West', '(GMT-11 , 00) International Date Line West'),
    new TimeZoneName('Pacific/Midway', '(GMT-11 , 00) Midway Island'),
    new TimeZoneName('US/Hawaii', '(GMT-10 , 00) Hawaii'),
    new TimeZoneName('US/Alaska', '(GMT-09 , 00) Alaska'),
    new TimeZoneName('Canada/Pacific', '(GMT-08 , 00) Pacific Time (US &amp; Canada)'),
    new TimeZoneName('America/Tijuana', '(GMT-08 , 00) Tijuana'),
    new TimeZoneName('US/Arizona', '(GMT-07 , 00) Arizona'),
    new TimeZoneName('America/Chihuahua', '(GMT-07 , 00) Chihuahua'),
    new TimeZoneName('America/Mazatlan', '(GMT-07 , 00) Mazatlan'),
    new TimeZoneName('US/Mountain', '(GMT-07 , 00) Mountain Time (US &amp; Canada)'),
    new TimeZoneName('US/Central', '(GMT-06 , 00) Central America'),
    new TimeZoneName('Canada/Central', '(GMT-06 , 00) Central Time (US &amp; Canada)'),
    new TimeZoneName('America/Mexico_City', '(GMT-06 , 00) Guadalajara'),
    new TimeZoneName('Mexico/General', '(GMT-06 , 00) Mexico City'),
    new TimeZoneName('America/Monterrey', '(GMT-06 , 00) Monterrey'),
    new TimeZoneName('Canada/Saskatchewan', '(GMT-06 , 00) Saskatchewan'),
    new TimeZoneName('America/Bogota', '(GMT-05 , 00) Bogota'),
    new TimeZoneName('Canada/Eastern', '(GMT-05 , 00) Eastern Time (US &amp; Canada)'),
    new TimeZoneName('US/East-Indiana', '(GMT-05 , 00) Indiana (East)'),
    new TimeZoneName('America/Lima', '(GMT-05 , 00) Lima'),
    new TimeZoneName('America/Guayaquil', '(GMT-05 , 00) Quito'),
    new TimeZoneName('Canada/Atlantic', '(GMT-04 , 00) Atlantic Time (Canada)'),
    new TimeZoneName('America/Caracas', '(GMT-04 , 30) Caracas'),
    //new TimeZoneName('Georgetown', '(GMT-04 , 00) Georgetown'),
    //new TimeZoneName('La Paz', '(GMT-04 , 00) La Paz'),
    new TimeZoneName('America/Santiago', '(GMT-04 , 00) Santiago'),
    new TimeZoneName('Canada/Newfoundland', '(GMT-03 , 30) Newfoundland'),
    new TimeZoneName('America/Sao_Paulo', '(GMT-03 , 00) Brasilia'),
    new TimeZoneName('America/Argentina/Buenos_Aires', '(GMT-03 , 00) Buenos Aires'),
    //new TimeZoneName('Greenland', '(GMT-03 , 00) Greenland'),
    new TimeZoneName('America/Montevideo', '(GMT-03 , 00) Montevideo'),
    //new TimeZoneName('Mid-Atlantic', '(GMT-02 , 00) Mid-Atlantic'),
    new TimeZoneName('Atlantic/Azores', '(GMT-01 , 00) Azores'),
    new TimeZoneName('Atlantic/Cape_Verde', '(GMT-01 , 00) Cape Verde Is.'),
    new TimeZoneName('Europe/London', '(GMT+00 , 00) London, Edinburgh'),
    new TimeZoneName('Europe/Lisbon', '(GMT+00 , 00) Lisbon'),
    new TimeZoneName('Africa/Monrovia', '(GMT+00 , 00) Monrovia'),
    new TimeZoneName('Africa/Casablanca', '(GMT+00 , 00) Casablanca'),
    new TimeZoneName('Etc/UTC', '(GMT+00 , 00) UTC'),
    new TimeZoneName('Europe/Amsterdam', '(GMT+01 , 00) Amsterdam'),
    new TimeZoneName('Europe/Belgrade', '(GMT+01 , 00) Belgrade'),
    new TimeZoneName('Europe/Berlin', '(GMT+01 , 00) Berlin'),
    new TimeZoneName('Europe/Zurich', '(GMT+01 , 00) Bern'),
    new TimeZoneName('Europe/Bratislava', '(GMT+01 , 00) Bratislava'),
    new TimeZoneName('Europe/Brussels', '(GMT+01 , 00) Brussels'),
    new TimeZoneName('Europe/Budapest', '(GMT+01 , 00) Budapest'),
    new TimeZoneName('Europe/Copenhagen', '(GMT+01 , 00) Copenhagen'),
    new TimeZoneName('Europe/Dublin', '(GMT+01 , 00) Dublin'),
    new TimeZoneName('Europe/Berlin', '(GMT+01 , 00) Europe/Berlin'),
    new TimeZoneName('Europe/Ljubljana', '(GMT+01 , 00) Ljubljana'),
    new TimeZoneName('Europe/Madrid', '(GMT+01 , 00) Madrid'),
    new TimeZoneName('Europe/Paris', '(GMT+01 , 00) Paris'),
    new TimeZoneName('Europe/Prague', '(GMT+01 , 00) Prague'),
    new TimeZoneName('Europe/Rome', '(GMT+01 , 00) Rome'),
    new TimeZoneName('Europe/Sarajevo', '(GMT+01 , 00) Sarajevo'),
    new TimeZoneName('Europe/Skopje', '(GMT+01 , 00) Skopje'),
    new TimeZoneName('Europe/Stockholm', '(GMT+01 , 00) Stockholm'),
    new TimeZoneName('Europe/Vienna', '(GMT+01 , 00) Vienna'),
    new TimeZoneName('Europe/Warsaw', '(GMT+01 , 00) Warsaw'),
    //new TimeZoneName('West Central Africa', '(GMT+01 , 00) West Central Africa'),
    new TimeZoneName('Europe/Zagreb', '(GMT+01 , 00) Zagreb'),
    new TimeZoneName('Europe/Zurich', '(GMT+01 , 00) Zurich'),
    new TimeZoneName('Europe/Athens', '(GMT+02 , 00) Athens'),
    new TimeZoneName('Europe/Bucharest', '(GMT+02 , 00) Bucharest'),
    new TimeZoneName('Africa/Cairo', '(GMT+02 , 00) Cairo'),
    new TimeZoneName('Africa/Harare', '(GMT+02 , 00) Harare'),
    new TimeZoneName('Europe/Helsinki', '(GMT+02 , 00) Helsinki'),
    new TimeZoneName('Asia/Jerusalem', '(GMT+02 , 00) Jerusalem'),
    //new TimeZoneName('Kaliningrad', '(GMT+02 , 00) Kaliningrad'), wrong time zone
    //new TimeZoneName('Kyiv', '(GMT+02 , 00) Kyiv'),
    new TimeZoneName('Africa/Johannesburg', '(GMT+02 , 00) Pretoria'),
    new TimeZoneName('Europe/Riga', '(GMT+02 , 00) Riga'),
    new TimeZoneName('Europe/Sofia', '(GMT+02 , 00) Sofia'),
    new TimeZoneName('Europe/Tallinn', '(GMT+02 , 00) Tallinn'),
    new TimeZoneName('Europe/Vilnius', '(GMT+02 , 00) Vilnius'),
    new TimeZoneName('Europe/Istanbul', '(GMT+02 , 00) Istanbul'),
    new TimeZoneName('Asia/Baghdad', '(GMT+03 , 00) Baghdad'),
    new TimeZoneName('Asia/Kuwait', '(GMT+03 , 00) Kuwait'),
    new TimeZoneName('Europe/Minsk', '(GMT+03 , 00) Minsk'),
    new TimeZoneName('Africa/Nairobi', '(GMT+03 , 00) Nairobi'),
    new TimeZoneName('Asia/Riyadh', '(GMT+03 , 00) Riyadh'),
    new TimeZoneName('Asia/Tehran', '(GMT+03 , 30) Tehran'),
    new TimeZoneName('Asia/Dubai', '(GMT+04 , 00) Abu Dhabi'),
    new TimeZoneName('Europe/Moscow', '(GMT+04 , 00) Moscow, St. Petersburg'),
    new TimeZoneName('Asia/Baku', '(GMT+04 , 00) Baku'),
    new TimeZoneName('Asia/Muscat', '(GMT+04 , 00) Muscat'),
    new TimeZoneName('Europe/Samara', '(GMT+04 , 00) Samara'),
    new TimeZoneName('Asia/Tbilisi', '(GMT+04 , 00) Tbilisi'),
    new TimeZoneName('Europe/Volgograd', '(GMT+04 , 00) Volgograd'),
    new TimeZoneName('Asia/Yerevan', '(GMT+04 , 00) Yerevan'),
    new TimeZoneName('Asia/Kabul', '(GMT+04 , 30) Kabul'),
    //new TimeZoneName('Ekaterinburg', '(GMT+05 , 00) Ekaterinburg'),
    //new TimeZoneName('Islamabad', '(GMT+05 , 00) Islamabad'),
    new TimeZoneName('Asia/Karachi', '(GMT+05 , 00) Karachi'),
    new TimeZoneName('Asia/Tashkent', '(GMT+05 , 00) Tashkent'),
    //new TimeZoneName('Chennai', '(GMT+05 , 30) Chennai'),
    new TimeZoneName('Asia/Kolkata', '(GMT+05 , 30) Kolkata'),
    new TimeZoneName('Asia/Kolkata', '(GMT+05 , 30) New Delhi'),
    new TimeZoneName('Asia/Kolkata', '(GMT+05 , 30) Mumbai'),
    new TimeZoneName('Asia/Colombo', '(GMT+05 , 30) Sri Jayawardenepura'),
    new TimeZoneName('Asia/Kathmandu', '(GMT+05 , 45) Kathmandu'),
    new TimeZoneName('Asia/Almaty', '(GMT+06 , 00) Almaty'),
    new TimeZoneName('Asia/Dhaka', '(GMT+06 , 00) Dhaka'),
    new TimeZoneName('Asia/Urumqi', '(GMT+06 , 00) Urumqi'),
    new TimeZoneName('Asia/Rangoon', '(GMT+06 , 30) Rangoon'),
    new TimeZoneName('Asia/Bangkok', '(GMT+07 , 00) Bangkok'),
    new TimeZoneName('Asia/Bangkok', '(GMT+07 , 00) Hanoi'),
    new TimeZoneName('Asia/Jakarta', '(GMT+07 , 00) Astana'),
    new TimeZoneName('Asia/Jakarta', '(GMT+07 , 00) Jakarta'),
    new TimeZoneName('Asia/Krasnoyarsk', '(GMT+07 , 00) Krasnoyarsk'),
    new TimeZoneName('Asia/Novosibirsk', '(GMT+07 , 00) Novosibirsk'),
    new TimeZoneName('Asia/Shanghai', '(GMT+08 , 00) Beijing'),
    new TimeZoneName('Asia/Chongqing', '(GMT+08 , 00) Chongqing'),
    new TimeZoneName('Asia/Hong_Kong', '(GMT+08 , 00) Hong Kong'),
    //new TimeZoneName('Asia/Irkutsk', '(GMT+08 , 00) Irkutsk'), wrong time zone
    new TimeZoneName('Asia/Kuala_Lumpur', '(GMT+08 , 00) Kuala Lumpur'),
    new TimeZoneName('Australia/Perth', '(GMT+08 , 00) Perth'),
    new TimeZoneName('Asia/Singapore', '(GMT+08 , 00) Singapore'),
    new TimeZoneName('Asia/Taipei', '(GMT+08 , 00) Taipei'),
    new TimeZoneName('Asia/Ulaanbaatar', '(GMT+08 , 00) Ulaanbaatar'),
    new TimeZoneName('Asia/Seoul', '(GMT+09 , 00) Seoul'),
    new TimeZoneName('Asia/Tokyo', '(GMT+09 , 00) Tokyo, Osaka, Sapporo'),
    //new TimeZoneName('Asia/Yakutsk', '(GMT+09 , 00) Yakutsk'), wrong time zone
    new TimeZoneName('Australia/Adelaide', '(GMT+09 , 30) Adelaide'),
    new TimeZoneName('Australia/Darwin', '(GMT+09 , 30) Darwin'),
    new TimeZoneName('Australia/Brisbane', '(GMT+10 , 00) Brisbane'),
    new TimeZoneName('Australia/Canberra', '(GMT+10 , 00) Canberra'),
    new TimeZoneName('Pacific/Guam', '(GMT+10 , 00) Guam'),
    new TimeZoneName('Australia/Hobart', '(GMT+10 , 00) Hobart'),
    new TimeZoneName('Australia/Melbourne', '(GMT+10 , 00) Melbourne'),
    new TimeZoneName('Pacific/Port_Moresby', '(GMT+10 , 00) Port Moresby'),
    new TimeZoneName('Australia/Sydney', '(GMT+10 , 00) Sydney'),
    //new TimeZoneName('Asia/Vladivostok', '(GMT+10 , 00) Vladivostok'), wrong time zone
    //new TimeZoneName('Asia/Magadan', '(GMT+11 , 00) Magadan'), wrong time zone
    //new TimeZoneName('New Caledonia', '(GMT+11 , 00) New Caledonia'),
    //new TimeZoneName('Solomon Is.', '(GMT+11 , 00) Solomon Is.'),
    //new TimeZoneName('Srednekolymsk', '(GMT+11 , 00) Srednekolymsk'),
    new TimeZoneName('Pacific/Auckland', '(GMT+12 , 00) Auckland'),
    new TimeZoneName('Pacific/Fiji', '(GMT+12 , 00) Fiji'),
    new TimeZoneName('Asia/Kamchatka', '(GMT+12 , 00) Kamchatka'),
    //new TimeZoneName('Marshall Is.', '(GMT+12 , 00) Marshall Is.'),
    new TimeZoneName('Pacific/Auckland', '(GMT+12 , 00) Wellington'),
    new TimeZoneName('Pacific/Chatham', '(GMT+12 , 45) Chatham Is.'),
    new TimeZoneName('Pacific/Tongatapu', '(GMT + 13, 00) Nukualofa'),
    //new TimeZoneName('Samoa', '(GMT+13 , 00) Samoa'),
    //new TimeZoneName('Tokelau Is.', '(GMT+13 , 00) Tokelau Is.'),
];