import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
    selector: '[clickOutsidePopup]',
    standalone:true,
})
export class ClickOutsidePopupDirective {
    constructor(private _elementRef: ElementRef) {
    }

    @Output()
    public clickOutsidePopup = new EventEmitter();


    @Input()
    clickOutsideHold = false;
    first = true;

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement) {
        if (this.first) {
            this.first = false;
            return;
        }
        if (this.clickOutsideHold) return;
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutsidePopup.emit(null);
        }
    }
}