import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { SessionService } from '../session.service';
import { SpaceSearchListComponent } from './space.search.list.component';

@Component({
  selector: 'app-space-search',
  standalone: true,
  imports: [CommonModule, SpaceSearchListComponent],
  templateUrl: './space.search.page.html',
})
export class SpaceSearchPage {
  @HostBinding('class') css = 'max-scroll';
  constructor(private session: SessionService) { }
  ngOnInit() { }; _common = _common
};
