<section
  class="carousel-wrapper"
  #carouselContainer
  [ngStyle]="carouselWrapperStyle"
  (panstart)="onPanStart($event)"
  (panend)="onPanEnd($event)"
>
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items" class="carousel-item" [style.width.px]="_width" [style.height.px]="_height">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
    <ng-container *ngIf="wrap">
      <li *ngFor="let item of items" class="carousel-item" [style.width.px]="_width" [style.height.px]="_height">
        <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
      </li>
    </ng-container>
  </ul>
</section>
<div
  *ngIf="showControls && _showDots"
  class="tt_carousel_button showdots"
  [class.showdots]="showDots && _showDots"
  style="right: 0"
>
  <button (click)="next()" class="button icon_right">
    <fa-icon icon="chevron-right"></fa-icon>
  </button>
</div>
<div
  *ngIf="showControls && _showDots"
  class="tt_carousel_button"
  [class.showdots]="showDots && _showDots"
  style="left: 0"
>
  <button (click)="prev()" class="button icon_left">
    <fa-icon icon="chevron-left"></fa-icon>
  </button>
</div>
<div class="tt_carousel_dots" *ngIf="showDots && _showDots">
  <div
    *ngFor="let item of items; let i = index"
    class="dot"
    (click)="move(i)"
    [class.selected_dot]="i == currentSlide % items.length"
  ></div>
</div>
