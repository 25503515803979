import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { NgIf } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { ImageViewerService } from './image.viewer.service';

export interface IImageViewerSource {
   object: any; 
   numImages: number;
   curIndex: number;
   labelFunc?: (object: any, index: number) => string;
   pathFunc: (object: any, index: number) => string;
}


@Component({
    selector: 'app-image-viewer',
    templateUrl: './image.viewer.component.html',
    styleUrls: [
        './image.viewer.component.scss',
    ],
    standalone: true,
    imports: [NgIf, FontAwesomeModule]
})
export class ImageViewerComponent {
   // @HostBinding('class') css = '';

   source: IImageViewerSource;

   _showImage = false;
   scale = 1.0;
   offsetX = 0;
   offsetY = 0;

   subscription1: Subscription
   subscription2: Subscription
   constructor(
      private router: Router,
      private service: ImageViewerService,) {


   }

   @ViewChild('image') image: ElementRef;
   ngOnInit() {
      this.subscription1 = this.router.events.subscribe((result) => {
         this.closeImage();
      })
      this.subscription2 = this.service.state.subscribe((source) => {
         this.source = source;
         if (this.source) {
            this._showImage = true;
            // this.css = 'tt_image_viewer';
            // document.body.classList.add('tt_image_viewer_body');
            document.body.classList.add('noscroll');
         } else {
            this.closeImage();


         }

      });



   }

   ngOnDestroy() {
      this.subscription1.unsubscribe()
      this.subscription2.unsubscribe()
   }

   closeImage() {
      this._showImage = false;
      // this.css = '';

      // document.body.classList.remove('tt_image_viewer_body');
      document.body.classList.remove('noscroll');

   }
   prevImage() {
      if (this.source && this.source.curIndex > 0) {
         this.scale = 1
         this.source.curIndex--;
      }

   }
   nextImage() {
      if (this.source && this.source.curIndex < this.source.numImages - 1) {
         this.scale = 1
         this.source.curIndex++;
      }

   }

   // @HostBinding('style.top.px')
   offset = 0;
   @HostListener("window:scroll", ["$event"])
   onWindowScroll(event) {
      this.offset = window.pageYOffset;
   }
   @HostListener('document:keydown.escape', ['$event'])
   onKeydownHandler(event: KeyboardEvent) {
      this.closeImage();
   }


   _scale = 1;
   _offsetX = 0;
   _offsetY = 0;
   _origX = 0;
   _origY = 0;
   onPanStart(event:any) {
      this._origX = event.center.x;
      this._origY = event.center.y;
      this.onPan(event);
   }
   onPan(event:any) {
      this.offsetX += (event.center.x - this._origX);
      this.offsetY += (event.center.y - this._origY);

      this._origX = event.center.x;
      this._origY = event.center.y;

   }
   onPinchStart(event:any) {
      this._scale = this.scale;
      this.onPinch(event);
   }
   onPinch(event:any) {
      this.scale = this._scale * event.scale;
      if (this.scale >= 10)
         this.scale = 10;
      if (this.scale < 0.2)
         this.scale = 0.2;


   }
}
