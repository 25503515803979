import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebUtils } from "../utils";
import { ListFilterConfig } from "./list.filter";


@Injectable({ providedIn: 'root' })
export class ListFilterCache {


   cache: { [key: string]: ListFilterConfig };
   constructor(private http: HttpClient) {

   }
   load(object: string, cb?: (list: ListFilterConfig) => void) {
      if (this.cache) {
         let ff = this.cache[object];
         ff = ff || { filters: [] }
         this._filter(ff);
         if (cb) cb(this.cache[object])
         return Promise.resolve(this.cache[object])
      }
      return new Promise((resolve, reject) => {
         WebUtils.web_result(this.http.get('/api/account/filters'), (oo) => {

            this.cache = oo as any;
            let ff = this.cache[object]
            ff = ff || { filters: [] }
            this.cache[object] = ff;
            this._filter(ff)
            if (cb)
               cb(this.cache[object])
            resolve(this.cache[object])
         }, null, (err) => reject(err))

      })
   }
   _filter(list: ListFilterConfig) {
      list.filters = list.filters || [];

   }

}