<!-- @format -->

<!--<mat-toolbar style="display: flex">
  <span>Spaces</span>

  <div class="flex-auto"></div>
</mat-toolbar>-->
<div class="flex justify-center space-x-4 mb-4">
  <div class="search_bar spacer">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input
      class="w-full"
      matInput
      [(ngModel)]="filter"
      (keyup)="applyFilter($event.target)"
      placeholder="{{ _common.search }}"
      #_search
    />
    <fa-icon
      icon="times"
      matSuffix
      (click)="clearFilter(_search)"
      class="clearSearch"
    ></fa-icon>
  </div>

  <span class="spacer"></span>
  <span> </span>
</div>

<div
  *ngIf="!objects.data.length"
  class="text-center"
  style="margin-top: 10%"
>
  <div class="flex justify-center">
    <img src="/silone/silone_notfound.png" style="height: 100px" />
  </div>

  <div class="mt-4">{{ _common.no_result }}</div>
</div>
<app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  #scroll
>
  <div class="item_list">
    <div *ngFor="let o of objects.data">
      <div style="position: relative">
        <app-space-card [space]="o.space"></app-space-card>
        <div style="position: absolute; left: 10px; top: 10px">
          <!-- <fa-icon icon="heart" (click)="unlike(o)"></fa-icon> -->
          

          <div *ngIf="!unlike" (click)="unlike(o)"
            class="client_button btn_circle" 
          >
            <fa-icon [icon]="['far','heart']"></fa-icon>
          </div>
          <div
            class="client_button btn_circle" 
          >
            <fa-icon *ngIf="unlike" (click)="unlike(o)"
              icon="heart"
              style="color: red;"
            ></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-scroll-container>
