import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { VizCommonModule } from './common/common.module';
import { VizFormModule } from './common/components/form/form.module';
import { utils, WebUtils } from './common/utils';
import { SessionService } from './session.service';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    VizCommonModule,
    VizFormModule,
    ReactiveFormsModule,
    MatInputModule,
  ],

  templateUrl: './login.page.html',
})
export class LoginPage {
  form: FormGroup;
  _common = _common;
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private session: SessionService,
    private router: Router,
  ) {
    this.form = this.fb.group(
      {
        username: ['', Validators.required],
        password: ['', Validators.required],
        // password2: ['', Validators.required],
      },
      {
        // validators: [validateMatchPasswords],
      }
    );
  }
  ngOnInit() { }
  login() {
    if (!this.form.valid) {
      return utils.validateAllFormFields(this.form);
    }

    WebUtils.web_result(
      this.http.post('/login', this.form.value),
      (result) => { 
        this.session.refresh();
        // this.session.setUser(result.user);
        this.router.navigate([this.session.path('/')])
      },
      this.snackBar,
      (err) => {
        this.snackBar.open('電郵地址或密碼錯誤。');
      }
    );
  }
}
