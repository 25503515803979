import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { LoaderComponent } from './common/loader/loader.component';
import { FeaturedSpaceListPage } from './featured.space.list.page';
import { NearbyLocationListPage } from "./nearby.location.list";
import { RandomLocationListPage } from './random.location.list';
import { SessionService } from './session.service';
import { UpcomingBookingListPage } from './upcoming.booking.list.page';
import { CarouselWidget } from './user/carousel';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    RouterOutlet,
    ReactiveFormsModule,
    FormsModule,
    FeaturedSpaceListPage,
    MatInputModule,
    RouterLink,
    LoaderComponent,
    FontAwesomeModule,
    UpcomingBookingListPage,
    CommonModule,
    CarouselWidget,
    NearbyLocationListPage,
    RandomLocationListPage,
],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  @HostBinding('class') css = 'max-scroll';
  search = ''; _common = _common

  constructor(
    private http: HttpClient,
    private router: Router,
    public session: SessionService
  ) { }
  ngOnInit() {

  }
  goSearch() {
    if (!this.search.length) return;
    this.router.navigate([this.session.path('/space')], {
      queryParams: {
        search: this.search,
      },
    });
  }
}
