/** @format */

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NgFor } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VizFormModule } from '../form/form.module';

@Component({
  selector: 'app-tags-select-edit',
  templateUrl: './tags.select.edit.component.html',
  standalone: true,
  imports: [
    VizFormModule,
    NgFor,
    FontAwesomeModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
  ],
})
export class TagsSelectEditComponent implements OnInit {
  _common = _common;

  @Input()
  tags: string[] = [];

  @Input()
  values: string[] = [];

  @Input()
  title = _common.tags;

  @Output()
  updated = new EventEmitter<string[]>();
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input()
  allowAdd = false;

  @ViewChild('input') input: ElementRef;
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {}

  ngOnInit() {}

  removeTag(tag: string) {
    let index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.updated.next(this.tags);
    }
  }
  addTag(event: MatChipInputEvent) {
    if (!this.allowAdd) return;
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.tags.push(value.trim());

      this.updated.next(this.tags);
    }

    // Reset the input value
    if (input) input.value = '';
  }
  selected(event: MatAutocompleteSelectedEvent) {
    let value = event.option.viewValue;
    if (this.tags && this.tags.indexOf(value) < 0) {
      this.tags.push(value);
      this.updated.next(this.tags);
    }
    this.input.nativeElement.value = '';
  }
}
