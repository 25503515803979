import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SessionService } from '../session.service';
import { LockerSearchListComponent } from './locker.search.list.component';

@Component({
  selector: 'app-locker-search',
  standalone: true,
  imports: [CommonModule, LockerSearchListComponent],
  templateUrl: './locker.search.page.html',
})
export class LockerSearchPage {
  constructor(private session: SessionService) {}
  ngOnInit() {}
}
