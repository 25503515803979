<app-form-field appearance="fill" style="width:100%">
   <app-label>{{title}}</app-label>
   <mat-chip-grid #tagList [attr.aria-label]="_common.tags">
      <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
         {{tag.name}}
         <fa-icon icon="times" matChipRemove></fa-icon>
      </mat-chip-row>
      <input [placeholder]="_common.object_common.addTagInstruction" #input [matAutocomplete]="auto"
         [matChipInputFor]="tagList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      <!-- [matChipInputAddOnBlur]="true"
         (matChipInputTokenEnd)="addTag($event)" -->

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
         <mat-option *ngFor="let s of values" [value]="s">
            {{s.name}}
         </mat-option>
      </mat-autocomplete>
   </mat-chip-grid>
</app-form-field>