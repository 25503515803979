import { Component, Input } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { VizFormModule } from '../../common/components/form/form.module';
import { BookContainerBase } from '../space.book.container';
import { Bookable } from '../thinkdesks.entities';


@Component({
  selector: 'app-book-date-select-simple',
  standalone: true,
  imports: [
    VizFormModule,
    MatDatepickerModule,
    MatSelectModule,
  ],
  templateUrl: './book.date.select.simple.html',
})
export class BookDateSelectSimple {
  _common = _common;


  @Input()
  container: BookContainerBase<Bookable>;
  constructor() {}
  ngOnInit() {}
}
