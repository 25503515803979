import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MonthCalendarItem } from './month.calendar.component';






@Component({
   templateUrl: './month.calendar.date.dialog.html',
   standalone:true,
   imports: [
      CommonModule,
      FontAwesomeModule,
      MatDialogModule,

   ]

})
export class MonthCalendarDateDialogComponent {


   item: MonthCalendarItem;
   constructor(@Inject(MAT_DIALOG_DATA) public data: any,

      public dialogRef: MatDialogRef<MonthCalendarDateDialogComponent>) {



      this.item = this.data.item;


   }

   cancel() {
      this.dialogRef.close();
   }
   closeDialog() {
      this.dialogRef.close();
   }
}
