import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FilterText } from "./list.filter";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-filter-text",
  templateUrl: "./filter.text.component.html",
  // styles: [``],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, FontAwesomeModule, FormsModule],
})
export class FilterTextComponent implements OnInit {
   faTimes=faTimes
  _common = _common;

  @Input()
  filter = new FilterText();

  @Input()
  label = "";

  @Input()
  visible = true;

  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {}

  clear() {
    this.filter.v = "";
    this.refresh();
  }
  refresh() {
    this.change.next(void 0);
  }
}
