<!-- @format -->

<!--<mat-toolbar style="display: flex">
  <span>Spaces</span>

  <div class="flex-auto"></div>
</mat-toolbar>-->
<div class="flex justify-center space-x-4 mb-4">
  <div class="search_bar spacer">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input class="w-full" matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target)"
      placeholder="{{ _common.search }}" #_search />
    <fa-icon icon="times" matSuffix (click)="clearFilter(_search)" class="clearSearch"></fa-icon>
  </div>
  <div class="client_button btn_circle" (click)="filterObject.openFilter()">
    <fa-icon icon="sliders-h"></fa-icon>
  </div>

  <span class="spacer"></span>
  <span> </span>
</div>

<div *ngIf="!objects.data.length && (filter && filter!='')" class="text-center" style="margin-top: 10%;">
  <div class="flex justify-center">
    <img src="/silone/silone_notfound.png" style="height: 100px;" />
  </div>

  <div class="mt-4">{{ _common.no_result }}</div>
</div>
<app-scroll-container [more]="hasMore()" (scrolled)="handleScroll($event)" #scroll>
  <div class="item_list">
    <div *ngFor="let o of objects.data">
      <app-space-card [space]="o"></app-space-card>
    </div>
  </div>
</app-scroll-container>

<!--Filter Overlay-->
<div *ngIf="filterObject.showFilter" class="ctlFilter listFilter" style="z-index: 100;"
  (clickOutsidePopup)="filterObject.closeFilter()">
  <div class="header">
    <div class="title">
      <fa-icon icon="sliders-h" style="margin-right: 5px"></fa-icon>
      {{_common.Filter.filter}}
    </div>
    <div class="spacer"></div>
    <div (click)="filterObject.closeFilter()" style="font-size: 1.5em; cursor: pointer">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div class="body p-4">
    <div>

      <app-form-field class="w-full">
        <app-label>{{_common.Filter.cat_name}}</app-label>
        <mat-select [(ngModel)]="type" [compareWith]="compareWith" (selectionChange)="refresh()">
          <mat-option [value]="ALL">{{_common.Filter.cat_all}}</mat-option>
          <mat-option *ngFor="let o of types" [value]="o">
            {{ o.name }}
          </mat-option>
        </mat-select>
      </app-form-field>

    </div>
    <hr>

    <div class="space-y-2">
      <div>
        <app-form-field class="w-full">
          <app-label>{{_common.Area}}</app-label>
          <mat-select [(ngModel)]="area" (selectionChange)="updateArea()">
            <mat-option value="">{{_common.Filter.all}}</mat-option>
            <mat-option value="HK">{{_common.HK}}</mat-option>
            <mat-option value="KLN">{{_common.KLN}}</mat-option>
            <mat-option value="NT">{{_common.NT}}</mat-option>
          </mat-select>
        </app-form-field>
      </div>
      <div>
        <app-form-field class="w-full">
          <mat-select [(ngModel)]="district" (selectionChange)="refresh()">
            <mat-option value="">{{_common.Filter.all}}</mat-option>
            <ng-container *ngIf="area == 'HK'">
              <mat-option value="HK_CS">{{_common.HK_CS}}</mat-option>
              <mat-option value="HK_WC">{{_common.HK_WC}}</mat-option>
              <mat-option value="HK_E">{{_common.HK_E}}</mat-option>
              <mat-option value="HK_S">{{_common.HK_S}}</mat-option>
            </ng-container>
            <ng-container *ngIf="area == 'KLN'">
              <mat-option value="KLN_YTM">{{_common.KLN_YTM}}</mat-option>
              <mat-option value="KLN_SSP">{{_common.KLN_SSP}}</mat-option>
              <mat-option value="KLN_KC">{{_common.KLN_KC}}</mat-option>
              <mat-option value="KLN_WTS">{{_common.KLN_WTS}}</mat-option>
              <mat-option value="KLN_KT">{{_common.KLN_KT}}</mat-option>
            </ng-container>
            <ng-container *ngIf="area == 'NT'">
              <mat-option value="NT_KT">{{_common.NT_KT}}</mat-option>
              <mat-option value="NT_TW">{{_common.NT_TW}}</mat-option>
              <mat-option value="NT_TM">{{_common.NT_TM}}</mat-option>
              <mat-option value="NT_YL">{{_common.NT_YL}}</mat-option>
              <mat-option value="NT_N">{{_common.NT_N}}</mat-option>
              <mat-option value="NT_TP">{{_common.NT_TP}}</mat-option>
              <mat-option value="NT_ST">{{_common.NT_ST}}</mat-option>
              <mat-option value="NT_SK">{{_common.NT_SK}}</mat-option>
              <mat-option value="NT_Islands">{{_common.NT_Islands}}</mat-option>
            </ng-container>
          </mat-select>
        </app-form-field>

      </div>


    </div>
    <hr>

    <div>
      <app-form-field class="w-full">
        <app-label>{{_common.Filter.capacity}}</app-label>
        <mat-select [(ngModel)]="capacity" (selectionChange)="refresh()">
          <mat-option [value]="allCapacity">{{_common.Filter.noSpecify}}</mat-option>
          <mat-option *ngFor="let o of capacities" [value]="o">
            <ng-container *ngIf="o.min && o.max">
              {{ o.min }}-{{ o.max }}
            </ng-container>
            <ng-container *ngIf="o.min && !o.max">
              &gt;={{ o.min }}
            </ng-container>
          </mat-option>
        </mat-select>
      </app-form-field>

    </div>
    <hr>
    <div>
      <app-form-field class="w-full">
        <app-label>{{_common.Filter.org_name}}</app-label>
        <mat-select [(ngModel)]="organization" [compareWith]="compareWith" (selectionChange)="updateOrganization()">
          <mat-option [value]="ALL">{{_common.Filter.noSpecify}}</mat-option>

          <mat-option *ngFor="let o of organizations" [value]="o">
            {{ o.name }}
          </mat-option>
        </mat-select>
      </app-form-field>

    </div>
    <hr>
    <div>
      <app-form-field class="w-full">
        <app-label>{{_common.Filter.center_name}}</app-label>
        <mat-select [(ngModel)]="location" [compareWith]="compareWith" (selectionChange)="refresh()">
          <mat-option [value]="ALL">{{_common.Filter.noSpecify}}</mat-option>

          <mat-option *ngFor="let o of locations" [value]="o">
            {{ o.name }}
          </mat-option>
        </mat-select>
      </app-form-field>

    </div>


  </div>
  <div class="buttons">
    <div (click)="clear()" class="clear_button">
      {{_common.Filter.clear}}
    </div>
  </div>
</div>