/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import { PasswordDialogComponent } from '../../common/dialogs/password.dialog';
import { DELETED, WebUtils, utils } from '../../common/utils';
import { AccessUtils, TOrganization, TUser } from '../../core/entities';
import {
  PASSWORD_LENGTH,
  PASSWORD_PATTERN,
  SessionService,
} from '../../session.service';
import { OrganizationSearchDialog } from '../organization/organization.search.dialog';

@Component({
  templateUrl: './user.edit.dialog.html',
  standalone: true,
  imports: [VizFormModule, MatTabsModule, MatInputModule, MatDialogModule],
})
export class UserEditDialogComponent {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TUser;

  organizations: TOrganization[] = [];

  api = '/api/user';

  accessCode: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserEditDialogComponent>
  ) {
    this.objectId = data.objectId;

    this.form = this.fb.group({
      name: ['', Validators.required],

      email: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TUser;

          AccessUtils.fixUser(this.object);

          this.organizations = this.object.organizations;
          utils.fill_form_values(this.object, this.form);

          this.updateForm();
        }
      );
    }
  }

  updateForm() {
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);

    query.organizations = this.organizations.map((c) => WebUtils.objName(c));
    if (this.objectId) {
      WebUtils.web_result(
        this.http.put(`${this.api}/${this.objectId}`, query),
        async (result) => {
          this.object = result as TUser;
          AccessUtils.fixUser(this.object);

          this.dialogRef.close(this.object);
        }
      );
    } else {
      WebUtils.web_result(
        this.http.post(`${this.api}`, query),
        async (result) => {
          this.object = result as TUser;
          AccessUtils.fixUser(this.object);

          this.dialogRef.close(this.object);
        }
      );
    }
  }

  removeOrganization(index: number) {
    this.organizations.splice(index, 1);
  }
  addOrganization() {
    let ref = this.dialog.open(OrganizationSearchDialog, {
      data: {
        multiple: true,
      },
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        for (let g of result) {
          if (!this.organizations.find((c) => c.id == g.id)) {
            this.organizations.push(g);
          }
        }
      }
    });
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }

  changePassword() {
    const dialogRef = this.dialog.open(PasswordDialogComponent, {
      data: {
        passwordLength: PASSWORD_LENGTH,
        passwordPattern: PASSWORD_PATTERN,
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        WebUtils.web_result(
          this.http.post(`${this.api}/${this.object.id}/change_password`, {
            password: value,
          }),
          () => {
            this.snackBar.open(_common.settings.update.PasswordUpdated);
          },
          this.snackBar
        );
      }
    });
  }
}
