import { SafeUrl } from '@angular/platform-browser';
import { FileItem, FileUploader } from 'ng2-file-upload';
import * as short_uuid from 'short-uuid';
import { v4 as uuidv4 } from 'uuid';
import { IResizeOptions, ImageUtils } from '../../common/images/image.resize';
import { WebUtils } from '../../common/utils';
export interface LImageUploadItem {
  id: number;
  name: string;

  mimeType?: string;

  // for other parts of the system
  uuid?: string;
  path?: string;

  width?: number;
  height?: number;

  // for editing
  _removed: boolean;
  _file: File;
  _dataUrl?: string;
  _fileUrl?: string; //file url
  _fileSafeUrl?: SafeUrl; //file url

  _blob?: Blob;
  _thumbnail?: Blob;
}

export class LImageUploadContainer {
  imageNextId = 1;
  images: LImageUploadItem[] = [];

  clear() {
    this.imageNextId = 1;
    this.images = [];
  }
  removeAll() {
    this.images = this.images.filter((o) => !o._blob && !o._file);
    this.images.forEach((o) => (o._removed = true));
  }

  hasNew() {
    let created = this.images.filter((o) => o._file);
    return !created.length;
  }
  load(
    source: {
      numImages: number;
      imageIds: number[];
      imageFiles?: File[];
      imageUuids?: string[];
      names?: string[];
      mimeTypes?: string[];
      imageSizes?: number[];
    },
    getPath: (index: number, imageId: number) => string
  ) {
    this.images = [];
    this.imageNextId = 1;

    let _imageIds: number[] = source.imageIds.map((o) => {
      if (typeof o == 'string') return parseInt(o);
      return o as number;
    });
    for (let i = 0; i < source.numImages; i++) {
      let id = _imageIds[i];
      if (typeof id === 'string') id = null;
      if (isNaN(id)) id = null;
      const item: LImageUploadItem = {
        id,
        uuid: source.imageUuids ? source.imageUuids[i] : null,
        name: source.names ? source.names[i] : 'filename',
        mimeType: source.mimeTypes ? source.mimeTypes[i] : null,
        path: getPath(i, _imageIds[i]),
        width: source.imageSizes ? source.imageSizes[i * 2] : null,
        height: source.imageSizes ? source.imageSizes[i * 2 + 1] : null,
        _removed: false,
        _file: source.imageFiles ? source.imageFiles[i] : null,
        _dataUrl: null,
      };
      this.images.push(item);
      if (item._file) this.loadImage(item);

      if (source.imageIds[i] > this.imageNextId)
        this.imageNextId = source.imageIds[i] + 1;
    }
  }
  addItem(item: LImageUploadItem) {
    this.images.push(item);
  }
  nextId() {
    let id = 1;

    for (const o of this.images) {
      if (o.id >= id) id = o.id + 1;
    }
    let index = id;
    if (this.imageNextId > index) index = this.imageNextId;
    this.imageNextId = index + 1;
    return index;
  }
  summary() {
    return {
      numImages: this.numImages(),
      imageIds: this.images.filter((o) => !o._removed).map((o) => o.id),
      imageUuids: this.images.filter((o) => !o._removed).map((o) => o.uuid),
    };
  }

  moldImagePaths() {
    this.images
      .filter((o) => o._file)
      .forEach((c) => {
        let index = c.name.lastIndexOf('.');
        let name = c.name;
        let ext = '';
        if (index >= 0) {
          name = c.name.substring(0, index);
          ext = c.name.substring(index);
        }
        if (name.length > 150) name = name.substring(0, 150);
        c.name = `${short_uuid.generate()}${ext.toLowerCase()}`;
      });
  }
  getImageIds() {
    return this.images.filter((o) => !o._removed).map((o) => o.id);
  }
  getImageNames() {
    return this.images.filter((o) => !o._removed).map((o) => o.name);
  }
  getImageFiles() {
    return this.images.filter((o) => !o._removed).map((o) => o._file);
  }
  getImageMimeTypes() {
    return this.images.filter((o) => !o._removed).map((o) => o.mimeType);
  }
  numImages() {
    let count = 0;
    for (const i of this.images) {
      if (!i._removed) count++;
    }
    return count;
  }
  removeImage(i: LImageUploadItem) {
    if (i._file) {
      const index = this.images.indexOf(i);
      if (index >= 0) this.images.splice(index, 1);
    } else {
      i._removed = true;
    }
  }
  static normalizeName(s: string) {
    s = WebUtils.clean(s);
    s = s.toLocaleLowerCase();

    let index = s.lastIndexOf('.');
    let ext = '';
    if (index >= 0) {
      ext = s.substring(index);
      s = s.substring(0, index);
    }
    s = s.replace(/[&\/\\#,+()$~\-%.'":*?<>{} ]/g, '_');
    s += ext;
    let encoded = new TextEncoder().encode(s);

    if (encoded.length > 230) {
      let index = s.lastIndexOf('.');
      if (index > 0) {
        s = s.substring(0, 50) + s.substring(index);
      } else {
        s = s.substring(0, 50);
      }
    }
    return s;
  }
  addImage(item: FileItem, cb?: () => void, force?: boolean) {
    const i: LImageUploadItem = {
      id: this.nextId(),
      uuid: uuidv4(),
      name: LImageUploadContainer.normalizeName(item.file.name),
      mimeType: item.file.type,
      _removed: false,
      _file: item._file,
    };

    const reader = new FileReader();

    reader.onloadend = () => {
      i._dataUrl = reader.result as string;
      this.images.push(i);

      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        i.width = loadedImage.width;
        i.height = loadedImage.height;
      };
      img.src = reader.result as string;

      if (cb) cb();
    };
    if (!force && item.file.name.toLowerCase().endsWith('mp4')) {
      // is a video
      i._dataUrl = '/images/novideo.png';
      this.images.push(i);
      if (cb) cb();
    } else {
      reader.readAsDataURL(item._file);
    }
  }

  loadImage(item: LImageUploadItem) {
    const reader = new FileReader();

    reader.onloadend = () => {
      item._dataUrl = reader.result as string;

      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        item.width = loadedImage.width;
        item.height = loadedImage.height;
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(item._file);
  }
  addFile(item: FileItem) {
    const i: LImageUploadItem = {
      id: this.nextId(),
      uuid: uuidv4(),
      name: item.file.name,
      mimeType: item.file.type,
      _removed: false,
      _file: item._file,
      _dataUrl: null,
    };
    this.images.push(i);
  }

  async addVideo(item: FileItem) {
    let url = URL.createObjectURL(item._file);

    var result = await LImageUploadContainer.getVideoDimensionsOf(url);
    if (!result) return null;

    const i: LImageUploadItem = {
      id: this.nextId(),
      uuid: uuidv4(),
      name: item.file.name,

      mimeType: item.file.type,
      _removed: false,
      _file: item._file,
      width: result.width,
      height: result.height,
      _dataUrl: null,
      _fileUrl: url,
    };
    this.images.push(i);
    return i;
  }

  static getVideoDimensionsOf(url): Promise<{ height: number; width: number }> {
    return new Promise(function (resolve) {
      // create the video element
      let video = document.createElement('video');

      // place a listener on it
      video.addEventListener(
        'loadedmetadata',
        function () {
          // retrieve dimensions
          let height = this.videoHeight;
          let width = this.videoWidth;
          // send back result
          resolve({
            height: height,
            width: width,
          });
        },
        false
      );
      video.addEventListener(
        'error',
        function () {
          resolve(null);
        },
        false
      );
      // start download meta-datas
      video.src = url;
    });
  }
  async resize(opts: IResizeOptions) {
    for (let i of this.images) {
      await LImageUploadContainer.resizeImage(i, opts);
    }
  }
  async thumbnail(opts: IResizeOptions) {
    for (let i of this.images) {
      await LImageUploadContainer.thumbnailImage(i, opts);
    }
  }
  hasBaseDropZoneOver: boolean;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  static async resizeImage(i: LImageUploadItem, opts: IResizeOptions) {
    if (!i || !i._file) return;
    i._blob = (await ImageUtils.resizeImage(
      Object.assign({ file: i._file }, opts)
    )) as Blob;
  }
  static async thumbnailImage(i: LImageUploadItem, opts: IResizeOptions) {
    if (!i || !i._file) return;
    i._thumbnail = (await ImageUtils.resizeCropCoverImage(
      Object.assign({ file: i._file }, opts)
    )) as Blob;
  }
}

export class LSingleImageUploadContainer {
  image: LImageUploadItem;

  uploader: FileUploader;

  constructor() {
    this.uploader = new FileUploader({
      url: 'test',
      maxFileSize: 5 * 1024 * 1024,
      allowedFileType: ['image'],
    });
    this.uploader.onAfterAddingFile = (item: FileItem) => {
      this.addImage(item);
    };
  }
  addImage(item: FileItem) {
    const reader = new FileReader();

    reader.onloadend = () => {
      const i: LImageUploadItem = {
        id: 0,
        uuid: uuidv4(),
        name: item.file.name,
        _removed: false,
        _file: item._file,
        _dataUrl: reader.result as string,
      };
      this.image = i;

      const img = new Image();
      img.onload = function (event) {
        const loadedImage: any = event.currentTarget;
        i.width = loadedImage.width;
        i.height = loadedImage.height;
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(item._file);
  }

  async resize(opts: IResizeOptions) {
    LImageUploadContainer.resizeImage(this.image, opts);
  }
  async thumbnail(opts: IResizeOptions) {
    LImageUploadContainer.thumbnailImage(this.image, opts);
  }
  hasBaseDropZoneOver: boolean;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
}
