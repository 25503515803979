/** @format */

import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppFormField, AppLabel } from '../components/form/form.field';
import { DELETED, WebUtils, utils } from '../utils';

@Component({
  templateUrl: './custom.list.filter.dialog.html',
  standalone: true,
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    ReactiveFormsModule,
    AppFormField,
    AppLabel,
    MatInputModule,
    NgIf,
  ],
})
export class CustomListFilterDialogComponent implements OnInit {
  _common = _common;
  form: UntypedFormGroup;

  object: string;
  filter: any;
  id: number;

  locale: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CustomListFilterDialogComponent>,
  ) {
    this.object = data.object;
    this.filter = data.filter;
    this.locale = data.locale || 'en';
    this.id = data.id;
    this.form = this.fb.group({
      name: ['', Validators.required],
    });

    let name = this.filter.name;

    this.filter.name_lang = this.filter.name_lang || {};
    name = this.filter.name_lang[this.locale] || this.filter.name;

    let query = {
      name,
    };
    utils.fill_form_values(query, this.form);
  }

  ngOnInit(): void {}

  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let cc = Object.assign({}, this.filter);
    Object.assign(cc, this.form.value);

    cc.name_lang[this.locale] = cc.name;

    let query = {
      id: this.id,
      filter: cc,
      object: this.object,
    };

    WebUtils.web_result(
      this.http.post(`/api/account/filters`, query),
      (result) => {
        this.dialogRef.close(result);
      },
    );
  }
  remove() {
    let query = {
      id: this.id,
      object: this.object,
    };
    WebUtils.web_result(
      this.http.post(`/api/account/filters`, query),
      (result) => {
        this.dialogRef.close(DELETED);
      },
    );
  }
  useGuest() {
    this.dialogRef.close([null]);
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
