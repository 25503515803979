import { Component, Input, OnInit } from '@angular/core';
import { FilterMultiComponent } from './filter.multi.component';
import { NgIf, NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';





@Component({
    selector: 'app-filter-multi-chip',
    templateUrl: './filter.multi.chip.component.html',
    standalone: true,
    imports: [NgIf, NgFor,FontAwesomeModule]
})
export class FilterMultiChipComponent implements OnInit {
   faTimes = faTimes

   @Input()
   filter: FilterMultiComponent;

   ngOnInit() {
   }


   name(c: string) {
      let index = this.filter.values.indexOf(c);
      if (index < 0) return c;
      return this.filter.names[index]
   }
   refresh() {
      this.filter.change.next(void 0);
   }

}
