<!-- @format -->

<div class="flex items-center pr-0">
  <span
    style="width: fit-content"
    [class.custom_date_field]="filter.range.type == types.custom"
  >
    <span matPrefix>
      <fa-icon [icon]="faClock" class="field_icon"></fa-icon>
    </span>
    <mat-select [(ngModel)]="filter.range.type" (selectionChange)="refresh()">
      <mat-option [value]="types.day14">{{
        _common.filterObject.day14
      }}</mat-option>
      <mat-option [value]="types.day7">{{
        _common.filterObject.day7
      }}</mat-option>
      <mat-option [value]="types.today">{{
        _common.filterObject.today
      }}</mat-option>
      <mat-option [value]="types.all">{{
        _common.filterObject.all_time
      }}</mat-option>
      <mat-option [value]="types.custom">{{
        _common.filterObject.custom_time
      }}</mat-option>
    </mat-select>
    <ng-container *ngIf="filter.range.type == types.custom">
      <mat-date-range-input [rangePicker]="picker" style="width: 160px">
        <input
          matStartDate
          placeholder="From"
          [(ngModel)]="filter.range.start"
        />
        <input
          matEndDate
          placeholder="To"
          [(ngModel)]="filter.range.end"
          (dateChange)="refresh()"
        />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </ng-container>
  </span>
</div>
