<div>
  <div style="text-align: center; padding-top: 1rem;">
    <h3>{{_common.MyPass}}</h3>
  </div>


  <div style="min-height: 250px; min-width: 250px;">
    <app-qrcode> </app-qrcode>
  </div>
</div>
