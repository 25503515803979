<div class="client_login_page">
  <div class="desktop_hide" style="height: 10%;"></div>
  <div class="font-bold text-2xl mb-8">{{_common.login}}</div>

  <img src="/silone/silone_friends_tgt.png" />
  <div [formGroup]="form" class="card login_card">
    <div class="cardBody space-y-4">
      <div
        id="error"
        style="
          display: none;
          color: red;
          font-size: smaller;
          margin-top: 2em;
          margin-bottom: 1em;
        "
      >
        Invalid Username/Password
      </div>
      <div class="flex">
        <div class="flex items-center mr-4">
          <fa-icon icon="user" style="font-size: 22px"></fa-icon>
        </div>
        <app-form-field class="w-full">
          <input matInput formControlName="username" [placeholder]="_common.email" />
        </app-form-field>
      </div>
      <div class="flex">
        <div class="flex items-center mr-4">
          <img
            src="/silone/silone_pw.png"
            style="object-fit: contain; height: 25px"
          />
        </div>

        <app-form-field class="w-full">
          <!--<app-label>密碼</app-label>-->
          <input
            matInput
            formControlName="password"
            type="password"
            [placeholder]="_common.password"
          />
        </app-form-field>
      </div>

      <div class="client_button" style="width: 100%" (click)="login()">
        {{_common.login}}
      </div>
    </div>
  </div>
</div>
