import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, ViewChild } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import moment from 'moment-timezone';
import {
  BookingDayCalendarBase,
  DaySection,
} from '../common/components/dayview/dayview.base';
import {
  DayViewTimeColumn,
  DayViewTimeComponent,
  DayViewTimeItem,
  DayViewTimeLoad,
  DayViewTimeRow,
} from '../common/components/dayview/dayview.time.component';
import { VizFormModule } from '../common/components/form/form.module';
import { date_utils } from '../common/date_utils';
import { TimeAtom } from '../common/timespan/time_utils';
import { WebUtils } from '../common/utils';
import { AccessUtils, TBooking, TSpace } from '../core/entities';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { TBookingStatus } from '../thinkdesks/thinkdesks.entities';
// import { BookingDialogComponent } from './booking.dialog.component';
// import { BookingSpaceDialog } from './booking.space.dialog';
// import { BookingSpaceSelectDialogComponent } from './booking.space.select.dialog';
@Component({
  selector: 'app-booking-space-calendar',
  standalone: true,
  imports: [
    VizFormModule,
    MatDialogModule,
    MatSelectModule,
    DayViewTimeComponent,
    MatToolbarModule,
    FontAwesomeModule,
    MatDatepickerModule,
  ],
  templateUrl: './booking.space.calendar.component.html',
})
export class BookingSpaceCalendarComponent extends SiteFormatMixin(
  BookingDayCalendarBase
) {
  @HostBinding('class') css = 'max-height';
  _common = _common;

  spaces: TSpace[] = [];
  space: TSpace;
  date = moment();
  constructor(
    private cache: SessionCache,
    private dialog: MatDialog,
    private http: HttpClient,
    private session: SessionService
  ) {
    super();
    this.cache.loadSpace(0, (list) => {
      this.spaces = list;
      if (this.spaces.length) {
        this.space = this.spaces[0];
      }
    });
  }
  ngOnInit() {}
  @ViewChild('calendar') calendar: DayViewTimeComponent;
  refresh() {
    this.calendar.refresh();
  }

  prev() {
    this.date = this.date.clone().add(-7, 'day');
    this.refresh();
  }

  next() {
    this.date = this.date.clone().add(7, 'day');
    this.refresh();
  }
  start: moment.Moment;
  end: moment.Moment;
  loadData(event: DayViewTimeLoad) {
    // if (!this.location) return;
    let start = this.date.clone().startOf('date');
    let end = start.clone().add(1, 'day');

    start = date_utils.getFirstDayOfWeek(start);
    end = start.clone().add(7, 'day');
    this.start = start;
    this.end = end;

    // let d = moment(this.viewDate);
    // const date = date_utils.getFirstDayOfWeek(d);
    // const start = date_utils.toQuery(date);
    // const end = date_utils.toQuery(date.clone().add(6, 'day'));

    WebUtils.web_list(
      this.http.post('/api/booking/list', {
        start,
        end,
        status: [TBookingStatus.approved, TBookingStatus.pending],
        space: WebUtils.objId(this.space),
      }),
      (result) => {
        let d = start.clone();
        let dates = [];
        while (d.isBefore(end)) {
          dates.push(d.clone());
          d = d.add(1, 'day');
        }

        event.comp.setColumns([
          ...dates.map((d) => {
            return {
              id: d.format('YYYY-MM-DD'),
              title: d.format('YYYY-MM-DD'),
              lines: [d.format('ddd')],
            };
          }),
        ]);

        let list = result as TBooking[];
        for (let o of list) AccessUtils.fixBooking(o);

        for (let d of dates) {
          let id = d.format('YYYY-MM-DD');

          let schedules: DaySection[] = this.extractDay(d, result, list);
          this.addDay(event, id, schedules, {
            title: (s) => {
              return [{ text: s.data?.member?.name }];
            },
            color: (s) => {
              if (s.data.status == TBookingStatus.pending) {
                return {
                  color: this.bookedColor,
                  borderColor: this.bookedBorder,
                };
              }
              return null;
            },
          });
        }
      }
    );
  }

  extractDay(date: moment.Moment, result, list: TBooking[]) {
    // let schedule = this.scheduleManager.resolveResourceSchedule(this.object, this.location.location, date)

    let schedules: DaySection[] = [];

    // this.merge(schedules, {
    //   start: 0,
    //   end: 2400,
    //   qty: 2,
    // });

    for (let oo of list) {
      let dd = oo.startTime.clone().startOf('day');
      if (!dd.isSame(date)) continue;

      let o = {
        start: TimeAtom.fromMoment(oo.startTime).toTime(),
        end: TimeAtom.fromMoment(oo.endTime).toTime(),
        qty: 0,
        data: oo,
      } as DaySection;
      //
      schedules.push(o);

      // this.merge(schedules, o);
    }
    // if (schedule) {
    //   for (let slot of schedule.slots) {
    //     this.merge(schedules, {
    //       start: slot.start.toTime(),
    //       end: slot.end.toTime(),
    //       qty: this.namedResource ? 1 : this.location.qty,

    //     })
    //   }
    // }

    this.clean(schedules);
    return schedules;
  }
  // extractBookings(list: TAccessEntry[]) {
  //   let bookings = [];
  //   let cache: { [key: number]: any } = {};
  //   for (let o of list) {

  //     let date = o.startTime.clone().startOf('day');

  //     let booking = cache[o.bookingId]
  //     if (!booking) {
  //       booking = {
  //         id: o.bookingId,
  //         title: o.bookingName,
  //         items: []
  //       } as any;
  //       cache[o.bookingId] = booking
  //       bookings.push(booking);
  //     }
  //     let c = booking.items.find((c) => c.time == o.time && c.endTime == o.endTime)
  //     if (c) {
  //       c.qty += c.qty;
  //     } else {
  //       booking.items.push(o);
  //     }
  //   }
  //   return bookings;
  // }
  _oo = false;
  openItem(event: {
    column: DayViewTimeColumn;
    // row: DayViewTimeRow;
    item: DayViewTimeItem;
  }) {
    // const ref = this.dialog.open(BookingDialogComponent, {
    //   data: {
    //     objectId: event.item.data.schedule.data.id,
    //   },
    // });
    // ref.afterClosed().subscribe((result) => {
    //   if (result) {
    //     this.refresh();
    //   }
    // });
  }

  openTime(event: { column: DayViewTimeColumn; row: DayViewTimeRow }) {
    console.log(event);
    // const dialogRef = this.dialog.open(BookingSpaceDialog, {
    //   data: {
    //     objectId: this.space.id,
    //   },
    // });
  }

  add() {
    // let ref = this.dialog.open(BookingSpaceSelectDialogComponent, {
    //   data: {},
    // });
    // ref.afterClosed().subscribe((result) => {
    //   if (result) {
    //     let ref = this.dialog.open(BookingSpaceDialog, {
    //       data: {
    //         objectId: result.id,
    //       },
    //     });
    //     ref.afterClosed().subscribe((result) => {});
    //   }
    // });
  }
}
