/** @format */

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroupDirective,
  NgForm,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VizFormModule } from '../components/form/form.module';
import { utils } from '../utils';
import { validateMatchPasswords, validatePassword } from '../validators';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  templateUrl: './password.dialog.html',
  standalone: true,
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    ReactiveFormsModule,
    VizFormModule,
    MatInputModule,
  ],
})
export class PasswordDialogComponent implements OnInit {
  _common = _common;

  form: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<PasswordDialogComponent>,
  ) {
    data = data || {};
    const passwordLength = data.passwordLength || 6;
    const passwordPattern = data.passwordPattern || 'Aa0';

    this.form = this.fb.group(
      {
        password: new UntypedFormControl('', [
          Validators.required,
          validatePassword(passwordLength, passwordPattern),
        ]),
        password2: new UntypedFormControl('', [
          Validators.required,
          validatePassword(passwordLength, passwordPattern),
        ]),
      },
      { validator: validateMatchPasswords },
    );
  }

  @ViewChild('password') password: ElementRef<HTMLInputElement>;
  @ViewChild('password2') password2: ElementRef<HTMLInputElement>;
  hidePassword = true;
  togglePassword() {
    let f = (password, hide) => {
      if (hide) {
        password.nativeElement.type = 'password';
      } else {
        password.nativeElement.type = 'text';
      }
    };
    this.hidePassword = !this.hidePassword;
    f(this.password, this.hidePassword);
    f(this.password2, this.hidePassword);
  }
  ngOnInit(): void {}

  reset() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    if (this.form.value.password !== this.form.value.password2) return;

    this.dialogRef.close(this.form.value.password);
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  _confirmText() {
    return _common?.sys_common?.confirm_password ?? 'Confirm Password';
  }
}
