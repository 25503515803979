<!-- @format -->

<div class="cdialog" style="width: 85vw; height: 85vh">
  <div class="dialogHeader">
    <div mat-dialog-title>Agent Content</div>

    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-height dialogBody">
      <app-agent-content-list [agent]="object"> </app-agent-content-list>
    </div>
  </mat-dialog-content>
</div>
