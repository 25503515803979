import { CommonModule } from "@angular/common";
import { FactoryProvider, NgModule, makeEnvironmentProviders } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FilterTabsComponent } from "./filter.tabs.component";
import { FilterSaveComponent } from "./filter.save.component";
import { FilterDateRangeComponent } from "./filter.date.range.component";
import { FilterDateFilterComponent } from "./filter.date.filter.component";
import { FilterMultiComponent } from "./filter.multi.component";
import { FilterMultiFilterComponent } from "./filter.multi.filter.component";
import { FilterMultiChipComponent } from "./filter.multi.chip.component";
import { FilterSingleComponent } from "./filter.single.component";
import { FilterTextComponent } from "./filter.text.component";
import { FilterTextFilterComponent } from "./filter.text.filter.component";
import { FilterRadioComponent } from "./filter.radio.component";
import { FilterBoolComponent } from "./filter.bool.component";
import { FilterBoolChipComponent } from "./filter.bool.chip.component";
import { FilterBoolFilterComponent } from "./filter.bool.filter.component";
import { FilterDateRangButtonComponent } from "./filter.date.range.button.component";
import { FilterSelectObjectComponent } from "./filter.select.object.component";
import { FilterSelectObjectFilterComponent } from "./filter.select.object.filter.component";
import { FilterSelectObjectsComponent } from "./filter.select.objects.component";
import { FilterSelectObjectsFilterComponent } from "./filter.select.objects.filter.component";
import { ListFilterCache } from "./list.filter.cache";

@NgModule({
  exports: [
    FilterTabsComponent,
    FilterSaveComponent,
    FilterDateRangeComponent,
    FilterDateFilterComponent,
    FilterMultiComponent,
    FilterMultiFilterComponent,
    FilterMultiChipComponent,
    FilterSingleComponent,
    FilterTextComponent,
    FilterTextFilterComponent,
    FilterRadioComponent,
    FilterBoolComponent,
    FilterBoolChipComponent,
    FilterBoolFilterComponent,
    FilterDateRangButtonComponent,
    FilterSelectObjectComponent,
    FilterSelectObjectFilterComponent,
    FilterSelectObjectsComponent,
    FilterSelectObjectsFilterComponent,
  ],
  imports: [
    FilterTabsComponent,
    FilterSaveComponent,
    FilterDateRangeComponent,
    FilterDateFilterComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FilterMultiComponent,
    FilterMultiFilterComponent,
    FilterMultiChipComponent,
    FilterSingleComponent,
    FilterTextComponent,
    FilterTextFilterComponent,
    FilterRadioComponent,
    FilterBoolComponent,
    FilterBoolChipComponent,
    FilterBoolFilterComponent,
    FilterDateRangButtonComponent,
    FilterSelectObjectComponent,
    FilterSelectObjectFilterComponent,
    FilterSelectObjectsComponent,
    FilterSelectObjectsFilterComponent,
  ],
  providers: [ListFilterCache],
  bootstrap: [],
})
export class VizFilterModule {}

export function provideFilterComponents() {
    return makeEnvironmentProviders([
        ListFilterCache
    ])
}
