<!-- @format -->

<div class="cdialog">
  <div class="dialogHeader">
    <div mat-dialog-title *ngIf="!objectId">新增設備</div>
    <div mat-dialog-title *ngIf="objectId">
      <div class="font-bold">{{object?.name}}</div>
    </div>
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <div class="max-scroll dialogBody">
      <form [formGroup]="form">
        <div class="card">
          <!-- <div class="cardHeader">Settings</div> -->
          <div class="cardBody space-y-2">
            <div>
              <app-form-field class="w-full">
                <app-label>{{_common.name}}</app-label>
                <input matInput formControlName="name" required />
              </app-form-field>
            </div>
            <ng-container *ngFor="let dlang of dlangs">
              <div class="flex flex-col space-y-2">
                <div>{{_common.langs??[dlang]??dlang}}</div>

                <div [formGroup]="$any(form.controls['name_lang'])">
                  <app-form-field style="width: 100%" appearance="outline">
                    <app-label>Name</app-label>
                    <input matInput [formControlName]="dlang" />
                  </app-form-field>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
