<!-- @format -->

<div class="flex items-center space-x-2" [formGroup]="form">
  <ng-container>{{ title }}</ng-container>
  <mat-radio-group (change)="updateForm()" formControlName="open">
    <div class="flex items-center space-x-4">
      <mat-radio-button [value]="true">{{ _common.thinkdesks.open }}</mat-radio-button>
      <app-form-field *ngIf="form.value.open">
        <!-- <app-label>{{ _common.time }}</app-label> -->
        <input matInput formControlName="time" />
      </app-form-field>
    </div>
    <div>
      <mat-radio-button [value]="false">{{ _common.thinkdesks.closed }}</mat-radio-button>
    </div>
  </mat-radio-group>
  <div class="flex-auto justify-self-end text-right">
    <ng-content></ng-content>
  </div>
</div>