import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VizFormModule } from '../common/components/form/form.module';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { LockerBookContainer } from '../thinkdesks/locker.book.container';


@Component({
  selector: 'app-locker-book-page',
  standalone: true,
  imports: [
    VizFormModule,
    MatDatepickerModule,
    MatSelectModule,
  ],
  templateUrl: './locker.book.page.html',
})
export class LockerBookPage extends LockerBookContainer {
  susbcription: Subscription;

  constructor(
    public route: ActivatedRoute,
    public override snackBar: MatSnackBar,
    public override http: HttpClient,
    public override router: Router,
    public override cache: SessionCache,
    private fb: FormBuilder,
    private session: SessionService
  ) {
    super();
    this.form = fb.group({
      startTime: ['', Validators.required],
      duration: ['', Validators.required],
    });
  }
  ngOnInit() {
    if(!this.session.user) {
      this.router.navigate([this.session.path('/')])
      return
    }
    this.susbcription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));
      this.refresh();
    });
  }
  ngOnDestroy() {
    this.susbcription?.unsubscribe();
  }
}
