import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TimeAtom } from "./time_utils";


export class DaySlot {
   start: TimeAtom;
   end: TimeAtom;
   constructor(o?: { start?: TimeAtom, end?: TimeAtom }) {
      if (o) {
         this.start = o.start
         this.end = o.end;
      }
   }

   save() {
      return {
         s: this.start.toString(),
         e: this.end.toString(),
      }
   }
   toText() {
      return `${this.start.toString()}-${this.end.toString()}`;
   }
   toEditText() {
      return `${this.start.toNumString()}-${this.end.toNumString()}`;
   }
   static parse(s: string) {
      let list = s.split('-')
      list = list.filter((c) => c && c != '');
      if (list.length == 1) {

         let start = TimeAtom.parseText(list[0]);
         if (!start) return null;
         let end = start.clone().add(60);
         return new DaySlot({ start, end });



      } else {
         let start = TimeAtom.parseText(list[0]);
         if (!start) return null;
         let end = TimeAtom.parseText(list[1])
         if (!end) return null;
         return new DaySlot({ start, end })
      }

   }

   static slotFormat(): ValidatorFn {
      return (c: AbstractControl): ValidationErrors | null => {
         let v = c.value
         if (!v || v == '') return null;

         if (!DaySchedule.parse(v)) {
            return {
               'format': {
                  valid: false
               }
            }
         }
         return null;

      }

   }
}
export class DaySchedule {

   slots: DaySlot[] = [];
   isEmpty() {
      return !this.slots.length;
   }
   toEditText() {
      if (!this.slots.length)
         return null;
      return this.slots.map((c) => c.toEditText()).join(' ')
   }
   toText() {
      if (!this.slots.length)
         return null;
      return this.slots.map((c) => c.toText()).join(' ')
   }
   includes(o: TimeAtom) {

      for (let ss of this.slots) {
         if (o.between(ss.start, ss.end)) {
            return true;

         }
      }
      return false;

   }
   includePeriod(time: { time: TimeAtom, endTime: TimeAtom }) {
      for (let ss of this.slots) {
         if (time.time.between(ss.start, ss.end) &&
            time.endTime.between(ss.start, ss.end, { startExclusive: true, endInclusive: true }))
            return true;
      }
      return false;
   }
   match(list: TimeAtom[]) {
      for (let s of list) {
         let found = false;
         for (let ss of this.slots) {
            if (s.between(ss.start, ss.end)) {
               found = true;
               break;
            }
         }
         if (!found) return false;
      }
      return true;
   }
   merge(o: DaySchedule) {
      //TODO !!!!!!
   }
   cut(t: TimeAtom) {

      let time = t.toTime();
      let list = [];
      for (let s of this.slots) {

         console.log(s.start.toTime())
         if (s.start.isBefore(t))
            continue;

         list.push(s);
      }
      this.slots = list;
   }
   save() {
      return {
         slots: this.slots.map((c) => c.save())
      }
   }
   static parse(s: string) {
      let list: string[] = [];

      if (s) {
         s = s.replace(',', ' ')
         s = s.replace(';', ' ')
         let _list = s.split(' ')
         if (!_list.length) return null;


         for (let i = 0; i < _list.length; i++) {
            let s = _list[i]
            if (s == '-') {
               if (!list.length) return null;
               let p = list[list.length - 1]
               if (p.includes('-')) return null;

               if (i + 1 >= list.length) return null;
               let n = _list[i + 1]
               if (n.includes('-')) return null;

               list[list.length - 1] = `${p}-${n}`
               i++;


            } else if (s.endsWith('-')) {
               if (i + 1 >= list.length) return null;
               let n = _list[i + 1]
               if (n.includes('-')) return null;
               list[list.length - 1] = `${s}${n}`
               i++;
            } else if (s.startsWith('-')) {
               if (!list.length) return null;
               let p = list[list.length - 1]
               if (p.includes('-')) return null;
               list[list.length - 1] = `${p}${s}`

            } else {
               list.push(s);
            }
         }
      }
      let ss = new DaySchedule();
      for (let c of list) {

         let slot = DaySlot.parse(c);
         if (!slot) return null;
         ss.slots.push(slot);
      }
      return ss;


   }

}