
export let Translate = {
  en: {
    search: 'Search',
    delete: 'Delete',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    cancel: "Back",//for cancel booking, "cancel" is confused. changed to "back"
    confirm: 'Confirm',
    email: 'Email',
    name: 'Name',
    code: 'Code',
    username: 'Username',
    date: 'Date',
    time: 'Time',
    member: 'Member',
    password: 'Password',
    change_password: 'Change Password',
    logout: "Logout",
    login: "Login",
    optional: "Optional",
    share: "Share",
    linkCopied: "Link Copied",
    copied: "Copied",
    other_language: 'Other Languages',
    enable: 'Enable',
    disable: 'Disable',
    active: "Active",
    no_result: "No results found",
    change: "Change",
    day: 'day(s)',
    or: 'or',

    en: 'English',
    zh_cn: 'Simplified Chinese',

    //error message
    error: {
      'integrity.error': 'Cannot Delete: It already linked with other items',
    },

    thinkdesks: {
      time:"Time",
      duration:"Duration",
      set_special_day:"Set Special Day",
      open:"Open",
      closed:"Closed",
      special_day:'Special Day',
      set_schedule:"Set Schedule",
      from:"From",
      to:"To",
      schedule_info:"Schedule Info",
      copy_to:"Copy To",
      select_all:"Select All",
      mon:"Mon",
      tue:"Tue",
      wed:"Wed",
      thu:"Thu",
      fri:"Fri",
      sat:"Sat",
      sun:"Sun",
      public_holiday:"Public Holiday",
      regular_schedule:'Regular Schedule',
      selected_day:"Selected Day",
      special_in_use:"Special In Use",
      onwards:"Onwards",
      applicable_date:"Applicable Date",

      //error message
      exceed_booking_hour: "Maximum session per booking is",
    },

    

    interval: {
      days: 'day(s)',
      hour: 'hr',
      hours: 'hrs',
      mins: 'mins',
      secs: 'secs',
      from: 'From',
      to: 'to',
    },


    filterObject: {
      _: 'Selections in Filters',
      all_time: 'All Time',
      today: 'Today',
      day14: 'Last 14 Days',
      day7: 'Last 7 Days',
      custom_time: 'Custom',
      Contains: 'contains',
      EndsWith: 'ends With',
      greaterThan: 'greater than',
      lessThan: 'less than',
      lessThan_equal: 'less than/equal to',
      greaterThan_equal: 'greater than/equal to',
    },

    //Menu//
    Home: "Home",
    SpaceBookings: 'Space Booking',
    favourites_space: 'My Favourites',
    MyBookings: 'My Bookings',
    Profile: "Profile",
    AllCenters: "Center List",
    MyCenters: "My Centers",
    MySpaces: "My Spaces",
    Centers: "Centers",
    Spaces: 'Spaces',
    Space: "Space",
    BookingManagement: "Manage Bookings",
    Manage: "Manage",

    //Home//
    HomeGreeting1: "Hello!",
    HomeGreeting2: "Finding Space?",
    NearbyCenters: "Nearby Centers",
    Centers_random: "Centers",
    ViewMap: "View Map",
    ViewAll: "View All",
    RecommendedSpaces: "Recommended Spaces",
    SearchSpaces: "Search Spaces",

    //Status//
    bookingStatus: {
      status: 'Status',
      pending: 'Pending',
      cancelled: 'Cancelled',
      approved: 'Confirmed',
      rejected: 'Rejected',
    },

    //My bookiong//
    UpComing: "Upcoming",
    Past: "Past",
    AddToCalendar: "Add To Calendar",
    CenterInfo: "Center Info",
    Remarks: "Remarks",
    SpaceFacilities: "Space Facilities",
    FacilitiesIncluded: "Facilities Included",
    AdditionalFacilitiesAvailableForRent: "Additional Facilities Available",
    RebookSameSpace: "Book the same room again",
    AdditionalFacilities: " Additional Facilities",

    //SpaceCard//
    Capacity: "Capacity",
    VenueSize: "",
    People: "ppl.",

    //Booking dialog//
    Booking: 'Booking',
    LockerBooking: 'Locker Booking',
    NotSelected: 'Not Selected',
    SelectMember: 'Select Member',
    StartTime: 'Start Time',
    Duration: 'Duration',
    Book: 'Book',
    Status: 'Status',
    Lockers: 'Lockers',
    Locker: 'Locker',
    Listview: 'List view',
    Calendarview: 'Calendar view',
    Selectspacebook: 'Select the space you want to book',
    Pending: 'Pending',
    Rejected: 'Rejected',
    Approved: 'Approved',
    Cancelled: 'Cancelled',
    SetSpecialDay: 'Set Special Day',
    SetSchedule: 'Set Schedule',
    RegularSchedule: 'Regular Schedule',
    SpecialDay: 'Special Day',
    ApplicableDate: 'Applicable Date',
    Onwards: 'Onwards',
    Selectedday: 'Selected',
    SpecialInuse: 'Special In use',
    Closed: 'Closed',
    From: 'From',
    To: 'To',
    ScheduleInfo: 'Schedule Info',
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Holiday: 'Holiday',
    CopyTo: 'Copy To',
    SelectAll: 'Select All',
    Open: 'Open',
    BookingDetails: "Booking Details",
    Sync: "Sync",
    DoYouNeedToRentAnyAdditionalFacilities: "Do you want additional facilities?",
    Yes: "Yes",
    No: "No",
    BookingPerson: "Booking Person",
    ContactPerson: "Contact Person",
    SameAsBookingPerson: "Same as Booking Person",
    SelectDateAndTime: "Select Date and Time",
    SelectTime: "Please select time",
    time_available: "Available",
    time_unavailable: "Unvailable",
    time_selected: "Selected",
    ConfirmTitleText: "Please confirm the following booking details!",
    DateAndTime: "Date and Time",
    TermsAndConditions: "Terms and Conditions",
    TermsAndConditions_space: "Space Booking Terms and Conditions",
    TermsAndConditions_space_exp: "If leave this field empty, Center default/System default T&C will be applied.",
    TermsAndConditions_centerDefault: "Booking Terms and Conditions (Center Default)",
    TermsAndConditions_centerDefault_exp: "If the Space have no T&C, the following Center default T&C will be applied. If leave this field empty, System default will be applied.",
    BookingConfirmed: "Booking Confirmed",
    QRCodeText: "Please present your membership QR code at check-in",
    usePasscode: 'use the passcode',
    //Booking dialog - snackbar//
    select_date_alert: "Please select Date and Time",
    select_facility_alert: "Please select facility",

    //Center//
    space_facilities: "Space Facilities",
    AddCenter: "Add Center",
    Introduction: "Introduction",
    ContactInfo: "Contact Info",
    Area: "Area",
    HK: "Hong Kong Island",
    KLN: "Kowloon",
    NT: "New Territories",
    HK_CS: "Central and Western",
    HK_WC: "Wan Chai",
    HK_E: "Eastern",
    HK_S: "Southern",
    KLN_YTM: "Yau Tsim Mong",
    KLN_SSP: "Sham Shui Po",
    KLN_KC: "Kowloon City",
    KLN_WTS: "Wong Tai Sin",
    KLN_KT: "Kwun Tong",
    NT_KT: "Kwai Tsing",
    NT_TW: "Tsuen Wan",
    NT_TM: "Tuen Mun",
    NT_YL: "Yuen Long",
    NT_N: "North",
    NT_TP: "Tai Po",
    NT_ST: "Sha Tin",
    NT_SK: "Sai Kung",
    NT_Islands: "Islands",
    address: "Address",
    tel_no: "Tel No.",
    center_contact_person: "Center Contact Person",
    opening_hours: "Opening Hours",
    map_settings: "Map Settings",
    google_map_url: "Google Map URL",
    latitude: "Latitude",
    longitude: "Longitude",
    Categories: "Categories",
    DefualThemes: "Defult Themes",
    Schedules: 'Schedules',
    PublicHolidayCalendar: "Public Holiday Calendar",
    Settings: 'Settings',
    Locations: "Locations",
    Location: "Location",
    center: "Center",
    organization: "Organization",
    Min: 'Min',
    Increment: 'Increment',
    Max: 'Max',
    Mins: "Mins",
    Events: "Events",
    Event: "Event",
    Bookings: "Bookings",
    ViewDetails: "View Details",

    //Locker//
    Code: 'Code',
    DeviceCode: 'Device Code:',
    TextDescription: 'Text Description',
    BookingSettings: 'Booking Settings',
    Calendar: 'Calendar',
    images: 'Images',
    Active: 'Active',
    ManualApproval: 'Manual Approval',
    Locked: 'Locked',
    Cells: 'Cells',

    //Member//
    ChineseName: 'Chinese Name',
    PolicyChecked: 'Policy Checked',
    Locale: 'Locale',
    English: 'English',
    Chinese: 'Chinese',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    AccessRight: 'Access Right',
    Roles: 'Roles',
    ResetPassword: 'Reset Password',
    MyPass: 'My QR Code Pass',
    //Member GP//
    MemberGPname: 'Member Group Name',


    //Space//
    AddSpace: 'Add Space',
    EditSpace: 'Edit Space',
    AutoApprove: 'Auto Approve',
    Bookable: 'Bookable',
    Dependencies: 'Dependencies',
    Facilities: 'Facilities',
    Type: 'Type',
    FacilityName: 'Facility Name',
    Space_rental: 'Spaces',
    OtherFacilities: 'Other Facilities',
    space_available: 'Space available',
    space_categories: "Space Categories",
    space_usage_theme: "Space Usage Theme",

    //SpaceType//
    AddSpaceType: 'Add Space Type',
    EditSpaceType: 'Edit Space Type',

    //Space - 21C//
    add_Theme: "Add Theme",
    editTheme: "Edit Theme",
    addcategory: "Add Category",
    editcategory: "Edit Category",
    add_space: "Add Space",
    space_settings: "Space Settings",
    space_name: "Space Name",
    remarks: "Remarks",
    capacity: "Capacity",
    venue_size: "",
    facilities_included: "Facilities Included",
    additional_facilities_available_for_rent:
      "Additional Facilities Available For Rent",
    no_facility_found:
      "No additional facility available for spaces under this center",
    booking_settings: "Booking Settings",
    defual_space_usage_themes: "Defual Space Usage Themes",
    usage_theme: "Theme",
    days_in_advance: "Future Booking Allowed",
    no_slot_available: 'No Available Slot',
    min: "Min",
    max: "Max",
    increment: "Increment",
    mins: "Mins",
    space_status: "Space Status",
    auto_approve: "Auto Booking Approval",
    auto_approve_on: "Auto Booking Approval: OFF",
    auto_approve_off: "Auto Booking Approval: ON",
    door_lock_settings: "Door Lock Settings",
    smart_lock: "Smart Lock",
    booking_Records: "Booking Records",
    calendar: "Calendar",
    event: "Event",
    public_holiday_calendar: "Public Holiday Calendar",
    duration: "Duration",

    //User//
    User: 'User',
    AdminUser: 'Admin User',

    //Booking//
    created_time: 'Created Time',
    booked_slot: 'Booked Slot',
    approve: 'Approve',
    reject: 'Reject',
    booking_approval: 'Booking Approval',
    checkin: 'Checkin',
    invite_frd: 'Invite Friends',
    copy_invite_msg: 'Copy Invitation Message',
    share_whatsapp: 'Share to WhatsApp',
    cancel_booking: 'Cancel Booking',
    cancel_booking_confirm: 'Are you sure you want to cancel this booking?',
    booking_remind_msg: {
      days_after1: 'Will be start in',
      days_after2: 'day(s)!',
      starting_soon: 'Starting soon!',
      checkin_now: 'Checkin now',
      checkin_done: 'Checked in',
      checkin_no: 'No checkin record',
    },


    //Doors//
    locks_control: 'Door Locks',
    passcode: 'Passcode',
    door_log: 'Door Open Log',

    Filter: {
      filter: 'Filter',
      all: 'All',
      district:'District',
      cat_name: 'Category',
      cat_all: 'All Categories',
      capacity: 'Capacity',
      noSpecify: 'Not Specified',
      org_name: 'Organization',
      center_name: 'Center',
      clear: 'Clear',
     
      }
    
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  zh_hk: {
    search: '搜尋',
    delete: '刪除',
    save: '儲存',
    add: '新增',
    edit: '編輯',
    cancel: "返回",//for cancel booking, "cancel" is confused. changed to "back"
    confirm: '確認',
    email: '電郵',
    name: '名稱',
    code: '編號',
    username: '用戶名稱',
    date: '日期',
    time: '時間',
    member: '會員',
    locker_bookings: '儲物櫃預約',
    password: '密碼',
    change_password: '更改密碼',
    logout: "登出",
    login: "登入",
    optional: "選填",
    share: "分享",
    linkCopied: "已複製連結",
    copied: "已複製",
    other_language: '其他語言',
    enable: '啟用',
    disable: '停用',
    active: "有效",
    no_result: "找不到結果",
    change: "更改",
    day: '日',
    or: '或',
    en: '英文',
    zh_cn: '簡體中文',

    //error message
    error: {
    'integrity.error': '不能刪除：此項目與其他項目有連結',
    },

    thinkdesks: {
      time:"時間",
      duration:"時長",
      set_special_day:"設置特殊日子",
      open:"開放時間",
      closed:"休息",
      special_day:'特殊日子',
      set_schedule:"設置時間表",
      from:"由",
      to:"至",
      schedule_info:"時間表資訊",
      copy_to:"複製至",
      select_all:"全選",
      mon:"週一",
      tue:"週二",
      wed:"週三",
      thu:"週四",
      fri:"週五",
      sat:"週六",
      sun:"週日",
      public_holiday:"公眾假期",
      regular_schedule:'常規時間表',
      selected_day:"已選擇日期",
      special_in_use:"正使用特殊開放時間",
      onwards:"起",
      applicable_date:"適用日期",

      //error message
      exceed_booking_hour: "每次最多預約",
    },

    interval: {
      days: '日',
      hours: '小時',
      hour: '小時',
      mins: '分鐘',
      secs: '秒',
      from: '由',
      to: '至',
    },


    filterObject: {
      _: '篩選項目',
      all_time: '所有日期',
      today: '今天',
      day14: '最近14天',
      day7: '最近7天',
      custom_time: '自訂',
      greaterThan: '大於',
      lessThan: '小於',
      lessThan_equal: '小於/等於',
      greaterThan_equal: '大於/等於',
    },

    //Menu//
    Home: "主頁",
    SpaceBookings: '房間預約',
    favourites_space: '我的最愛',
    MyBookings: '我的預約',
    Profile: "我的帳戶",
    AllCenters: "中心一覽",
    MyCenters: "我的中心",
    MySpaces: "我的房間",
    Centers: "中心",
    Spaces: "房間",
    Space: "房間",
    BookingManagement: "管理預約",
    Manage: "管理",

    //Home//
    HomeGreeting1: "Hello!",
    HomeGreeting2: "想搵咩地方?",
    NearbyCenters: "附近中心",
    Centers_random: "各區中心",
    ViewMap: "查看地圖",
    ViewAll: "查看全部",
    RecommendedSpaces: "推薦房間",
    SearchSpaces: "搜尋房間",

    //Status//
    bookingStatus: {
      status: '預約狀態',
      pending: '待批核',
      cancelled: '已取消',
      approved: '已確認',
      rejected: '已拒絕',
    },

    //My bookiong//
    UpComing: "近期預約",
    Past: "過往預約",
    AddToCalendar: "新增到日歷",
    CenterInfo: "中心資料",
    Remarks: "備註",
    FacilitiesIncluded: "已包括設備",
    SpaceFacilities: "房間設備",
    AdditionalFacilitiesAvailableForRent: "可額外借用設備",
    RebookSameSpace: "預約相同房間",
    AdditionalFacilities: "額外借用設備",

    //SpaceCard//
    Capacity: "可容納",
    VenueSize: "面積",
    People: "人",

    //Booking dialog//
    Booking: '預約',
    NotSelected: '未選擇',
    SelectMember: '選擇會員',
    StartTime: '選擇時間',
    Duration: '租借時長',
    Book: '預約',
    Status: '狀態',
    Lockers: '儲物櫃',
    Listview: '列表檢視',
    Calendarview: '日曆檢視',
    Selectspacebook: '選擇預約房間',
    Pending: '待批核',
    Rejected: '拒絕',
    Approved: '已批核',
    Cancelled: '已取消',
    SetSpecialDay: '設定特別日子',
    SetSchedule: '設定日程',
    RegularSchedule: '平常日子',
    SpecialDay: '特別日子',
    ApplicableDate: '生效日期由',
    Onwards: '開始',
    Selectedday: '已選日期',
    SpecialInuse: '特別使用時間',
    Closed: '不開放',
    From: '由',
    To: '至',
    ScheduleInfo: '日程詳細',
    Mon: '星期一',
    Tue: '星期二',
    Wed: '星期三',
    Thu: '星期四',
    Fri: '星期五',
    Sat: '星期六',
    Sun: '星期日',
    Holiday: '公眾假期',
    CopyTo: '複製至',
    SelectAll: '全選',
    Open: '開放',
    BookingDetails: "預約詳情",
    Sync: "同步",
    DoYouNeedToRentAnyAdditionalFacilities: "是否需要借用額外設備",
    Yes: "是",
    No: "否",
    BookingPerson: "預約人",
    ContactPerson: "聯絡人",
    SameAsBookingPerson: "與預約人相同",
    SelectDateAndTime: "選擇日期及時間",
    SelectTime: "請選擇時段",
    time_available: "可選",
    time_unavailable: "不可用",
    time_selected: "已選",
    DateAndTime: "日期及時間",
    ConfirmTitleText: "請確認以下預約資料！",
    TermsAndConditions: "條款及細則",
    TermsAndConditions_space: "房間預約條款及細則",
    TermsAndConditions_space_exp: "如留空此欄，將會默認使用中心預設/系統預設條款及細則。",
    TermsAndConditions_centerDefault: "預約條款及細則 (中心預設)",
  TermsAndConditions_centerDefault_exp: "當房間沒有設定條款及細則，會默認使用此中心預設條款及細則。如留空此欄，將會默認使用系統預設條款及細則。",
    BookingConfirmed: "已完成預約",
    QRCodeText: "請在簽到時出示會員QR Code",
    usePasscode: '使用預約密碼開門',

    //Booking dialog - snackbar//
    select_date_alert: "請選擇日期及時間",
    select_facility_alert: "請選擇房間設備",





    //Center//
    space_facilities: "房間設備",
    AddCenter: "新增中心",
    introduction: "介紹",
    ContactInfo: "聯絡資料",
    Area: "地區",
    HK: "港島",
    KLN: "九龍",
    NT: "新界",
    HK_CS: "中西區",
    HK_WC: "灣仔",
    HK_E: "東區",
    HK_S: "南區",
    KLN_YTM: "油尖旺",
    KLN_SSP: "深水埗",
    KLN_KC: "九龍城",
    KLN_WTS: "黃大仙",
    KLN_KT: "觀塘",
    NT_KT: "葵青",
    NT_TW: "荃灣",
    NT_TM: "屯門",
    NT_YL: "元朗",
    NT_N: "北區",
    NT_TP: "大埔",
    NT_ST: "沙田",
    NT_SK: "西貢",
    NT_Islands: "離島",
    address: "地址",
    tel_no: "電話",
    center_contact_person: "中心負責人姓名",
    opening_hours: "營業時間",
    map_settings: "地圖設定",
    google_map_url: "Google地圖連結",
    latitude: "緯度",
    longitude: "經度",
    Categories: "分類",
    DefualThemes: "預設主題",
    Schedules: '開放時間表',
    PublicHolidayCalendar: "公眾假期日歷",
    Settings: '設定',
    Locations: "地點",
    Location: "地點",
    center: "中心",
    organization: "機構",
    Min: "最短預約時間",
    Max: "最長預約時間",
    Increment: "時段長度",
    Mins: "分鐘",
    Events: "活動",
    Event: "活動",
    Bookings: "預約",
    ViewDetails: "查看詳情",

    //Locker//
    DeviceCode: '裝置編號:',
    TextDescription: '文字描述',
    BookingSettings: '預約設定',
    Calendar: '日曆',
    images: '圖片',
    Active: '啟用',
    ManualApproval: '手動批核',
    Locked: '鎖定',
    Cells: '儲存格',

    //Member//
    ChineseName: '中文姓名',
    PolicyChecked: '已確認條款及細則',
    Locale: '語言設定',
    English: '英文',
    Chinese: '中文',
    StartDate: '委任生效日期',
    EndDate: '委任結束日期',
    AccessRight: '訪問權限',
    Roles: '職位',
    ResetPassword: '重設密碼',
    MyPass: '我的QR Code',
    //Member GP//
    MemberGPname: '會員分類名稱',

    //Space//
    AddSpace: '新增房間',
    EditSpace: '編輯房間',
    AutoApprove: '自動批核',
    Bookable: '可預約',
    Dependencies: '關聯房間',
    Facilities: '設備',
    Type: '類型',
    FacilityName: '設備名稱',
    Space_rental: '租借場地',
    OtherFacilities: '其他設施',
    space_available: '可供借用場地',
    space_categories: "房間分類",
    space_usage_theme: "房間主題",
    //SpaceType//
    AddSpaceType: '新增房間類別',
    EditSpaceType: '編輯房間類別',


    //Space - 21C//
    add_theme: "新增主題",
    edit_theme: "編輯主題",
    add_category: "新增分類",
    edit_category: "編輯分類",
    add_space: "新增房間",
    space_settings: "房間設定",
    space_name: "房間名稱",
    remarks: "備註",
    capacity: "可容納人數",
    venue_size: "房間面積",
    facilities_included: "已包括設備",
    additional_facilities_available_for_rent: "可額外借用設備",
    no_facility_found:
    "此中心沒有提供可額外借用設備選擇",
    booking_settings: "預約設定",
    defual_space_usage_themes: "預設房間主題",
    usage_theme: "主題",
    days_in_advance: "未來可預約日數",
    no_slot_available: '沒有可預約的時段',
    min: "最短預約時間",
    max: "最長預約時間",
    increment: "時段長度",
    mins: "分鐘",
    space_status: "房間狀態",
    auto_approve: "自動批核預約",
    auto_approve_on: "自動批核預約: 開啓",
    auto_approve_off: "自動批核預約: 關閉",
    door_lock_settings: "門鎖設定",
    smart_lock: "智能門鎖",
    booking_records: "預約紀錄",
    calendar: "日歷",
    event: "活動",
    public_holiday_calendar: "公眾假期日曆",
    duration: "租借時長",
    space_facility: "房間設備",

    //User//
    User: '用戶',
    AdminUser: '管理員用戶',

    //Booking//
    created_time: '建立時間',
    booked_slot: '已預約時段',
    approve: '批核',
    reject: '駁回',
    booking_approval: '預約批核',
    checkin: '簽到',
    invite_frd: '邀請朋友',
    copy_invite_msg: '複製邀請訊息',
    share_whatsapp: '分享到WhatsApp',
    cancel_booking: '取消預約',
    cancel_booking_confirm: '確定取消此預約?',
    booking_remind_msg: {
      days_after1: '即將在',
      days_after2: '日後開始!',
      starting_soon: '即將開始!',
      checkin_now: '立即簽到',
      checkin_done: '已簽到',
      checkin_no: '沒有簽到紀錄',
    },

    //Doors//
    locks_control: '門鎖控制',
    passcode: '開門密碼',
    door_log: '開門記錄',

    Filter: {
      filter: '篩選',
      all: '全部',
      district:'地區',
      cat_name: '分類名稱',
      cat_all: '全部分類',
      capacity: '容納人數',
      noSpecify: '沒有指定',
      org_name: '機構名稱',
      center_name: '中心名稱',
      clear: '清除',

    }

  },
  zh_cn: {
    search: '搜寻',
    delete: '删除',
    save: '储存',
    add: '新增',
    edit: '编辑',
    cancel: "返回",//for cancel booking, "cancel" is confused. changed to "back"
    confirm: '确认',
    email: '电邮',
    name: '名称',
    code: '编号',
    username: '用户名称',
    date: '日期',
    time: '时间',
    member: '会员',
    locker_bookings: '储物柜预约',
    password: '密码',
    change_password: '更改密码',
    logout: "登出",
    login: "登入",
    optional: "选填",
    share: "分享",
    linkCopied: "已复制连结",
    copied: "已复制",
    other_language: '其他语言',
    enable: '启用',
    disable: '停用',
    active: "有效",
    no_result: "找不到结果",
    change: "更改",
    day: '日',
    or: '或',

    en: '英文',
    zh_cn: '简体中文',

    //error message
    error: {
      'integrity.error': '不能删除：此项目与其他项目有连结',
    },

    thinkdesks: {
    time:"时间",
    duration:"时长",
    set_special_day:"设置特殊日子",
    open:"开放时间",
    closed:"休息",
    special_day:'特殊日子',
    set_schedule:"设置时间表",
    from:"由",
    to:"至",
    schedule_info:"时间表资讯",
    copy_to:"复制至",
    select_all:"全选",
    mon:"周一",
    tue:"周二",
    wed:"周三",
    thu:"周四",
    fri:"周五",
    sat:"周六",
    sun:"周日",
    public_holiday:"公众假期",
    regular_schedule:'常规时间表',
    selected_day:"已选择日期",
    special_in_use:"正使用特殊开放时间",
    onwards:"起",
    applicable_date:"适用日期",

    //error message
    exceed_booking_hour: "每次最多预约",
    },

    interval: {
      days: '日',
      hours: '小时',
      hour: '小时',
      mins: '分',
      secs: '秒',
      from: '由',
      to: '至',
    },


    filterObject: {
    _: '筛选项目',
    all_time: '所有日期',
    today: '今天',
    day14: '最近14天',
    day7: '最近7天',
    custom_time: '自订',
    greaterThan: '大于',
    lessThan: '小于',
    lessThan_equal: '小于/等于',
    greaterThan_equal: '大于/等于',
    },

    //Menu//
    Home: "主页",
    SpaceBookings: '房间预约',
    favourites_space: '我的最爱',
    MyBookings: '我的预约',
    Profile: "我的帐户",
    AllCenters: "中心一览",
    MyCenters: "我的中心",
    MySpaces: "我的房间",
    Centers: "中心",
    Spaces: "房间",
    Space: "房间",
    BookingManagement: "管理预约",
    Manage: "管理",

    //Home//
    HomeGreeting1: "Hello!",
    HomeGreeting2: "想搵咩地方?",
    NearbyCenters: "附近中心",
    Centers_random: "各区中心",
    ViewMap: "查看地图",
    ViewAll: "查看全部",
    RecommendedSpaces: "推荐房间",
    SearchSpaces: "搜寻房间",

    //Status//
    bookingStatus: {
      status: '预约状态',
      pending: '待批核',
      cancelled: '已取消',
      approved: '已确认',
      rejected: '已拒绝',
    },

    //My bookiong//
    UpComing: "近期预约",
    Past: "过往预约",
    AddToCalendar: "新增到日历",
    CenterInfo: "中心资料",
    Remarks: "备注",
    FacilitiesIncluded: "已包括设备",
    SpaceFacilities: "房间设备",
    AdditionalFacilitiesAvailableForRent: "可额外借用设备",
    RebookSameSpace: "预约相同房间",
    AdditionalFacilities: "额外借用设备",

    //SpaceCard//
    Capacity: "可容纳",
    VenueSize: "面积",
    People: "人",

    //Booking dialog//
    Booking: '预约',
    NotSelected: '未选择',
    SelectMember: '选择会员',
    StartTime: '选择时间',
    Duration: '租借时长',
    Book: '预约',
    Status: '状态',
    Lockers: '储物柜',
    Listview: '列表检视',
    Calendarview: '日历检视',
    Selectspacebook: '选择预约房间',
    Pending: '待批核',
    Rejected: '拒绝',
    Approved: '已批核',
    Cancelled: '已取消',
    SetSpecialDay: '设定特别日子',
    SetSchedule: '设定日程',
    RegularSchedule: '平常日子',
    SpecialDay: '特别日子',
    ApplicableDate: '生效日期由',
    Onwards: '开始',
    Selectedday: '已选日期',
    SpecialInuse: '特别使用时间',
    Closed: '不开放',
    From: '由',
    To: '至',
    ScheduleInfo: '日程详细',
    Mon: '星期一',
    Tue: '星期二',
    Wed: '星期三',
    Thu: '星期四',
    Fri: '星期五',
    Sat: '星期六',
    Sun: '星期日',
    Holiday: '公众假期',
    CopyTo: '复制至',
    SelectAll: '全选',
    Open: '开放',
    BookingDetails: "预约详情",
    Sync: "同步",
    DoYouNeedToRentAnyAdditionalFacilities: "是否需要借用额外设备",
    Yes: "是",
    No: "否",
    BookingPerson: "预约人",
    ContactPerson: "联络人",
    SameAsBookingPerson: "与预约人相同",
    SelectDateAndTime: "选择日期及时间",
    SelectTime: "请选择时段",
    time_available: "可选",
    time_unavailable: "不可用",
    time_selected: "已选",
    DateAndTime: "日期及时间",
    ConfirmTitleText: "请确认以下预约资料！",
    TermsAndConditions: "条款及细则",
    TermsAndConditions_space: "房间预约条款及细则",
    TermsAndConditions_space_exp: "如留空此栏，将会默认使用中心预设/系统预设条款及细则。",
    TermsAndConditions_centerDefault: "预约条款及细则 (中心预设)",
 TermsAndConditions_centerDefault_exp: "当房间没有设定条款及细则，会默认使用此中心预设条款及细则。如留空此栏，将会默认使用系统预设条款及细则。",
    BookingConfirmed: "已完成预约",
    QRCodeText: "请在签到时出示会员QR Code",
    usePasscode: '使用预约密码开门',
    //Booking dialog - snackbar//
    select_date_alert: "请选择日期及时间",
    select_facility_alert: "请选择房间设备",



    //Center//
    space_facilities: "房间设备",
    AddCenter: "新增中心",
    introduction: "介绍",
    ContactInfo: "联络资料",
    Area: "地区",
    HK: "港岛",
    KLN: "九龙",
    NT: "新界",
    HK_CS: "中西区",
    HK_WC: "湾仔",
    HK_E: "东区",
    HK_S: "南区",
    KLN_YTM: "油尖旺",
    KLN_SSP: "深水埗",
    KLN_KC: "九龙城",
    KLN_WTS: "黄大仙",
    KLN_KT: "观塘",
    NT_KT: "葵青",
    NT_TW: "荃湾",
    NT_TM: "屯门",
    NT_YL: "元朗",
    NT_N: "北区",
    NT_TP: "大埔",
    NT_ST: "沙田",
    NT_SK: "西贡",
    NT_Islands: "离岛",
    address: "地址",
    tel_no: "电话",
    center_contact_person: "中心负责人姓名",
    opening_hours: "营业时间",
    map_settings: "地图设定",
    google_map_url: "Google地图连结",
    latitude: "纬度",
    longitude: "经度",
    Categories: "分类",
    DefualThemes: "预设主题",
    Schedules: '开放时间表',
    PublicHolidayCalendar: "公众假期日历",
    Settings: '设定',
    Locations: "地点",
    Location: "地点",
    center: "中心",
    organization: "机构",
    Min: "最短预约时间",
    Max: "最长预约时间",
    Increment: "时段长度",
    Mins: "分钟",
    Events: "活动",
    Event: "活动",
    Bookings: "预约",
    ViewDetails: "查看详情",

    //Locker//
    DeviceCode: '装置编号:',
    TextDescription: '文字描述',
    BookingSettings: '预约设定',
    Calendar: '日历',
    images: '图片',
    Active: '启用',
    ManualApproval: '手动批核',
    Locked: '锁定',
    Cells: '储存格',

    //Member//
    ChineseName: '中文姓名',
    PolicyChecked: '已确认条款及细则',
    Locale: '语言设定',
    English: '英文',
    Chinese: '中文',
    StartDate: '委任生效日期',
    EndDate: '委任结束日期',
    AccessRight: '访问权限',
    Roles: '职位',
    ResetPassword: '重设密码',
    MyPass: '我的QR Code',
    //Member GP//
    MemberGPname: '会员分类名称',

    //Space//
    AddSpace: '新增房间',
    EditSpace: '编辑房间',
    AutoApprove: '自动批核',
    Bookable: '可预约',
    Dependencies: '关联房间',
    Facilities: '设备',
    Type: '类型',
    FacilityName: '设备名称',
    Space_rental: '租借场地',
    OtherFacilities: '其他设施',
    space_available: '可供借用场地',
    space_categories: "房间分类",
    space_usage_theme: "房间主题",
    //SpaceType//
    AddSpaceType: '新增房间类别',
    EditSpaceType: '编辑房间类别',


    //Space - 21C//
    add_theme: "新增主题",
    edit_theme: "编辑主题",
    add_category: "新增分类",
    edit_category: "编辑分类",
    add_space: "新增房间",
    space_settings: "房间设定",
    space_name: "房间名称",
    remarks: "备注",
    capacity: "可容纳人数",
    venue_size: "房间面积",
    facilities_included: "已包括设备",
    additional_facilities_available_for_rent: "可额外借用设备",
    no_facility_found: "此中心没有提供可额外借用设备选择",
    booking_settings: "预约设定",
    defual_space_usage_themes: "预设房间主题",
    usage_theme: "主题",
    days_in_advance: "未来可预约日期",
    no_slot_available: '没有可预约的时段',
    min: "最短预约时间",
    max: "最长预约时间",
    increment: "时段长度",
    mins: "分钟",
    space_status: "房间状态",
    auto_approve: "自动批核预约",
    auto_approve_on: "自动批核预约: 开启",
    auto_approve_off: "自动批核预约: 关闭",
    door_lock_settings: "门锁设定",
    smart_lock: "智能门锁",
    booking_records: "预约纪录",
    calendar: "日历",
    event: "活动",
    public_holiday_calendar: "公众假期日历",
    duration: "租借时长",
    space_facility: "房间设备",

    //User//
    User: '用户',
    AdminUser: '管理员用户',

    //Booking//
    created_time: '建立时间',
    booked_slot: '已预订时段',
    approve: '批核',
    reject: '驳回',
    booking_approval: '预约批核',
    checkin: '签到',
    invite_frd: '邀请朋友',
    copy_invite_msg: '复制邀请讯息', 
    share_whatsapp: '分享到WhatsApp',
    cancel_booking: '取消预约',
    cancel_booking_confirm: '确定取消此预约?',
    booking_remind_msg: {
      days_after1: '即将在',
      days_after2: '日后开始!',
      starting_soon: '即将开始!',
      checkin_now: '立即签到',
      checkin_done: '已签到',
      checkin_no: '没有签到纪录',
    },
    
    

    //Doors//
    locks_control: '门锁控制',
    passcode: '开门密码',
    door_log: '开门记录',

    Filter: {
      filter: '筛选',
      all: '全部',
      district:'地区',
      cat_name: '分类名称',
      cat_all: '全部分类',
      capacity: '容纳人数',
      noSpecify: '没有指定',
      org_name: '机构名称',
      center_name: '中心名称',
      clear: '清除',
    }
  }
};

export let _common = Object.assign({}, Translate.zh_hk);
