import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { VizFormModule } from '../../common/components/form/form.module';
import { EmptyObject, utils } from '../../common/utils';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';



//import { TeamListAddDialogComponent } from './shared/team.list.add.dialog';



@Component({
   selector: 'app-booking-calendar-day',
   templateUrl: './booking.calendar.day.component.html',
   standalone:true,
   imports: [
    MatRadioModule,
    CommonModule,
    VizFormModule,
    MatInputModule,
   ]
})

export class BookingCalendarDayComponent extends SiteFormatMixin(EmptyObject) {

   _common = _common;



   @Input()
   title: string = '';



   @Input()
   form: UntypedFormGroup;



   ngOnInit() {
      this.updateForm()
   }
   updateForm() {
      if (this.form.value.open) {
         this.form.controls['time'].enable();
      } else {
         this.form.controls['time'].disable();

      }

      utils.updateValidity(this.form);

   }


}