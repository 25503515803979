/** @format */

import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment';
import { MaxError, MinError } from './errors/error-messages';

//const isUpperCase = (string) => /^[A-Z]*$/.test(string);

const isUpperCase = (string: any) => /(?=.*[A-Z])/.test(string);
const isLowerCase = (string): any => /(?=.*[a-z])/.test(string);
const isNumber = (string: any) => /(?=.*[0-9])/.test(string);
const isSpecial = (string: any) => /(?=.*[@#$])/.test(string);

// const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
export const URL_REGEX =
  // /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w\.-]*)*\/?$/g
  // /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g;
  // /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
export class AppValidators {
  static isNumber(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == '') return null;

      let vv = parseFloat(v);
      if (isNaN(vv)) {
        return {
          number: {
            valid: false,
          },
        };
      }

      return null;
    };
  }
  static functional(ff: (v) => ValidationErrors | null): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == '') return null;

      return ff(v);
    };
  }
  static nonzero(): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == undefined) return null;
      if (typeof v == 'string') v = parseFloat(v);

      if (!v) return { nonzero: { valid: false } };

      return null;
    };
  }
  static min(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == undefined) return null;
      if (typeof v == 'string') v = parseFloat(v);

      if (v < min)
        return {
          min: {
            min: min,
            actual: v,
          } as MinError,
        };

      return null;
    };
  }
  static above(min: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == undefined) return null;
      if (typeof v == 'string') v = parseFloat(v);

      if (v <= min)
        return {
          above: {
            min: min,
            actual: v,
          } as MinError,
        };

      return null;
    };
  }
  static below(max: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      let v = c.value;
      if (v == null || v == undefined) return null;

      if (typeof v == 'string') v = parseFloat(v);

      if (v >= max)
        return {
          below: {
            max: max,
            actual: v,
          } as MaxError,
        };

      return null;
    };
  }
  static future(offset?: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value == '') return null;

      let v = c.value as moment.Moment;
      let now = moment().startOf('day');

      if (!v.isAfter(now)) return { date: { valid: false } };

      return null;
    };
  }
  static nowOrFuture(offset?: number): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
      if (!c.value || c.value == '') return null;

      let v = c.value as moment.Moment;
      let now = moment().startOf('day');
      if (offset) now = now.clone().add(offset, 'day');

      if (!v.isSameOrAfter(now)) return { date: { valid: false } };

      return null;
    };
  }
}

// export function validateUrl(): ValidatorFn {

export let validateUrl = (c: AbstractControl): ValidationErrors | null => {
  const v = c.value;
  if (!v || v == '') return null;
  if (v.indexOf('://') < 0) {
    return { url: { valid: false } };
  }

  return null;
};
// }

export function validatePassword(length: number, pattern: string): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const v = c.value;
    if (v.length < length)
      return { password: { valid: false, length, pattern } };

    if (pattern.indexOf('a') >= 0)
      if (!isLowerCase(v)) {
        return { password: { valid: false, length, pattern } };
      }
    if (pattern.indexOf('A') >= 0)
      if (!isUpperCase(v)) {
        return { password: { valid: false, length, pattern } };
      }
    if (pattern.indexOf('1') >= 0)
      if (!isNumber(v)) {
        return { password: { valid: false, length, pattern } };
      }
    if (pattern.indexOf('@') >= 0)
      if (!isSpecial(v)) {
        return { password: { valid: false, length, pattern } };
      }

    return null;
  };
}

export function validateMatchPasswords(group: UntypedFormGroup) {
  // here we have the 'passwords' group
  const pass = group.controls['password'].value;
  const confirmPass = group.controls['password2'].value;

  if (pass === confirmPass) return null;

  group.controls['password2'].setErrors({ passwordNotMatched: true });
  return { passwordNotMatched: true };
}
