import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EmptyObject } from '../common/utils';
import { TSpace } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SitePathMixin } from '../core/site.path.utils';
import { calculateDistance } from '../distance';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-space-card',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, FontAwesomeModule],
  templateUrl: './space.card.html',
})
export class SpaceCard extends SiteLangMixin(SitePathMixin(EmptyObject)) {
  @Input()
  space: TSpace;
  _common = _common;

  @Input()
  spaceLink = true;
  constructor(public override session: SessionService) {
    super();
  }
  hasDistance = false;
  distance: number;
  ngOnInit() {
    if (this.space.location && this.session.location) {
      this.distance = calculateDistance(
        this.session.location.lat,
        this.session.location.lng,
        this.space.location.lat,
        this.space.location.lng
      );
      this.distance = Math.round(this.distance * 10) / 10;
      this.hasDistance = true;
    }
  }
}
