/** @format */

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DaySchedule } from '../../common/timespan/day.schedule';
import { DELETED, EmptyObject, WebUtils, utils } from '../../common/utils';


import { VizFormModule } from '../../common/components/form/form.module';
import { BookingCalendarDayComponent } from './booking.calendar.day.component';
import { BookingCalendarEntities, TBookingCalendarDate } from './booking.calendar.entities';
import { BookingCalendarUtils } from './booking.calendar.utils';

@Component({
  templateUrl: './booking.calendar.day.edit.dialog.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    FontAwesomeModule,
    VizFormModule,
    BookingCalendarDayComponent,
  ],
})
export class BookingCalendarDayEditDialogComponent extends EmptyObject {
  form: UntypedFormGroup;
  _common = _common;

  day: TBookingCalendarDate;
  // oday: TBookingScheduleDate;
  schedule: DaySchedule;

  apiUrl = '/api/schedule-calendar';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BookingCalendarDayEditDialogComponent>
  ) {
    super();

    this.day = this.data.day ?? this.data.oday;
    // this.oday = this.data.oday;
    this.schedule = this.data.schedule;

    this.form = BookingCalendarUtils.createDay(fb);

    let text = '';
    if (this.schedule) text = this.schedule.toEditText();

    let open = text != null && text != '';
    utils.fill_form_values(
      {
        open,
        time: text,
      },
      this.form
    );
  }

  updateForm() {
    utils.updateValidity(this.form);
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });

    let body = Object.assign({}, this.form.value);
    let url = `${this.apiUrl}/date/${this.day.id}`;


    body.schedule = BookingCalendarUtils.saveDay(body);
    WebUtils.web_result(this.http.put(url, body), (result) => {
      BookingCalendarEntities.fixCalendarDate(result);
      this.dialogRef.close(result);
    });
  }
  remove() {

    let url = `${this.apiUrl}/date/${this.day.id}`;

    WebUtils.web_result(this.http.delete(url), (result) => {
      this.dialogRef.close(DELETED);
    });
  }
  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
