import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterSelectObject } from './list.filter';
import { NgIf } from '@angular/common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@Component({
    selector: 'app-filter-object',
    templateUrl: './filter.select.object.component.html',
    standalone: true,
    imports: [NgIf, FontAwesomeModule]
})
export class FilterSelectObjectComponent implements OnInit {
   faTimes = faTimes
   _common = _common

   @Input()
   filter = new FilterSelectObject();


   @Output()
   select = new EventEmitter<FilterSelectObject>();
   @Output()
   change = new EventEmitter<void>();



   @Input()
   visible = true


   ngOnInit() {
   }




   refresh() {
      this.change.next(void 0);
   }
   clear(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()

      this.filter.clear();
      this.refresh();
   }
   _select() {
      this.select.next(this.filter)
   }
}
