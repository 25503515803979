/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { VizFormModule } from '../components/form/form.module';
import { FilterBool, FilterBoolTypes } from './list.filter';

@Component({
  selector: 'app-filter-bool',
  templateUrl: './filter.bool.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    VizFormModule,
    MatRadioModule,
    MatMenuModule,
  ],
})
export class FilterBoolComponent implements OnInit {
  _common = _common;

  @Input()
  filter = new FilterBool();
  @Input()
  names: string[];

  @Input()
  visible = true;

  @Input()
  set common(c: any) {
    // this.names = this.values.map((v) => c[v] || v)
  }

  @Output()
  change = new EventEmitter<void>();

  boolTypes = FilterBoolTypes;

  ngOnInit() {}

  refresh() {
    this.change.next(void 0);
  }
  clear() {
    this.filter.set(null);
    this.refresh();
  }
}
