import { Component, Input } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import moment from 'moment-timezone';
import { VizFormModule } from '../../common/components/form/form.module';
import { BookHour } from '../booking.session.service';
import { SpaceBookContainer } from '../space.book.container';
import { Thinkdesks } from '../thinkdesks';
@Component({
  selector: 'app-book-date-select-combined',
  standalone: true,
  styles: [
    `
      .hourChip {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px 10px;
        width: fit-content;
        margin: 5px;
        text-align: center;
        cursor: pointer;
        &.selected {
          background-color: blue;
        }
        &.disabled {
          background-color: #ccc;
          color: white;
          cursor: no-drop;
        }
      }
    `,
  ],
  imports: [VizFormModule, MatDatepickerModule],
  templateUrl: './book.date.select.combined.html',
})
export class BookDateSelectCombined {
  _common = _common;
  @Input()
  container: SpaceBookContainer;
  constructor() {}
  ngOnInit() {}
  now = moment();

  slotText(c: BookHour) {
    let timeFormat = Thinkdesks.timeFormat;
    let q = this.container.object.bookSlotIncrement;
    let t = c.time;
    let end = t.clone().add(Thinkdesks.BOOK_MINS * q);

    let time = t.moment(this.now);
    let endTime = end.moment(this.now);
    return `${time.format(timeFormat)} - ${endTime.format(timeFormat)}`;
  }
}
