import { LocationStrategy } from '@angular/common';


import { TBookingBase } from './thinkdesks.entities';
export class BookingCalendarUtils {
  // https://www.google.com/calendar/render?action=TEMPLATE&text=Meeting+with+Client&dates=20230720T143000Z/20230720T163000Z&details=Discuss+project+status&location=123+Main+St%2C+Anytown%2C+USA&sf=true&output=xml
  static googleCalendar(
    locationStrategy: LocationStrategy,
    booking: TBookingBase
  ) {
    let pp = `my/booking/${booking.id}`;
    
    let path = `${
      window.location.origin
    }${locationStrategy.getBaseHref()}${pp}`;

    let url = 'https://www.google.com/calendar/render';
    // 'Meeting+with+Client&dates='
    // 20230720T143000Z/20230720T163000Z&
    // 'details=Discuss+project+status&location=123+Main+St%2C+Anytown%2C+USA&sf=true&output=xml'

    let params = new URLSearchParams();
    let startDate = booking.startTime;
    let endDate = booking.endTime;
    
    startDate = startDate.clone().utc();
    endDate = endDate.clone().utc();
    let date = `${startDate.format('YYYYMMDD')}T${startDate.format(
      'HHmm00'
    )}Z/${endDate.format('YYYYMMDD')}T${endDate.format('HHmm00')}Z`;

    params.set('dates', date);

    params.set(
      'text',
      `${booking.space.name} `
    );
    params.set('location', `${booking.space?.location?.name}`);
    params.set(
      'details',
      `Manage Your Booking from here\n<a href="${path}">${path}</a>`
    );
    params.set('action', 'TEMPLATE');
    window.open(url + '?' + params.toString(), '_blank');
  }
}
