/** @format */

import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { ScrollContainerComponent } from './components/scroll.container/scroll.container.component';
import { NumberPair } from './utils';

export const LIST_TRIGGER = trigger('listTrigger', [
  transition('* => *', [
    // this hides everything right away
    query(':enter', style({ opacity: 0 }), { optional: true }),

    // starts to animate things with a stagger in between
    // query(':enter', stagger('100ms', [
    //   animate('1s', style({ opacity: 1 }))
    // ]))
    query(':enter', animate('0.3s', style({ opacity: 1 })), { optional: true }),
  ]),
]);

export interface ListOption {
  limit?: number;
  offset?: number;
  pageSort?: string;
  pageSortDir?: string;
}
/*

   table:
    matSort (matSortChange)="sortData($event)"
[matSortActive]="pageSort" [matSortDirection]="pageSortDir"
 
    column:
    mat-header-cell
    mat-sort-header="name"

      <mat-paginator [length]="rowCount" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
         (page)="updatePage($event)">
      </mat-paginator>

*/
@Directive()
export class SelectableList<T> {
  objects: MatTableDataSource<T> = new MatTableDataSource<T>([]);

  @Input()
  public selection = new SelectionModel<T>(true, []);

  isSelected() {
    return this.selection.selected.length > 0;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.objects.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.objects.data.forEach((row) => this.selection.select(row));
  }
}

@Directive()
export abstract class ListComponentBase<T>
  extends SelectableList<T>
  implements OnInit, AfterViewInit
{
  searchSubject = new Subject<string>();
  pageSort = '';
  pageSortDir: 'asc' | 'desc' = 'asc';
  rowCount = 0;
  pageIndex = 0;
  pageSize = 10;
  filter = '';
  @Input()
  refreshOnLoad = true;

  constructor(pageSort?: string, pageSortDir?: string) {
    super();
    if (pageSort && Array.isArray(pageSort)) {
      this.pageSort = (pageSort as string[])[0];
      this.pageSortDir = (pageSort as string[])[1] as any;
    } else {
      this.pageSort = pageSort || this.pageSort;
      this.pageSortDir = pageSortDir || (this.pageSortDir as any);
    }
    if (!this.pageSort) this.pageSort = '';
    if (!this.pageSortDir) this.pageSortDir = 'asc';
  }

  ngOnInit() {
    this.searchSubject.pipe(debounce(() => timer(500))).subscribe((value) => {
      this.filter = value;
      this.refreshFilter();
    });
  }
  ngAfterViewInit() {
    // searchSubject will
    this.firstRefresh();
  }
  firstRefresh() {
    if (this.refreshOnLoad) this.refresh();
  }
  refreshFilter() {
    this.refresh();
  }

  sortData(event: Sort) {
    this.pageSort = event.active;
    this.pageSortDir = event.direction as any;
    this.refresh();
  }
  updatePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.refresh();
  }
  applyFilter(s: string | EventTarget) {
    // .target.value
    let filter: string;
    if (typeof s == 'string') filter = s.trim().toLowerCase();
    else {
      filter = (s as HTMLInputElement).value;
    }
    this.searchSubject.next(filter);
  }
  clearFilter(input: HTMLInputElement) {
    if (input) input.value = '';
    this.applyFilter('');
  }
  itemToggle(event: MatCheckboxChange, o: T) {
    if (event.checked) this.selection.select(o);
    else this.selection.deselect(o);
  }
  static _listModifyUrl(
    url: string,
    params: { [param: string]: string | string[] },
  ) {
    let pp = new HttpParams({
      fromObject: params,
    });

    let _url = `${url}?${pp.toString()}`;
    return encodeURI(_url);
  }
  _listModifyUrl(
    url: string,
    params?: { [param: string]: string | string[] },
    options?: ListOption,
  ) {
    options = options || {};

    let _url =
      '?search=' +
      encodeURIComponent(this.filter) +
      '&offset=' +
      (options.offset || this.pageIndex * this.pageSize) +
      '&limit=' +
      (options.limit || this.pageSize) +
      '&sort=' +
      encodeURIComponent(options.pageSort || this.pageSort) +
      '&dir=' +
      (options.pageSortDir || this.pageSortDir);
    if (params) {
      let p = new HttpParams({ fromObject: params });
      _url += '&' + p.toString();
    }

    return encodeURI(url) + _url;
  }
  _listModifyFullUrl(url: string) {
    return encodeURI(
      url +
        '?search=' +
        this.filter +
        '&offset=0' +
        '&limit=-1' +
        '&sort=' +
        this.pageSort +
        '&dir=' +
        this.pageSortDir,
    );
  }
  _addToList(o: T) {
    //TODO inconsistency might happen
    const list = this.objects.data;

    this.rowCount++;
    list.splice(0, 0, o);
    this.objects = new MatTableDataSource<T>(list);
  }
  _appendToList(o: T) {
    //TODO inconsistency might happen
    const list = this.objects.data;

    this.rowCount++;
    list.push(o);
    this.objects = new MatTableDataSource<T>(list);
  }

  _removeFromList(o: T) {
    const list = this.objects.data;
    const index = list.indexOf(o);
    if (index >= 0) {
      this.rowCount--;
      list.splice(index, 1);
      this.objects = new MatTableDataSource<T>(list);
    }
  }

  _listLoadObjectList(objects: T[]) {
    this.rowCount = objects.length;
    const list = objects;
    this.listFixList(list);
    this.objects = new MatTableDataSource<T>(list);
  }
  _listLoadList(
    request: Observable<Object>,
    snackBar: MatSnackBar,
    error?: (err: string) => void,
    sort?: MatSort,
  ) {
    request.subscribe({
      next: (result: any) => {
        this.rowCount = result.count as number;
        const list = <T[]>result.results;
        this.listFixList(list);
        this.objects = new MatTableDataSource<T>(list);
        if (sort) this.objects.sort = sort;
      },

      error: (err) => {
        snackBar.open(err);
        if (error) error(err as string);
      },
    });
  }
  _listLoadCompleted(list: T[]) {}
  _listLoadResult(
    request: Observable<Object>,
    snackBar: MatSnackBar,
    error?: (err: string) => void,
    cb?: (list: T[]) => void,
    sort?: MatSort,
  ) {
    request.subscribe({
      next: (result: any) => {
        this.rowCount = result.count as number;
        let list = <T[]>result.results;
        this.listFixList(list);
        list = this.listFilter(list);
        this.objects = new MatTableDataSource<T>(list);
        if (sort) this.objects.sort = sort;

        this._listLoadCompleted(list);
        if (cb) cb(list);
      },
      error: (err) => {
        snackBar.open(err);
        if (error) error(err as string);
      },
    });
  }

  _listModifyMoreUrl(
    url: string,
    params?: { [param: string]: string | string[] },
    options?: ListOption,
  ) {
    options = options || {};

    let _url =
      url +
      '?search=' +
      this.filter +
      '&offset=' +
      (options.offset || this.objects ? this.objects.data.length : 0) +
      '&limit=' +
      (options.limit || this.pageSize) +
      '&sort=' +
      (options.pageSort || this.pageSort) +
      '&dir=' +
      (options.pageSortDir || this.pageSortDir);
    if (params) {
      let p = new HttpParams({ fromObject: params });
      _url += '&' + p.toString();
    }
    return encodeURI(_url);
  }

  listLoading = false;
  _listLoadMoreResult(
    request: Observable<Object>,
    snackBar: MatSnackBar,
    error?: (err: string) => void,
    cb?: (list: T[]) => void,
  ) {
    this.listLoading = true;
    request.subscribe({
      next: (result: any) => {
        this.rowCount = <number>result.count;
        const list = <T[]>result.results;
        this.listFixList(list);
        if (!this.objects) {
          this.objects = new MatTableDataSource<T>(list);
        } else {
          let data = this.objects.data.concat(list);
          this.objects = new MatTableDataSource<T>(data);
        }
        this.listLoading = false;

        if (cb) cb(list);
      },
      error: (err) => {
        this.listLoading = false;
        snackBar.open(err);
        if (error) error(err as string);
      },
    });
  }

  hasMore() {
    return !this.objects || this.objects.data.length < this.rowCount;
  }

  abstract refresh(): void;
  selectObject(o: T) {}
  listFixList(o: T[]) {}
  listFilter(o: T[]) {
    return o;
  }
}

@Directive()
export abstract class ScrollableListComponentBase<
  T,
> extends ListComponentBase<T> {
  @ViewChild('scroll', { static: true }) scroll: ScrollContainerComponent;
  pageLimit = 30;
  constructor(
    public http: HttpClient,
    public snackBar: MatSnackBar,
    public elRef: ElementRef,

    pageSort?: string,
    pageSortDir?: string,
  ) {
    super(pageSort, pageSortDir);
  }

  // abstract getUrl(): string;
  createRequest(options: ListOption): Observable<Object> | null {
    return null;
  }

  override ngAfterViewInit() {
    // searchSubject will
    if (this.refreshOnLoad) this._refresh();
  }

  refresh() {
    if (this.selection) this.selection.clear();
    this.pageIndex = 0;
    this._refresh();
  }
  _refresh() {
    const element = this.elRef.nativeElement as HTMLElement;
    const req = this.createRequest({ limit: this.pageLimit });
    if (!req) return;
    this._listLoadResult(req, this.snackBar);
  }

  handleScroll(scrolled: boolean) {
    if (scrolled) {
      this.loadMore();
    }
  }
  loadMore() {
    const req = this.createRequest({
      limit: this.pageLimit,
      offset: this.objects ? this.objects.data.length : 0,
    });
    if (!req) return;

    this._listLoadMoreResult(req, this.snackBar);
  }
  completed() {
    return this.rowCount == this.objects.data.length;
  }
  loadAll(cb?: () => void) {
    if (this.rowCount == this.objects.data.length) {
      if (cb) cb();
      return;
    }
    const req = this.createRequest({
      limit: this.rowCount - this.objects.data.length,
      offset: this.objects ? this.objects.data.length : 0,
    });
    if (!req) {
      if (cb) cb();
      return;
    }

    this._listLoadMoreResult(req, this.snackBar, (error) => {}, cb);
  }

  masterToggleAll() {
    this.isAllSelected() ? this.selectNone() : this.selectAll();
  }

  selectAll() {
    this.loadAll(() => {
      this.selection.clear();
      this.selection.select(...this.objects.filteredData);
    });
  }
  selectNone() {
    this.selection.clear();
  }
}

@Directive({})
export abstract class NumberPairListComponentBase<
  T extends NumberPair,
> extends ListComponentBase<T> {
  constructor(pageSort?: string, pageSortDir?: string) {
    super(pageSort, pageSortDir);
  }

  override itemToggle(event: MatCheckboxChange, o: T) {
    for (let _o of this.selection.selected) {
      if (_o.id == o.id) {
        this.selection.deselect(_o);

        if (event.checked) this.selection.select(o);

        return;
      }
    }
    if (event.checked) this.selection.select(o);
    else this.selection.deselect(o);
  }
}
