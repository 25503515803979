<ng-container *ngIf="visible && filter.visible">
   <div style="padding: 0px 5px !important;
   display: flex;
   height: 100%;
   align-items: center;">

      <input class="input" style="border: none;
      flex: 1 1 auto;
      padding:10px;
      margin-right:5px;
      " matInput [placeholder]="label" [(ngModel)]="filter.v" (keydown.enter)="refresh()">
      <div matSuffix (click)="clear()">
         <fa-icon [icon]="faTimes"></fa-icon>
      </div>

   </div>


</ng-container>