<div class="sectionHeader" (click)="filterObject.toggleFilter(id,$event)" *ngIf="filter.visible">
   <div class="spacer">
      <ng-content></ng-content>
   </div>
   <div>
      <fa-icon [icon]="faChevronDown" *ngIf="!filterObject.section[id]"></fa-icon>
      <fa-icon [icon]="faChevronUp" *ngIf="filterObject.section[id]"></fa-icon>
   </div>
</div>
<div class="sectionBody" *ngIf="filterObject.section[id]">
   <div>
      <app-form-field style="width: 90%;; margin: 0 10px;">
         <input matInput [(ngModel)]="filter.v" (blur)="blur($event)" placeholder="{{_common.Filter.type_to_search}}">
      </app-form-field>
   </div>

   <div class="bottom">
      <div class="button" (click)="filter.clear(); refresh()">{{_common.clear}}</div>
   </div>
</div>