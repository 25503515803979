import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmptyObject } from '../common/utils';
import { TBooking } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';

@Injectable()
export class BookingSharingService extends SiteLangMixin(
  SiteFormatMixin(EmptyObject)
) {
  constructor(
    public override session: SessionService,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard
  ) {
    super();
  }
  text(object: TBooking) {
    switch (this.session.lang) {
      case 'zh_hk':
        return `
        ${object.member.name} 邀請你參與活動。
        地點: ${this.lang(object.space?.location?.name, object.space?.location?.name_lang)}
        房間: ${this.lang(object.space?.name, object.space?.name_lang)}
        日期: ${this.formatLongDate(this.session.lang, object.startTime)}
        時間: ${object.startTime.format('hh:mmA')} - ${object.endTime.format(
          'hh:mmA'
        )} (${this.formatMins(object.durationMins, _common)})
        開門密碼: ${object.passcode}`;
      case 'zh_cn':
        return `
        ${object.member.name} 邀请你参与活动。
        地点: ${this.lang(object.space?.location?.name, object.space?.location?.name_lang)}
        房间: ${this.lang(object.space?.name, object.space?.name_lang)}
        日期: ${this.formatLongDate(this.session.lang, object.startTime)}
        时间: ${object.startTime.format('hh:mmA')} - ${object.endTime.format(
          'hh:mmA'
        )} (${this.formatMins(object.durationMins, _common)})
        开门密码: ${object.passcode}`;
      case 'en':
      default:
        return `
        ${object.member.name} is inviting you to join an event.
        Location: ${this.lang(object.space?.location?.name, object.space?.location?.name_lang)}
        Space: ${this.lang(object.space?.name, object.space?.name_lang)}
        Date: ${this.formatLongDate(this.session.lang, object.startTime)}
        Time: ${object.startTime.format('hh:mmA')} - ${object.endTime.format(
          'hh:mmA'
        )} (${this.formatMins(object.durationMins, _common)})
        Passcode: ${object.passcode}`;
    }
  }
  copy(object: TBooking) {
    this.clipboard.copy(this.text(object));
    this.snackBar.open(_common.copied);
  }
  share(object: TBooking) {
    let url = `https://wa.me/?text=${encodeURIComponent(this.text(object))}`;
    window.open(url);
  }
}
