import { CONTENT_PATH } from '../session.service';
import {
  TLocationBase,
  TLockerBase,
  TSpaceBase,
} from '../thinkdesks/thinkdesks.entities';
import { TAgentContent, TSpaceFacility } from './entities';

type Constructor<T = {}> = new (...args: any[]) => T;
export function SitePathMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }

    lockerImage(o: TLockerBase, image: string) {
      return `${CONTENT_PATH}/locker/${o.id}/${image}`;
    }
    spaceImage(o: TSpaceBase, image: string) {
      return `${CONTENT_PATH}/space/${o.id}/${image}`;
    }
    facilityImage(o: TSpaceFacility, image: string) {
      return `${CONTENT_PATH}/space-facility/${o.id}/${image}`;
    }

    locationImage(o: TLocationBase, image: string) {
      return `${CONTENT_PATH}/location/${o.id}/${image}`;
    }
    contentImage(o: TAgentContent) {
      return `${CONTENT_PATH}/content/${o.id}${o.ext}`;
    }
  };
}
