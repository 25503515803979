<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab label="Locker">
        <div class="max-scroll dialogBody">
          <form [formGroup]="form">
            <div class="card">
              <!-- <div class="cardHeader">Settings</div> -->
              <div class="cardBody space-y-2">
                <div>
                  <mat-slide-toggle formControlName="active"></mat-slide-toggle>
                  Active
                </div>
                <div>
                  <mat-slide-toggle formControlName="active"></mat-slide-toggle>
                  Approved
                </div>
                <div>
                  <mat-slide-toggle formControlName="active"></mat-slide-toggle>
                  Locked (A Smart Lock Room, cannot change delete, and?)
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>Organization</app-label>
                    <mat-select
                      formControlName="organization"
                      [compareWith]="compareWith"
                      (selectionChange)="updateLocation()"
                    >
                      <mat-option *ngFor="let o of organizations" [value]="o"
                        >{{o.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>Location</app-label>
                    <mat-select
                      formControlName="location"
                      [compareWith]="compareWith"
                      (selectionChange)="updateLocation()"
                    >
                      <mat-option *ngFor="let o of locations" [value]="o"
                        >{{o.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>Code</app-label>
                    <input matInput formControlName="code" required />
                  </app-form-field>
                </div>
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>Name</app-label>
                    <input matInput formControlName="name" required />
                  </app-form-field>
                </div>
                <div *ngIf="object">Code: {{object?.thinkdesksLockerCode}}</div>

                <div>
                  <ckeditor [editor]="Editor" formControlName="description">
                  </ckeditor>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="cardHeader">Booking Settings</div>
              <div class="cardBody space-y-2">
                <div>
                  <app-form-field style="width: 50%">
                    <app-label>Calendar</app-label>
                    <mat-select
                      formControlName="bookSchedule"
                      [compareWith]="compareWith"
                    >
                      <mat-option [value]="v" *ngFor="let v of schedules"
                        >{{v.name}}</mat-option
                      >
                    </mat-select>
                  </app-form-field>
                </div>
                <app-form-field style="width: 50%">
                  <app-label>Min</app-label>
                  <mat-select formControlName="bookMinSlots">
                    <mat-option [value]="1">30 Mins</mat-option>
                    <mat-option [value]="2">60 Mins</mat-option>
                    <mat-option [value]="3">90 Mins</mat-option>
                    <mat-option [value]="4">120 Mins</mat-option>
                  </mat-select>
                </app-form-field>
                <app-form-field style="width: 50%">
                  <app-label>Increment</app-label>
                  <mat-select formControlName="bookSlotIncrement">
                    <mat-option [value]="1">30 Mins</mat-option>
                    <mat-option [value]="2">60 Mins</mat-option>
                  </mat-select>
                </app-form-field>
                <app-form-field style="width: 50%">
                  <app-label>Max</app-label>
                  <mat-select formControlName="bookMaxSlots">
                    <mat-option [value]="1">30 Mins</mat-option>
                    <mat-option [value]="2">60 Mins</mat-option>
                    <mat-option [value]="3">90 Mins</mat-option>
                    <mat-option [value]="4">120 Mins</mat-option>
                    <mat-option [value]="5">150 Mins</mat-option>
                    <mat-option [value]="6">180 Mins</mat-option>
                    <mat-option [value]="7">210 Mins</mat-option>
                    <mat-option [value]="8">240 Mins</mat-option>
                  </mat-select>
                </app-form-field>
              </div>
            </div>
            <div class="card">
              <div class="cardHeader">Images</div>
              <div class="cardBody space-y-2">
                <app-image-list
                  [multiple]="true"
                  [imageContainer]="container"
                ></app-image-list>
              </div>
            </div>

            <div class="card" *ngIf="object">
              <div class="cardHeader">Cells</div>
              <div class="cardBody space-y-2">
                <div *ngFor="let c of object.cells; let i = index">
                  {{c.name}} {{c.thinkdesksCellCode}}
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-tab>
      <ng-container *ngIf="object">
        <mat-tab [label]="_common.Calendar">
          <app-schedule-calendar [key]="key" [subkey]="subkey">
          </app-schedule-calendar>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="objectId">
      <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
      >{{_common.delete}}
    </button>

    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
