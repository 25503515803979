import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { Translate, _common as __common } from './_common';
import { AppConfig } from './common/app.config';
import { WebUtils } from './common/utils';
import {
  AccessUtils,
  TMember,
  TOrganization,
  TSpaceFacility,
  TSpaceType,
} from './core/entities';
import { SessionCache } from './session.cache';
import { Thinkdesks, ThinkdesksTimeMode } from './thinkdesks/thinkdesks';

export let TIMEZONE = 'Asia/Hong_Kong';
export let CONTENT_PATH = '/site_content';
export let GOOGLE_MAP_APIKEY = 'AIzaSyCISVKAK2YPCrXd1JDjg3FCsaY9M-yCECA';
export const PASSWORD_LENGTH = 6;
export const PASSWORD_PATTERN = 'Aa0';

@Injectable()
export class SessionService {
  user: TMember;
  userStream = new BehaviorSubject(null);
  organizations: TOrganization[];

  lang: string = 'zh_hk';
  langStream = new Subject();
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private cache: SessionCache
  ) {}
  async init() {
    let lang = this.route.snapshot.queryParamMap.get('lang');
    lang ??= 'zh_hk';
    if (lang != 'zh_hk' && lang != 'en') {
      lang = 'zh_hk';
    }
    this.lang = lang;
    AppConfig.appConfig = (window as any).appConfig;

    // Thinkdesks customization

    Thinkdesks.init(30, {
      timeFormat: 'hh:mmA',
      timeMode: ThinkdesksTimeMode.start,
    });

    await this.refresh();
    this.getLocation();

    // let ss = new ScriptService();
    // await ss.load(`nls/${this.locale}/_common.js`);
    this.changeLocale(this.user?.locale ?? this.lang);
  }
  terms: any;
  loadTerms() {
    return new Promise((resolve, reject) => {
      if (this.terms) {
        resolve(this.terms);
        return;
      }
      WebUtils.web_result(
        this.http.get('/api/systerms'),
        (result) => {
          this.terms = result;
          resolve(this.terms);
        },
        null,
        (err) => reject(err)
      );
    });
  }

  locationStream = new BehaviorSubject(null);
  // locationLoaded = false;
  location: {
    lat: number;
    lng: number;
  };
  getLocation(): void {
    // this.locationLoaded = true;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // this.locationLoaded = true;

          const lng = position.coords.longitude;
          const lat = position.coords.latitude;
          // this.location = {
          //   lng,
          //   lat,
          // };
          this.locationStream.next({
            lng,
            lat,
          });
        },
        () => {
          // this.locationLoaded = true;
        }
      );
    } else {
      // this.locationLoaded = true;
      console.log('No support for geolocation');
    }
  }
  refresh() {
    return new Promise((resolve, reject) => {
      WebUtils.web_result(
        this.http.get('/api/session'),
        (result) => {
          this.setUser(result.user);

          let spaceTypes = result.spaceTypes as TSpaceType[];
          for (let o of spaceTypes) AccessUtils.fixSpaceType(o);
          this.cache.spaceTypes = spaceTypes;

          let spaceFacilities = result.spaceFacilities as TSpaceFacility[];
          for (let o of spaceFacilities) AccessUtils.fixSpaceFacility(o);
          // this.cache.spaceFacilities = spaceFacilities;

          resolve(null);
        },
        null,
        (err) => reject(err)
      );
    });
  }

  setUser(user: TMember) {
    if (user) {
      AccessUtils.fixMember(user);
    }
    this.user = user;

    this.userStream.next(this.user);
    this.organizations = this.user?.organizations ?? [];
  }

  login() {
    // window.location.href='/authorize'
    // let redirect = window.location.origin + '/callback';
    // let url = `${OPENID_URL}/authorize?client_id=${OPENID_CLIENTID}&responseType=code&redirect_uri=${encodeURIComponent(
    //   redirect
    // )}`;
    // window.location.href = url;
    this.router.navigate([this.path('/login')]);
  }
  logout() {
    // window.location.href='/logout'
    WebUtils.web_result(this.http.get('/logout'), (result) => {
      this.setUser(null);

      this.router.navigate([this.path('/')]);
    });
  }
  changeLocale(lang: string) {
    this.lang = lang;
    if (Translate[lang])
      Object.assign(__common, Object.assign({}, Translate[lang]));
    else Object.assign(__common, Object.assign({}, Translate.en));

    this.langStream.next(lang);
    _common = __common;
  }
  path(p: string) {
    return `/${this.lang}${p}`;
  }

  hasPermission(p: string) {
    let user = this.user;
    if (!user?.memberType) return false;
    return user.memberType.permissions.includes(p);
  }
  userQuery() {
    return {};
    // if (this.user.userType == TUserType.admin) {
    //   return {};
    // }

    // return {
    //   organizations: this.user.organizations.map((c) => c.id),
    // };
  }
  setTitle(title?: string) {
    let _title = document.getElementById('_title') as HTMLMetaElement;
    _title.innerHTML = title ?? '21c';
  }
}
