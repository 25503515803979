import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import { SessionService } from '../session.service';
import { LocationSearchListComponent } from './location.search.list.component';

@Component({
  selector: 'app-location-search',
  standalone: true,
  imports: [
    CommonModule,
    LocationSearchListComponent,
    RouterLink,
    FontAwesomeModule,
    ScrollContainerComponent,
  ],
  templateUrl: './location.search.page.html',
})
export class LocationSearchPage {
  @HostBinding('class') css = 'max-height';
  _common = _common;

  @ViewChild('list') list: LocationSearchListComponent;
  @ViewChild('scroll') scroll: ScrollContainerComponent;
  // constructor(private session: SessionService) {}
  constructor(
    private http: HttpClient,
    private router: Router,
    public session: SessionService
  ) {}

  hasMore() {
    return this.list?.hasMore();
  }
  handleScroll(scrolled: boolean) {
    return this.list?.handleScroll(scrolled);
  }
  ngOnInit() {}
}
