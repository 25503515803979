import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from './common/utils';
import { AccessUtils, TSpace } from './core/entities';
import { SitePathMixin } from './core/site.path.utils';
import { SessionCache } from './session.cache';
import { SessionService } from './session.service';
import { SpaceCard } from './user/space.card';

@Component({
  selector: 'app-featured-space-list',

  standalone: true,
  imports: [CommonModule, SpaceCard, RouterLink],
  templateUrl: './featured.space.list.page.html',
})
export class FeaturedSpaceListPage extends SitePathMixin(EmptyObject) {
  susbcription: Subscription;
  id: number;

  description: SafeHtml;
  position: any;
  options: {};
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private session: SessionService,
    private cache: SessionCache
  ) {
    super();
  }
  ngOnInit() {
    this.refresh();
  }

  list: TSpace[] = [];

  refresh() {
    WebUtils.web_list(
      this.http.post(`/api/public/space/list?limit=10`, {}),
      (result) => {
        let list = result as TSpace[];
        for (let o of list) AccessUtils.fixSpace(o);
        this.list = list;
      },
      null
    );
  }
}
