import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ImageViewerComponent } from './image.viewer.component';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        ImageViewerComponent,
    ],
    exports: [
        ImageViewerComponent,
    ],
    providers: [
        ImageViewerComponent,
    ]
})

export class VizImageViewerModule {
   constructor(library: FaIconLibrary) {

      library.addIcons(faTimes)
   }

}
