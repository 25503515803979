import { Component, Input, OnInit } from "@angular/core";
import { FilterBoolComponent } from "./filter.bool.component";
import { FilterBoolTypes } from "./list.filter";
import { NgIf } from "@angular/common";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
  selector: "app-filter-bool-chip",
  templateUrl: "./filter.bool.chip.component.html",
  standalone: true,
  imports: [NgIf, FontAwesomeModule],
})
export class FilterBoolChipComponent implements OnInit {
  faTimes = faTimes;

  @Input()
  filter: FilterBoolComponent;
  boolTypes = FilterBoolTypes;

  ngOnInit() {}

  refresh() {
    this.filter.change.next(void 0);
  }
}
