/** @format */

import moment from 'moment-timezone';
import { date_utils } from '../date_utils';
import { NumberPair } from '../utils';

//////////////////////////////////////////
//
// number based time
//
//////////////////////////////////////////

export namespace time_utils {
  export function fromMoment(d: moment.Moment) {
    return d.hours() * 100 + d.minutes();
  }
  export function toTime(hour: number, min: number) {
    return hour * 100 + min;
  }

  export function fromTime(v: number) {
    const hour = Math.floor(v / 100);
    const min = v % 100;
    return {
      hour,
      min,
    };
  }
  export function text(o: number) {
    if (o == null) return 'N/A';
    let s1 = o.toString();
    if (s1.length == 3) return '0' + s1;
    else if (s1.length == 2) return '00' + s1;
    else if (s1.length == 1) return '000' + s1;
    return s1;
  }
  export function parse(v: string) {
    const index = v.indexOf(':');
    if (index > 0) {
      let s = v.substr(0, index);
      if (s.length === 1) s = '0' + s;
      if (s.length !== 2) return null;
      const e = v.substr(index + 1);
      if (e.length !== 2) return null;
      v = s + e;
    } else {
      if (v.length === 2) v = v + '00';
      else if (v.length === 1) v = '0' + v + '00';
      else if (v.length === 3) v = '0' + v;
      else if (v.length !== 4) return null;
    }
    const vv = parseInt(v, 10);
    if (isNaN(vv)) return null;
    return vv;
  }
}

type Constructor<T = {}> = new (...args: any[]) => T;
export function TimeAtomMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }
    timeString(t: number) {
      if (t == null) return '';

      let tt = TimeAtom.parse(t);
      return tt.toString();
    }
  };
}

export class TimeAtom {
  hour: number;
  min: number;
  constructor(hour?: number, min?: number) {
    this.hour = hour || 0;
    this.min = min || 0;
  }
  static parseText(s: string) {
    if (s.includes(':')) s = s.replace(':', '');
    if (s.length > 4) return null;
    if (s.length <= 2)
      // hour
      s = s + '00';
    let h = parseInt(s);
    if (isNaN(h)) return null;
    let t2 = TimeAtom.parse(h);
    return t2;
  }
  static parse(v: number) {
    var m = v % 100;
    var h = Math.floor(v / 100);
    return new TimeAtom(h, m);
  }
  static fromMoment(o: moment.Moment) {
    return new TimeAtom(o.hours(), o.minutes());
  }

  static fromText(s: string) {
    if (s == null || s == '') return null;
    let t = moment(s.toString(), 'HH:mm');
    return TimeAtom.fromMoment(t);
  }
  static fromTimeToText(s: string) {
    if (s == null || s == '') return null;
    let atom = this.fromText(s);
    return atom.toString();
  }

  static fromTextToTime(s: string) {
    if (s == null || s == '') return null;
    let t = moment(s, 'HH:mm');
    let a = TimeAtom.fromMoment(t);
    return a.toTime();
  }
  static combineTimeLocal(d: moment.Moment, s: string) {
    if (!d) return null;
    let dd = moment.tz(d.format('YYYY-MM-DD'), date_utils.tz);
    if (s == null || s == '') return dd;
    let t = moment(s, 'HH:mm');
    return dd.minute(t.minute()).hour(t.hour());
  }
  static combineTime(d: moment.Moment, s: string) {
    if (!d) return null;
    if (s == null || s == '') return d.clone();
    let t = moment(s, 'HH:mm');

    return d.clone().minute(t.minute()).hour(t.hour());
  }
  static textTime(s: string) {
    let t = TimeAtom.fromText(s);
    if (!t) return null;
    return t.toTime();
  }

  static range(t: moment.Moment, opt: { start: TimeAtom; end: TimeAtom }) {
    t = t.clone();
    t.hour(opt.start.hour);
    t.minute(opt.start.min);
    let end = t.clone();
    if (opt.end.isBefore(opt.start)) {
      end = end.add(1, 'day');
    }
    end.hour(opt.end.hour);
    end.minute(opt.end.min);
    return {
      start: t,
      end,
    };
  }
  clone() {
    return new TimeAtom(this.hour, this.min);
  }
  moment(o: moment.Moment): moment.Moment {
    o = o.clone();
    o.hour(this.hour);
    o.minute(this.min);
    o.second(0);
    o.millisecond(0);

    return o;
  }
  toTime() {
    return this.hour * 100 + this.min;
  }
  hours() {
    return this.hour + this.min / 60;
  }
  mins() {
    return this.hour * 60 + this.min;
  }
  normalize() {
    if (this.min >= 60) {
      this.hour += Math.floor(this.min / 60);
      this.min = this.min % 60;
    } else if (this.min < 0) {
      this.hour = Math.ceil(this.min / 60);
      this.min = this.min & 60;
      if (this.min < 0) {
        this.hour--;
        this.min += 60;
      }
      if (this.hour < 0) this.hour += 24;
    }
  }
  // normalize hours
  normalizeHour() {
    var day = Math.floor(this.hour / 24);

    this.hour = this.hour % 24;
    return day;
  }

  minus(mins: number) {
    while (mins > 0) {
      if (this.min <= 0) {
        this.min = 60;
        this.hour--;
      }
      if (mins >= this.min) {
        mins -= this.min;
        this.min = 0;
      } else {
        this.min -= mins;
        mins = 0;
      }
    }

    return this;
  }

  add(mins: number) {
    this.min += mins;
    this.normalize();

    return this;
  }
  equals(b: TimeAtom) {
    return this.hour == b.hour && this.min == b.min;
  }
  diff(b: TimeAtom) {
    var h = this.hour - b.hour;
    var m = this.min - b.min;
    if (m < 0) {
      m += 60;
      h--;
    }
    return new TimeAtom(h, m);
  }
  ceil(unit: number, offset: number) {
    let c = (this.min - offset) % unit;
    if (c) {
      this.min -= c;

      if (c > 0) this.add(unit);
      else this.normalize();
    }
  }
  isBefore(a: TimeAtom) {
    let time = this.toTime();
    let _a = a.toTime();
    return time < _a;
  }
  isSameOrBefore(a: TimeAtom) {
    let time = this.toTime();
    let _a = a.toTime();
    return time <= _a;
  }
  isAfter(a: TimeAtom) {
    let time = this.toTime();
    let _a = a.toTime();
    return time > _a;
  }
  isSameOrAfter(a: TimeAtom) {
    let time = this.toTime();
    let _a = a.toTime();
    return time >= _a;
  }
  between(
    a: TimeAtom,
    b: TimeAtom,
    opts?: {
      startExclusive?: boolean;
      endInclusive?: boolean;
    },
  ) {
    let time = this.toTime();
    let _a = a.toTime();
    let _b = b.toTime();

    while (_b < _a) _b += 2400;

    opts = opts || {};
    if (!opts.startExclusive) {
      if (!(_a <= time)) return false;
    } else {
      if (!(_a < time)) return false;
    }
    if (opts.endInclusive) {
      if (!(time <= _b)) return false;
    } else {
      if (!(time < _b)) return false;
    }

    return true;
    // return _a <= time && time < _b;
  }
  _text(o: number) {
    let s1 = o.toString();
    if (s1.length < 2) s1 = '0' + s1;
    return s1;
  }
  toShortString() {
    if (!this.min) {
      return this.hour.toString();
    }
    return `${this.hour}:${this._text(this.min)}`;
  }
  toNumString() {
    return this._text(this.hour) + this._text(this.min);
  }
  toString(opts?: { noramlize?: boolean }) {
    opts = opts || {};
    let hour = this.hour;
    let min = this.min;
    if (opts.noramlize) {
      hour = hour % 24;
      min = min % 60;
    }

    return this._text(hour) + ':' + this._text(min);
  }

  static getHoursValues(slot?: number) {
    slot = slot || 60;
    const list: NumberPair[] = [];
    let slots = (24 * 60) / slot;
    let atom = new TimeAtom();
    for (let i = 0; i < slots; i++) {
      list.push({
        id: atom.toTime(),
        name: atom.toString(),
      });
      atom.add(slot);
    }
    return list;
  }
}
