

export class Color {
   r: number;
   g: number;
   b: number;
   a: number;
   constructor(r?: number | string, g?: number, b?: number, a?: number) {

      if (r && typeof r === 'string') {
         this.parseColor(<string>r);

      } else {
         this.r = <number>r;
         this.g = g;
         this.b = b;
         this.a = a;
      }
   }
   textColor() {
      return (this.r * 0.299 + this.g * 0.587 + this.b * 0.114) > 186;
   }
   parseColor(hex: string) {
      hex = hex.trim();
      if (hex.startsWith('rgb(')) {
         hex = hex.substr(4, hex.length - 5);
         const tokens = hex.split(',');
         this.r = parseInt(tokens[0]);
         this.g = parseInt(tokens[1]);
         this.b = parseInt(tokens[2]);
      } else if (hex.startsWith('rgba(')) {
         hex = hex.substr(5, hex.length - 6);
         const tokens = hex.split(',');
         this.r = parseInt(tokens[0]);
         this.g = parseInt(tokens[1]);
         this.b = parseInt(tokens[2]);
         this.a = parseFloat(tokens[3]);

      } else {
         hex = String(hex).replace(/[^0-9a-f]/gi, '');
         if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
         }
         // convert to decimal and change luminosity

         this.r = parseInt(hex.substr(0, 2), 16);
         this.g = parseInt(hex.substr(2, 2), 16);
         this.b = parseInt(hex.substr(4, 2), 16);

      }
      return this;
   }
   setColor(v: number[]) {
      this.r = v[0];
      this.g = v[1];
      this.b = v[2];
      if (v.length > 3)
         this.a = v[3];
      return this;
   }
   rgba2rgb(RGB_background: Color, RGBA_color: Color) {
      const alpha = RGBA_color.a;

      this.r = Math.round((1 - alpha) * RGB_background.r + alpha * RGBA_color.r);
      this.g = Math.round((1 - alpha) * RGB_background.g + alpha * RGBA_color.g);
      this.b = Math.round((1 - alpha) * RGB_background.b + alpha * RGBA_color.b);
   }
   changeIntensity(lum: number) {
      lum = lum || 0;
      this.r = Math.round(Math.min(Math.max(0, this.r + (this.r * lum)), 255));
      this.g = Math.round(Math.min(Math.max(0, this.g + (this.g * lum)), 255));
      this.b = Math.round(Math.min(Math.max(0, this.b + (this.b * lum)), 255));
   }


   withAlpha(alpha: number) {
      this.a = alpha;
      return this;
   }
   rgba() {
      return this.toCss(true);
   }
   rgb() {
      return this.toCss(false);
   }
   componentToHex(c: number) {
      const hex = c.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
   }

   hex() {
      return this.componentToHex(this.r) + this.componentToHex(this.g) + this.componentToHex(this.b);
   }

   toCss(includeAlpha?: boolean) {
      if (this.a == null)
         includeAlpha = false;
      const rgb = this.r + ', ' + this.g + ', ' + this.b;
      return (includeAlpha ? 'rgba(' + rgb + ', ' + this.a : 'rgb(' + rgb) + ')';
   }

}


export class color_utils {


   static color_sets: string[] = [
      '#EF5350',
      '#EC407A',
      '#AB47BC',
      '#7E57C2',
      '#5C6BC0',
      '#42A5F5',
      '#29B6F6',
      '#26C6DA',
      '#26A69A',
      '#66BB6A',
      '#9CCC65',
      '#D4E157',
      '#FFEE58',
      '#FFCA28',
      '#FFA726',
      '#FF7043',
   ];

   static getColor(index: number) {
      return color_utils.color_sets[index & color_utils.color_sets.length];
   }
   static findColor(s: string) {
      if (s == null) return color_utils.color_sets[0];
      let sum = 0;
      for (let i = 0; i < s.length; i++)
         sum = sum * 13 + s.charCodeAt(i);

      sum = sum % color_utils.color_sets.length;
      return color_utils.color_sets[sum];
   }

   static textColor(bgColor: string) {
      if (!bgColor) return '#000';
      const c = new Color(bgColor);
      return c.textColor() ? '#000' : '#fff';
   }
}

type Constructor<T = {}> = new (...args: any[]) => T;
export function ColorMixin<TBase extends Constructor>(Base: TBase) {
   return class extends Base {
      constructor(...args: any[]) {
         super(...args);
      }
      findColor(s: string) {
         return color_utils.findColor(s);
      }

   };
}

