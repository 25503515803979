import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from './loader.service';
import { LoaderState } from './loader';

@Component({
   selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.css'],
    standalone: true
})
export class LoaderComponent implements OnInit, OnDestroy {

   show = false;

   @Input()
   image:string='/img/spinner.gif';
   private subscription: Subscription;

   constructor(private loaderService: LoaderService,
      private cdRef: ChangeDetectorRef) { }

   ngOnInit() {
      this.subscription = this.loaderService.loaderState
         .subscribe((state: LoaderState) => {
            this.show = state.count > 0;
            this.cdRef.detectChanges();
         });
   }

   ngOnDestroy() {
      this.subscription.unsubscribe();
   }
}
