<div class="ctlMonthSelector">
  <div (click)="goPrevYear()" class="button">
    <fa-icon icon="angle-double-left"></fa-icon>
  </div>
  <div (click)="goPrev()" class="button">
    <fa-icon icon="angle-left"></fa-icon>
  </div>
  <div class="title">
    <input
      matInput
      [matDatepicker]="picker"
      style="width: 0px"
      [(ngModel)]="date"
    />
    <span (click)="open(picker)">{{ title }}</span>

    <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
    <mat-datepicker
      #picker
      startView="year"
      (monthSelected)="chosenMonthHandler($event, picker)"
    ></mat-datepicker>
  </div>
  <div (click)="goNext()" class="button">
    <fa-icon icon="angle-right"></fa-icon>
  </div>
  <div (click)="goNextYear()" class="button">
    <fa-icon icon="angle-double-right"></fa-icon>
  </div>
</div>
