<div class="dialog-max cdialog">
  <div class="dialogHeader">
    <h2 mat-dialog-title>{{_common.filters}}</h2>
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="margin_top max-scroll">
    <div class="dialogBody">
      <form [formGroup]="form">
        <app-form-field>
          <app-label>{{_common.name}}</app-label>
          <input matInput formControlName="name" />
        </app-form-field>
      </form>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <button class="app-btn btn-outline" (click)="remove()" *ngIf="id">{{_common.remove}}</button>
    <div class="spacer"></div>
    <button class="app-btn" (click)="save()" color="primary">
      {{_common.add}}
    </button>
  </div>
</div>