<!-- @format -->

<div
  class="dialog-max cdialog overlay material-max"
  style="width: 60vw; height: 80vh"
>
  <div class="dialogHeader tab">
    <div class="spacer"></div>
    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>

  <mat-dialog-content class="max-height">
    <mat-tab-group>
      <mat-tab label="Organization">
        <form [formGroup]="form" class="max-scroll dialogBody ctlSettings">
          <div class="card">
            <!-- <div class="cardHeader">Settings</div> -->
            <div class="cardBody space-y-2">
              <div>
                <app-form-field style="width: 50%">
                  <app-label>{{_common.code}}</app-label>
                  <input matInput formControlName="code" required />
                </app-form-field>
              </div>
              <div>
                <app-form-field style="width: 50%">
                  <app-label>{{_common.name}}</app-label>
                  <input matInput formControlName="name" required />
                </app-form-field>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="{{_common.other_language}}">
        <div class="dialogBody max-scroll">
          <div *ngFor="let dlang of dlangs" class="card">
            <div class="cardBody flex flex-col space-y-2">
              <div class="lang-chip">{{_common[_common.langs??[dlang]??dlang]}}</div>

              <div [formGroup]="$any(form.controls['name_lang'])">
                <app-form-field style="width: 100%" appearance="outline">
                  <app-label>{{_common.space_name}}</app-label>
                  <input matInput [formControlName]="dlang" />
                </app-form-field>
              </div>

              
              
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="_common.Locations" *ngIf="object">
        <div class="max-height dialogBody">
          <app-location-list [organization]="object"></app-location-list>
        </div>
      </mat-tab>
      <mat-tab [label]="_common.Spaces" *ngIf="object">
        <div class="max-height dialogBody">
          <app-space-list [organization]="object"></app-space-list>
        </div>
      </mat-tab>

      <mat-tab [label]="_common.Schedules" *ngIf="object">
        <div class="max-height dialogBody">
          <app-schedule-calendar-list
            [query]="scheduleQuery"
          ></app-schedule-calendar-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <div class="dialogBar">
    <ng-container *ngIf="objectId">
      <button class="app-btn btn-outline" (click)="remove()">
        <fa-icon icon="trash" style="margin-right: 5px"></fa-icon
        >{{_common.delete}}
      </button>
      <!--<button class="app-btn btn-outline" (click)="changePassword()">
        {{_common.change_password}}
      </button>-->
    </ng-container>
    <div class="spacer"></div>

    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>
