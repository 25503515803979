<div *ngIf="object" class="client_pageBody item_details_page">
  <div class="tt_sidebar">
    <div *ngFor="let image of object.images" class="mobile_hide">
      <img [src]="spaceImage(object,image)" class="tt_sidebar_image" />
    </div>

    <!--Mobile Carousel-->
    <div class="desktop_hide"
        style="
        height: 200px;
        border: 1px solid #ccc;
        position: relative;
      ">
        <fixed-carousel>
          <ng-container *ngFor="let image of object.images; let i=index">
            <ng-container *fixedCarouselItem>
              <img [src]="spaceImage(object,image)" style="width: 100%;"/>
            </ng-container>
          </ng-container>
        </fixed-carousel>

        <ng-container *ngIf="session.user">
          <div style="position: absolute; top: 10px; right: 10px;">
            <div  (click)="wishlist()"
              class="client_button btn_circle" 
            >
              <fa-icon *ngIf="!_wishlist" [icon]="['far','heart']"></fa-icon>
              <fa-icon *ngIf="_wishlist" 
                icon="heart"
                style="color: red;"
              ></fa-icon>
            </div>

          </div>
          
        </ng-container>
    </div>

  </div>

  <!--Right Side-->
  <div class="tt_right">
    <div class="flex mb-4" style="color: #707070">
      <div *ngFor="let type of object.types" class="spaceType-tags flex">
        <div>{{ lang(type.name,type.name_lang) }}</div>
      </div>
    </div>
    <div class="font-bold text-2xl mb-1 break-words">
      {{ lang(object.name,object.name_lang) }}
    </div>

    <div>{{ lang(object.location?.name,object.location?.name_lang) }}</div>

    <!--Remarks-->
    <ng-container *ngIf="object.remarks">
      <div
        class="space-y-2 mt-4"
        style="
          background: #f7fbff;
          color: #0096ff;
          border: 1px solid #0096ff;
          padding: 1rem;
          border-radius: 10px;
        "
      >
        <div>{{object.remarks}}</div>
      </div>
    </ng-container>

    <!--Description-->
    <div
      [innerHtml]="description"
      style="margin: 1rem 0"
      class="ckeditor-content"
    ></div>

    <div class="item_info">
      <div class="line_item">
        <fa-icon icon="phone-flip" class="icon"></fa-icon>
        {{object.location?.tel}}
      </div>
      <div class="line_item">
        <fa-icon icon="map-location-dot" class="icon"></fa-icon>
        <div (click)="openGoogleMap()" class="text-link">
          {{ lang(object.location?.address,object.location?.address_lang) }}
        </div>
      </div>
      <div class="line_item">
        <fa-icon icon="clock" class="icon"></fa-icon>
        <div>
          <div *ngFor="let l of openingHours">
            {{l}}
            <!--{{ lang(l, l_lang) }}-->
          </div>
        </div>
      </div>
      <div class="line_item">
        <fa-icon icon="users" class="icon"></fa-icon>
        {{_common.Capacity}} {{object.capacity}} {{_common.People}}
        ({{object.area}}m<sup>2</sup>)
      </div>
    </div>
    <hr />

    <div class="space-y-2 mt-8">
      <div class="font-bold">{{_common.SpaceFacilities}}</div>
      <div>
        <div class="underline">{{_common.FacilitiesIncluded}}</div>
        <ng-container *ngIf="!object.includedFacilities"> N/A </ng-container>
        <div>
          {{ lang(object.includedFacilities,object.includedFacilities_lang) }}
        </div>
      </div>
      <div>
        <div class="underline">
          {{_common.AdditionalFacilitiesAvailableForRent}}
        </div>
        <div class="flex">
          <ng-container *ngIf="!object.facilities.length"> N/A </ng-container>
          <div
            *ngFor="let f of object.facilities"
            class="list_comma_separated flex"
          >
            {{ lang(f.name,f.name_lang) }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      {{_common.share}}:
      <span (click)="share()" class="cursor-pointer">
        <fa-icon icon="link"></fa-icon>
      </span>
    </div>

    <div class="flex space-x-2 mt-8 justify-center items-center sm:justify-start">
      <div class="client_button" (click)="book()">{{_common.Book}}</div>
      <ng-container *ngIf="session.user">
        <div *ngIf="!_wishlist" (click)="wishlist()"
          class="client_button btn_circle mobile_hide"
        >
          <fa-icon [icon]="['far','heart']"></fa-icon>
        </div>
        <div *ngIf="_wishlist" (click)="wishlist()"
          class="client_button btn_circle mobile_hide"
        >
          <fa-icon icon="heart"style="color: red;"></fa-icon>
        </div>
      </ng-container>
    </div>
  </div>
</div>
