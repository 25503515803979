/** @format */

import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { FilterObject, _ListFilter } from './list.filter';

@Component({
  selector: 'app-filter-tabs',
  standalone: true,
  styleUrls: ['./filter.tabs.component.css'],
  templateUrl: './filter.tabs.component.html',
  imports: [NgIf, NgFor, MatTabsModule],
})
export class FilterTabsComponent implements OnInit {
  @Input()
  locale: string;
  @Input()
  filterObject = new FilterObject();

  @Input()
  _common: any = {};

  @Input()
  extra: {
    id: string;
    name: string;
    filter: _ListFilter;
  }[] = [];
  @Output()
  extraSelected = new EventEmitter<string>();
  ngOnInit() {}

  selectFilter(o) {
    this.filterObject.curFilter = o.filter;
    this.extraSelected.next(o.id);
  }
  lang(f: _ListFilter) {
    if (!f.name_lang) return f.name;
    return f.name_lang[this.locale] || f.name;
  }
}
