import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { VizFormModule } from '../common/components/form/form.module';
import { CustomListFilterDialogComponent } from '../common/filter/custom.list.filter.dialog';
import { WebUtils } from '../common/utils';
import { TBooking, TLocation } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { BookDuration, BookHour } from '../thinkdesks/booking.session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { SpaceBookContainer } from '../thinkdesks/space.book.container';
import { BookDateSelectCombined } from '../thinkdesks/widgets/book.date.select.combined';
import { BookDateSelectSimple } from '../thinkdesks/widgets/book.date.select.simple';
import { SpaceCard } from './space.card';

@Component({
  selector: 'app-space-book-page',
  standalone: true,
  imports: [
    VizFormModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    SpaceCard,
    FormsModule,
    MatSlideToggleModule,
    CustomListFilterDialogComponent,
    BookDateSelectSimple,
    BookDateSelectCombined,
  ],
  templateUrl: './space.book.page.html',
})
export class SpaceBookPage extends SiteLangMixin(
  SiteFormatMixin(SpaceBookContainer)
) {
  @HostBinding('class') css = 'max-scroll';
  susbcription: Subscription;
  _common = _common;
  constructor(
    public override snackBar: MatSnackBar,
    public override http: HttpClient,
    private route: ActivatedRoute,
    public override router: Router,
    public override cache: SessionCache,
    private fb: FormBuilder,
    public override session: SessionService
  ) {
    super();
    this.form = fb.group({
      contact: [true],
      contactName: [''],
      contactEmail: [''],
      contactMobile: [''],
      startTime: [null, Validators.required],
      duration: [null, Validators.required],
      _facilities: [false],
      facilities: this.fb.array([]),
      remarks: [''],
    });
  }
  get formFacilities() {
    return this.form.controls['facilities'] as FormArray;
  }
  get container() {
    return this;
  }
  ngOnInit() {
    if (!this.session.user) {
      this.router.navigate([this.session.path('/')]);
      return;
    }
    this.susbcription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));
      this.refresh();
    });
  }
  terms: any = {};
  termsLines = [];
  override initObject(): void {
    let ff = this.formFacilities;
    ff.clear();
    for (let f of this.object.facilities) {
      ff.push(new FormControl(false));
    }

    if (this.object.terms && this.object.terms != '') {
      this.terms = {
        terms: this.object.terms,
        terms_lang: this.object.terms_lang,
      };
      this.processTerms();
    } else {
      let location = this.object.location as TLocation;
      if (location && location.terms && location.terms != '') {
        this.terms = {
          terms: location.terms,
          terms_lang: location.terms_lang,
        };
        this.processTerms();
      } else {
        let cb = async () => {
          this.terms = await this.session.loadTerms();
          this.processTerms();
        };
        cb();
      }
    }
  }
  processTerms() {
    let terms = this.lang(this.terms.terms, this.terms.terms_lang);
    this.termsLines = terms.split('\n');
  }
  ngOnDestroy() {
    this.susbcription?.unsubscribe();
  }

  override getQuery() {
    let q = super.getQuery();
    if (!q) {
      if (
        !this.selected ||
        !this.form.value.duration ||
        !this.form.value.startTime
      ) {
        this.snackBar.open(_common.select_date_alert);
      }
      return null;
    }
    let list = [];
    let ff = this.formFacilities;
    this.object.facilities.forEach((f, index) => {
      if (ff.controls[index].value) {
        list.push(WebUtils.objName(f));
      }
    });
    q.facilities = list;
    if (this.form.value._facilities && !list.length) {
      this.snackBar.open(_common.select_facility_alert);
      return null;
    }
    return q;
  }
  confirming = false;
  confirm() {
    let info = this.getQuery();
    if (!info) return;
    this.confirming = true;
    window.scrollTo(0, 0);
  }
  override afterBooking(o: TBooking): void {
    this.router.navigate([`/my/booking/${o.id}/completed`]);
  }

  selectedText() {
    let date = this.selected;
    if (!date) return null;
    date = date.clone();
    let slot = this.form.value.startTime as BookHour;
    if (!slot) return null;

    let duration = this.form.value.duration as BookDuration;
    if (!duration) return null;
    let start = slot.time.moment(date);
    let _end = slot.time.clone();
    _end.add(duration.value);
    let end = _end.moment(date);
    return `${this.formatLongDate(this.session.lang, date)} ${start.format(
      'hh:mmA'
    )} - ${end.format('hh:mmA')}`;
  }
  showDialog = false;
  timeText() {
    let date = this.selected;
    if (!date) return null;
    date = date.clone();
    let slot = this.form.value.startTime as BookHour;

    let duration = this.form.value.duration as BookDuration;
    let start = slot.time.moment(date);
    let _end = slot.time.clone();
    _end.add(duration.value);
    let end = _end.moment(date);
    return `${start.format('hh:mmA')} - ${end.format('hh:mmA')}`;
  }
}
