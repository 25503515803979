import { DaySchedule } from '../common/timespan/day.schedule';
import { TimeAtom } from '../common/timespan/time_utils';
import { AccessUtils, TBooking, TLocker } from '../core/entities';
import { Thinkdesks } from './thinkdesks';
import {
  BookingCalendarEntities,
  TBookingCalendar,
  TBookingCalendarDate,
} from './widgets/booking.calendar.entities';

export class BookingAvailabilityManager {
  schedule: DaySchedule[] = [new DaySchedule()];
  bookings: TBooking[] = [];
  load(
    dd: moment.Moment, // local tz
    data: any
  ) {
    dd = dd.clone();
    this.bookings = data.bookings as TBooking[];
    for (let o of this.bookings) AccessUtils.fixBooking(o);

    let periods: TBookingCalendar[] = data.periods as TBookingCalendar[];
    let dates: TBookingCalendarDate[] = data.dates as TBookingCalendarDate[];
    let odates: TBookingCalendarDate[] = data.odates as TBookingCalendarDate[];

    for (let o of periods) BookingCalendarEntities.fixSchedulePeriod(o);
    for (let d of dates) BookingCalendarEntities.fixCalendarDate(d);
    for (let d of odates) BookingCalendarEntities.fixCalendarDate(d);

    this.schedule = [];
    for (var i = 0; i < Thinkdesks.BOOK_DAYS; i++) {
      let d = dd.add(i, 'days');

      let _periods = periods.filter((c) => {
        return (
          !c.startDate.isAfter(d) &&
          (c.endDate == null || !d.isAfter(c.endDate!))
        );
      });
      let _dates = dates.filter((c) => {
        return c.date.isSame(d);
      });

      let _odates = odates.filter((c) => {
        return c.date.isSame(d);
      });
      let schedule = this.loadDate(d, {
        periods: _periods,
        dates: _dates,
        odates: _odates,
      });
      this.schedule.push(schedule);
    }
  }
  loadDate(
    dd: moment.Moment,
    opts: {
      periods: TBookingCalendar[];
      dates: TBookingCalendarDate[];
      odates: TBookingCalendarDate[];
    }
  ) {
    let periods = opts.periods;
    let dates = opts.dates;
    let odates = opts.odates;
    let ss = new DaySchedule();

    for (let c of periods) {
      let w = dd.weekday();
      let schedule: DaySchedule;

      //TODO check holiday
      switch (w) {
        case 0:
          schedule = c.sun;
          break;
        case 1:
          schedule = c.mon;
          break;
        case 2:
          schedule = c.tue;
          break;
        case 3:
          schedule = c.wed;
          break;
        case 4:
          schedule = c.thu;
          break;
        case 5:
          schedule = c.fri;
          break;
        case 6:
          schedule = c.sat;
          break;
      }
      ss = schedule;
      break;
    }

    if (odates.length) {
      ss = odates[0].schedule;
    } else if (dates.length) {
      ss = dates[0].schedule;
    }

    return ss;
  }
  match(list: TimeAtom[]): boolean {
    for (var s of list) {
      s = s.clone();
      var day = s.normalizeHour();
      if (day >= this.schedule.length) return false;

      if (!this.schedule[day]?.match([s])) {
        return false;
      }
    }
    return true;
  }
  between(d: moment.Moment, booking: TBooking) {
    var startTime = booking.startTime;
    if (startTime != null) {
      if (startTime.isAfter(d)) return false;
    }
    var endTime = booking.endTime;
    if (endTime != null) {
      if (!endTime.isAfter(d)) return false;
    }
    return true;
  }
  checkTime(date: moment.Moment, time: TimeAtom) {
    if (!this.bookings.length) return true;
    var dd = time.moment(date);

    return !this.bookings.find((c) => {
      return this.between(dd, c);
    });
  }
  checkLockerTime(date: moment.Moment, locker: TLocker, time: TimeAtom) {
    return !!this.availableCells(locker, date, time).length;
  }
  availableCells(locker: TLocker, date: moment.Moment, time: TimeAtom) {
    let available = locker.cells.slice();
    //TODO remove cells based on cell schedule

    //remove cell if a booking exiss
    if (this.bookings.length) {
      let list = available.slice();
      for (let cell of list) {
        if (
          this.bookings.find((c) => {
            return c.lockerCell?.id == cell.id && this.between(date, c);
          })
        ) {
          let index = available.indexOf(cell);
          available.splice(index, 1);
        }
      }
    }

    return available;
  }
}
