/** @format */

import { ElementRef } from '@angular/core';
import moment from 'moment-timezone';
import { AppConfig } from './app.config';
import { TimeAtom } from './timespan/time_utils';
import { NumberPair } from './utils';

export enum DataInterval {
  'MONTH' = 'MONTH',
  'WEEK' = 'WEEK',
  'DATE' = 'DATE',
  'HOUR' = 'HOUR',
  'MIN30' = 'MIN30',
  'MIN15' = 'MIN15',
}

export enum DateOfWeek {
  SUN = 'SUN',
  MON = 'MON',
}
export enum WeekOfYear {
  JAN1 = 'JAN1',
  FIRST_THUR = 'FIRST_THUR',
}

// Weekend/Weekday group
export enum DayValues {
  ALL = 'ALL',
  WK_SATSUN = 'WK_SATSUN',
  WK_FRISAT = 'WK_FRISAT',
  WK_THUSUN = 'WK_THUSUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export enum DateSlice {
  NONE = 'NONE',
  ALL = 'ALL',
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
  SUNTHU = 'SUNTHU',
  FRISAT = 'FRISAT',
  MONTHU = 'MONTHU',
  FRISUN = 'FRISUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export class date_utils {
  static tz = moment.tz.guess();
  static localTz = moment.tz.guess();

  static buildDuration(o: moment.Moment, tz?: string) {
    if (o == null) return '';
    if (tz) {
      o = o.clone().tz(tz);
    }
    var now = moment();
    var diffDays = now.diff(o, 'day');
    var diffHours = now.diff(o, 'hour');
    var diffMinutes = now.diff(o, 'minute');
    var diffMonths = now.diff(o, 'month');

    if (diffDays == 0) {
      if (diffHours == 0) return `${diffMinutes} Mins`;
      else return `${diffHours} Hours  ${diffMinutes % 60} Mins`;
    } else if (diffDays <= 31) {
      return `${diffDays} Days`;
    } else {
      return `${diffMonths} Months`;
    }
  }
  static buildDateText(o: moment.Moment, tz?: string) {
    if (o == null) return '';
    if (tz) {
      o = o.clone().tz(tz);
    }
    var now = moment();
    var diffDays = now.diff(o, 'day');
    var diffHours = now.diff(o, 'hour');
    var diffMinutes = now.diff(o, 'minute');
    var format = 'DD MMM';
    var format2 = 'HH:mm';

    var locale = moment.locale();
    if (locale && locale.startsWith('zh')) format = 'MMMD日';

    if (diffDays > 1) {
      return `${o.format(format)} at ${o.format(format2)}`;
    } else if (diffDays == 1) {
      return `Yesterday at ${o.format(format2)}`;
    } else if (diffHours > 1) {
      return `${diffHours} hours`;
    } else if (diffHours == 1) {
      return `${diffHours} hour`;
    } else if (diffMinutes == 1) {
      return '1 min';
    } else {
      return `${diffMinutes} mins`;
    }
  }
  static isOverlap(
    s1: { start: moment.Moment; end: moment.Moment },
    s2: { start: moment.Moment; end: moment.Moment }
 ) {
    if (s1.start.isSameOrBefore(s2.start) && s1.end.isSameOrAfter(s2.start))
       return true;
    if (s1.start.isSameOrBefore(s2.end) && s1.end.isSameOrAfter(s2.end))
       return true;
    if (s2.start.isSameOrBefore(s1.start) && s2.end.isSameOrAfter(s1.start))
       return true;
    if (s2.start.isSameOrBefore(s1.end) && s2.end.isSameOrAfter(s1.end))
      return true;

    return false;
 }
  static asUtcMoment(o: moment.Moment) {
    if (!o) return null;
    var s = o.format('YYYY-MM-DD HH:mm:ss');
    return moment.tz(s, 'UTC');
  }
  static toMoment(str: any, tz?: string) {
    if (!str) return null;
    if (tz) return moment.tz(<string>str, tz);
    else return moment.utc(<string>str);
  }
  static toMomentLocal(str: any) {
    if (!str) return null;
    if (moment.isMoment(str)) return str.tz(this.tz);
    return moment(<string>str).tz(this.tz);
  }

  static toLocalDate(o: moment.Moment) {
    const s = o.format('YYYY-MM-DD HH:mm:ss');
    return new Date(s);
  }
  static fromLocalDate(date: Date) {
    let s = moment(date).tz('UTC');
    let offset = date.getTimezoneOffset();
    s = s.clone().add(-offset, 'minute');
    return s;
  }

  static splitTime(date: moment.Moment) {
    if (date) {
      return date.format('HH:mm');
    } else {
      return null;
    }
  }
  static timeValidator(func: () => ElementRef) {
    return (c): any => {
      try {
        let ref = func();
        if (!ref) return;

        let v = ref.nativeElement.value;
        let vv = c.value;
        if (v != vv) {
          if (!v.includes(':')) {
            let tt = TimeAtom.parseText(v);
            v = tt.toString();
          }

          c.value = v;
        }
        return null;
      } catch (ex) {
        return { required: true };
      }
    };
  }
  static combineTimeLocal(date: moment.Moment, time: string) {
    if (!date) return null;

    let d = date.format('YYYY-MM-DD') + ' ' + time;
    return moment.tz(d, 'YYYY-MM-DD HH:mm', date_utils.tz);
  }

  static combineTime(date: moment.Moment, time: string) {
    if (!date) return null;

    if (time) {
      var _time = moment(time, 'HH:mm');
      date.hours(_time.hours());
      date.minutes(_time.minutes());
    }
    return date;
  }
  static toQuery(d: moment.Moment) {
    if (!d) return null;
    return d.format('YYYY-MM-DD');
  }
  static toQueryTime(d: moment.Moment) {
    if (d == null) return null;
    return d.format('YYYY-MM-DD HH:mm:ss');
  }

  // d has local time as UTC
  static toQueryAsUTC(d: moment.Moment) {
    if (!d) return null;
    const s = d.format('YYYY-MM-DD HH:mm:ss');
    d = moment.tz(s, 'YYYY-MM-DD HH:mm:ss', date_utils.tz).utc();
    return d.format('YYYY-MM-DD HH:mm:ss');
  }
  static toQueryList(dates: moment.Moment[]) {
    if (!dates) return [];
    const list = [];
    for (const c of dates) list.push(c.format('YYYY-MM-DD'));
    return list;
  }
  static fromQuery(s: string | Date | moment.Moment, tz?: string) {
    if (!s) return null;
    if (s instanceof Date) return moment.tz(s, tz);
    else if (typeof s == 'string')
      return moment.tz(s as string, 'YYYY-MM-DD', tz);
    else {
      let m = s as moment.Moment;
      if (tz) {
        m = m.tz(tz);
      }
      return m;
    }
  }

  static momentConvertUTCDate(
    o: moment.Moment,
    opts?: {
      time?: boolean;
    },
  ) {
    if (!o) return null;

    opts = opts || {};
    let s: string;
    if (opts.time) s = o.format('YYYY-MM-DD HH:mm:ss');
    else s = o.format('YYYY-MM-DD');
    return moment.tz(s, 'UTC');
  }

  static momentUTCToLocalDate(
    o: moment.Moment,
    opts?: {
      time?: boolean;
    },
  ) {
    if (!o) return null;
    opts = opts || {};
    let s: string;
    if (opts.time) s = o.format('YYYY-MM-DD HH:mm:ss');
    else s = o.format('YYYY-MM-DD');

    return moment.tz(s, date_utils.tz);
  }

  // seems to be always UTC
  static utcToLocal(str: any) {
    if (!str) return null;
    if (moment.isMoment(str)) return str;
    if (str.endsWith('Z')) str = str.substring(0, str.length - 1);
    return moment.utc(<string>str).tz(this.tz);
  }
  static utcToLocal2(str: any) {
    if (!str) return null;
    if (moment.isMoment(str)) return str;
    if (str.endsWith('Z')) str = str.substring(0, str.length - 1);
    return moment.tz(<string>str, this.tz);
  }

  static getWeekdayNames(format?: string, noAdjust?: boolean) {
    format = format || 'ddd';
    const now = moment();
    let d = date_utils.getFirstDayOfWeek(now);
    if (noAdjust) {
      d = now.add(-now.days() + 1, 'day'); // mon at zero
    }
    const list = [];
    for (let i = 0; i < 7; i++) {
      list.push(d.format(format));
      d.add(1, 'day');
    }
    return list;
  }
  static getFirstDayOfWeek(date: moment.Moment): moment.Moment {
    let _offset = AppConfig?.appConfig?.dateOptions?.offsetDayOfWeek;
    if (_offset == null) {
      _offset = 1;
    }

    const t = date.day();

    let offset = _offset - t;
    if (offset > 0) {
      offset -= 7;
    }

    return date.clone().add(offset, 'day');
  }

  static getLastDayOfWeek(date: moment.Moment) {
    let _offset = AppConfig.appConfig.dateOptions.offsetDayOfWeek;
    if (_offset == null) {
      _offset = 1;
    }

    const t = date.day();

    let offset = _offset + 6 - t;
    if (offset >= 7) {
      offset -= 7;
    }

    return moment(date).add(offset, 'day');
  }

  static getMinsValues(interval: number) {
    const list: NumberPair[] = [];
    for (let i = 0; i < 60; i += interval) {
      let name = i.toString();
      if (name.length === 1) name = '0' + name;

      list.push({
        id: i,
        name: name,
      });
    }
    return list;
  }
  static getHoursValues() {
    const list: NumberPair[] = [];
    for (let i = 0; i < 24; i++) {
      let name = i.toString();
      if (name.length === 1) name = '0' + name;

      list.push({
        id: i,
        name: name,
      });
    }
    return list;
  }
  static alignDate(o: moment.Moment) {
    if (!o) return null;
    return o.startOf('day');
  }
  static alignLocalDate(o: moment.Moment) {
    if (!o) return null;
    return o.tz(date_utils.tz).startOf('day').utc();
  }

  static DAY_VALUES_MAP: { [key: string]: DateSlice[] } = {
    ALL: [DateSlice.ALL],
    WK_SATSUN: [DateSlice.WEEKDAY, DateSlice.WEEKEND],
    WK_FRISAT: [DateSlice.SUNTHU, DateSlice.FRISAT],
    WK_FRISUN: [DateSlice.MONTHU, DateSlice.FRISUN],
  };
  static resolveDayValues(dayValues: DayValues) {
    let slices = [DateSlice.ALL];
    if (dayValues && date_utils.DAY_VALUES_MAP[dayValues])
      slices = date_utils.DAY_VALUES_MAP[dayValues];
    return slices;
  }

  static startOfWeek(o: moment.Moment, dow: DateOfWeek) {
    var offset = 0;
    switch (dow) {
      case DateOfWeek.MON:
        offset = 1;
        break;
      case DateOfWeek.SUN:
        offset = 0;
        break;
    }
    let d = o.weekday();
    d = d - offset;
    if (d < 0) d += 7;
    return o.clone().add(-d, 'day');
  }
  static weekYear(o: moment.Moment, dow: DateOfWeek) {
    let start = date_utils.startOfWeek(o, dow);

    let d = o.clone().month(0).date(1);
    let year = d.year();
    let dd = date_utils.startOfWeek(d, dow).add(7, 'day');

    // last week of prev year
    if (start.isBefore(dd)) {
      d.add(-1, 'year');
      year = d.year();
      dd = date_utils.startOfWeek(d, dow).add(7, 'day');
    }

    let week = start.diff(dd, 'days');
    week /= 7;

    return {
      year,
      week,
    };
  }
  static buildSmartRange(start: moment.Moment, end: moment.Moment) {
    let format1 = 'DD MMM, YYYY';
    let format2 = 'DD MMM';
    let format3 = 'DD';

    if (!start && !end) return 'All Time';
    if (start && !end) return `After ${start.format(format1)}`;
    if (!start && end) return `Before ${end.format(format1)}`;

    start = start.startOf('day');
    end = end.startOf('day');

    if (start.isSame(end)) {
      return start.format(format1);
    }
    if (start.year() != end.year()) {
      return `${start.format(format1)} - ${end.format(format1)}`;
    } else if (start.month() != end.month()) {
      return `${start.format(format2)} - ${end.format(format1)}`;
    } else {
      return `${start.format(format3)} - ${end.format(format1)}`;
    }
  }
  static minText(mins: number,common?: any) {
    if (mins < 60) {
      return `${mins}${common?.interval?.mins ?? 'mins'}`;
    }
    let hours = Math.floor(mins / 60);
    mins = mins % 60;
    if (mins == 0) {
      return `${hours}${common?.interval?.hour ?? 'hrs'}`;
    }
    return `${hours}${common?.interval?.hours ?? 'hrs'} ${mins}${
      common?.interval?.mins ?? 'mins'
    }`;
  }
}
