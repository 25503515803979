<mat-toolbar style="display: flex">
  <!--<span>Resource Booking</span>-->

  <app-form-field>
    <mat-select [(ngModel)]="space" (selectionChange)="refresh()">
      <mat-option *ngFor="let o of spaces" [value]="o">{{ o.name }}</mat-option>
    </mat-select>
  </app-form-field>

  <div>
    <div (click)="prev()">
      <fa-icon icon="chevron-left"></fa-icon>
    </div>
    <div style="display: none">
      <input
        matInput
        [matDatepicker]="datepicker"
        [(ngModel)]="date"
        (dateChange)="refresh()"
      />
    </div>
    <div>
      {{ formatDateRange(start, end) }}
    </div>
    <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
    <div (click)="next()">
      <fa-icon icon="chevron-right"></fa-icon>
    </div>

  </div>
  

  <!--<fa-icon icon="sync" (click)="refresh()"></fa-icon>-->
  <div class="flex-auto"></div>
  <!--<div>
    <div class="app-btn" (click)="add()">
      <fa-icon icon="plus"></fa-icon> {{ _common.add }}
    </div>
  </div>-->
</mat-toolbar>

<app-dayview-time
  *ngIf="space"
  [hours]="28"
  [date]="date"
  [cssClass]="'ctlBookingCalendar'"
  (loadData)="loadData($event)"
  (itemClick)="openItem($event)"
  (timeClick)="openTime($event)"
  #calendar
></app-dayview-time>
