import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { GOOGLE_MAP_APIKEY } from '../session.service';

@Injectable()
export class MapService {
  apiLoader = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) {}
  async loadMap() {
    return new Promise((resolve, reject) => {
      if (!this.apiLoader.value) {
        let extra = '';

        //   if (this.session.lang == 'zh_hk') {
        //     extra = '&language=zh-HK&region=HK';
        //   } else {
        extra = '&language=en&region=US';
        //   }

        let url = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_APIKEY}${extra}`;
        let rr = this.http.jsonp(url, 'callback').pipe(
          map(() => true),
          catchError((v) => {
            return of(false);
          })
        );

        rr.subscribe((result) => {
          this.apiLoader.next(true);

          resolve(null);
          // this.fetchChildren();
        });
      } else {
        resolve(null);
      }
    });
  }
}
