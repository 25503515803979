<!-- @format -->

<div
  class="max-height noselect tt_image_viewer"
  *ngIf="source && _showImage"
  [style.top.px]="offset"
  style="height: 100%"
>
  <div (click)="closeImage()" class="close_btn">
    <fa-icon icon="times" style="font-size: 2em"></fa-icon>

    <!-- <img src="/images/close.svg" /> -->
  </div>

  <div class="vcontainer">
    <div (click)="prevImage()" class="prevBtn">
      <fa-icon icon="chevron-left" style="font-size: 3em"></fa-icon>
    </div>

    <div class="imageContainer">
      <img
        [src]="source.pathFunc(source.object, source.curIndex)"
        class="image"
        #image
        (panStart)="onPanStart($event)"
        (pan)="onPan($event)"
        (pinchStart)="onPinchStart($event)"
        (pinch)="onPinch($event)"
        [style.transform]="
          'translateX(' +
          offsetX +
          'px) translateY(' +
          offsetY +
          'px) scale(' +
          this.scale +
          ')'
        "
      />
      <div *ngIf="source.labelFunc">
        {{ source.labelFunc(source.object, source.curIndex) }}
      </div>
    </div>

    <div (click)="nextImage()" class="nextBtn">
      <fa-icon icon="chevron-right" style="font-size: 3em"></fa-icon>
    </div>
  </div>
</div>
