import { FormBuilder } from '@angular/forms';
import { SessionService } from '../session.service';

export class SiteEditUtils {
  static DEFAULT_LANG = 'zh_hk';
  static OTHER_LANG = 'en';
  static OTHER_LANGS = ['en', 'zh_cn'];
  static langArray(fb: FormBuilder) {
    let lang: any = {};

    lang[this.DEFAULT_LANG] = [''];
    for (let d of this.OTHER_LANGS) lang[d] = [''];

    return fb.group(lang);
  }
  static langValues(query: any, name: string) {
    let lang = query[`${name}_lang`] || {};

    if (typeof lang == 'string') {
      if (lang == '') lang = {};
      else lang = JSON.parse(lang);
    }
    for(let id in lang) {
      lang[id]=lang[id].trim();
    }
    query[name]=query[name]?.trim()
    // lang['en'] = query[name];
    lang[SiteEditUtils.DEFAULT_LANG] = query[name]?.trim();
    query[`${name}_lang`] = JSON.stringify(lang);
  }
}

export class Lang {
  static get(lang: string, object: any, id: string) {
    let v = object[id];
    if (!lang || lang == '') return v;
    let vv = object[`${id}_lang`];
    if (!vv) return v;
    let _v = vv[lang];
    if (!_v || _v == '') return v;
    return _v;
  }
  static getLang(
    lang: string,
    v: string | null,
    vv: any,
    defaultValue?: string
  ) {
    if (v == '') v = null;

    if (!vv) return v ?? defaultValue ?? '';
    let _v = vv[lang];
    if (!_v || _v == '') return v ?? defaultValue ?? '';
    return _v;
  }
  static getValue(lang: string, v: string, vv: any) {
    if (!lang && lang != '') lang = 'en';

    if (!vv) return v;
    return vv[lang] || v;
  }
  static parse(s: any) {
    if (s && typeof s == 'string' && s != '') return JSON.parse(s);
    return s ?? {};
  }
}

type Constructor<T = {}> = new (...args: any[]) => T;
export function SiteLangMixin<TBase extends Constructor>(Base: TBase) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
    }

    session: SessionService;
    lang(v: string, vv: any, defaultValue?: string) {
      return Lang.getLang(this.session.lang, v, vv, defaultValue);
    }
    lang_get(v: string, vv: any) {
      return Lang.getValue(this.session.lang, v, vv);
    }
  };
}
