<div class="client_pageBody space_book_page" style="max-width: 500px">
  <ng-container *ngIf="object">
    <ng-container *ngIf="session.user; let user">
      <ng-container *ngIf="!confirming">
        <ng-container [formGroup]="form">
          <!--Stepper-->
          <div class="flex justify-center mb-8">
            <div class="stepper">
              <div class="stepper_item current">1</div>
              <div class="progressbar"></div>
              <div class="stepper_item">2</div>
              <div class="progressbar"></div>
              <div class="stepper_item">3</div>
            </div>
          </div>

          <!--Member info card-->
          <div class="card">
            <div class="cardHeader">{{_common.BookingPerson}}</div>
            <div class="cardBody">
              <div class="flex">
                <div class="w-14">{{_common.name}}</div>
                <div>{{user.name}}</div>
              </div>
              <div class="flex">
                <div class="w-14">{{_common.email}}</div>
                <div>{{user.email}}</div>
              </div>
              <div class="flex">
                <div class="w-14">{{_common.tel_no}}</div>
                <div>{{user.mobile}}</div>
              </div>

              <div class="mt-4 mb-2 flex items-baseline">
                <div class="font-bold spacer text-lg">
                  {{_common.ContactPerson}}
                </div>
                <div>
                  {{_common.SameAsBookingPerson}}
                  <mat-checkbox formControlName="contact"></mat-checkbox>
                </div>
              </div>
              <div *ngIf="!form.value.contact" class="space-y-2">
                <div class="flex items-center">
                  <div class="w-14">{{_common.name}}</div>
                  <app-form-field class="w-full">
                    <!--<app-label>Contact</app-label>-->
                    <input matInput formControlName="contactName" />
                  </app-form-field>
                </div>

                <div class="flex items-center">
                  <div class="w-14">{{_common.email}}</div>
                  <app-form-field class="w-full">
                    <!--<app-label>Contact Email</app-label>-->
                    <input matInput formControlName="contactEmail" />
                  </app-form-field>
                </div>

                <div class="flex items-center">
                  <div class="w-14">{{_common.tel_no}}</div>
                  <app-form-field class="w-full">
                    <!--<app-label>Contact Mobile</app-label>-->
                    <input matInput formControlName="contactMobile" />
                  </app-form-field>
                </div>
              </div>
            </div>
          </div>

          <!--Space Card-->
          <div>
            <app-space-card [space]="object"></app-space-card>
          </div>

          <!--Date Card-->
          <div class="card">
            <div class="cardHeader">{{_common.SelectDateAndTime}}</div>
            <div class="cardBody">
              <!--<div>
                {{selectedText() ?? 'Not Selected'}}
              </div>
              <div class="app-btn" (click)="showDialog=true">Select</div>-->

              <div class="inputField_placeholder" (click)="showDialog=true">
                <div class="spacer" style="color: #707070">
                  {{selectedText() ?? [_common.SelectDateAndTime]}}
                </div>

                <fa-icon icon="calendar-days"></fa-icon>
              </div>
            </div>
          </div>

          <!-- <div>
            <div></div>
            <div style="width: 300px">
              <mat-calendar
                [(selected)]="selected"
                (selectedChange)="dateUpdated()"
              ></mat-calendar>
            </div>

            <div>
              <app-form-field>
                <app-label>Time</app-label>
                <mat-select
                  formControlName="startTime"
                  (selectionChange)="updateDuration()"
                >
                  <mat-option
                    *ngFor="let o of hours"
                    [value]="o"
                    [disabled]="!o.active"
                  >
                    {{o.name}}
                  </mat-option>
                </mat-select>
              </app-form-field>
            </div>
            <div>
              <app-form-field>
                <app-label>Duration</app-label>
                <mat-select formControlName="duration">
                  <mat-option
                    *ngFor="let o of slots"
                    [value]="o"
                    [disabled]="!o.active"
                  >
                    {{o.name}}
                  </mat-option>
                </mat-select>
              </app-form-field>
            </div>
          
          </div> -->

          <!--Facility Card-->
          <div class="card">
            <div class="cardHeader">{{_common.SpaceFacilities}}</div>
            <div class="cardBody space-y-4">
              <div>
                <div class="underline">{{_common.FacilitiesIncluded}}</div>
                <ng-container *ngIf="!object.includedFacilities"> N/A </ng-container>
                <div>
                  {{
                  lang(object.includedFacilities,object.includedFacilities_lang)
                  }}
                </div>
              </div>
              <div [formGroup]="form" *ngIf="object.facilities?.length">
                <div class="flex">
                  <div class="spacer font-bold">
                    {{_common.DoYouNeedToRentAnyAdditionalFacilities}}
                  </div>
                  <mat-slide-toggle formControlName="_facilities" #_facilities>
                    <span
                      *ngIf="_facilities.checked;
                      else _facilitiesnotSelected"
                    >
                      {{_common.Yes}}
                    </span>
                    <ng-template #_facilitiesnotSelected>
                      <span>{{_common.No}}</span>
                    </ng-template>
                  </mat-slide-toggle>
                </div>

                <ng-container *ngIf="form.value._facilities">
                  <div *ngIf="formFacilities; let ff">
                    <div *ngFor="let f of object.facilities; let i=index">
                      <mat-checkbox
                        [formControl]="$any(ff.controls[i])"
                      ></mat-checkbox>
                      {{ lang(f.name,f.name_lang) }}
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="cardHeader">
              {{_common.Remarks}}
              <span class="text-md">({{_common.optional}})</span>
            </div>
            <div class="cardBody">
              <app-form-field class="w-full">
                <textarea matInput formControlName="remarks"> </textarea>
              </app-form-field>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <div class="client_button" (click)="confirm()">
              {{_common.Book}}
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!--Step 2-->
      <ng-container *ngIf="confirming">
        <!--Stepper-->
        <div class="flex justify-center mb-8">
          <div class="stepper">
            <div>
              <img src="/silone/silone_step.png" />
            </div>
            <div class="progressbar completed"></div>
            <div class="stepper_item current">2</div>
            <div class="progressbar"></div>
            <div class="stepper_item">3</div>
          </div>
        </div>

        <div class="flex">
          <img src="/silone/silone_hi.png" />
          <div class="font-bold text-2xl flex items-center ml-4">
            {{_common.ConfirmTitleText}}
          </div>
        </div>

        <div class="card">
          <div class="cardHeader">{{_common.BookingPerson}}</div>
          <div class="cardBody">
            <div class="flex">
              <div class="w-14">{{_common.name}}</div>
              <div>{{user.name}}</div>
            </div>
            <div class="flex">
              <div class="w-14">{{_common.email}}</div>
              <div>{{user.email}}</div>
            </div>
            <div class="flex">
              <div class="w-14">{{_common.tel_no}}</div>
              <div>{{user.mobile}}</div>
            </div>

            <div class="mt-4 mb-2 flex items-baseline">
              <div class="font-bold spacer text-lg">
                {{_common.ContactPerson}}
              </div>
              <div *ngIf="form.value.contact">
                *{{_common.SameAsBookingPerson}}
              </div>
            </div>
            <div *ngIf="!form.value.contact">
              <div class="flex">
                <div class="w-14">{{_common.name}}</div>
                <div>{{form.value.contactName}}</div>
              </div>
              <div class="flex">
                <div class="w-14">{{_common.email}}</div>
                <div>{{form.value.contactEmail}}</div>
              </div>
              <div class="flex">
                <div class="w-14">{{_common.tel_no}}</div>
                <div>{{form.value.contactMobile}}</div>
              </div>
              <!--<div>Name: {{form.value.contact}}</div>
              <div>Email: {{form.value.contactEmail}}</div>
              <div>Mobile: {{form.value.contactMobile}}</div>-->
            </div>
          </div>
        </div>
        <div>
          <app-space-card [space]="object"></app-space-card>
        </div>

        <!--Date Card-->
        <div class="card">
          <div class="cardHeader">{{_common.DateAndTime}}</div>
          <div class="cardBody">
            <div class="font-bold">
              {{formatLongDate(session.lang, selected)}}
            </div>
            <div>{{timeText()}}</div>
          </div>
        </div>
        <!--<div *ngIf="form.value.contact">
          <div>Name: {{user.name}}</div>
          <div>Email: {{user.email}}</div>
          <div>Mobile: {{user.mobile}}</div>
        </div>-->

        <div class="card">
          <div class="cardHeader">{{_common.SpaceFacilities}}</div>
          <div class="cardBody space-y-4">
            <div>
              <div class="underline">{{_common.FacilitiesIncluded}}</div>
              <ng-container *ngIf="!object.includedFacilities"> N/A </ng-container>
              <div>
                {{
                lang(object.includedFacilities,object.includedFacilities_lang)
                }}
              </div>
            </div>
            <div>
              <div class="underline">{{_common.AdditionalFacilities}}</div>
              <ng-container *ngIf="!form.value._facilities"> N/A </ng-container>
              <ng-container *ngIf="form.value._facilities">
                <div *ngIf="formFacilities; let ff">
                  <div *ngFor="let f of object.facilities; let i=index">
                    <ng-container *ngIf="ff.controls[i].value">
                      {{ lang(f.name,f.name_lang) }}
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="cardHeader">{{_common.Remarks}}</div>
          <div class="cardBody">
            <ng-container *ngIf="!form.value.remarks"> N/A </ng-container>
            {{form.value.remarks}}
          </div>
        </div>

        <!--Terms-->
        <div class="card">
          <div class="cardHeader">{{_common.TermsAndConditions}}</div>
          <div class="cardBody">
            <div *ngFor="let l of termsLines">{{l}}</div>
          </div>
        </div>

        <div class="flex justify-center mt-8">
          <div class="client_button" (click)="proceed()">
            {{_common.confirm}}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<!--Date Select Overlay-->
<div *ngIf="showDialog" class="overlay_dialog">
  <div class="overlay_dialog_content">
    <div>
      <app-book-date-select-combined [container]="container">
      </app-book-date-select-combined>
      <!-- <app-book-date-select-simple
        [container]="container"
      ></app-book-date-select-simple> -->
      <!-- <ng-container [formGroup]="form">
        <div></div>
        <div style="width: 300px">
          <mat-calendar
            [(selected)]="selected"
            (selectedChange)="dateUpdated()"
          ></mat-calendar>
        </div>

        <div>
          <app-form-field>
            <app-label>Time</app-label>
            <mat-select
              formControlName="startTime"
              (selectionChange)="updateDuration()"
            >
              <mat-option
                *ngFor="let o of hours"
                [value]="o"
                [disabled]="!o.active"
              >
                {{o.name}}
              </mat-option>
            </mat-select>
          </app-form-field>
        </div>
        <div>
          <app-form-field>
            <app-label>Duration</app-label>
            <mat-select formControlName="duration">
              <mat-option
                *ngFor="let o of slots"
                [value]="o"
                [disabled]="!o.active"
              >
                {{o.name}}
              </mat-option>
            </mat-select>
          </app-form-field>
        </div>
      </ng-container> -->
    </div>
    <div class="flex justify-center mt-2">
      <div class="client_button" (click)="showDialog=false">
        {{_common.confirm}}
      </div>
    </div>
  </div>
</div>
