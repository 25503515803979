import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import { date_utils } from "../../date_utils";
import { MonthSelectorComponent } from "../month.selector/month.selector.component";
import { DayCalendarComponent } from "./day.calendar.component";
import { MonthCalendarDateDialogComponent } from "./month.calendar.date.dialog";
import { WeekCalendarComponent } from "./week.calendar.component";

export interface MonthCalendarLoader {
  loadData(
    comp: MonthCalendarComponent,
    start: moment.Moment,
    end: moment.Moment,
    opts?: {
      dayComp?: DayCalendarComponent,
      weekComp?: WeekCalendarComponent,
    }
  );
  onEdit(item: CalendarItem);
}
export interface CalendarItem {
  date: moment.Moment;
  title: string;
  style?: any;
  titles?: string[];
  styles?: any[];
  notes: string;
  badge?: string;
  data?: any;
  color?: string;
  icon?: IconProp;
  css?: string;
  actions?: {
    icon: string,
    action: string,
  }[];
}

export interface MonthCalendarItem {
  date: moment.Moment;
  day: number;
  sameMonth: boolean;
  items: CalendarItem[];
}

@Component({
  selector: "app-month-calendar",
  templateUrl: "./month.calendar.component.html",
  standalone:true,
  imports: [
    CommonModule,
    MonthSelectorComponent,
    FontAwesomeModule,
  ]
})
export class MonthCalendarComponent implements OnInit {
  @HostBinding('class')
  @Input()
  css = 'max-height';

  _common = _common;
  constructor(private dialog: MatDialog) {

  }
  @Input() loader: MonthCalendarLoader;
  @Output() dateClicked = new EventEmitter<MonthCalendarItem>();
  @Output() moreClicked = new EventEmitter<MonthCalendarItem>();
  @Output() dateAction = new EventEmitter<{
    item: MonthCalendarItem,
    action: string
  }>();
  date = moment();
  title: string;
  items: MonthCalendarItem[][] = [];
  data: { [key: string]: MonthCalendarItem } = {};
  names = [];

  @Input()
  loadOnInit = true;

  @Input()
  maxItems = 0;
  @Input()
  actions: {
    icon: string,
    action: string;
  }[] = [];
  focus() { }
  ngOnInit() {
    this.names = date_utils.getWeekdayNames();
    this.date = moment().date(1).hours(0).minutes(0).seconds(0).milliseconds(0);
    //this.refresh();

    if (this.loadOnInit) this.refresh();

  }
  // goNext() {
  //    const d = this.date;
  //    this.date = d.clone().date(1).add(1, 'month');
  //    this.refresh();
  // }
  // goPrev() {
  //    const d = this.date;
  //    this.date = d.clone().date(1).add(-1, 'month');
  //    this.refresh();
  // }
  firstDay: moment.Moment;
  lastDay: moment.Moment;
  refresh(dd?: moment.Moment) {
    if (dd) this.date = dd;

    this.data = {};
    const date = this.date;
    this.title = date.format("MMM, YYYY");

    this.firstDay = this.date.clone().date(1).startOf("day");
    this.lastDay = this.firstDay.clone().add(1, "month");

    //const offset = firstDay.day();

    //const d = firstDay.clone().add(-offset, 'day');
    const d = date_utils.getFirstDayOfWeek(this.firstDay);

    this.items = [];

    //    //first row

    while (d.isBefore(this.lastDay)) {
      const list: MonthCalendarItem[] = [];
      this.items.push(list);

      for (let i = 0; i < 7; i++) {
        const item = {
          date: d.clone(),
          day: d.date(),
          sameMonth: d.month() === date.month(),
          items: [],
        };
        // if (item.sameMonth) {
        this.data[d.format('YYYY-MM-DD')] = item;
        // }
        list.push(item);
        d.add(1, "day");
      }
    }
    if (this.loader) this.loader.loadData(this, this.firstDay, this.lastDay);
  }
  loadData() { }
  numItems() {
    // let cell = this.data[d];
    // if (!cell)
    //    return null;
    // return cell._cell.children.length - 1;
  }

  @Input()
  moreText = '...';

  openDate(item: MonthCalendarItem) {
    if (this.moreClicked.observers.length)
      this.moreClicked.emit(item);
    else
      this.dialog.open(MonthCalendarDateDialogComponent, {
        data: {
          item: item
        }
      })
  }

  clickDate(item: MonthCalendarItem) {
    this.dateClicked.emit(item);
  }
  clickAction(event: MouseEvent, item: MonthCalendarItem, action: string) {
    event.stopPropagation();
    event.preventDefault()
    this.dateAction.emit({
      item,
      action
    });
  }
  addItemRange(start: moment.Moment, end: moment.Moment, item: CalendarItem) {
    let d = start.clone();
    end = end || this.lastDay

    while (!d.isAfter(end)) {
      const cell = this.data[d.format('YYYY-MM-DD')];

      const _item = Object.assign({}, item);
      _item.date = d.clone();
      if (cell) cell.items.push(_item);

      d = d.add(1, "day");
    }
  }


  getItems(d: moment.Moment) {
    const cell = this.data[d.format('YYYY-MM-DD')];
    if (!cell) return null;
    return cell.items;
  }
  hasItems(d: moment.Moment) {
    const cell = this.data[d.format('YYYY-MM-DD')];
    if (!cell) return false;
    return cell.items.length != 0;
  }
  addItemEx(item: CalendarItem) {
    const cell = this.data[item.date.format('YYYY-MM-DD')];
    if (!cell) return;

    // cell.items.push(item);
    cell.items = cell.items || []
    cell.items.push(item);

  }
  addItem(d: moment.Moment, item: CalendarItem) {
    const cell = this.data[d.format('YYYY-MM-DD')];

    if (!cell) return;

    // cell.items.push(item);
    cell.items = [item];
  }
  replaceItem(item: CalendarItem, newItem: CalendarItem) {
    let key1 = item.date.format('YYYY-MM-DD');
    let key2 = newItem.date.format('YYYY-MM-DD');
    if (key1 != key2) {
      this.removeItem(item);
      this.addItemEx(newItem);
    } else {
      const cell = this.data[key1];
      if (!cell) return;
      Object.assign(item, newItem);
    }
  }

  removeWhere(filter: (CalendarItem) => boolean) {
    for (let key in this.data) {
      let cell = this.data[key]
      cell.items = cell.items.filter((c) => !filter(c))
    }
  }
  removeItem(item: CalendarItem) {
    let d = item.date;
    const cell = this.data[d.format('YYYY-MM-DD')];
    if (cell) {
      let index = cell.items.indexOf(item);
      if (index >= 0) cell.items.splice(index, 1);
    }
  }
  onEdit(event: MouseEvent, item: MonthCalendarItem, calItem: CalendarItem) {
    event.stopPropagation();
    event.cancelBubble = true;

    this.loader.onEdit(calItem);
  }
  getDay(d) {
    return d.getDay();
  }
}
