<!-- @format -->

<mat-toolbar style="display: flex">
  <span>{{_common.MyCenters}}</span>

  <div class="flex-auto"></div>
</mat-toolbar>
<!--<div class="ctlSearchBar">
  <div class="searchField">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input
      matInput
      (keyup)="applyFilter($any($event.target).value)"
      placeholder="{{ _common.search }}"
      #_search
    />
    <fa-icon
      icon="times"
      matSuffix
      (click)="clearFilter(_search)"
      class="clearSearch"
    ></fa-icon>
  </div>

  <div class="app-filter-bar">
    <div>
      <fa-icon icon="sliders-h"></fa-icon>
    </div>
    <div class="content">
      <app-filter-multi
          [filter]="status"
          #_status
          (change)="refresh()"
          [values]="[
            statusList.published,
            statusList.draft,
            statusList.expired,
            statusList.scheduled
          ]"
          [common]="_common.pricingRuleStatus"
          >{{ _common.Status }}</app-filter-multi
        >
    </div>
    <div (click)="filterObject.openFilter()" class="openFilter">
      <fa-icon icon="ellipsis-h"></fa-icon>
    </div>
  </div>
  <div class="app-btn btn-icon" (click)="refresh()">
    <fa-icon icon="sync"></fa-icon>
  </div>

  <span class="spacer"></span>
  <span>
    <button class="app-btn" (click)="add()" color="primary">
      <fa-icon icon="plus"></fa-icon> {{ _common.add }}
    </button>
  </span>
</div>-->

<app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  class="max-scroll card-shadow"
  #scroll
>
  <table
    mat-table
    [dataSource]="objects"
    class="app-table scroll"
    matSort
    (matSortChange)="sortData($event)"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="datatable-select">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" style="width: 100px">
        <img
          [src]="locationImage(element, element.images[0])"
          style="width: 100px; height: 70px; object-fit: cover"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
        {{ _common.name }}
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="color: #707070; font-size: smaller">
          {{ lang(element.organization?.name,element.organization?.name_lang) }}
        </div>
        {{ lang(element.name,element.name_lang) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="organization">
      <th mat-header-cell *matHeaderCellDef>Organization</th>
      <td mat-cell *matCellDef="let element">
        {{ lang(element.organization?.name,element.organization?.name_lang) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="numSpaces">
      <th mat-header-cell *matHeaderCellDef># {{ _common.Spaces }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.numSpaces }}
      </td>
    </ng-container>
    <ng-container matColumnDef="controls" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="floating-buttons">
        <button class="app-btn btn-text" (click)="selectObject(element)">
          <fa-icon icon="pen"></fa-icon>
          <div class="mobile_hide">
            {{ _common.edit }}
          </div>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="datatable-row"
    ></tr>
  </table>
</app-scroll-container>

<div
  *ngIf="filterObject.showFilter"
  class="ctlFilter listFilter"
  (clickOutsidePopup)="filterObject.closeFilter()"
>
  <div class="header">
    <div class="title">
      <fa-icon icon="sliders-h" style="margin-right: 5px"></fa-icon>
      {{ _common.filters }}
    </div>
    <div class="spacer"></div>
    <div
      (click)="filterObject.closeFilter()"
      style="font-size: 1.5em; cursor: pointer"
    >
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div class="body"></div>
  <div class="buttons">
    <div (click)="filterObject.clear(); refresh()" class="clear_button">
      {{ _common.ClearAll }}
    </div>
  </div>
</div>