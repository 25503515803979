import { Routes } from '@angular/router';
import { OrganizationListComponent } from './admin/organization/organization.list.component';
import { SpaceFacilityListComponent } from './admin/space.facility/space.facility.list.component';
import { SpaceTypeListComponent } from './admin/space.type/space.type.list.component';
import { UserListComponent } from './admin/user/user.list.component';
import { BookingMainComponent } from './booking/booking.main.component';
import { HomeComponent } from './home.component';
import { LangComponent } from './lang.component';
import { LoginPage } from './login.page';
import { AgentListComponent } from './manage/agent/agent.list.component';
import { DeviceListComponent } from './manage/device/device.list.component';
import { EventListComponent } from './manage/event/event.list.component';
import { LocationListComponent } from './manage/location/location.list.component';
import { LockerListComponent } from './manage/locker/locker.list.component';
import { SpaceListComponent } from './manage/space/space.list.component';
import { NotFoundPage } from './notfound.page';
import { TermsPage } from './terms.page';
import { LocationMapPage } from './user/location.map.page';
import { LocationPage } from './user/location.page';
import { LocationSearchPage } from './user/location.search.page';
import { LockerBookPage } from './user/locker.book.page';
import { LockerPage } from './user/locker.page';
import { LockerSearchPage } from './user/locker.search.page';
import { MyBookingCompletedPage } from './user/my.booking.completed.page';
import { MyBookingListPage } from './user/my.booking.list.page';
import { MyBookingPage } from './user/my.booking.page';
import { MyWishlistPage } from './user/my.wishlist.page';
import { ProfilePage } from './user/profile.page';
import { QRCodePage } from './user/qrcode.page';
import { SpaceBookPage } from './user/space.book.page';
import { SpacePage } from './user/space.page';
import { SpaceSearchPage } from './user/space.search.page';

export const route_map: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'terms', component: TermsPage },

  { path: 'login', component: LoginPage },
  { path: 'manage/location', component: LocationListComponent },
  { path: 'manage/space', component: SpaceListComponent },
  { path: 'manage/locker', component: LockerListComponent },
  { path: 'manage/event', component: EventListComponent },
  { path: 'manage/booking', component: BookingMainComponent },
  { path: 'manage/device', component: DeviceListComponent },
  { path: 'manage/agent', component: AgentListComponent },
  { path: 'admin/user', component: UserListComponent },
  { path: 'admin/organization', component: OrganizationListComponent },
  { path: 'admin/space-type', component: SpaceTypeListComponent },

  { path: 'admin/space-facility', component: SpaceFacilityListComponent },

  // user view
  { path: 'space', component: SpaceSearchPage },
  { path: 'space/:id', component: SpacePage },
  { path: 'space/:id/book', component: SpaceBookPage },

  { path: 'locker', component: LockerSearchPage },
  { path: 'locker/:id', component: LockerPage },
  { path: 'locker/:id/book', component: LockerBookPage },

  { path: 'location-search', component: LocationSearchPage },
  { path: 'location', component: LocationMapPage },
  { path: 'location/:id', component: LocationPage },
  { path: 'event', component: EventListComponent },

  { path: 'my/qrcode', component: QRCodePage },
  { path: 'my/booking/:id', component: MyBookingPage },
  { path: 'my/booking/:id/completed', component: MyBookingCompletedPage },
  { path: 'my/booking', component: MyBookingListPage },
  { path: 'my/profile', component: ProfilePage },
  { path: 'my/wishlist', component: MyWishlistPage },

  { path: 'notfound', component: NotFoundPage },
];

export const routes: Routes = [
  {
    path: 'en',
    component: LangComponent,
    data: {
      lang: 'en',
    },
    children: route_map,
  },
  {
    path: 'zh_hk',
    component: LangComponent,
    data: {
      lang: 'zh_hk',
    },

    children: route_map,
  },
  {
    path: 'zh_cn',
    component: LangComponent,
    data: {
      lang: 'zh_cn',
    },

    children: route_map,
  },
  ...route_map,
];
