<ng-container *ngIf="space">
  <div
    [routerLink]="spaceLink?[session.path('/space/'+space.id)]:null"
    class="space_card"
  >
    <div>
      <img [src]="spaceImage(space,space.images[0])" />
    </div>
    <div class="space_info">
      <div class="flex truncate" style="color: #707070">
        <div *ngFor="let type of space.types" class="spaceType-tags flex">
          <div>{{ lang(type?.name,type?.name_lang) }}</div>
        </div>
      </div>
      <div class="font-bold text-lg truncate">
        {{ lang(space.name,space.name_lang) }}
      </div>
      <div class="text-xs truncate">
        {{ lang(space.location?.name,space.location?.name_lang) }}
      </div>
      <div class="mt-2 text-xs flex gap-2 items-center space_capacity">
        <ng-container *ngIf="space.capacity">
          <fa-icon icon="users" class="icon"></fa-icon>
          <div style="color: #707070">
            {{_common.Capacity}} {{space.capacity}} {{_common.People}}
          </div>
        </ng-container>
        <ng-container *ngIf="space.area">
          <div style="color: #707070">
            ({{_common.VenueSize}} {{space.area}}m<sup>2</sup>)
          </div>
        </ng-container>

      </div>

      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
