import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ContentChildren, Directive, InjectionToken, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';




export const APP_FORM_FIELD = new InjectionToken<AppFormField>('AppFormField');


@Directive({
    selector: 'app-form-field-addon',
    standalone: true,
})
export class AppFieldAddon { }

@Directive({
    selector: 'app-label',
    standalone: true,
})
export class AppLabel { }



@Directive({
    selector: 'app-hint',
    standalone: true,
})
export class AppHint { }



@Directive({
    selector: 'app-help',
    standalone: true,
})
export class AppHelp { }


export const APP_PREFIX = new InjectionToken<AppPrefix>('AppPrefix');
@Directive({
    selector: '[appPrefix], [appIconPrefix], [appTextPrefix]',
    providers: [{ provide: APP_PREFIX, useExisting: AppPrefix }],
    standalone: true,
})
export class AppPrefix {
   @Input('appTextPrefix')
   set _isTextSelector(value: '') {
      this._isText = true;
   }

   _isText = false;
}




export const APP_SUFFIX = new InjectionToken<AppSuffix>('AppSuffix');

/** Suffix to be placed at the end of the form field. */
@Directive({
    selector: '[appSuffix], [appIconSuffix], [appTextSuffix]',
    providers: [{ provide: APP_SUFFIX, useExisting: AppSuffix }],
    standalone: true,
})
export class AppSuffix {
   @Input('appTextSuffix')
   set _isTextSelector(value: '') {
      this._isText = true;
   }

   _isText = false;
}



export const APP_ERROR = new InjectionToken<AppError>('AppError');

/** Single error message to be shown underneath the form-field. */
@Directive({
    selector: 'app-error, [appError]',
    host: {
    // 'class': 'mat-mdc-form-field-error mat-mdc-form-field-bottom-align',
    // 'aria-atomic': 'true',
    // '[id]': 'id',
    },
    providers: [{ provide: APP_ERROR, useExisting: AppError }],
    standalone: true,
})
export class AppError {
   //   @Input() id: string = `mat-mdc-error-${nextUniqueId++}`;

   //   constructor(@Attribute('aria-live') ariaLive: string, elementRef: ElementRef) {
   //     // If no aria-live value is set add 'polite' as a default. This is preferred over setting
   //     // role='alert' so that screen readers do not interrupt the current task to read this aloud.
   //     if (!ariaLive) {
   //       elementRef.nativeElement.setAttribute('aria-live', 'polite');
   //     }
   //   }
}

@Component({
    selector: 'app-form-field',
    templateUrl: './form.field.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: APP_FORM_FIELD, useExisting: AppFormField },
    ],
    standalone: true,
    imports: [NgIf],
})
export class AppFormField {


   @Input()
   prefixBorder = false;
   @Input()
   suffixBorder = false;

   @ContentChild(MatFormFieldControl) _formFieldControl: MatFormFieldControl<any>;
   @ContentChild(AppLabel) _labelChildNonStatic: AppLabel | undefined;
   @ContentChild(AppHint) _hintChild: AppHint | undefined;
   @ContentChild(AppHelp) _helpChild: AppHelp | undefined;
   @ContentChild(AppFieldAddon) _addonChild: AppFieldAddon | undefined;
   @ContentChildren(APP_PREFIX, { descendants: true }) _prefixChildren: QueryList<AppPrefix>;
   @ContentChildren(APP_SUFFIX, { descendants: true }) _suffixChildren: QueryList<AppSuffix>;

   @ContentChildren(APP_ERROR, { descendants: true }) _errorChildren: QueryList<AppError>;
   _hasIconPrefix = false;
   _hasTextPrefix = false;
   _hasIconSuffix = false;
   _hasTextSuffix = false;

   constructor(
      private _changeDetectorRef: ChangeDetectorRef

   ) {

   }

   ngOnInit() {

   }
   ngAfterViewInit() {
      // Initial focus state sync. This happens rarely, but we want to account for
      // it in case the form field control has "focused" set to true on init.
      this._updateFocusState();
      // Enable animations now. This ensures we don't animate on initial render.
      // this._subscriptAnimationState = 'enter';
      // Because the above changes a value used in the template after it was checked, we need
      // to trigger CD or the change might not be reflected if there is no other CD scheduled.
      this._changeDetectorRef.detectChanges();
   }


   ngAfterContentInit() {
      // this._assertFormFieldControl();
      this._initializeControl();
      // this._initializeSubscript();
      this._initializePrefixAndSuffix();
      // this._initializeOutlineLabelOffsetSubscriptions();
   }


   ngOnDestroy() {
      this._destroyed.next();
      this._destroyed.complete();
   }


   private _explicitFormFieldControl: MatFormFieldControl<any>;
   _isFocused: boolean | null = null;
   private _destroyed = new Subject<void>();


   get _control(): MatFormFieldControl<any> {
      return this._explicitFormFieldControl || this._formFieldControl;
   }
   set _control(value) {
      this._explicitFormFieldControl = value;
   }


   private _updateFocusState() {
      // Usually the MDC foundation would call "activateFocus" and "deactivateFocus" whenever
      // certain DOM events are emitted. This is not possible in our implementation of the
      // form field because we support abstract form field controls which are not necessarily
      // of type input, nor do we have a reference to a native form field control element. Instead
      // we handle the focus by checking if the abstract form field control focused state changes.
      if (this._control.focused && !this._isFocused) {
         this._isFocused = true;
      } else if (!this._control.focused && (this._isFocused || this._isFocused === null)) {
         this._isFocused = false;
      }

      // this._textField?.nativeElement.classList.toggle(
      //    'mdc-text-field--focused',
      //    this._control.focused,
      // );
   }


   /** Initializes the registered form field control. */
   private _initializeControl() {
      const control = this._control;

      // if (control.controlType) {
      //   this._elementRef.nativeElement.classList.add(
      //     `mat-mdc-form-field-type-${control.controlType}`,
      //   );
      // }

      // Subscribe to changes in the child control state in order to update the form field UI.
      control.stateChanges.subscribe(() => {
         this._updateFocusState();
         //   this._syncDescribedByIds();
         this._changeDetectorRef.markForCheck();
      });

      // Run change detection if the value changes.
      if (control.ngControl && control.ngControl.valueChanges) {
         control.ngControl.valueChanges
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._changeDetectorRef.markForCheck());
      }
   }

   private _checkPrefixAndSuffixTypes() {
      this._hasIconPrefix = !!this._prefixChildren.find(p => !p._isText);
      this._hasTextPrefix = !!this._prefixChildren.find(p => p._isText);
      this._hasIconSuffix = !!this._suffixChildren.find(s => !s._isText);
      this._hasTextSuffix = !!this._suffixChildren.find(s => s._isText);
   }
   private _initializePrefixAndSuffix() {
      this._checkPrefixAndSuffixTypes();
      // Mark the form field as dirty whenever the prefix or suffix children change. This
      // is necessary because we conditionally display the prefix/suffix containers based
      // on whether there is projected content.
      merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(() => {
         this._checkPrefixAndSuffixTypes();
         this._changeDetectorRef.markForCheck();
      });
   }
}





