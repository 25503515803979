<div *ngIf="mapService.apiLoader | async" class="map_widget">
  <google-map height="70vh" width="100%" [center]="hk" [zoom]="12">
    <!-- <map-marker [position]="hk" [options]="hkOptions"></map-marker> -->
    <map-marker
      *ngFor="let p of locations"
      #marker="mapMarker"
      [position]="p.position"
      [options]="p.options"
      (mapMouseover)="openInfoWindow(marker,p)"
      (mapClick)="click(marker,p)"
    ></map-marker>
    <map-info-window>
      <div *ngIf="selected?.location; let p" class="info_item">
        <div>
          <img
            [src]="locationImage(p,p.images[0])"
            style="width: 100px; height: 100px"
          />
        </div>
        <div class="flex flex-col space-y-2">
          <div class="font-bold">{{p.name}}</div>
          <div class="flex gap-2">
            <fa-icon icon="map-location-dot"></fa-icon>
            {{p.address}}
          </div>
          <div class="spacer"></div>
          <div
            [routerLink]="[session.path('/location/'+p.id)]"
            class="text-right cursor-pointer"
          >
            {{_common.ViewDetails}}
            <fa-icon icon="chevron-right"></fa-icon>
          </div>
        </div>
      </div>
    </map-info-window>
  </google-map>
</div>
