import { color_utils } from "./color_utils";
import { NumberPair, utils } from "./utils";

type Constructor<T = {}> = new (...args: any[]) => T;
export function PeopleUtilsMixin<TBase extends Constructor>(Base: TBase) {
   return class extends Base {
      constructor(...args: any[]) {
         super(...args);
      }
      nameColor(name: string) {
         return color_utils.findColor(name);

      }
      peopleColor(p: NumberPair) {
         return color_utils.findColor(p.name);
      }
      peopleShortName(p: NumberPair) {
         return utils.createNameShortHand(p.name);
      }


   };
}
