import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { date_utils } from '../../date_utils';
import { NgFor, NgIf } from '@angular/common';

export interface CalendarEvent {
   start: moment.Moment;
   end: moment.Moment;
   color: string;
}
export interface CalendarDate {
   mDate: moment.Moment;
   selected?: boolean;
   today?: boolean;
   color: string;
}

@Component({
    selector: 'app-calendar',
    styleUrls: ['calendar.component.scss'],
    templateUrl: './calendar.component.html',
    standalone: true,
    imports: [NgFor, NgIf],
})
export class CalendarComponent implements OnInit, OnChanges {

   _common = _common;

   dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
   weeks: CalendarDate[][] = [];
   // sortedDates: CalendarDate[] = [];


   @Input() currentDate;
   @Input() events: CalendarEvent[] = [];
   @Output() selectedDate = new EventEmitter<CalendarDate>();

   constructor() { }

   ngOnInit(): void {
      this.dayNames = date_utils.getWeekdayNames('dd');
      if (!this.currentDate) {
         this.currentDate = moment();
      }
      this.generateCalendar();
   }

   setCurrentDate(date: moment.Moment) {
      this.currentDate = date;
      this.generateCalendar();
   }
   ngOnChanges(changes: SimpleChanges): void {
      if (changes['events']) {
         this.generateCalendar();
      } else if (changes['currentDate']) {
         this.generateCalendar();
      }
   }

   // date checkers

   isToday(date: moment.Moment): boolean {
      return moment().isSame(moment(date), 'day');
   }


   isSelectedMonth(date: moment.Moment): boolean {
      return moment(date).isSame(this.currentDate, 'month');
   }

   selectDate(date: CalendarDate): void {
      this.selectedDate.emit(date);
   }

   // actions from calendar

   prevMonth(): void {
      this.currentDate = moment(this.currentDate).subtract(1, 'months');
      this.generateCalendar();
   }

   nextMonth(): void {
      this.currentDate = moment(this.currentDate).add(1, 'months');
      this.generateCalendar();
   }

   firstMonth(): void {
      this.currentDate = moment(this.currentDate).startOf('year');
      this.generateCalendar();
   }

   lastMonth(): void {
      this.currentDate = moment(this.currentDate).endOf('year');
      this.generateCalendar();
   }

   prevYear(): void {
      this.currentDate = moment(this.currentDate).subtract(1, 'year');
      this.generateCalendar();
   }

   nextYear(): void {
      this.currentDate = moment(this.currentDate).add(1, 'year');
      this.generateCalendar();
   }

   getSelectedColor(date: moment.Moment): string {

      for (const evt of this.events) {
         if (date.isBetween(evt.start, evt.end, 'day', '[]')) {
            return evt.color;
         }
      }
      return null;
   }
   isSelected(date: moment.Moment): boolean {
      return _.findIndex(this.events, (event) => {
         return moment(date).isSame(event.start, 'day');
      }) > -1;
   }

   // generate the calendar grid

   generateCalendar(): void {
      const dates = this.fillDates(this.currentDate);
      const weeks: CalendarDate[][] = [];
      while (dates.length > 0) {
         weeks.push(dates.splice(0, 7));
      }
      this.weeks = weeks;
   }

   fillDates(currentMoment: moment.Moment): CalendarDate[] {
      const firstOfMonth = moment(currentMoment).startOf('month').day();
      const firstDayOfGrid = date_utils.getFirstDayOfWeek(moment(currentMoment).startOf('month'));
      //const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
      const start = firstDayOfGrid.date();
      return _.range(start, start + 42)
         .map((date: number): CalendarDate => {
            const d = moment(firstDayOfGrid).date(date);
            const color = this.getSelectedColor(d);

            return {
               today: this.isToday(d),
               selected: color != null,
               mDate: d,
               color: color
            };
         });
   }

   // fillDates(currentMoment: moment.Moment): CalendarDate[] {
   //    const firstOfMonth = moment(currentMoment).startOf('month').day();
   //    //const firstDayOfGrid = date_utils.getFirstDayOfWeek(moment(currentMoment).startOf('month'));
   //    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
   //    const start = firstDayOfGrid.date();
   //    return _.range(start, start + 42)
   //       .map((date: number): CalendarDate => {
   //          const d = moment(firstDayOfGrid).date(date);
   //          const color = this.getSelectedColor(d);

   //          return {
   //             today: this.isToday(d),
   //             selected: color != null,
   //             mDate: d,
   //             color: color
   //          };
   //       });
   // }
}
