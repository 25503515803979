import {
  CdkDragDrop,
  DragDropModule,
  DropListOrientation,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageViewerService } from '../../common/components/image.viewer/image.viewer.service';
import { ImageUploadBase } from './image.upload.base';
import { LImageUploadContainer, LImageUploadItem } from './upload.container';

@Component({
  selector: 'app-image-list',
  templateUrl: './object.image.list.html',
  standalone: true,
  imports: [
    CommonModule,
    FileUploadModule,
    DragDropModule,
    FontAwesomeModule,
  ],
})
export class ObjectImageListComponent extends ImageUploadBase {
  _common = _common;

  @Input()
  multiple = true;

  @Input()
  cssClass = '';
  @Input()
  direction: DropListOrientation = 'horizontal';

  @Input()
  override imageContainer: LImageUploadContainer;

  @Input()
  original = false;

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    private imageViewer: ImageViewerService,
    public override snackBar: MatSnackBar
  ) {
    super(snackBar);
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(
      this.imageContainer.images,
      event.previousIndex,
      event.currentIndex
    );
  }
  showImage(i: LImageUploadItem, index: number) {
    this.imageViewer.show({
      object: this.imageContainer,
      curIndex: index,
      numImages: this.imageContainer.images.length,
      pathFunc: (o: LImageUploadContainer, index: number) => {
        let i = this.imageContainer.images[index];
        return i.path;
      },
    });
  }
  numImages() {
    return this.imageContainer.numImages();
  }
  save(opt?: { mold: boolean }) {
    if (opt && opt.mold) this.imageContainer.moldImagePaths();
    return {
      imageIds: this.imageContainer.getImageIds(),
      imagePaths: this.imageContainer.getImageNames(),
    };
  }
  saveTemp() {
    return {
      imageIds: this.imageContainer.getImageIds(),
      imagePaths: this.imageContainer.getImageNames(),
      imageFiles: this.imageContainer.getImageFiles(),
    };
  }
}
