/** @format */

import { HttpClient } from '@angular/common/http';
import { Component, Inject, NgZone } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

import { VizFormModule } from '../../common/components/form/form.module';
import {
  CompareWithMixin,
  DELETED,
  EmptyObject,
  WebUtils,
  utils,
} from '../../common/utils';
import { AccessUtils, TAgentContent } from '../../core/entities';
import { SiteEditUtils } from '../../core/lang';
import { SitePathMixin } from '../../core/site.path.utils';
import { SessionCache } from '../../session.cache';
import { SessionService, TIMEZONE } from '../../session.service';
@Component({
  templateUrl: './agent.content.edit.dialog.html',
  standalone: true,
  imports: [
    VizFormModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatDatepickerModule,
  ],
})
export class AgentContentEditDialogComponent extends SitePathMixin(
  CompareWithMixin(EmptyObject)
) {
  form: UntypedFormGroup;
  _common = _common;

  objectId: number;
  object: TAgentContent;

  api = '/api/agent-content';
  dlangs = SiteEditUtils.OTHER_LANGS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public session: SessionService,
    public http: HttpClient,
    public ngZone: NgZone,
    public snackBar: MatSnackBar,
    private cache: SessionCache,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgentContentEditDialogComponent>
  ) {
    super();
    this.objectId = data.objectId;

    // this.cache.loadLocation(this.data.organization.id, (list) => {
    //   this.locations = list;
    // });

    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    if (this.objectId) {
      WebUtils.web_result(
        this.http.get(`${this.api}/${this.objectId}`),
        (result) => {
          this.object = result as TAgentContent;

          AccessUtils.fixAgentContent(this.object);

          let query = Object.assign({}, this.object) as any;

          utils.fill_form_values(query, this.form);

          this.updateForm();
        }
      );
    } else {
      let query = {
        timezone: TIMEZONE,
        location: this.data?.location,
        space: this.data?.space,
      } as any;

      utils.fill_form_values(query, this.form);

      this.updateForm();
    }
  }

  updateForm() {
    utils.updateValidity(this.form);
  }
  save() {
    if (!this.form.valid)
      return utils.validateAllFormFields(this.form, {
        snackBar: this.snackBar,
      });
    let query = Object.assign({}, this.form.value);

    // WebUtils.web_result(
    //   this.http.put(`${this.api}/${this.objectId}`, query),
    //   async (result) => {
    //     this.object = result as TSpaceType;
    //     AccessUtils.fixSpaceType(this.object);

    //     this.dialogRef.close(this.object);
    //   }
    // );
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  remove() {
    WebUtils.web_result(
      this.http.delete(`${this.api}/${this.objectId}`),
      (result) => {
        this.dialogRef.close(DELETED);
      }
    );
  }
}
