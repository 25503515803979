<div>
   <app-month-selector [date]="dateCur" (dateChange)="refreshDates($event)"> </app-month-selector>
</div>
<div style="text-align: center;">

   <div class="calendar-container" style="width:30%">
      <app-calendar #calPrev [events]="events" [currentDate]="datePrev" (selectedDate)="selectDate($event)"></app-calendar>
   </div>
   <div class="calendar-container" style="width:30%">
      <app-calendar #calCur [events]="events" [currentDate]="dateCur" (selectedDate)="selectDate($event)"></app-calendar>
   </div>
   <div class="calendar-container" style="width:30%">
      <app-calendar #canNext [events]="events" [currentDate]="dateNext" (selectedDate)="selectDate($event)"></app-calendar>
   </div>
</div>