import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { IImageViewerSource } from './image.viewer.component';



@Injectable({
   providedIn: 'root'
})
export class ImageViewerService {

   static INSTANCE: ImageViewerService;

   private subject = new Subject<IImageViewerSource>();

   state = this.subject.asObservable();


   constructor() {
      console.log('image.service')
      ImageViewerService.INSTANCE = this;
   }

   show(source: IImageViewerSource) {
      this.subject.next(source);
   }

   hide() {
      this.subject.next(void 0);
   }
}
