<ng-container *ngIf="filter">
  <div class="app-chip mr-2" *ngIf="filter.filter.v == boolTypes.TRUE">
    <div>{{ filter.names[1] }}</div>
    <div class="cursor-pointer ml-1">
      <fa-icon [icon]="faTimes" (click)="filter.filter.clear()"></fa-icon>
    </div>
  </div>
  <div class="app-chip mr-2" *ngIf="filter.filter.v == boolTypes.FALSE">
    <div>{{ filter.names[2] }}</div>
    <div class="cursor-pointer ml-1">
      <fa-icon [icon]="faTimes" (click)="filter.filter.clear()"></fa-icon>
    </div>
  </div>
</ng-container>
<!-- <ng-container *ngIf="!filter">
   no filter
</ng-container> -->
