import moment from 'moment-timezone';
import { DateSlice, date_utils } from './date_utils';



export enum DateSelector_RangeType {
   custom = 'custom',
   today = 'today',
   yesterday = 'yesterday',

   today_align = 'today_align',
   yesterday_align = 'yesterday_align',
   this_week = 'this_week',
   thisweek = 'thisweek',
   last_week = 'last_week',
   lastweek = 'lastweek',
   week = 'week',
   prev_week = 'week2',
   week_align = 'week_align',

   month = 'month',
   prev_month = 'month2', // last month
   last_month = 'last_month', // last month
   month_align = 'month_align',


   running_13weeks_lastweek = 'running_13weeks_lastweek',
   running_13weeks_thisweek = 'running_13weeks_thisweek',
   running_13weeks_lastweek_align = 'running_13weeks_lastweek_align',
   running_13weeks_thisweek_align = 'running_13weeks_thisweek_align',

   last14days = 'last14days',
   last7days = 'last7days',
   last30days = 'last30days',

   mtd = 'mtd',
   mtd_align = 'mtd_align',

   year = 'year',
   prev_year = 'year2',
   weekyear = 'weekyear',
   year_align = 'year_align',
   year_month_align = 'year_month_align',


   wtd = 'wtd',
   qtd = 'qtd',

   ytd = 'ytd',
   ytd_align = 'ytd_align',

   _7days = '7days',
   _30days = '30days',
   next_sun = 'next_sun',
   next_sat = 'next_sat',

}
export const DateSelector_RangeTypeUI: DateSelector_RangeType[] = [
   DateSelector_RangeType.custom,
   DateSelector_RangeType.today,
   DateSelector_RangeType.today_align,
   DateSelector_RangeType.yesterday,
   DateSelector_RangeType.yesterday_align,

   DateSelector_RangeType.this_week,
   DateSelector_RangeType.last_week,
   DateSelector_RangeType.week,
   DateSelector_RangeType.prev_week,
   DateSelector_RangeType.week_align,

   DateSelector_RangeType.month,
   DateSelector_RangeType.prev_month,
   DateSelector_RangeType.month_align,

   DateSelector_RangeType.running_13weeks_thisweek,
   DateSelector_RangeType.running_13weeks_lastweek,
   DateSelector_RangeType.running_13weeks_thisweek_align,
   DateSelector_RangeType.running_13weeks_lastweek_align,

   DateSelector_RangeType.last14days,

   DateSelector_RangeType.mtd,
   DateSelector_RangeType.mtd_align,

   DateSelector_RangeType.year,
   DateSelector_RangeType.prev_year,
   DateSelector_RangeType.year_align,
   DateSelector_RangeType.year_month_align,

   DateSelector_RangeType.weekyear,

   DateSelector_RangeType.ytd,
   DateSelector_RangeType.ytd_align,

   DateSelector_RangeType._7days,
   DateSelector_RangeType._30days,
   DateSelector_RangeType.next_sun,
   DateSelector_RangeType.next_sat,
];

export enum DateSelector_SubRangeType {
   // subrange
   custom = 'custom',
   none = 'none',
   this = 'this',
   prev = 'prev',
   prevweek = 'prevweek',
   prevweek2 = 'prevweek2',
   prevmonth = 'prevmonth',
   prevmonth2 = 'prevmonth2',
   prevyear = 'prevyear',
   prevyear2 = 'prevyear2',
   prevweekyear = 'prevweekyear',
   prevweekyear2 = 'prevweekyear2',
   prev52week = 'prev52week'
   // week = 'week',
   // month = 'month',
   // mtd = 'mtd',
   // year = 'year',
   // ytd = 'ytd',
   // _7days = '7days',
   // _30days = '30days'

   // there are some code down below that uses week/month/mtd

}

export const DateSelector_SubRangeTypeUI: DateSelector_SubRangeType[] = [
   DateSelector_SubRangeType.custom,
   DateSelector_SubRangeType.prev,
   DateSelector_SubRangeType.prevweek,
   DateSelector_SubRangeType.prevweek2,
   DateSelector_SubRangeType.prevmonth,
   DateSelector_SubRangeType.prevmonth2,
   DateSelector_SubRangeType.prevyear,
   DateSelector_SubRangeType.prevyear2,
   DateSelector_SubRangeType.prevweekyear,
   DateSelector_SubRangeType.prevweekyear2,
   DateSelector_SubRangeType.prev52week

];



export namespace daterange_utils {

   const periodOptions = {
      defaultConfig: {
         periodOption: 'MMM DD,YYYY',
         periodOption2a: 'MMM DD',
         periodOption2b: 'MMM DD,YYYY',
         periodOption2a2: 'MMM DD ddd',
         periodOption2b2: 'MMM DD ddd,YYYY',
         periodOption2a3: 'MMM DD,YYYY HH:mm',
         periodOption2b3: 'HH:mm',
         periodOption3: 'DD',
         periodOption3a: 'MMM DD',
         periodOption3b: 'DD,YYYY',
         periodOption3a2: 'MMM DD ddd',
         periodOption3b2: 'DD ddd,YYYY',
         periodOption4: 'MMM YYYY',
         periodOption4a: 'MMM',
      },
      jp: {
         periodOption: 'YYYY/MM/DD',
         periodOption2a: 'YYYY/MM/DD',
         periodOption2b: 'MM/DD',
         periodOption2a2: 'YYYY/MM/DD ddd',
         periodOption2b2: 'MM/DD ddd',
         periodOption2a3: 'MMM DD,YYYY HH:mm',
         periodOption2b3: 'HH:mm',
         periodOption3: 'DD',
         periodOption3a: 'YYYY/MM/DD ddd',
         periodOption3b: 'DD ddd',
         periodOption4: 'MMM YYYY',
         periodOption4a: 'MMM',

      }
   };

   // export function isWeekend(d: moment.Moment) {
   //    if (AppConfig.appConfig.dateOptions.weekendDay === 'FriSat') {
   //       if (d.day() === 5 || d.day() === 6)
   //          return true;
   //       else
   //          return false;
   //    } else {
   //       if (d.day() === 0 || d.day() === 6)
   //          return true;
   //       else
   //          return false;

   //    }

   // }


   // const uiShortDateOption = 'MM-DD';
   // const uiYearOption = 'YYYY';
   // const uiMonthOption = 'MMM,YYYY';

   // const uiDateWithWeekOption = 'ddd MMM DD';
   // const uiDateOption = 'MMM DD,YYYY';
   // const uiDateOption2 = 'MMM DD ddd,YYYY';
   // const uiTimeOption = 'MMM DD,YYYY HH:mm';
   // const uiTimeOption2 = 'MMM DD ddd,YYYY HH:mm';
   // const uiTimeOption3 = 'MMM DD HH:mm:ss';

   // const uiWeekOption = 'MMM DD';

   // const uiHourOption = 'HH:mm';
   // const uiDayOption = 'ddd';
   // const parseDateOption = 'YYYYMMDD';
   // const parseOption = 'YYYYMMDDHHmm';
   // const dateOption = 'YYYY-MM-DD';


   // export function isHourInterval(interval: DataInterval) {
   //    return interval === DataInterval.HOUR || interval === DataInterval.MIN30 || interval === DataInterval.MIN15;
   // }
   // export function parseTime(s: string | Date | moment.Moment) {
   //    if (typeof s === 'string') {
   //       if (s.length !== 12)
   //          return null;
   //       return moment(s, parseOption);

   //    } else if (s instanceof Date) {
   //       return moment(<Date>s);
   //    } else {
   //       return <moment.Moment>(s);
   //    }
   // }

   // export function formatUIShortDate(d) {
   //    return d.format(uiShortDateOption);
   // }
   // export function formatUIDay(d) {
   //    return d.format(uiDayOption);
   // }
   // export function formatUIHour(d) {
   //    return d.format(uiHourOption);
   // }
   // export function formatUITime(d) {
   //    return d.format(uiTimeOption);
   // }
   // export function formatUITime2(d) {
   //    return d.format(uiTimeOption2);
   // }
   // export function formatUITime3(d) {
   //    return d.format(uiTimeOption3);
   // }
   // export function formatUIDateWithWeek(d) {
   //    return d.format(uiDateWithWeekOption);
   // }
   // export function formatUIDate(d) {
   //    return d.format(uiDateOption);
   // }
   // export function formatUIDate2(d) {
   //    return d.format(uiDateOption2);
   // }
   // export function formatUIWeek(d) {
   //    return d.format(uiWeekOption);
   // }
   // export function formatUIMonth(d) {
   //    return d.format(uiMonthOption);
   // }
   // export function formatUIYear(d) {
   //    return d.format(uiYearOption);
   // }


   // export function formatDate(d) {
   //    if (d === null || d === '') return null;
   //    return d.format(parseDateOption);
   // }

   // export function fromQuery(s: string) {
   //    return moment(s, 'YYYY-MM-DD');
   // }

   // export function format_time(value: string) {
   //    if (!value || value === '')
   //       return '';
   //    const d = parseTime(value);
   //    return formatUITime(d);
   // }


   // export function parseDate(s: Date | string | moment.Moment) {

   //    if (s instanceof Date)
   //       return moment(<Date>s);
   //    if (typeof s === 'string') {
   //       const _s = <string>s;
   //       if (_s.length !== 8)
   //          return null;
   //       return moment(_s, 'YYYYMMDD');
   //    }
   //    return <moment.Moment>s;
   // }


   // export function toQuery(d: moment.Moment) {
   //    return d.format('YYYY-MM-DD');
   // }
   // export function toQueryTime(d: moment.Moment) {
   //    return d.format('YYYYMMDDHHmm');
   // }

   export function formatPeriod(period: DateRange, interval?: string) {
      let a, b;
      // let startDate = this.parseTime(period.startDate);
      // let endDate = this.parseTime(period.endDate);
      const startDate = period.startDate;
      const endDate = period.endDate;
      // let o = periodOptions[AppConfig.appConfig.locale];
      let o = periodOptions['defaultConfig'];
      if (o == null)
         o = periodOptions['defaultConfig'];

      if (interval === 'MONTH') {
         if (startDate.year() === endDate.year()) {
            if (startDate.month() === endDate.month()) {
               a = startDate.format(o.periodOption4);
            } else {
               a = startDate.format(o.periodOption4a);
               b = endDate.format(o.periodOption4);

            }
         } else {
            a = startDate.format(o.periodOption4);
            b = endDate.format(o.periodOption4);

         }

      } else {

         if (startDate.year() === endDate.year()) {

            if (startDate.month() === endDate.month()) {
               if (startDate.date() === endDate.date()) {
                  a = startDate.format(o.periodOption2b);
               } else {
                  a = startDate.format(o.periodOption3a);
                  b = endDate.format(o.periodOption3b);
               }
            } else {
               a = startDate.format(o.periodOption2a);
               b = endDate.format(o.periodOption2b);
            }
         } else {
            a = startDate.format(o.periodOption);
            b = endDate.format(o.periodOption);
         }
      }
      if (!b) return a;
      return a + '-' + b;
   }

   // export function getWeekdayNames(format?: string, noAdjust?: boolean) {
   //    format = format || 'ddd';
   //    const now = moment();
   //    let d = date_utils.getFirstDayOfWeek(now);
   //    if (noAdjust) {
   //       d = now.add(-now.days() + 1, 'day'); // mon at zero
   //    }
   //    const list = [];
   //    for (let i = 0; i < 7; i++) {
   //       list.push(d.format(format));
   //       d.add(1, 'day');
   //    }
   //    return list;
   // }
   // // export function getWeekdayNames() {

   // //    const names = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
   // //    const results = [];

   // //    let _offset = AppConfig.appConfig.dateOptions.offsetDayOfWeek;
   // //    if (_offset == null)
   // //       _offset = 1;

   // //    for (let i = 0; i < 7; i++) {
   // //       results[i] = names[_offset];
   // //       _offset++;
   // //       if (_offset >= 7)
   // //          _offset = 0;
   // //    }
   // //    return results;
   // // }

   // export function getFirstDayOfWeek(date: moment.Moment): moment.Moment {

   //    // let _offset = AppConfig.appConfig.dateOptions.offsetDayOfWeek;
   //    let _offset = 0;
   //    if (_offset == null) {
   //       _offset = 1;
   //    }

   //    const t = date.day();

   //    let offset = _offset - t;
   //    if (offset > 0) {
   //       offset -= 7;
   //    }

   //    return date.clone().add(offset, 'day');
   // }

   // export function getLastDayOfWeek(date: moment.Moment) {

   //    let _offset = AppConfig.appConfig.dateOptions.offsetDayOfWeek;
   //    if (_offset == null) {
   //       _offset = 1;
   //    }

   //    const t = date.day();

   //    let offset = _offset + 6 - t;
   //    if (offset >= 7) {
   //       offset -= 7;
   //    }

   //    return moment(date).add(offset, 'day');
   // }

   export function align_range(r: DateSelectorRange, type: DateSelector_RangeType) {
      const d = moment();
      switch (type) {
         case DateSelector_RangeType.today:
            r.start = d;
            r.end = moment(r.start);
            break;
         case DateSelector_RangeType.yesterday:
            r.start = moment().add(-1, 'day');
            r.end = moment(r.start);
            break;

         case DateSelector_RangeType.today_align:
            r.start = moment(r.end);
            break;
         case DateSelector_RangeType.yesterday_align:
            r.end = r.end.add(-1, 'day');
            r.start = moment(r.end);
            break;


         case DateSelector_RangeType.this_week:
         case DateSelector_RangeType.thisweek:
         case DateSelector_RangeType.week:
            r.start = date_utils.getFirstDayOfWeek(d);
            r.end = moment(r.start).add(6, 'day');
            break;
         case DateSelector_RangeType.last_week:
         case DateSelector_RangeType.lastweek:
         case DateSelector_RangeType.prev_week:
            r.start = date_utils.getFirstDayOfWeek(d);
            r.start = moment(r.start).add(-7, 'day');
            r.end = moment(r.start).add(6, 'day');
            break;

         case DateSelector_RangeType.week_align:
            r.start = date_utils.getFirstDayOfWeek(r.end);
            r.end = moment(r.start).add(6, 'day');
            break;
         //TODO prevweek_align

         case DateSelector_RangeType.month:
            r.start = r.start.date(1);
            r.end = moment(r.start).add(1, 'month').add(-1, 'day');
            break;
         case DateSelector_RangeType.last_month:
         case DateSelector_RangeType.prev_month:
            r.start = r.start.date(1);
            r.start.add(-1, 'month');
            r.end = moment(r.start).add(1, 'month').add(-1, 'day');
            break;

         case DateSelector_RangeType.month_align:
            r.start = moment(r.end).date(1);
            r.end = moment(r.start).add(1, 'month').add(-1, 'day');
            break;


         case DateSelector_RangeType.running_13weeks_lastweek:
            r.start = date_utils.getFirstDayOfWeek(d);
            r.end = r.start.clone().add(-1, 'day');
            r.start = r.start.add(-13 * 7, 'day');
            break;
         case DateSelector_RangeType.running_13weeks_thisweek:
            r.start = date_utils.getFirstDayOfWeek(d);
            r.end = r.start.clone().add(+6, 'day');
            r.start = r.start.add(-12 * 7, 'day');
            break;


         case DateSelector_RangeType.running_13weeks_lastweek_align:
            r.start = date_utils.getFirstDayOfWeek(r.end);
            r.end = r.start.clone().add(-1, 'day');
            r.start = r.start.add(-13 * 7, 'day');
            break;
         case DateSelector_RangeType.running_13weeks_thisweek_align:
            r.start = date_utils.getFirstDayOfWeek(r.end);
            r.end = r.start.clone().add(+6, 'day');
            r.start = r.start.add(-12 * 7, 'day');
            break;

         case DateSelector_RangeType.year:
            r.start = r.start.month(0).date(1);
            r.end = moment(r.start).add(1, 'year').add(-1, 'day');
            break;
         case DateSelector_RangeType.prev_year:
            r.start = r.start.month(0).date(1);
            r.start.add(-1, 'year');
            r.end = moment(r.start).add(1, 'year').add(-1, 'day');
            break;
         case DateSelector_RangeType.year_align:
            r.start = moment(r.end).month(0).date(1);
            r.end = moment(r.start).add(1, 'year').add(-1, 'day');
            break;
         case DateSelector_RangeType.year_month_align:
            r.start = moment(r.end).date(1).add(-11, 'month');
            r.end = moment(r.start).add(12, 'month');
            break;


         case DateSelector_RangeType.weekyear:
            r.start = date_utils.getFirstDayOfWeek(r.start.week(1));
            r.end = r.start.clone().add(55, 'week');
            r.end.week(1).add(-1, 'day');
            break;
         case DateSelector_RangeType.mtd:
            r.start = moment(d).date(1);
            r.end = moment(d);
            break;

         case DateSelector_RangeType.ytd:
            r.start = moment(d).month(0).date(1);
            r.end = moment(d);
            break;
         case DateSelector_RangeType.mtd_align:
            r.start = moment(r.end).date(1);
            break;

         case DateSelector_RangeType.ytd_align:
            r.start = moment(r.end).month(0).date(1);
            break;

         case DateSelector_RangeType.last7days:
            r.end = d.clone();
            r.start = d.clone().add(-6, 'day');
            break;
         case DateSelector_RangeType.last30days:
            r.end = d.clone();
            r.start = d.clone().add(-29, 'day');
            break;
         case DateSelector_RangeType.last14days:
            r.end = d.clone();
            r.start = d.clone().add(-13, 'day');
            break;
         case DateSelector_RangeType._7days:
            r.end = moment(r.start).add(6, 'day');
            break;
         case DateSelector_RangeType._30days:
            r.end = moment(r.start).add(1, 'month');
            break;
         case DateSelector_RangeType.next_sun:
            r.start = r.start.clone();
            r.start.add(7 - r.start.day(), 'day');
            r.end = r.start.clone();
            break;
         case DateSelector_RangeType.next_sat:
            r.start = r.start.clone();
            r.start.add(6 - r.start.day(), 'day');
            r.end = r.start.clone();
            break;
      }


   }


   export function align_subrange(r: DateSelectorSubRange, prev: DateSelectorRangeBase, type: DateSelector_SubRangeType) {
      const d = moment();
      switch (type) {
         case DateSelector_SubRangeType.prev:
            let diff = prev.end.diff(prev.start, 'day');
            diff++;
            r.start = moment(prev.start).add(-diff, 'day');
            r.end = moment(prev.end).add(-diff, 'day');
            break;
         case DateSelector_SubRangeType.this:
            r.start = moment(r.start);
            r.end = moment(r.end);
            break;
         case DateSelector_SubRangeType.prevweek:
            r.start = moment(prev.start).add(-7, 'day');
            r.end = moment(prev.end).add(-7, 'day');
            break;
         case DateSelector_SubRangeType.prevweek2:
            r.start = moment(prev.start).add(-14, 'day');
            r.end = moment(prev.end).add(-14, 'day');
            break;
         case DateSelector_SubRangeType.prev52week:
            r.start = moment(prev.start).add(-7 * 52, 'day');
            r.end = moment(prev.end).add(-7 * 52, 'day');
            break;
         case DateSelector_SubRangeType.prevmonth:
            r.start = moment(prev.start).add(-1, 'month');
            r.end = moment(prev.end).add(1, 'day').add(-1, 'month').add(-1, 'day');
            //r.end = moment(prev.end).add(-1, 'month');
            break;
         case DateSelector_SubRangeType.prevmonth2:
            r.start = moment(prev.start).add(-2, 'month');
            r.end = moment(prev.end).add(1, 'day').add(-2, 'month').add(-1, 'day');
            //r.end = moment(prev.end).add(-2, 'month');
            break;
         case DateSelector_SubRangeType.prevyear:
            r.start = moment(prev.start).add(-1, 'year');
            r.end = moment(prev.end).add(-1, 'year');
            break;
         case DateSelector_SubRangeType.prevyear2:
            r.start = moment(prev.start).add(-2, 'year');
            r.end = moment(prev.end).add(-2, 'year');
            break;
         case DateSelector_SubRangeType.prevweekyear:
            r.start = date_utils.getFirstDayOfWeek(r.start.week(1));

            r.start.add(-40, 'week');
            r.start.week(1);
            r.end = r.start.clone().add(55, 'week');
            r.end.week(1).add(-1, 'day');
            break;
         case DateSelector_SubRangeType.prevweekyear2:
            r.start = date_utils.getFirstDayOfWeek(r.start.week(1));
            r.start.add(-52 - 40, 'week');
            r.start.week(1);
            r.end = r.start.clone().add(55, 'week');
            r.end.week(1).add(-1, 'day');
            break;
         case DateSelector_SubRangeType.custom:
            break;
         default:
            console.log('Error with SubRange');
            break;

         // case DateSelector_SubRangeType.week:
         //    r.start = this.getFirstDayOfWeek(d);
         //    r.end = moment(r.start).add(6, 'day');
         //    break;

         // case DateSelector_SubRangeType.month:
         //    r.start = r.start.date(1);
         //    r.end = moment(r.start).add(1, 'month').add(-1, 'day');
         //    break;
         // case DateSelector_SubRangeType.year:
         //    r.start = r.start.month(0).date(1);
         //    r.end = moment(r.start).add(1, 'year').add(-1, 'day');
         //    break;
         // case DateSelector_SubRangeType.mtd:
         //    r.start = moment(r.end).date(1);
         //    break;
         // case DateSelector_SubRangeType.ytd:
         //    r.start = moment(r.end).month(0).date(1);
         //    break;


         // case DateSelector_SubRangeType._7days:
         //    r.end = moment(r.start).add(6, 'day');
         //    break;
         // case DateSelector_SubRangeType._30days:
         //    r.end = moment(r.start).add(1, 'month');
         //    break;
      }
   }

}



export class DateRange {
   startDate: moment.Moment;
   endDate: moment.Moment;
   constructor(data?: any) {
      if (data) {
         this.startDate = moment(data.startDate, 'YYYYMMDDhhmm');
         this.endDate = moment(data.endDate, 'YYYYMMDDhhmm');
      }
   }
   equals(r: DateRange) {
      return this.startDate.isSame(r.startDate) && this.endDate.isSame(r.endDate);
   }
}




/////////////////////////////
// Date Selector Range

export abstract class DateSelectorRangeBase {
   start: moment.Moment;
   end: moment.Moment;
   startHour = '00:00';
   endHour = '23:00';
   constructor(copy?: DateSelectorRangeBase) {
      if (copy) {
         this.copy(copy);
      }
   }

   copy(copy: DateSelectorRangeBase) {
      this.start = moment(copy.start);
      this.end = moment(copy.end);
      this.startHour = copy.startHour;
      this.endHour = copy.endHour;
   }

   daySpan() {
      return this.end.diff(this.start, 'day') + 1;
   }



   abstract hasEndDate(): boolean;
   abstract hasStartDate(): boolean;

   toText() {
      const range = new DateRange();
      range.startDate = this.start;
      range.endDate = this.end;
      return daterange_utils.formatPeriod(range);
   }
}

export class DateSelectorRange extends DateSelectorRangeBase {
   type: DateSelector_RangeType = DateSelector_RangeType.custom;

   constructor(copy?: DateSelectorRange) {
      super(copy);
      if (copy)
         this.type = copy.type;
   }

   clone() {
      return new DateSelectorRange(this);
   }

   adjust() {
      daterange_utils.align_range(this, this.type);
   }
   hasEndDate() {
      switch (this.type) {
         case DateSelector_RangeType.mtd:
         case DateSelector_RangeType.custom:
            return true;
      }
      return false;
   }
   hasStartDate() {
      return this.type === DateSelector_RangeType.custom ||
         (this.type.indexOf('this') !== 0 &&
            this.type.indexOf('last') !== 0 &&
            this.type !== 'mtd' && this.type !== 'ytd');

   }



}

export class DateSelectorSubRange extends DateSelectorRangeBase {
   type: DateSelector_SubRangeType = DateSelector_SubRangeType.prev;
   constructor(copy?: DateSelectorSubRange) {
      super(copy);
      if (copy)
         this.type = copy.type;
   }
   clone() {
      return new DateSelectorSubRange(this);
   }

   adjust(prev: DateSelectorRangeBase) {
      daterange_utils.align_subrange(this, prev, this.type);
   }
   hasEndDate() {
      return this.type === DateSelector_SubRangeType.custom;
   }
   hasStartDate() {
      return this.type === DateSelector_SubRangeType.custom || (this.type.indexOf('prev') !== 0);
   }
}



export class DateSelectorConfig {
   day: DateSlice = DateSlice.ALL;
   range: DateSelectorRange = new DateSelectorRange();
   subranges: DateSelectorSubRange[] = [];

   constructor(copy?: DateSelectorConfig) {
      if (copy) {
         this.day = copy.day;
         this.range = new DateSelectorRange(copy.range);
         for (const r of copy.subranges) {
            this.subranges.push(new DateSelectorSubRange(r));
         }
      }
   }
   init(offset?: number, numDays?: number) {
      numDays = numDays || 7;
      offset = offset || numDays;
      let start = moment().startOf('day');
      start = start.add(-offset, 'day');
      let end = start.clone().add(numDays, 'day');
      this.range.start = start;
      this.range.end = end;

   }
   clone() {
      return new DateSelectorConfig(this);
   }

   // dates copied
   setRange(start: moment.Moment, end: moment.Moment, type?: DateSelector_RangeType) {
      type = type || DateSelector_RangeType.custom;
      this.range.type = type;
      this.range.start = start.clone();
      this.range.end = end.clone();
      this.removeSubRanges();
   }

   removeSubRanges() {
      this.subranges.length = 0;
   }
   getText() {
      const list = [];
      list.push(this.range.toText());

      for (const s of this.subranges) {
         list.push(s.toText());
      }
      return list;

   }

   removeRange(r: DateSelectorSubRange): any {
      const index = this.subranges.indexOf(r);
      if (index >= 0)
         this.subranges.splice(index, 1);

   }
   addPrevSubRange(type?: DateSelector_SubRangeType): any {
      type = type || DateSelector_SubRangeType.prev;
      const r = new DateSelectorSubRange();
      r.type = type;
      this.addSubRange(r);

   }

   addSubRange(r: DateSelectorSubRange) {
      let prev: DateSelectorRangeBase = this.range;
      if (this.subranges.length)
         prev = this.subranges[this.subranges.length - 1];

      r.adjust(prev);
      this.subranges.push(r);
   }





}
