<!-- @format -->

<div class="dialog-max cdialog ctlSettings" style="width: 35vw; height: 40vh">
  <div class="dialogHeader" style="display: flex">
    <h2 mat-dialog-title>
      {{ _common?.sys_common?.change_password ?? 'Change Password'}}
    </h2>
    <div class="spacer"></div>
    <span (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </span>
  </div>
  <mat-dialog-content style="display: flex">
    <form [formGroup]="form" class="max-scroll dialogBody">
      <div class="margin_top">
        <app-form-field class="w-full">
          <app-label
            >{{ _common?.sys_common?.change_password ?? 'Change Password'
            }}</app-label
          >
          <input
            matInput
            [placeholder]="_common?.sys_common?.passwordInstruction ?? ''"
            type="password"
            #password
            formControlName="password"
          />
          <div appSuffix>
            <fa-icon
              icon="eye-slash"
              *ngIf="hidePassword"
              (click)="togglePassword()"
            ></fa-icon>
            <fa-icon
              icon="eye"
              *ngIf="!hidePassword"
              (click)="togglePassword()"
            ></fa-icon>
          </div>
        </app-form-field>
      </div>
      <div>
        <app-form-field class="w-full">
          <app-label>{{ _confirmText()}}</app-label>
          <input
            matInput
            [placeholder]="_common?.sys_common?.confirm_password"
            type="password"
            #password2
            formControlName="password2"
          />
          <div appSuffix>
            <fa-icon
              icon="eye-slash"
              *ngIf="hidePassword"
              (click)="togglePassword()"
            ></fa-icon>
            <fa-icon
              icon="eye"
              *ngIf="!hidePassword"
              (click)="togglePassword()"
            ></fa-icon>
          </div>
        </app-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <div class="dialogBar">
    <!--<button class="app-btn btn-text" (click)="cancel()">Cancel</button>-->
    <button class="app-btn" (click)="reset()" color="primary">
      {{ _common?.ok ?? 'Save' }}
    </button>
  </div>
</div>
