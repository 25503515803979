/** @format */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  locale: string = 'en';
  constructor() {
    console.log('create locale service');
  }
  setLocale(locale: string) {
    this.locale = locale;
  }
}
