import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, HostBinding } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from '../common/utils';
import { AccessUtils, TLocation } from '../core/entities';
import { FullWidthCarouselComponent } from '../core/fixed.carousel.component';
import { FixedCarouselItemDirective } from '../core/fixed.carousel.item.directive';
import { SiteLangMixin } from '../core/lang';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionService } from '../session.service';
import { MapService } from './map.service';
import { SpaceCard } from './space.card';

@Component({
  selector: 'app-location-page',
  standalone: true,
  imports: [
    CommonModule,
    GoogleMapsModule,
    RouterLink,
    SpaceCard,
    FontAwesomeModule,
    FullWidthCarouselComponent,
    FixedCarouselItemDirective,
  ],
  templateUrl: './location.page.html',
})
export class LocationPage extends SiteLangMixin(SitePathMixin(EmptyObject)) {
  @HostBinding('class') css = 'max-scroll';
  _common = _common;
  susbcription: Subscription;
  id: number;

  object: TLocation;
  description: SafeHtml;
  position: any;
  options: {};

  openingHours: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    public override session: SessionService,
    public mapService: MapService
  ) {
    super();
  }
  ngOnInit() {
    this.mapService.loadMap();
    this.susbcription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));
      this.refresh();
    });
  }

  ngOnDestroy() {
    this.session.setTitle();
  }
  refresh() {
    WebUtils.web_result(
      this.http.get(`/api/public/location/${this.id}`),
      (result) => {
        this.object = result as TLocation;
        AccessUtils.fixLocation(this.object);
        this.session.setTitle(this.object.name);

        this.description = this.sanitizer.bypassSecurityTrustHtml(
          this.lang(this.object.description ?? '', this.object.description_lang)
        );
        this.position = {
          lat: this.object.lat,
          lng: this.object.lng,
        };
        this.options = {
          title: this.object.name,
        };
        let s = this.object?.openingHours ?? '';
        this.openingHours = s.split('\n');
      },
      null,
      (err) => {
        this.router.navigate(['/notfound']);
      }
    );
  }
  openGoogleMap() {
    window.open(this.object.googleMapUrl);
  }
}
