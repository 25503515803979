import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { date_utils } from '../common/date_utils';
import { TimeAtom } from '../common/timespan/time_utils';
import { WebUtils, utils } from '../common/utils';
import { AccessUtils, TBooking, TLocker } from '../core/entities';
import { BookingAvailabilityManager } from './booking.availability.manager';
import { BookContainerBase } from './space.book.container';
import { TBookingType } from './thinkdesks.entities';

export class LockerBookContainer extends BookContainerBase<TLocker> {
  id: number;
  // object: TLocker;
  // selected = moment.tz(date_utils.tz).startOf('day');
  // hours: BookHour[] = [];
  // slots: BookDuration[] = [];

  createLockerRequest(): Observable<Object> {
    return this.http.get(`/api/public/locker/${this.id}`);
  }

  createLockerAvailavility(start: moment.Moment, end: moment.Moment) {
    return this.http.post(`/api/public/locker/${this.id}/availability`, {
      start,
      end,
    });
  }

  createLockerBookingRequest(query): Observable<Object> {
    return this.http.post('/api/public/booking/create/locker', query);
  }
  createLockerManageBookingRequest(query): Observable<Object> {
    return this.http.post('/api/manage/booking/create/locker', query);
  }
  refresh() {
    WebUtils.web_result(
      this.createLockerRequest(),
      (result) => {
        this.object = result as TLocker;
        AccessUtils.fixLocker(this.object);

        this.slots = this.createSlots(this.object);

        // let mins = Thinkdesks.BOOK_MINS;

        // this.slots = [];
        // for (
        //   let i = this.object.bookMinSlots;
        //   i <= this.object.bookMaxSlots;
        //   i += this.object.bookSlotIncrement
        // ) {
        //   let m = mins * i;
        //   this.slots.push({
        //     slots: i,
        //     value: m,
        //     name: `${m} mins`,
        //     active: false,
        //   });
        // }
        let now = moment.tz(date_utils.tz).startOf('day');
        this.startDate = now.clone();
        this.endDate = now.clone().add(this.object.bookDays - 1, 'day');
      },
      null,
      (err) => {
        this.router.navigate(['/notfound']);
      }
    );
    let d = moment().tz(date_utils.tz);

    this.availability(this.selected);
  }

  override availability(d: moment.Moment) {
    WebUtils.web_result(
      this.createLockerAvailavility(d, d.clone().add(1, 'day')),
      (result) => {
        this.availabilityManager = new BookingAvailabilityManager();
        this.availabilityManager.load(d, result);
        this.updateTime();
      }
    );
  }
  // updateTime() {
  //   let list = Thinkdesks.HOURS.filter((c) => {
  //     return this.availabilityManager.match([c.time]);
  //   }).map((c) => {
  //     return Object.assign(
  //       {
  //         active: true,
  //       },
  //       c
  //     );
  //   });
  //   this.hours = [];
  //   for (let i = 0; i < list.length; i += this.object.bookSlotIncrement) {
  //     if (i + this.object.bookSlotIncrement <= list.length) {
  //       this.hours.push(list[i]);
  //     } else {
  //       break;
  //     }
  //   }

  //   for (let h of this.hours) {
  //     h.active = this.checkTime(this.selected, h.time)
  //   }
  // }

  override checkTime(date: moment.Moment, time: TimeAtom) {
    return this.availabilityManager.checkLockerTime(date, this.object, time);
  }
  // updateDuration() {
  //   let startTime = this.form.value.startTime;
  //   let duration = this.form.value.duration;
  //   for (let s of this.slots) {
  //     s.active = false;
  //   }
  //   if (!startTime) return;

  //   let start = startTime.time as TimeAtom;
  //   start = start.clone();
  //   for (let s of this.slots) {
  //     let booked = false;
  //     for (let i = 0; i < this.object.bookMinSlots; i++) {
  //       if (!this.availabilityManager.checkLockerTime(this.object, start)) {
  //         booked = true;
  //         break;
  //       }
  //       start.add(Thinkdesks.BOOK_MINS);
  //     }
  //     if (!booked) {
  //       s.active = true;
  //     }
  //   }
  //   if (!duration?.active) this.form.controls['duration'].setValue(null);
  // }

  getQuery() {
    if (!this.form.valid) return utils.validateAllFormFields(this.form);

    let value = Object.assign({}, this.form.value);
    let date = this.selected;
    value.startTime = value.startTime.value;
    value.duration = value.duration.value;

    let time = TimeAtom.parse(value.startTime);
    let startTime = time.moment(date);
    let endTime = startTime.clone().add(value.duration, 'minute');

    let query = {
      startTime,
      endTime,

      type: TBookingType.locker,
      locker: WebUtils.objName(this.object),
    };
    return query;
  }
  proceed() {
    let query = this.getQuery();
    if (!query) return;

    WebUtils.web_result(
      this.createLockerBookingRequest(query),
      (result) => {
        this.router.navigate([`/my/booking/${result.id}`]);
      },
      this.snackBar
    );
  }
  proceedManage(dto: any, cb: (o: TBooking) => void) {
    let query = this.getQuery();
    if (!query) return;
    Object.assign(query, dto);

    WebUtils.web_result(
      this.createLockerManageBookingRequest(query),
      (result) => {
        AccessUtils.fixBooking(result);
        cb(result);
      },
      this.snackBar
    );
  }
}
