/** @format */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';

import { NgFor } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateSlice, date_utils } from '../../date_utils';
import {
  DateSelectorConfig,
  DateSelectorRangeBase,
  DateSelectorSubRange,
  DateSelector_SubRangeType,
} from '../../daterange_utils';
import { NamePair } from '../../utils';

import { VizFormModule } from '../form/form.module';
import { CalendarDate, CalendarEvent } from './calendar.component';
import { ColorTheme } from './color_theme';
import {
  CommonDateSelector,
  CommonDateSelectorGroup,
  CommonDateSelectors,
} from './date_common';
import { DateRangeComponent } from './daterange.component';
import { DateSubRangeComponent } from './datesubrange.component';
import { ThreeCalendarComponent } from './three.calendar.component';

@Component({
  templateUrl: './date.selector.dialog.html',
  standalone: true,
  imports: [
    MatDialogModule,
    ThreeCalendarComponent,
    DateRangeComponent,
    NgFor,
    DateSubRangeComponent,
    FontAwesomeModule,
    VizFormModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class DateSelectorDialogComponent implements OnInit, AfterViewInit {
  @Inject(LOCALE_ID) public locale: string;

  @ViewChild('rangeSelector', { static: true })
  rangeSelector: DateRangeComponent;
  @ViewChildren(DateSubRangeComponent)
  subrangeSelectors: QueryList<DateSubRangeComponent>;

  config: DateSelectorConfig = new DateSelectorConfig();
  events: CalendarEvent[] = [];

  selectors: CommonDateSelectorGroup[] = CommonDateSelectors;
  trigger = new EventEmitter<DateSelectorConfig>();

  curRange: DateSelectorRangeBase;
  curStart: boolean;

  dayFilters: NamePair[];
  _common = _common;
  constructor(
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<DateSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.config = <DateSelectorConfig>data.config;
    this.dayFilters = this.getDayFilterList();
  }
  namePair(id: string, name: string): NamePair {
    return { id: id, name: name };
  }
  getDayFilterList() {
    const list = [];

    const dayNames = date_utils.getWeekdayNames();
    list.push(
      this.namePair(DateSlice.ALL, _common.DateRangeSelector.dayFilters.none),
    );
    list.push(
      this.namePair(
        DateSlice.WEEKDAY,
        _common.DateRangeSelector.dayFilters.weekday,
      ),
    );
    list.push(
      this.namePair(
        DateSlice.WEEKEND,
        _common.DateRangeSelector.dayFilters.weekend,
      ),
    );
    list.push(
      this.namePair(
        DateSlice.MONTHU,
        _common.DateRangeSelector.dayFilters.mon_thu,
      ),
    );
    list.push(
      this.namePair(
        DateSlice.FRISUN,
        _common.DateRangeSelector.dayFilters.fri_sun,
      ),
    );
    list.push(this.namePair(DateSlice.MON, dayNames[0]));
    list.push(this.namePair(DateSlice.TUE, dayNames[1]));
    list.push(this.namePair(DateSlice.WED, dayNames[2]));
    list.push(this.namePair(DateSlice.THU, dayNames[3]));
    list.push(this.namePair(DateSlice.FRI, dayNames[4]));
    list.push(this.namePair(DateSlice.SAT, dayNames[5]));
    list.push(this.namePair(DateSlice.SUN, dayNames[6]));

    return list;
  }

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.refresh();
    this.clickReset();
    this.cdRef.detectChanges();
  }

  addPeriod() {
    const r = new DateSelectorSubRange();
    r.type = DateSelector_SubRangeType.prev;
    this.config.addSubRange(r);
    this.refresh();
    this.clickReset();
    this.trigger.emit(this.config);
  }

  convertToEvent(r: DateSelectorRangeBase, colorIndex: number): CalendarEvent {
    // const theme = AppConfig.appConfig.theme;
    return {
      start: r.start,
      end: r.end,
      color: ColorTheme.getColor(colorIndex),
    };
  }
  refresh() {
    this.config.range.adjust();
    let prev: DateSelectorRangeBase = this.config.range;
    for (const r of this.config.subranges) {
      r.adjust(prev);
      prev = r;
    }

    // rebuild event list
    this.events = [];
    let index = 0;
    this.events.push(this.convertToEvent(this.config.range, index++));
    for (const r of this.config.subranges) {
      this.events.push(this.convertToEvent(r, index++));
    }
  }

  clickHighligh() {
    this.rangeSelector.highlight(this.curRange, this.curStart);
    this.subrangeSelectors.forEach((item) => {
      item.highlight(this.curRange, this.curStart);
    });
  }

  clickReset() {
    this.curRange = this.config.range;
    this.curStart = true;
    if (!this.curRange.hasStartDate()) this.clickMove();
    else this.clickHighligh();
  }

  clickMove() {
    const config = this.config;
    const length = this.config.subranges.length;
    if (!config.range.hasEndDate() && !config.range.hasStartDate()) {
      let checked = true;
      for (let i = 0; i < length; i++) {
        if (
          config.subranges[i].hasStartDate() ||
          config.subranges[i].hasEndDate()
        ) {
          checked = true;
          break;
        }
      }
      if (checked) {
        this.curRange = null;
        this.curStart = true;
        this.clickHighligh();
        return;
      }
    }
    let index = -1;
    if (this.curRange !== config.range)
      index = config.subranges.indexOf(<DateSelectorSubRange>this.curRange);

    while (true) {
      if (this.curStart) {
        this.curStart = false;
        if (this.curRange.hasEndDate()) break;
      }

      if (index < 0) {
        if (length) {
          index = 0;
          this.curRange = this.config.subranges[0];
        }
      } else {
        if (index === length - 1) {
          this.curRange = this.config.range;
          index = -1;
        } else {
          this.curRange = this.config.subranges[index + 1];
          index++;
        }
      }
      this.curStart = true;
      if (this.curRange.hasStartDate()) break;
    }
    this.clickHighligh();
  }

  selectDate(date: CalendarDate) {
    const config = this.config;

    if (!this.curRange) return;

    let index = -1;
    if (this.curRange !== config.range)
      index = config.subranges.indexOf(<DateSelectorSubRange>this.curRange);

    if (this.curStart) {
      if (this.curRange.hasEndDate()) {
        const diff = date.mDate.diff(this.curRange.start, 'day');
        this.curRange.end.add(diff, 'day');
      }
      this.curRange.start = date.mDate.clone();
    } else {
      this.curRange.end = date.mDate.clone();
    }
    if (index < 0) {
      this.rangeSelector.refresh();
    } else {
      const c = this.subrangeSelectors.toArray()[index];

      c.refresh();
    }

    this.clickMove();
    this.refresh();
    this.trigger.emit(this.config);
  }

  onRemove(range: DateSelectorSubRange) {
    this.config.removeRange(range);
    this.refresh();
    this.trigger.emit(this.config);
  }
  onChange() {
    this.refresh();
    this.clickReset();
    this.trigger.emit(this.config);
  }
  shortcut(s: CommonDateSelector) {
    s.setup(this.config);
    this.refresh();
    this.clickReset();
    this.trigger.emit(this.config);
  }
  cancel() {
    this.dialogRef.close();
  }
  ok() {
    this.dialogRef.close({
      config: this.config,
    });
  }
}
