<!-- @format -->
<div class="ctlSearchBar">
    <div class="searchField">
      <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
      <input
        matInput
        (keyup)="applyFilter($any($event.target).value)"
        placeholder="{{ _common.search }}"
        #_search
      />
      <fa-icon
        icon="times"
        matSuffix
        (click)="clearFilter(_search)"
        class="clearSearch"
      ></fa-icon>
    </div>
  
    <div class="ctlButtons">
      <div style="cursor: inherit">
        <fa-icon icon="sliders-h"></fa-icon>
      </div>
  
      <!-- <app-filter-multi
        [filter]="tierFilter"
        #_tier
        (change)="refresh()"
        [values]="tierFilterValues"
        [names]="tierFilterNames"
        >{{ _common.customers.Tier }}</app-filter-multi
      > -->
    </div>
    <span class="spacer"></span>
    <span class="button">
      <!-- <button class="app-btn" (click)="add()" color="primary">
              <fa-icon icon="plus"></fa-icon> Add
           </button> -->
      <!-- <button class="app-btn btn-text" (click)="removeTeam()">
              <fa-icon icon="times"></fa-icon> {{_common.remove}}
  
           </button> -->
    </span>
  </div>
  
  <app-scroll-container
    [more]="hasMore()"
    (scrolled)="handleScroll($event)"
    class="max-scroll card-shadow"
    #scroll
  >
    <table mat-table [dataSource]="objects" style="width: 100%">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="datatable-select">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ _common.name }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      
      
  
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>{{ _common.email }}</th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.email }}
          </div>
          <div *ngIf="element.mobile && element.mobile != ''">
            {{ element.mobile }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="controls">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="floating-buttons">
          <button class="app-btn btn-text" (click)="selectObject(element)">
            <fa-icon icon="pen"></fa-icon>
            {{ _common.edit }}
          </button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="datatable-row"
      ></tr>
    </table>
  </app-scroll-container>
  