import { NgClass, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../../common/list.component.base';
import { DELETED } from '../../common/utils';
import { AccessUtils, TSpaceType } from '../../core/entities';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';
import { SpaceTypeEditDialogComponent } from './space.type.edit.dialog';

class _Base extends ScrollableListComponentBase<TSpaceType> {}

@Component({
  selector: 'app-space-type-list',
  templateUrl: './space.type.list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
  ],
})
export class SpaceTypeListComponent extends SiteFormatMixin(_Base) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();
  //   status = new FilterMulti();

  //   type = new FilterMulti();

  displayedColumns: string[] = ['name', 'controls'];

  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {} as any;
    Object.assign(query, this.filterObject.query());

    const url = this._listModifyUrl(`/api/space-type/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TSpaceType[]) {
    for (let o of list) AccessUtils.fixSpaceType(o);
  }

  add() {
    const ref = this.dialog.open(SpaceTypeEditDialogComponent, {
      data: {},
    });
    ref.afterClosed().subscribe((result) => {
      if (result) {
        this._addToList(result);
      }
    });
  }

  override selectObject(o: TSpaceType) {
    const dialogRef = this.dialog.open(SpaceTypeEditDialogComponent, {
      data: {
        objectId: o.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        this._removeFromList(o);
      } else if (result) {
        Object.assign(o, result);
      }
    });
  }
}
