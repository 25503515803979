/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { VizFormModule } from '../components/form/form.module';
import { RangeType } from './filter.date.range';
import { FilterDateRange } from './list.filter';

@Component({
  selector: 'app-filter-date-range',
  templateUrl: './filter.date.range.component.html',
  standalone: true,
  imports: [
    VizFormModule,
    FontAwesomeModule,
    MatSelectModule,
    CommonModule,
    MatDatepickerModule,
  ],
})
export class FilterDateRangeComponent implements OnInit {
  faClock = faClock;
  _common = _common;

  types = RangeType;
  // @Input()
  // type = RangeType.day14;

  // start: moment.Moment;
  // end: moment.Moment;

  @Output()
  change = new EventEmitter<void>();

  @Input()
  filter: FilterDateRange;

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    // this.change.next({ type: this.type, start: this.start, end: this.end });

    this.filter.refresh();
    this.change.next(void 0);
  }
}
