<div class="client_pageBody home_body_top">
  <div class="content">
    <div class="body_section flex gap-4">
      <div>
        <img src="/silone/silone_hi.png" />
      </div>
      <div class="w-full">
        <div class="text-3xl">{{ _common.HomeGreeting1 }}</div>
        <div class="text-3xl font-bold mt-2 flex">
          {{ _common.HomeGreeting2 }}
          <div class="spacer" style="max-width: 100px"></div>
          <div class="client_button btn_circle" (click)="goSearch()">
            <fa-icon icon="arrow-right" style="font-size: 20px"></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <!--My Booking Section-->
    <ng-container *ngIf="session.user">
      <app-upcoming-booking-list></app-upcoming-booking-list>
    </ng-container>
  </div>
</div>

<div class="client_pageBody home_body_bottom">
  <div class="content">
    <!--Center Section-->
    <ng-container *ngIf="session.locationStream | async">
      <div class="body_section">
        <div class="sectionHeader">
          <div class="title spacer flex gap-2 items-center">
            {{ _common.NearbyCenters }}
            <div [routerLink]="[session.path('/location')]" class="client_button btn_circle" style="
                height: 32px;
                width: 32px;
                min-width: 32px;
                font-size: 14px;
              ">
              <fa-icon icon="map-location-dot"></fa-icon>
            </div>
          </div>
          <div [routerLink]="[session.path('/location-search')]" class="view_all_btn">
            {{ _common.ViewAll }}
            <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
          </div>

          <!-- <div [routerLink]="[session.path('/location')]" class="view_all_btn">
          <fa-icon icon="map-location-dot"></fa-icon>
          {{ _common.ViewMap }}
          <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
        </div> -->
        </div>
        <div>
          <app-nearby-location-list></app-nearby-location-list>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!(session.locationStream | async)">
      <div class="body_section">
        <div class="sectionHeader">
          <div class="title spacer flex gap-2 items-center">
            {{_common.Centers_random}}
            <div [routerLink]="[session.path('/location')]" class="client_button btn_circle" style="
                height: 32px;
                width: 32px;
                min-width: 32px;
                font-size: 14px;
              ">
              <fa-icon icon="map-location-dot"></fa-icon>
            </div>
          </div>
          <div [routerLink]="[session.path('/location-search')]" class="view_all_btn">
            {{ _common.ViewAll }}
            <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
          </div>

          <!-- <div [routerLink]="[session.path('/location')]" class="view_all_btn">
          <fa-icon icon="map-location-dot"></fa-icon>
          {{ _common.ViewMap }}
          <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
        </div> -->
        </div>
        <div>
          <app-random-location-list></app-random-location-list>
        </div>
      </div>
    </ng-container>

    <!--Space Section-->
    <div class="body_section">
      <!--Search Space-->
      <div class="search_bar mb-4 desktop_hide">
        <input class="w-full" type="text" matInput [(ngModel)]="search" placeholder="{{ _common.SearchSpaces }}"
          (keydown.enter)="goSearch()" />
        <div class="spacer"></div>
        <div class="cursor-pointer" (click)="goSearch()">
          <fa-icon icon="search"></fa-icon>
        </div>
      </div>
      <!--Search Space-->
      <div class="flex mobile_hide" style="margin-bottom: 3rem">
        <div style="display: flex; align-items: end; margin-bottom: 5px">
          <img src="/silone/silone_friends_tgt2.png" style="height: 120px" />
        </div>
        <div style="
            background: white;
            padding: 2rem 3rem 1.5rem 3rem;
            border-radius: 10px;
            flex: 1 1 auto;
          ">
          <div>
            <div style="font-size: 1.5rem; margin-bottom: 10px; font-weight: bold">
              {{ _common.SearchSpaces }}
            </div>
            <div class="search_bar mb-4" style="max-width: 100%">
              <input class="w-full" type="text" matInput [(ngModel)]="search" placeholder="{{ _common.SearchSpaces }}"
                (keydown.enter)="goSearch()" />
              <div class="spacer"></div>
              <div class="cursor-pointer" (click)="goSearch()">
                <fa-icon icon="search"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sectionHeader space-x-4">
        <div class="title">{{ _common.RecommendedSpaces }}</div>
        <div class="spacer"></div>
        <div [routerLink]="[session.path('/space')]" class="view_all_btn">
          {{ _common.ViewAll }}
          <fa-icon icon="chevron-right" class="arrow_icon"></fa-icon>
        </div>
      </div>
      <div>
        <app-featured-space-list></app-featured-space-list>
      </div>
    </div>
  </div>
</div>