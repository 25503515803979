import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmptyObject, WebUtils } from '../common/utils';
import { AccessUtils, TLocker } from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-locker-page',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './locker.page.html',
})
export class LockerPage extends SitePathMixin(EmptyObject) {
  susbcription: Subscription;
  id: number;

  object: TLocker;
  description: SafeHtml;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cache: SessionCache,
    public session: SessionService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }
  ngOnInit() {
    this.susbcription = this.route.paramMap.subscribe((result) => {
      this.id = parseInt(result.get('id'));
      this.refresh();
    });
  }
  ngOnDestroy() {
    this.susbcription.unsubscribe();
  }
  refresh() {
    WebUtils.web_result(
      this.http.get(`/api/public/locker/${this.id}`),
      (result) => {
        this.object = result as TLocker;
        AccessUtils.fixLocker(this.object);
      },
      null,
      (err) => {
        this.router.navigate(['/notfound']);
      }
    );
  }
}
