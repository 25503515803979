import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import { FilterDateRangButtonComponent } from '../common/filter/filter.date.range.button.component';
import { FilterMultiComponent } from '../common/filter/filter.multi.component';
import {
  FilterDateRange,
  FilterMulti,
  FilterObject,
} from '../common/filter/list.filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../common/list.component.base';
import { DELETED } from '../common/utils';
import { AccessUtils, TBooking, TSpace } from '../core/entities';
import { SiteLangMixin } from '../core/lang';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { BookingMixin } from '../thinkdesks/booking.mixin';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { ThinkdesksCache } from '../thinkdesks/thinkdesks.cache';
import {
  TBookingStatus,
  TBookingType,
} from '../thinkdesks/thinkdesks.entities';
import { BookingDialogComponent } from './booking.dialog.component';

class _Base extends ScrollableListComponentBase<TBooking> {}

@Component({
  selector: 'app-booking-space-list',
  templateUrl: './booking.space.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
    FilterMultiComponent,
    FilterDateRangButtonComponent,
  ],
})
export class BookingSpaceListComponent extends SiteLangMixin(
  BookingMixin(SiteFormatMixin(_Base))
) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();
  dateRange = new FilterDateRange();
  space = new FilterMulti();
  status = new FilterMulti();
  statusList = TBookingStatus;
  //   type = new FilterMulti();

  spaces: TSpace[] = []; 
  displayedColumns: string[] = [
    //'id',
    //'bookingNo',
    'status',
    'name',
    'space',
    'time',
    'purpose',
    'checkin',

    'date',

    'controls',
  ];

  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    private cache: SessionCache,
    public override session: SessionService,
    private thinkDesksCache: ThinkdesksCache
  ) {
    super(http, snackBar, elRef);
    this.pageSort = 'date';
    this.pageSortDir = 'desc';

    this.filterObject.add('space', this.space);
    this.filterObject.add('status', this.status);
    this.filterObject.add('dateRange', this.dateRange);

    this.thinkDesksCache.loadSpace(0, (list) => {
      this.spaces = list;
    });
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {} as any;
    Object.assign(query, this.filterObject.query());

    query.type = TBookingType.space;
    const url = this._listModifyUrl(`/api/booking/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TBooking[]) {
    for (let o of list) {
      AccessUtils.fixBooking(o);
      if(o.space)
      AccessUtils.fixSpace(o.space as TSpace);

    }
  }

  add() {
    // let ref = this.dialog.open(BookingSpaceSelectDialogComponent, {
    //   data: {},
    // });
    // ref.afterClosed().subscribe((result) => {
    //   if (result) {
    //     let ref = this.dialog.open(BookingSpaceDialog, {
    //       data: {
    //         objectId: result.id,
    //       },
    //     });
    //     ref.afterClosed().subscribe((result) => {
    //       if (result) {
    //         this._addToList(result);
    //       }
    //     });
    //   }
    // });
  }

  override selectObject(o: TBooking) {
    const dialogRef = this.dialog.open(BookingDialogComponent, {
      data: {
        objectId: o.id,
        object: o,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == DELETED) {
        this._removeFromList(o);
      } else if (result) {
        Object.assign(o, result);
      }
    });
  }
}
