<div class="calendar">

  <div class="month-grid">
    <div class="day-names">
      <div *ngFor="let name of dayNames" class="day-name p9">
        {{ name }}
      </div>
    </div>
    <div class="weeks">
      <div *ngFor="let week of weeks" class="week">
        <ng-container *ngFor="let day of week">
          <div class="week-date disabled" *ngIf="!isSelectedMonth(day.mDate)">
            <span class="date-text">{{ day.mDate.date() }}</span>
          </div>
          <div class="week-date enabled"
               *ngIf="isSelectedMonth(day.mDate)"
               (click)="selectDate(day)"
               [class.today]="day.today"
               [class.selected]="day.selected"
               [style.background-color]="day.color"
               >
            <span class="date-text">{{ day.mDate.date() }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>