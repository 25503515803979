import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';
import { VizFormModule } from '../../common/components/form/form.module';
import { ScrollContainerComponent } from '../../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../../common/list.component.base';
import { WebUtils } from '../../common/utils';
import { AccessUtils, TLocation } from '../../core/entities';
import { SessionCache } from '../../session.cache';
import { SessionService } from '../../session.service';
import { SiteFormatMixin } from '../../thinkdesks/site.format.mixin';
import {
  TAccessDeviceType,
  TDevice,
} from '../../thinkdesks/thinkdesks.entities';
// import { DeviceAccessDialogComponent } from './device.access.dialog';
// import { DeviceLockerUnlockDialogComponent } from './device.locker.unlock.dialog';

class _Base extends ScrollableListComponentBase<TDevice> {}

@Component({
  selector: 'app-device-list',
  templateUrl: './device.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    VizFormModule,
  ],
})
export class DeviceListComponent extends SiteFormatMixin(_Base) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();

  displayedColumns: string[] = ['name', 'type', 'numCells', 'controls'];

  types = TAccessDeviceType;

  locations: TLocation[] = [];
  location: TLocation;
  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    private cache: SessionCache,
    private session: SessionService
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';
    // this.pageSortDir = 'asc';
    this.cache.loadManagedLocation(this.session, (list) => {
      this.locations = list;
      this.location = list[0];
    });
  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      location: WebUtils.objId(this.location),
    } as any;
    // Object.assign(query, this.filterObject.query());

    const url = this._listModifyUrl(`/api/device/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TDevice[]) {
    for (let o of list) AccessUtils.fixDevice(o);
  }

  showAccess(o: TDevice) {
    // this.dialog.open(DeviceAccessDialogComponent, {
    //   data: {
    //     object: o,
    //   },
    // });
  }

  override selectObject(o: TDevice) {
    switch (o.type) {
      case TAccessDeviceType.door:
        WebUtils.web_result(
          this.http.post(`/api/device/${o.id}/unlock`, {}),
          (result) => {
            this.snackBar.open('Unlock Command Sent');
          }
        );
        break;
      //   case TAccessDeviceType.locker:
      //     this.dialog.open(DeviceLockerUnlockDialogComponent, {
      //       data: {
      //         object: o,
      //       },
      //     });
      //     break;
    }
  }
}
