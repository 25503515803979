/** @format */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { VizFormModule } from '../components/form/form.module';
import { FilterObject, FilterText } from './list.filter';

@Component({
  selector: 'app-filter-text-filter',
  templateUrl: './filter.text.filter.component.html',
  standalone: true,
  imports: [CommonModule, VizFormModule, MatInputModule, FontAwesomeModule],
})
export class FilterTextFilterComponent implements OnInit {
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  _common = _common;

  @Input()
  filterObject: FilterObject;
  @Input()
  id: string;
  @Input()
  filter = new FilterText();

  @Output()
  change = new EventEmitter<void>();

  ngOnInit() {}

  refresh() {
    this.change.next(null as any);
  }
  blur(event: any) {
    let v = event.target.value;
    this.filter.update(v);
    this.refresh();
  }
}
