import { NgFor } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';



@Component({
   templateUrl: './alert.dialog.html',
   standalone: true,
   imports: [MatDialogModule, NgFor]
})
export class AlertDialogComponent implements OnInit {
      _common = _common


   static open(dialog: MatDialog, title: string, content: string | string[], cb?: () => void) {
      const dialogRef = dialog.open(AlertDialogComponent, {
         data: {
            title,
            content,

         }
      });
      dialogRef.afterClosed().subscribe((result) => {

         if (cb)
            cb();

      });

   }

   title: string;
   content: string[];
   constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private fb: UntypedFormBuilder,
      private http: HttpClient,
      public dialogRef: MatDialogRef<AlertDialogComponent>) {


      this.title = data.title as string;
      if (typeof data.content == 'string')
         this.content = [data.content]
      else
         this.content = data.content


   }

   ngOnInit(): void {
   }

   confirm() {

      this.dialogRef.close(true);
   }
   cancel() {
      this.dialogRef.close();
   }
   closeDialog() {
      this.dialogRef.close();
   }
}
