import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SessionService } from '../session.service';
import { BookingSpaceCalendarComponent } from './booking.space.calendar.component';
import { BookingSpaceListComponent } from './booking.space.list.component';

enum Mode {
  list,
  calendar,
}
@Component({
  selector: 'app-booking-main',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    BookingSpaceListComponent,
    BookingSpaceCalendarComponent,
  ],
  templateUrl: './booking.main.component.html',
})
export class BookingMainComponent {
  @HostBinding('class') css = 'max-height'; _common = _common

  mode = Mode.list;
  modes = Mode;
  constructor(private session: SessionService) { }
  ngOnInit() { }
}
