import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HttpClient } from '@angular/common/http';
import { VizCommonModule } from '../common/common.module';
import {
  CompareWithMixin,
  EmptyObject,
  utils,
  WebUtils,
} from '../common/utils';
import { TBooking, TSpacePurpose } from '../core/entities';
import { SessionCache } from '../session.cache';

@Component({
  selector: 'app-booking-purpose-dialog',
  templateUrl: './booking.purpose.dialog.html',
  standalone: true,

  imports: [
    CommonModule,
    MatSelectModule,
    FontAwesomeModule,
    MatDialogModule,
    VizCommonModule,
  ],
})
export class BookingPurposeDialog extends CompareWithMixin(EmptyObject) {
  _common = _common;

  form: FormGroup;
  purposes: TSpacePurpose[] = [];
  object: TBooking;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private cache: SessionCache,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BookingPurposeDialog>
  ) {
    super();
    this.object = this.data.object;
    this.form = this.fb.group({
      purpose: [null],
    });
    this.cache.loadSpacePurposes((list) => {
      this.purposes = list;
    });
    utils.fill_form_values(this.object, this.form);
  }

  save() {
    if (!this.form.valid) {
      return utils.validateAllFormFields(this.form);
    }

    let query = Object.assign({}, this.form.value);
    WebUtils.web_result(
      this.http.put(`/api/booking/${this.object.id}`, query),
      (result) => {
        let object = result as TBooking;
        Object.assign(this.object, query);
        let _object = this.data._object;
        if (_object) Object.assign(_object, query);

        this.dialogRef.close(this.object);
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
