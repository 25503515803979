import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NamePair } from '../utils';
import { FilterSelectObjectsComponent } from './filter.select.objects.component';
import { FilterObject, FilterSelectObjects } from './list.filter';
import { NgIf, NgFor } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';




@Component({
    selector: 'app-filter-select-objects-filter',
    templateUrl: './filter.select.objects.filter.component.html',
    standalone: true,
    imports: [NgIf, NgFor, FontAwesomeModule]
})
export class FilterSelectObjectsFilterComponent implements OnInit {
   faChevronDown=faChevronDown
   faChevronUp=faChevronUp
   faTimes = faTimes
   _common = _common

   @Input()
   filterObject: FilterObject
   @Input()
   id: string;
   @Input()
   filter = new FilterSelectObjectsComponent();


   @Output()
   change = new EventEmitter<void>();


   @Output()
   select = new EventEmitter<FilterSelectObjects>();


   @Input()
   title = 'Objects'
   ngOnInit() {
   }

   refresh() {
      this.change.next(void 0);
   }
   remove(c: NamePair, event: Event) {
      this.filter.filter.remove(c, event)
      this.refresh();

   }
   clear(event: MouseEvent) {
      event.stopPropagation()
      event.preventDefault()

      this.filter.filter.clear();
      this.refresh();
   }

   _select() {
      this.select.next(this.filter.filter)
   }
}
