import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const TIMEOUT_INTERCEPTOR_LOGOUT = new InjectionToken<string>(
  'timeout.interceptor.logout'
);

@Injectable({
  providedIn: 'root',
})
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    @Inject(TIMEOUT_INTERCEPTOR_LOGOUT) @Optional() public logoutUrl: string
  ) {}
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const res = next.handle(req);
    return res.pipe(
      catchError((err: any, caught: Observable<any>) => {
        if (
          err.url &&
          err.url.startsWith(window.origin) &&
          err.status === 403
        ) {
          let url = this.logoutUrl || '/';
          let path = window.location.pathname;
          if (window.location.hash && window.location.hash != '')
            path += window.location.hash;
          if (url.indexOf('?') < 0) {
            url += '?path=';
          } else {
            url += '&path=';
          }
          url += encodeURIComponent(path);

          location.href = url;
          return of('Session timed out');
        } else if (err.status === 400) {
          let error = err.error;
          if (error.error && error.message) {
            error = error.error + ' - ' + error.message;
          }
          this.snackBar.open(error);
        }
        return throwError(err);

        // return of(err);
      })
    );
  }
}
