<!-- @format -->

<div class="dialog-max cdialog" style="min-width: 600px; height: calc(80vh - 48px)">
  <form [formGroup]="form">
    <div class="dialogHeader">
      <div mat-dialog-title>{{_common.thinkdesks.set_special_day}}</div>
      <div class="spacer"></div>
      <div (click)="cancel()" class="closeButton">
        <fa-icon icon="times"></fa-icon>
      </div>
    </div>
  </form>
  <mat-dialog-content>
    <div class="max-scroll dialogBody">
      <app-booking-calendar-day #calendarDay [form]="form"></app-booking-calendar-day>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <div class="spacer"></div>
    <button class="app-btn" (click)="save()" color="primary">
      {{_common.save}}
    </button>
  </div>
</div>