import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
   templateUrl: './upload.file.dialog.html',
   standalone: true,
   imports: [MatDialogModule]
})
export class UploadDialogComponent implements OnInit {
   title: string = '';
   prompt: string = '';
   process: (formData: FormData, file?: File) => Promise<boolean>;
   @ViewChild('fileNode', { static: true }) fileNode: ElementRef; 
   _common = _common



   static open(dialog: MatDialog, title: string, prompt: string, process: (formData: FormData) => Promise<boolean>) {
      dialog.open(UploadDialogComponent, {
         data: {
            title,
            prompt,
            process,
         }
      })
   }
   constructor(@Inject(MAT_DIALOG_DATA) public data: any,
      private snackBar: MatSnackBar,
      public dialogRef: MatDialogRef<UploadDialogComponent>) {

      this.title = data.title as string;
      this.prompt = data.prompt as string;
      this.process = data.process;

   }

   ngOnInit(): void {
   }

   async upload() {

      if (!this.fileNode.nativeElement.files.length)
         return;

      let element = this.fileNode.nativeElement as HTMLInputElement;
      let file = element.files?.[0];
      if (!file) return;
      const formData = new FormData();
      formData.append('file', file);

      const result = await this.process(formData, file);
      if (result)
         this.dialogRef.close(true);
   }
   cancel() {
      this.dialogRef.close();
   }
   closeDialog() {
      this.dialogRef.close();
   }
}
