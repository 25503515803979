import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Pair } from '../../utils';
import { ToolTipRendererDirective } from '../tooltip/tooltip.directive';


@Component({
    selector: 'app-list-selector',
    styleUrls: ['list.selector.component.scss'],
    templateUrl: './list.selector.component.html',
    standalone: true,
    imports: [
        MatCardModule,
        FontAwesomeModule,
        MatInputModule,
        DragDropModule,

        ReactiveFormsModule,
        FormsModule,

        NgFor,
        ToolTipRendererDirective,
        NgIf,
    ],
})
export class ListSelectorComponent implements OnInit, AfterViewInit, OnChanges {
   static syncNumerPairs(src: Pair<any>[], list: Pair<any>[], items: Pair<any>[]) {
      if (!src) return;
      for (const gg of src) {
         for (const g of items) {
            if (g.id === gg.id) {
               list.push(g);
               break;
            }
         }
      }

   }
   @HostBinding('class') css = 'max-height';


   @Input()
   canEdit = false;

   @Output()
   onEdit = new EventEmitter<Pair<any>>();

   @Input() items: Pair<any>[];
   @Input() selected: Pair<any>[];

   filter = '';
   notselected: Pair<any>[] = [];
   _notselected: Pair<any>[] = [];

   selected_highlight: Pair<any>[] = [];
   notselected_highlight: Pair<any>[] = [];

   constructor() { }

   ngOnInit(): void {

      this.refresh();
   }
   ngOnChanges(changes: SimpleChanges): void {
      this.refresh();
   }

   @Input()
   compareWith: (a: any, b: any) => boolean;
   indexOf(list: Pair<any>[], o: Pair<any>) {
      if (!this.compareWith)
         return list.indexOf(o);

      return list.findIndex((c) =>
         this.compareWith(c, o)
      );
   }
   refresh() {
      this.notselected = [];
      for (const i of this.items) {
         if (this.indexOf(this.selected, i) < 0) {
            // if (this.selected.indexOf(i) < 0) {
            this.notselected.push(i);
         }
      }

      this.applyFilter('');

   }
   drop(event: CdkDragDrop<Pair<any>[]>) {
      moveItemInArray(this.selected, event.previousIndex, event.currentIndex);
   }

   ngAfterViewInit(): void {
   }

   applyFilter(s?: string | EventTarget) {
      let filter;
      if (s) {
         if (typeof s == 'string')
            filter = s;
         else
            filter = (s as HTMLInputElement).value;
      }
      if (filter || filter === '')
         this.filter = filter;
      if (this.filter == null || this.filter === '') {
         this._notselected = this.notselected.slice();
      } else {
         this._notselected = [];
         for (const m of this.notselected) {
            if (m.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
               this._notselected.push(m);
         }
      }

   }
   left() {
      for (const i of this.selected_highlight) {
         this.notselected.push(i);
         this.notselected_highlight.push(i);
         // const index = this.selected.indexOf(i);
         const index = this.indexOf(this.selected, i);
         if (index >= 0)
            this.selected.splice(index, 1);
      }
      this.selected_highlight.length = 0;
      this.applyFilter();
   }
   leftAll() {
      for (const i of this.selected)
         this.notselected.push(i);
      this.selected.length = 0;
      this.applyFilter();
   }
   right() {
      for (const i of this.notselected_highlight) {
         this.selected.push(i);
         this.selected_highlight.push(i);
         // const index = this.notselected.indexOf(i);
         const index = this.indexOf(this.notselected, i);
         if (index >= 0)
            this.notselected.splice(index, 1);
      }
      this.notselected_highlight.length = 0;
      this.applyFilter();
   }
   rightAll() {
      for (const i of this.notselected)
         this.selected.push(i);
      this.notselected.length = 0;
      this.applyFilter();
   }
   moveRight(i: Pair<any>) {
      // let index = this.notselected.indexOf(i);
      let index = this.indexOf(this.notselected, i);
      this.notselected.splice(index, 1);
      this.selected.push(i);

      // index = this.notselected_highlight.indexOf(i);
      index = this.indexOf(this.notselected_highlight, i);
      if (index >= 0) {
         this.notselected_highlight.splice(index, 1);
         this.selected_highlight.push(i);
      }
      this.applyFilter();
   }
   moveLeft(i: Pair<any>) {
      // let index = this.selected.indexOf(i);
      let index = this.indexOf(this.selected, i);
      this.selected.splice(index, 1);
      this.notselected.push(i);

      // index = this.selected_highlight.indexOf(i);
      index = this.indexOf(this.selected_highlight, i);
      if (index >= 0) {
         this.selected_highlight.splice(index, 1);
         this.notselected_highlight.push(i);
      }
      this.applyFilter();
   }

   highlightNotSelected(i: Pair<any>) {
      // const index = this.notselected_highlight.indexOf(i);
      const index = this.indexOf(this.notselected_highlight, i);
      if (index < 0) {
         this.notselected_highlight.push(i);
      } else {
         this.notselected_highlight.splice(index, 1);
      }
   }

   highlightSelected(i: Pair<any>) {
      // const index = this.selected_highlight.indexOf(i);
      const index = this.indexOf(this.selected_highlight, i);
      if (index < 0) {
         this.selected_highlight.push(i);
      } else {
         this.selected_highlight.splice(index, 1);
      }

   }
}
