<!-- @format -->

<mat-toolbar style="display: flex">
  <!-- <fa-icon icon="shopping-bag" class="headerIcon"></fa-icon> -->
  <span>Spaces</span>

  <div class="flex-auto"></div>
</mat-toolbar>
<div class="ctlSearchBar">
  <div class="searchField">
    <fa-icon matPrefix icon="search" class="searchIcon"></fa-icon>
    <input
      matInput
      (keyup)="applyFilter($any($event.target).value)"
      placeholder="{{ _common.search }}"
      #_search
    />
    <fa-icon
      icon="times"
      matSuffix
      (click)="clearFilter(_search)"
      class="clearSearch"
    ></fa-icon>
  </div>

  <div class="app-filter-bar">
    <div>
      <fa-icon icon="sliders-h"></fa-icon>
    </div>
    <div class="content">
      <!-- <app-filter-multi
          [filter]="status"
          #_status
          (change)="refresh()"
          [values]="[
            statusList.published,
            statusList.draft,
            statusList.expired,
            statusList.scheduled
          ]"
          [common]="_common.pricingRuleStatus"
          >{{ _common.Status }}</app-filter-multi
        > -->
    </div>
    <div (click)="filterObject.openFilter()" class="openFilter">
      <fa-icon icon="ellipsis-h"></fa-icon>
    </div>
  </div>
  <div class="app-btn btn-icon" (click)="refresh()">
    <fa-icon icon="sync"></fa-icon>
  </div>

  <span class="spacer"></span>
  <span> </span>
</div>

<app-scroll-container
  [more]="hasMore()"
  (scrolled)="handleScroll($event)"
  class="max-scroll card-shadow"
  #scroll
>
  <ng-container *ngFor="let o of objects.data">
    <div [routerLink]="[session.path('/locker/' + o.id)]">
      <img [src]="lockerImage(o, o.images[0])" style="width: 80px" />
      {{ o.name }}
    </div>
  </ng-container>
</app-scroll-container>

<div
  *ngIf="filterObject.showFilter"
  class="ctlFilter listFilter"
  (clickOutsidePopup)="filterObject.closeFilter()"
>
  <div class="header">
    <div class="title">
      <fa-icon icon="sliders-h" style="margin-right: 5px"></fa-icon>
      {{ _common.filters }}
    </div>
    <div class="spacer"></div>
    <div
      (click)="filterObject.closeFilter()"
      style="font-size: 1.5em; cursor: pointer"
    >
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <div class="body"></div>
  <div class="buttons">
    <div (click)="filterObject.clear(); refresh()" class="clear_button">
      {{ _common.ClearAll }}
    </div>
  </div>
</div>
