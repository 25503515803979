import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { SessionService } from '../session.service';
import { MyWishlistListComponent } from './my.wishlist.list.component';

@Component({
  selector: 'app-my-wishlist',
  standalone: true,
  imports: [CommonModule, MyWishlistListComponent],
  templateUrl: './my.wishlist.page.html',
})
export class MyWishlistPage {
  @HostBinding('class') css = 'max-scroll';
  constructor(private session: SessionService) { }
  ngOnInit() { }; _common = _common
};
