import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostBinding } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Observable } from 'rxjs';

import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { VizFormModule } from '../common/components/form/form.module';
import { ScrollContainerComponent } from '../common/components/scroll.container/scroll.container.component';
import { FilterMultiComponent, FilterObject } from '../common/filter';
import {
  ListOption,
  ScrollableListComponentBase,
} from '../common/list.component.base';
import { CompareWithMixin, WebUtils } from '../common/utils';
import {
  AccessUtils,
  TLocation,
  TOrganization,
  TSpace,
  TSpaceType,
} from '../core/entities';
import { SitePathMixin } from '../core/site.path.utils';
import { SessionCache } from '../session.cache';
import { SessionService } from '../session.service';
import { SiteFormatMixin } from '../thinkdesks/site.format.mixin';
import { SpaceCard } from './space.card';

class _Base extends ScrollableListComponentBase<TSpace> {}

@Component({
  selector: 'app-space-search-list',
  templateUrl: './space.search.list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    FilterMultiComponent,
    ScrollContainerComponent,
    FontAwesomeModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatToolbarModule,
    SpaceCard,
    VizFormModule,
    FormsModule,
    FilterMultiComponent,
  ],
})
export class SpaceSearchListComponent extends CompareWithMixin(
  SitePathMixin(SiteFormatMixin(_Base))
) {
  _common = _common;
  @HostBinding('class') css = 'max-height';

  filterObject = new FilterObject();

  // district = new FilterMulti();

  displayedColumns: string[] = ['name', 'organization', 'location', 'controls'];
  ALL = { id: 0 } as any;

  area: string = '';
  district: string = '';
  organization: TOrganization = this.ALL;
  location: TLocation = this.ALL;
  type: TSpaceType = this.ALL;

  organizations: TOrganization[] = [];
  locations: TLocation[] = [];
  types: TSpaceType[] = [];

  allCapacity = { min: 0, max: 0 };
  capacities = [{ min: 1, max: 5 }, { min: 6, max: 10 }, { min: 11 }];
  capacity: any = this.allCapacity;
  clear() {
    (this.type = this.ALL), (this.location = this.ALL);
    this.organization = this.ALL;
    this.area = '';
    this.district = '';
    this.capacity = this.allCapacity;
    this.filterObject.clear();
    this.refresh();
  }
  constructor(
    override http: HttpClient,
    override elRef: ElementRef,
    private dialog: MatDialog,
    override snackBar: MatSnackBar,
    private cache: SessionCache,
    private session: SessionService,
    private route: ActivatedRoute
  ) {
    super(http, snackBar, elRef);
    // this.pageSort = 'seq';

    // this.pageSortDir = 'asc';

    // this.filterObject.add('organizations', this.forganization);
    // this.filterObject.add('district', this.district);
    this.cache.loadSpaceTypes((types) => {
      this.types = types;
    });
    this.cache.loadOrganization(this.session, (list) => {
      this.organizations = list;
      this.updateLocations();
    });
  }
  updateArea() {
    this.district = '';
    this.refresh();
  }
  updateOrganization() {
    this.updateLocations();
    this.refresh();
  }
  updateLocations() {
    let o = this.organization;
    if (o?.id) {
      this.locations = o.locations ?? [];
    } else {
      this.locations = [];
      for (let oo of this.organizations) {
        this.locations.push(...(oo.locations ?? []));
      }
    }
    let l = this.location;
    if (!this.locations.includes(l)) {
      this.location = this.ALL;
    }

  }

  override createRequest(options: ListOption): Observable<Object> {
    let query = {
      organization: WebUtils.objId(this.organization),
      location: WebUtils.objId(this.location),
      type: WebUtils.objId(this.type),
      area: this.area != '' ? this.area : null,
      district: this.district != '' ? this.district : null,
      capacityMin: this.capacity?.min,
      capacityMax: this.capacity?.max,
    } as any;
    Object.assign(query, this.filterObject.query());

    const url = this._listModifyUrl(`/api/public/space/list`, {}, options);
    return this.http.post(url, query);
  }
  override ngOnInit() {
    this.filter = this.route.snapshot.queryParamMap.get('search') ?? '';
    super.ngOnInit();
    // this.filterObject.add('type', this.type);
    // this.filterObject.add('status', this.status);
  }
  override listFixList(list: TSpace[]) {
    for (let o of list) AccessUtils.fixSpace(o);
  }

  override selectObject(o: TSpace) {}

  test() {
    console.log('test');
  }
}
