import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { CalendarComponent, CalendarDate, CalendarEvent } from './calendar.component';
import { MonthSelectorComponent } from '../month.selector/month.selector.component';

let _dateCur = moment();


@Component({
    selector: 'app-three-calendar',
    styleUrls: ['./three.calendar.component.scss'],
    templateUrl: './three.calendar.component.html',
    standalone: true,
    imports: [MonthSelectorComponent, CalendarComponent],
})
export class ThreeCalendarComponent implements OnInit {

   _common = _common;
   dateCur = _dateCur;
   datePrev: moment.Moment;
   dateNext: moment.Moment;

   @ViewChild(CalendarComponent, { static: true })
   private calCur: CalendarComponent;

   @ViewChild(CalendarComponent, { static: true })
   private calNext: CalendarComponent;

   @ViewChild(CalendarComponent, { static: true })
   private calPrev: CalendarComponent;

   display = '';

   @Input() events: CalendarEvent[] = [];
   @Output() selectedDate = new EventEmitter<CalendarDate>();

   constructor() { }

   ngOnInit(): void {
      this.refreshDates();
   }
   selectDate(date: CalendarDate) {
      this.selectedDate.emit(date);
   }
   refreshDates(newDate?: moment.Moment) {
      if (newDate)
         this.dateCur = newDate;
      const d = this.dateCur;

      this.dateCur = d.clone(); // enforce CD
      this.datePrev = d.clone().add(-1, 'month');
      this.dateNext = d.clone().add(1, 'month');

      _dateCur = this.dateCur.clone();

      this.display = this.dateCur.format('YYYY MMM');
   }
   // goPrevMonth() {
   //    this.dateCur.add(-1, 'month');
   //    this.refreshDates();
   // }
   // goNextMonth() {
   //    this.dateCur.add(1, 'month');
   //    this.refreshDates();
   // }

   // goPrevYear() {
   //    this.dateCur.add(-1, 'year');
   //    this.refreshDates();
   // }
   // goNextYear() {
   //    this.dateCur.add(1, 'year');
   //    this.refreshDates();
   // }


}
