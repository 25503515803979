<!-- @format -->

<div class="sectionHeader" (click)="filterObject.toggleFilter(id, $event)">
  <div class="spacer">
    <ng-content></ng-content>
  </div>
  <div>
    <fa-icon [icon]="faChevronDown" *ngIf="!filterObject.section[id]"></fa-icon>
    <fa-icon [icon]="faChevronUp" *ngIf="filterObject.section[id]"></fa-icon>
  </div>
</div>
<div class="sectionBody" *ngIf="filterObject.section[id]">
  <div>
    <div>
      <mat-radio-group [(ngModel)]="filter.range.type" (change)="refresh()">
        <div mat-menu-item>
          <mat-radio-button [value]="types.day14">{{
            _common.filterObject.day14
          }}</mat-radio-button>
        </div>
        <div mat-menu-item>
          <mat-radio-button [value]="types.day7">{{
            _common.filterObject.day7
          }}</mat-radio-button>
        </div>
        <div mat-menu-item>
          <mat-radio-button [value]="types.today">{{
            _common.filterObject.today
          }}</mat-radio-button>
        </div>
        <div mat-menu-item>
          <mat-radio-button [value]="types.all">{{
            _common.filterObject.all_time
          }}</mat-radio-button>
        </div>
        <div mat-menu-item>
          <mat-radio-button [value]="types.custom">{{
            _common.filterObject.custom_time
          }}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div *ngIf="filter.range.type == types.custom" class="p-2">
      <app-form-field class="w-full">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [(ngModel)]="filter.range.start" />
          <input
            matEndDate
            [(ngModel)]="filter.range.end"
            (dateChange)="refresh()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle appSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          (opened)="pickerOpen(true)"
          (closed)="pickerOpen(false)"
        >
        </mat-date-range-picker>
      </app-form-field>
    </div>
  </div>
</div>
