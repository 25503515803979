<!-- @format -->

<div class="dialog-max cdialog" style="min-width: 300px">
  <div class="dialogHeader">
    <div class="spacer"></div>

    <div (click)="cancel()" class="closeButton">
      <fa-icon icon="times"></fa-icon>
    </div>
  </div>
  <mat-dialog-content class="max-height">
    <div class="dialogBody ctlCalendar calendarDialog">
      <!-- (click)="onEdit($event, item, i)" -->
      <div
        *ngFor="let i of item.items"
        class="item"
        [style.backgroundColor]="i.color"
      >
        <fa-icon *ngIf="i.icon" [icon]="i.icon"></fa-icon>
        <div>
          <div>{{ i.title }}</div>
          <div *ngFor="let t of i.titles">{{ t }}</div>
        </div>
        <div *ngIf="i.badge">{{ i.badge }}</div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="dialogBar">
    <div class="spacer"></div>
    <div>
      <button class="app-btn" color="primary" (click)="cancel()">Close</button>
    </div>
  </div>
</div>
