import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core";
import {
   ControlValueAccessor,
   NG_VALUE_ACCESSOR
} from "@angular/forms";

@Component({
    selector: "app-toggle",
    templateUrl: "./app.toggle.html",
    styleUrls: ["./app.toggle.scss"],
    // Step 1: copy paste this providers property
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppToggleComponent),
            multi: true
        }
    ],
    standalone: true
})
// Step 2: Add "implements ControlValueAccessor"
export class AppToggleComponent implements ControlValueAccessor, OnInit {


   @Input()
   onText = 'ON'
   @Input()
   offText = 'OFF'
   // Step 3: Copy paste this stuff here
   onChange: any = () => { };
   onTouch: any = () => { };



   @Output()
   change = new EventEmitter<boolean>();

   registerOnChange(fn: any): void {
      this.onChange = fn;
   }

   registerOnTouched(fn: any): void {
      this.onTouch = fn;
   }

   constructor() { }

   ngOnInit() { }

   // Step 4: Define what should happen in this component, if something changes outside
   checked: boolean = false;
   writeValue(checked: boolean) {
      this.checked = checked;
   }

   onModelChange(e: boolean) {
      // Step 5a: bind the changes to the local value
      this.checked = e;

      // Step 5b: Handle what should happen on the outside, if something changes on the inside
      this.onChange(e);
      this.change.next(e);
   }

}