<div>Book</div>
<ng-container *ngIf="object">
  <div [formGroup]="form">
    <div style="width: 300px">
      <mat-calendar [(selected)]="selected" (selectedChange)="dateUpdated()"></mat-calendar>
    </div>

    <div>
      <app-form-field>
        <app-label>Time</app-label>
        <mat-select formControlName="startTime" (selectionChange)="updateDuration()">
          <mat-option *ngFor="let o of hours" [value]="o" [disabled]="!o.active">
            {{o.name}}
          </mat-option>
        </mat-select>
      </app-form-field>
    </div>
    <div>
      <app-form-field>
        <app-label>Duration</app-label>
        <mat-select formControlName="duration">
          <mat-option *ngFor="let o of slots" [value]="o" [disabled]="!o.active">
            {{o.name}}
          </mat-option>
        </mat-select>
      </app-form-field>
    </div>
    <div>
      <div class="app-btn" (click)="proceed()">Book</div>
    </div>
  </div>
</ng-container>
